import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Toggle from '../components/common/Toggle';
import restaurantsAPI from '../api/restaurants';
import Checkbox from '../components/common/Checkbox';
import Input from '../components/common/Input';
import Info from '../components/common/Info';
import { updateRestaurantApi } from '../redux/reducers/restaurant/actions';
import { showConfirmPhoneModal } from '../redux/reducers/auth/actions';
import Button from '../components/common/Button';
import Select from '../components/common/Select';
import Header from '../components/Header';
import IikoPaymentsSync from '../components/IikoPaymentsSync';
import PosSystemSyncButton from '../components/PosSystemSyncButton';

const DEFAULT_POS_STATE = {
  is_pos_system_active: false,
  pos_systems: {},
};

const PosSystems = ({
  restaurant,
  updateRestaurantApi,
  showConfirmPhoneModal,
}) => {
  const history = useHistory();

  const [posSystemsData, setPosSystemsData] = useState(DEFAULT_POS_STATE);
  const [organizations, setOrganizations] = useState([]);
  const [errors, setErrors] = useState('');
  const [loading, setLoading] = useState(false);
  const [dataChanged, setDataChanged] = useState(false);
  const [iikoPayments, setIikoPayments] = useState([]);
  const { t } = useTranslation();

  const handleUpdateExtra = async () => {
    const { data } = await restaurantsAPI.get(restaurant.id);
    setPosSystemsData({
      ...posSystemsData,
      is_pos_system_active: data.is_pos_system_active,
      pos_systems: data.pos_systems,
    });
  };

  useEffect(() => {
    if (restaurant?.id) {
      handleUpdateExtra().catch((e) => {
        console.error(e);
        if (e.code === 403) {
          showConfirmPhoneModal();
        }
      });
    }
    // eslint-disable-next-line
    }, [restaurant, showConfirmPhoneModal]);


  useEffect(() => {
    if (restaurant?.id) {
      if (
        restaurant.pos_systems?.iiko &&
        restaurant.pos_systems?.iiko?.default_menu_id !== '' &&
        organizations.length === 0
      ) {
        setLoading(true);
        const { user_id, user_secret, api_url } = restaurant.pos_systems.iiko;
        restaurantsAPI
          .getIikoOrganizations({
            user_id,
            user_secret,
            api_url,
          })
          .then(({ data }) => {
            setLoading(false);
            setOrganizations(data);
          })
          .catch((e) => {
            console.error(e);
            setErrors(e);
            if (e.code === 403) {
              showConfirmPhoneModal();
            }
          });
      }
    }
  }, [organizations, posSystemsData, restaurant, showConfirmPhoneModal]);

  useEffect(() => {
    if (restaurant?.id) {
      restaurantsAPI
        .getIikoPayments(restaurant.id)
        .then(({ data }) => {
          setIikoPayments(data);
        })
        .catch((e) => {
          console.error(e);
        });
    }
    // eslint-disable-next-line
  }, [restaurant]);

  const handleToggleChange = async () => {
    setPosSystemsData({
      ...posSystemsData,
      is_pos_system_active: !posSystemsData.is_pos_system_active,
    });
  };

  const handlePosSystemTypesCheckboxChange = async (
    event,
    posSystemTypeName
  ) => {
    const { checked } = event.target;

    setPosSystemsData({
      ...posSystemsData,
      pos_systems: {
        ...posSystemsData.pos_systems,
        [posSystemTypeName]: {
          ...posSystemsData.pos_systems[posSystemTypeName],
          is_active: checked,
        },
      },
    });
  };

  const handleBackClick = () => {
    history.goBack();
  };

  const handlePosSystemChange = (field, value, posSystemTypeName) => {
    setDataChanged(true);
    setPosSystemsData({
      ...posSystemsData,
      pos_systems: {
        ...posSystemsData.pos_systems,
        [posSystemTypeName]: {
          ...posSystemsData.pos_systems[posSystemTypeName],
          [field]: value,
        },
      },
    });
  };

  const handleSaveClick = async () => {
    const { pos_systems, is_pos_system_active } = posSystemsData;
    setErrors({});
    setLoading(true);
    const serverData = {
      id: restaurant.id,
      is_pos_system_active,
      pos_systems: Object.entries(pos_systems).reduce(
        (acc, [typeName, field]) => {
          acc[typeName] = field;
          return acc;
        },
        {}
      ),
    };
    try {
      await updateRestaurantApi(serverData);
      setLoading(false);
      setDataChanged(false);
      // history.push(`/restaurant/${restaurant.slug}`);
    } catch (e) {
      setLoading(false);
      setErrors(e);
      console.error(e);
    }
  };

  const getOrganizations = async (posSystemTypeName) => {
    setErrors({});
    setLoading(true);
    try {
      const { user_id, user_secret, api_url } = posSystemsData.pos_systems[
        posSystemTypeName
      ];
      setLoading(false);
      const { data } = await restaurantsAPI.getIikoOrganizations({
        user_id,
        user_secret,
        api_url,
      });
      setOrganizations(data);
    } catch (e) {
      setLoading(false);
      setErrors(e);
      console.error(e);
    }
  };
  const onSelectChange = (field, posSystemTypeName, option) => {
    setDataChanged(true);
    setPosSystemsData((extraData) => ({
      ...extraData,
      pos_systems: {
        ...extraData.pos_systems,
        [posSystemTypeName]: {
          ...extraData.pos_systems[posSystemTypeName],
          [field]: option.id,
        },
      },
    }));
  };

  const iikoPaymentTypeChange = (field, option) => {
    setPosSystemsData((extraData) => ({
      ...extraData,
      pos_systems: {
        ...extraData.pos_systems,
        iiko: {
          ...extraData.pos_systems.iiko,
          payment_types: {
            ...extraData.pos_systems.iiko.payment_types,
            [field]: option,
          },
        },
      },
    }));
  };
  const iikoTerminalGroupChange = (field, option) => {
    setPosSystemsData((extraData) => ({
      ...extraData,
      pos_systems: {
        ...extraData.pos_systems,
        iiko: {
          ...extraData.pos_systems.iiko,
          terminal_groups: {
            ...extraData.pos_systems.iiko.terminal_groups,
            [field]: option,
          },
        },
      },
    }));
  };

  const syncIsActive = () =>
    restaurant?.pos_systems?.iiko?.default_menu_id !== '';
  const saveIsActive = () => {
    return (
      loading ||
      posSystemsData.pos_systems.iiko?.default_menu_id === '' ||
      posSystemsData.pos_systems.iiko?.organization_id === ''
    );
  };

  return (
    <div className="page settings">
      <Header
        title={t('pos_system.title')}
        onLeftIconClick={handleBackClick}
        leftIcon="back"
      />
      <div className="settings__block">
        <div
          className={`settings__block-top ${
            posSystemsData.is_pos_system_active ? 'payment' : ''
          }`}
        >
          <Toggle
            label={t('pos_system.toggle_label')}
            checked={posSystemsData.is_pos_system_active}
            onChange={() => handleToggleChange('is_pos_system_active')}
            className="settings__block-toggle "
          />
          <Info name="pos_systems" containerClassName="settings__block-info" />
        </div>

        {posSystemsData.is_pos_system_active &&
          Object.entries(posSystemsData.pos_systems).map(
            ([posSystemTypeName, posSystem]) => (
              <div className="settings__payment-type" key={posSystemTypeName}>
                <div className="settings__checkbox ">
                  <Checkbox
                    // posSystem.is_active
                    checked
                    onChange={(e) =>
                      handlePosSystemTypesCheckboxChange(e, posSystemTypeName)
                    }
                  />
                  <p className="settings__checkbox-text">{posSystemTypeName}</p>
                </div>
                <Input
                  required
                  placeholder={t('pos_system.login_placeholder')}
                  value={posSystem.user_id}
                  onChange={(value) =>
                    handlePosSystemChange('user_id', value, posSystemTypeName)
                  }
                  error={errors?.user_id}
                />
                {/* <Input */}
                {/*  placeholder="Пароль" */}
                {/*  value={posSystem.user_secret} */}
                {/*  onChange={(value) => */}
                {/*    handlePosSystemChange( */}
                {/*      'user_secret', */}
                {/*      value, */}
                {/*      posSystemTypeName */}
                {/*    ) */}
                {/*  } */}
                {/*  error={errors?.user_secret} */}
                {/* /> */}
                <Input
                  required
                  placeholder={t('pos_system.link_placeholder')}
                  value={posSystem.api_url}
                  onChange={(value) =>
                    handlePosSystemChange('api_url', value, posSystemTypeName)
                  }
                  error={errors?.api_url}
                />
                {errors.organizations && (
                  <p className="settings__error">{errors.organizations}</p>
                )}
                {organizations.length === 0 &&
                !syncIsActive(posSystemTypeName) ? (
                  <Button
                    onClick={() => getOrganizations(posSystemTypeName)}
                    disabled={
                      loading ||
                      posSystem.api_url === '' ||
                      posSystem.user_id === ''
                    }
                    className="settings__button"
                    secondary
                  >
                    {t('pos_system.get_organizations')}
                  </Button>
                ) : (
                  <>
                    <Select
                      label={t('pos_system.organization_placeholder')}
                      options={organizations}
                      valueId={posSystem.organization_id}
                      onSelect={(option) =>
                        onSelectChange(
                          'organization_id',
                          posSystemTypeName,
                          option
                        )
                      }
                    />
                    <Select
                      label={t('pos_system.menu_placeholder')}
                      valueId={posSystem.default_menu_id}
                      options={restaurant.menu}
                      onSelect={(option) =>
                        onSelectChange(
                          'default_menu_id',
                          posSystemTypeName,
                          option
                        )
                      }
                    />
                    {syncIsActive(posSystemTypeName) && !dataChanged ? (
                      <PosSystemSyncButton
                        syncMenuRequest={() =>
                          restaurantsAPI.syncMenu(restaurant.id)
                        }
                      />
                    ) : (
                      <>
                        <p className="settings__sync-tip-text">
                          {t('pos_system.sync_tip_text')}
                        </p>
                        {errors?.pos_systems && (
                          <p className="settings__error">
                            {t('pos_system.sync_error')}
                          </p>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            )
          )}
      </div>
      {syncIsActive() && posSystemsData.is_pos_system_active && (
        <IikoPaymentsSync
          iikoPayments={iikoPayments}
          iikoData={posSystemsData.pos_systems.iiko}
          iikoPaymentTypeChange={iikoPaymentTypeChange}
          iikoTerminalGroupChange={iikoTerminalGroupChange}
        />
      )}
      <Button
        onClick={handleSaveClick}
        className="settings__button"
        disabled={saveIsActive()}
      >
        {t('contacts.save')}
      </Button>
    </div>
  );
};

const mapStateToProps = (state) => ({
  restaurant: state.restaurant.restaurantData,
});

export default connect(mapStateToProps, {
  updateRestaurantApi,
  showConfirmPhoneModal,
})(PosSystems);
