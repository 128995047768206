import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Header from '../components/Header';
import Button from '../components/common/Button';
import restaurantsAPI from '../api/restaurants';
import Input from '../components/common/Input';
import Loader from '../components/Loader';
import { showConfirmPhoneModal } from '../redux/reducers/auth/actions';

const QRCode = ({ restaurant, isShowLoader, showConfirmPhoneModal }) => {
  const [tableCount, setTableCount] = useState(1);
  const [errors, setErrors] = useState({});
  const [showThxMessage, setShowThxMessage] = useState(false);
  const history = useHistory();
  const { slug } = useParams();
  const { t } = useTranslation();

  const handleGenerateCode = async () => {
    setErrors({});
    await restaurantsAPI
      .generateQRcode(restaurant.id, tableCount)
      .then(() => setShowThxMessage(true))
      .catch((e) => {
        console.error(e);
        if (e.code === 403) {
          showConfirmPhoneModal();
        }
        setErrors(e);
      });
  };

  if (isShowLoader) {
    return <Loader />;
  }

  return (
    <div className="page qr-code">
      <Header rightIcon="close" onRightIconClick={() => history.goBack()} />
      <div data-aos="fade-up" data-aos-delay="300" className="qr-code__content">
        <div className="qr-code__title">
          {!showThxMessage && t('qrcode.title')}
        </div>
        {showThxMessage ? (
          <div className="qr-code__thx-message">
            <p className="qr-code__text">{t('qrcode.thx-message')}</p>
            <Button
              className="qr-code__btn"
              onClick={() => history.push(`/restaurant/${slug}`)}
            >
              {t('qrcode.return')}
            </Button>
          </div>
        ) : (
          <>
            <Input
              className="qr-code__table-input"
              value={tableCount}
              type="number"
              onChange={setTableCount}
              error={errors.quantity}
            />

            <div>
              <p className="qr-code__table-text">{t('qrcode.message')}</p>

              <Button className="qr-code__btn" onClick={handleGenerateCode}>
                {t('qrcode.generate')}
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isShowLoader: state.restaurant.isLoading,
  restaurant: state.restaurant.restaurantData,
});

export default connect(mapStateToProps, { showConfirmPhoneModal })(QRCode);
