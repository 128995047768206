// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Header from '../../../components/Header';
import Button from '../../../components/common/Button';
import Input from '../../../components/common/Input';
import staffAPI from '../../../api/staff';
import Loader from '../../../components/Loader';

const StaffModify = ({ restaurant }) => {
  const [worker, setWorker] = useState({
    name: '',
    email: '',
    role: '',
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { slug } = useParams();
  const { workerId } = useParams();
  const { t } = useTranslation();
  const isEditing = Boolean(workerId);

  useEffect(() => {
    // Using an IIFE
    (async () => {
      if (isEditing) {
        try {
          setLoading(true);
          const { data } = await staffAPI.get(workerId);
          setWorker(data);
        } catch (e) {
          console.error(e);
        }
      }
      setLoading(false);
    })();
  }, [isEditing, workerId]);

  const handleSaveClick = (e) => {
    e.preventDefault();
    setErrors({});
    worker.restaurant = restaurant.id;
    staffAPI[isEditing ? 'update' : 'create'](worker)
      .then(() => history.push(`/restaurant/${slug}/control-center/staff`))
      .catch((e) => {
        setErrors(e);
      });
  };

  const handleFieldChange = (field, value) => {
    worker[field] = value;
    setWorker({ ...worker });
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="page worker-modify">
      <Header
        title={
          isEditing
            ? t('control_center.staff.edit')
            : t('control_center.staff.add')
        }
        rightIcon="close"
        onRightIconClick={() => history.goBack()}
      />
      <form
        data-aos="fade-up"
        data-aos-delay="400"
        className="worker-modify__body"
        onSubmit={handleSaveClick}
      >
        <Input
          required
          error={errors.name}
          value={worker.name}
          onChange={(value) => handleFieldChange('name', value)}
          placeholder={t('control_center.staff.name_placeholder')}
        />
        <Input
          name="email"
          type="email"
          required
          error={errors.email}
          value={worker.email}
          onChange={(value) => handleFieldChange('email', value)}
          placeholder={t('control_center.staff.email_placeholder')}
        />
        <Input
          error={errors.role}
          value={worker.role}
          onChange={(value) => handleFieldChange('role', value)}
          placeholder={t('control_center.staff.role_placeholder')}
        />
        <p className="worker-modify__email-text">
          {t('control_center.staff.add_tip')}
        </p>
        <Button
          disabled={worker.name.length === 0}
          className="worker-modify__save-btn"
          type="submit"
        >
          {isEditing
            ? t('control_center.staff.save')
            : t('control_center.staff.add')}
        </Button>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  restaurant: state.restaurant.restaurantData,
});

export default connect(mapStateToProps)(StaffModify);
