import React from 'react';
import { connect } from 'react-redux';
import { show } from 'redux-modal';
import RestaurantMenuProduct from '../../components/RestaurantMenuProduct';
import Counter from '../../components/common/Counter';
import CategoriesLinksList from './CategoriesLinksList';
import {
  addProductWithOptionsApi,
  decrementLine,
  fetchCart,
  incrementLine,
  incrementProduct,
} from '../../redux/reducers/cart/actions';
import UserControls from '../../components/UserControls';
import Icon from '../../components/common/Icon';
import { MODAL_NAME as CART_PRODUCT_OPTIONS_MODAL } from '../../components/modals/CartProductOptions';
import { isShowCheckoutControls } from '../../redux/reducers/restaurant/selectors';
import { isMobile } from '../../redux/reducers/ui/selectors';

const UserRestaurantMenu = ({
  menu,
  addProduct,
  addProductWithOptions,
  show,
  addLine,
  restaurant,
  removeLine,
  cartProducts,
  isShowCheckoutControls,
  isCheckInByTable,
  isMobile,
}) => {
  const categories = menu.categories.filter(
    (category) =>
      category.is_active &&
      category.products.filter((product) => product.is_active).length > 0
  );

  const getProductCount = (product) => {
    const cartProduct = cartProducts.find(
      (item) => item.product.id === product.id
    );
    if (cartProduct) {
      return cartProduct.quantity;
    }
    return 0;
  };

  const getCartLineByProductId = (productId) => {
    return cartProducts.find((item) => item.product.id === productId);
  };

  const handleIncrementProduct = (product) => {
    const lineCartProduct = getCartLineByProductId(product.id);
    if (lineCartProduct) {
      addLine(lineCartProduct.line_id);
    } else {
      addProduct(product.id);
    }
  };

  const handleDecrementProduct = (product) => {
    const lineCartProduct = getCartLineByProductId(product.id);
    if (lineCartProduct) {
      removeLine(lineCartProduct.line_id);
    }
  };

  const handleAddProductWithOptions = async (product) => {
    show(CART_PRODUCT_OPTIONS_MODAL, {
      product,
      onProductAdd: addProductWithOptions,
    });
  };

  return (
    <div className="restaurant-menu__content restaurant-menu-user">
      <h2 className="restaurant-menu__menu-name restaurant-menu-user__menu-name">
        {menu.name}
      </h2>
      <CategoriesLinksList categories={categories} />
      <div className="restaurant-menu__category-list">
        {categories.map(
          (category, index) =>
            category.is_active &&
            category.products.length && (
              <div
                key={category.id}
                data-category={`${category.name}-${index}`}
                className="restaurant-menu__category-products-list"
              >
                <h2
                  className={`restaurant-menu-user__category-name ${
                    index === 0 &&
                    'restaurant-menu-user__category-name_not-displayed'
                  }`}
                >
                  {category.name}
                </h2>
                {category.products.map(
                  (product) =>
                    product.is_active && (
                      <RestaurantMenuProduct
                        key={product.id}
                        product={product}
                        isMobile={isMobile}
                        isUser
                      >
                        {isShowCheckoutControls &&
                          product.price !== 0 &&
                          product.price !== '' &&
                          (product.options.length > 0 ||
                          product.option_group_settings.length > 0 ? (
                            <div className="restaurant-menu-user__icons">
                              {getCartLineByProductId(product.id) && (
                                <Icon
                                  className="mr-2"
                                  withHover={false}
                                  name="cart"
                                />
                              )}
                              <Icon
                                onClick={(e) =>
                                  handleAddProductWithOptions(product, e)
                                }
                                name="plus"
                              />
                            </div>
                          ) : (
                            <Counter
                              value={getProductCount(product)}
                              handleIncrement={() =>
                                handleIncrementProduct(product)
                              }
                              handleDecrement={() =>
                                handleDecrementProduct(product)
                              }
                            />
                          ))}
                      </RestaurantMenuProduct>
                    )
                )}
              </div>
            )
        )}
      </div>
      <UserControls
        isShowWaiter={restaurant.is_call_waiter}
        isShowFeedBack={restaurant.is_feedback}
        isDineIn={isCheckInByTable}
        isShowCheckoutControls={isShowCheckoutControls}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  cartProducts: state.cart.products,
  restaurant: state.restaurant.restaurantData,
  isCheckInByTable: state.restaurant.isCheckInByTable,
  isShowCheckoutControls: isShowCheckoutControls(state),
  isMobile: isMobile(state),
});

export default connect(mapStateToProps, {
  fetchCart,
  show,
  addProduct: incrementProduct,
  addProductWithOptions: addProductWithOptionsApi,
  addLine: incrementLine,
  removeLine: decrementLine,
})(UserRestaurantMenu);
