import React, { createRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import autosize from 'autosize';
import { connect } from 'react-redux';
import TextareaAutosize from 'react-textarea-autosize';
import { isMobile } from '../../redux/reducers/ui/selectors';

const TextArea = ({ dispatch, isMobile, className, label, ...props }) => {
  const textAreaRef = createRef();

  useEffect(() => {
    if (isMobile) {
      autosize(textAreaRef.current);
    }
  });

  const onChange = ({ target }) => {
    props.onChange(target.value);
  };

  return (
    <div
      className={`textarea ${props.error ? 'input__error' : ''} ${
        className || ''
      }`}
    >
      {label && <label className="textarea__label">{label}</label>}
      <TextareaAutosize
        ref={textAreaRef}
        className="textarea__component"
        {...props}
        onChange={onChange}
      />
      {props.error && <p className="textarea__error-message">{props.error}</p>}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isMobile: isMobile(state),
});

export default connect(mapStateToProps, null)(TextArea);

TextArea.PropsTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  error: PropTypes.oneOf([PropTypes.string, PropTypes.array]),
  className: PropTypes.string,
  required: PropTypes.bool,
};

TextArea.defaultProps = {
  onChange: () => {},
  value: '',
  error: null,
  className: null,
  required: false,
};
