import React from 'react';
import PropTypes from 'prop-types';
import { srcSet } from '../../utils/files';

const ImageHeader = ({ className, image, icon, text, onClick }) => {
  return (
    <div
      data-aos-delay="250"
      data-aos="fade-up"
      className={`image-header ${className || ''}`}
      onClick={onClick}
    >
      {image ? (
        <div className="image-header__img-container">
          <img
            className="image-header__img"
            src={typeof image === 'string' ? image : image[400]}
            srcSet={srcSet(image)}
            alt="logo"
          />
        </div>
      ) : (
        <h4 className="image-header__text">{text}</h4>
      )}
      {icon && <div className="image-header__icon">{icon}</div>}
    </div>
  );
};

export default ImageHeader;

ImageHeader.defaultProps = {
  className: '',
  image: '',
  icon: '',
  text: '',
};

ImageHeader.propTypes = {
  className: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  icon: PropTypes.node,
  text: PropTypes.string,
};
