import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Toggle from '../../components/common/Toggle';
import TextArea from '../../components/common/TextArea';
import Checkbox from '../../components/common/Checkbox';
import Input from '../../components/common/Input';
import Info from '../../components/common/Info';
import { updateRestaurantApi } from '../../redux/reducers/restaurant/actions';
import { showConfirmPhoneModal } from '../../redux/reducers/auth/actions';

const Settings = ({
  settingsData,
  setSettingsData,
  errors,
  setErrors,
  controlCenterDisabled,
}) => {
  const { t } = useTranslation();
  const handleNoteTextChange = (value) => {
    setSettingsData({
      ...settingsData,
      dine_in: {
        ...settingsData.dine_in,
        note_text: value,
      },
    });
  };

  const handleToggleChange = async (property) => {
    setSettingsData({
      ...settingsData,
      [property]: !settingsData[property],
    });
  };

  const handlePaymentTypesCheckboxChange = async (event, paymentTypeName) => {
    setErrors({});
    const { checked } = event.target;

    setSettingsData({
      ...settingsData,
      payment_types: {
        ...settingsData.payment_types,
        [paymentTypeName]: {
          ...settingsData.payment_types[paymentTypeName],
          is_active: checked,
        },
      },
    });
  };

  const handleProviderChange = (
    field,
    value,
    paymentTypeName,
    providerName
  ) => {
    setErrors({});
    setSettingsData({
      ...settingsData,
      payment_types: {
        ...settingsData.payment_types,
        [paymentTypeName]: {
          ...settingsData.payment_types[paymentTypeName],
          providers: {
            ...settingsData.payment_types[paymentTypeName].providers,
            [providerName]: {
              ...settingsData.payment_types[paymentTypeName].providers[
                providerName
              ],
              [field]: value,
            },
          },
        },
      },
    });
  };

  return (
    <div className="page settings">
      <div className="settings__block">
        <div className="settings__block-top">
          <Toggle
            label={t('control_center.settings.dine_in')}
            checked={settingsData.is_dine_in}
            onChange={() => handleToggleChange('is_dine_in')}
            className="settings__block-toggle"
            disabled={controlCenterDisabled}
          />
          <Info name="order" containerClassName="settings__block-info" />
        </div>

        {settingsData.is_dine_in && (
          <TextArea
            onChange={handleNoteTextChange}
            label={t('control_center.settings.dine_in_label')}
            value={settingsData.dine_in.note_text}
            className="settings__block-order"
          />
        )}
      </div>

      <div className="settings__block">
        <div className="settings__block-top">
          <Toggle
            label={t('control_center.settings.call_waiter_label')}
            checked={settingsData.is_call_waiter}
            onChange={() => handleToggleChange('is_call_waiter')}
            className="settings__block-toggle"
            disabled={controlCenterDisabled}
          />
          <Info name="worker" containerClassName="settings__block-info" />
        </div>
      </div>
      <div className="settings__block">
        <Toggle
          label={t('control_center.settings.phone_validation_label')}
          checked={settingsData.is_order_phone_validation}
          onChange={() => handleToggleChange('is_order_phone_validation')}
          disabled={controlCenterDisabled}
        />
      </div>

      <div className="settings__block">
        <div className="settings__block-top">
          <Toggle
            label={t('control_center.settings.feedback_label')}
            checked={settingsData.is_feedback}
            onChange={() => handleToggleChange('is_feedback')}
            className="settings__block-toggle"
            disabled={controlCenterDisabled}
          />
          <Info name="review" containerClassName="settings__block-info" />
        </div>
      </div>

      <div className="settings__block">
        <div
          className={`settings__block-top ${
            settingsData.is_payment ? 'payment' : ''
          }`}
        >
          <Toggle
            label={t('control_center.settings.payment_label')}
            checked={settingsData.is_payment}
            onChange={() => handleToggleChange('is_payment')}
            className="settings__block-toggle "
            disabled={controlCenterDisabled}
          />
          <Info name="payment" containerClassName="settings__block-info" />
        </div>

        {settingsData.is_payment &&
          Object.entries(settingsData.payment_types).map(
            ([paymentTypeName, paymentType]) => (
              <div className="settings__payment-type" key={paymentTypeName}>
                <div className="settings__checkbox ">
                  <Checkbox
                    checked={paymentType.is_active}
                    onChange={(e) =>
                      handlePaymentTypesCheckboxChange(e, paymentTypeName)
                    }
                  />
                  <p className="settings__checkbox-text">{paymentType.name}</p>
                </div>

                {paymentType.providers &&
                  paymentType.is_active &&
                  Object.entries(paymentType.providers).map(
                    ([providerName, provider]) => (
                      <div
                        key={providerName}
                        className="settings__payment-type-provider"
                      >
                        <p className="settings__payment-type-provider-name">
                          {provider.name}
                        </p>
                        <Input
                          placeholder={t(
                            'control_center.settings.merchant_id_placeholder'
                          )} // TODO get from provider
                          name={provider.name}
                          value={provider.merchant_id}
                          onChange={(value) =>
                            handleProviderChange(
                              'merchant_id',
                              value,
                              paymentTypeName,
                              providerName
                            )
                          }
                          error={
                            errors.payment_providers
                              ? errors.payment_providers[
                                  provider.name.toLowerCase()
                                ]?.merchant_id
                              : ''
                          }
                        />
                        <Input
                          placeholder={t(
                            'control_center.settings.login_placeholder'
                          )}
                          name={provider.login}
                          value={provider.login}
                          onChange={(value) =>
                            handleProviderChange(
                              'login',
                              value,
                              paymentTypeName,
                              providerName
                            )
                          }
                          error={
                            errors.payment_providers
                              ? errors.payment_providers[
                                  provider.name.toLowerCase()
                                ]?.login
                              : ''
                          }
                        />
                        <Input
                          placeholder={t(
                            'control_center.settings.password_id_placeholder'
                          )}
                          name={provider.password}
                          value={provider.password}
                          type="password"
                          onChange={(value) =>
                            handleProviderChange(
                              'password',
                              value,
                              paymentTypeName,
                              providerName
                            )
                          }
                          error={
                            errors.payment_providers
                              ? errors.payment_providers[
                                  provider.name.toLowerCase()
                                ]?.password
                              : ''
                          }
                        />
                        <Toggle
                          label={t(
                            'control_center.settings.use_additional_merchant'
                          )}
                          checked={provider.secondary_merchant_enabled}
                          className="settings__payment-type-secondary-merchant"
                          onChange={() =>
                            handleProviderChange(
                              'secondary_merchant_enabled',
                              !provider.secondary_merchant_enabled,
                              paymentTypeName,
                              providerName
                            )
                          }
                        />
                        {provider.secondary_merchant_enabled && (
                          <>
                            <Input
                              placeholder={t(
                                'control_center.settings.merchant_id_placeholder'
                              )} // TODO get from provider
                              name={provider.name}
                              value={provider.secondary_merchant_id}
                              onChange={(value) =>
                                handleProviderChange(
                                  'secondary_merchant_id',
                                  value,
                                  paymentTypeName,
                                  providerName
                                )
                              }
                              error={
                                errors.payment_providers
                                  ? errors.payment_providers[
                                      provider.name.toLowerCase()
                                    ]?.secondary_merchant_id
                                  : ''
                              }
                            />
                            <Input
                              placeholder={t(
                                'control_center.settings.login_placeholder'
                              )}
                              name={provider.secondary_login}
                              value={provider.secondary_login}
                              onChange={(value) =>
                                handleProviderChange(
                                  'secondary_login',
                                  value,
                                  paymentTypeName,
                                  providerName
                                )
                              }
                              error={
                                errors.payment_providers
                                  ? errors.payment_providers[
                                      provider.name.toLowerCase()
                                    ]?.secondary_login
                                  : ''
                              }
                            />
                            <Input
                              placeholder={t(
                                'control_center.settings.password_id_placeholder'
                              )}
                              name={provider.secondary_password}
                              value={provider.secondary_password}
                              type="password"
                              onChange={(value) =>
                                handleProviderChange(
                                  'secondary_password',
                                  value,
                                  paymentTypeName,
                                  providerName
                                )
                              }
                              error={
                                errors.payment_providers
                                  ? errors.payment_providers[
                                      provider.name.toLowerCase()
                                    ]?.secondary_password
                                  : ''
                              }
                            />
                          </>
                        )}
                        <a
                          className="settings__payment-type-provider-link"
                          href={provider.help_url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {provider.help_text}
                        </a>
                      </div>
                    )
                  )}
              </div>
            )
          )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  restaurant: state.restaurant.restaurantData,
});

export default connect(mapStateToProps, {
  updateRestaurantApi,
  showConfirmPhoneModal,
})(Settings);
