import React, { useEffect, useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from '../components/common/Button';
import Header from '../components/Header';
import { showConfirmPhoneModal } from '../redux/reducers/auth/actions';
import restaurantsAPI from '../api/restaurants';
import useCollapse from '../hooks/useCollapsed';
import Toggle from '../components/common/Toggle';
import Info from '../components/common/Info';
import Input from '../components/common/Input';
import Icon from '../components/common/Icon';

const DEFAULT_SETTINGS = {
  required: false,
  min_amount: 0,
  max_amount: 0,
  product: '',
};
const OptionGroupSettings = ({ restaurant }) => {
  const history = useHistory();
  const [groupSettings, setGroupSettings] = useState(DEFAULT_SETTINGS);
  const [currentGroup, setCurrentGroup] = useState({});
  const { groupId, slug, productId, settingId } = useParams();
  const [errors, setErrors] = useState({});
  const { isOpened, toggleCollapse } = useCollapse(false);
  const { t } = useTranslation();

  const handleBlockClick = () => {
    toggleCollapse();
  };

  const handleBackClick = () => {
    history.goBack();
  };

  const currentLang = window.localStorage.getItem('language');

  const handleGetOptionGroup = async () => {
    const { data } = await restaurantsAPI.getOptionGroup(groupId, currentLang);
    setCurrentGroup(data);
  };

  const handleGetSettings = async () => {
    const { data } = await restaurantsAPI.getOptionGroupSettings(
      settingId,
      currentLang
    );
    setGroupSettings(data);
  };

  useEffect(() => {
    if (groupId) {
      handleGetOptionGroup().catch((e) => {
        console.error(e);
        if (e.code === 403) {
          showConfirmPhoneModal();
        }
      });
    }
    // eslint-disable-next-line
    }, [restaurant, showConfirmPhoneModal]);

  useEffect(() => {
    if (settingId) {
      handleGetSettings().catch((e) => {
        console.error(e);
        if (e.code === 403) {
          showConfirmPhoneModal();
        }
      });
    }
    // eslint-disable-next-line
    }, [restaurant, showConfirmPhoneModal]);

  const saveMethod = () => {
    if (settingId) {
      return restaurantsAPI.updateOptionGroupSettings(settingId, {
        ...groupSettings,
        group: currentGroup.id,
        product: productId,
      });
    }
    return restaurantsAPI.createOptionGroupSettings({
      ...groupSettings,
      group: currentGroup.id,
      product: productId,
    });
  };

  const handleSettingsSave = async () => {
    try {
      if (groupSettings.max_amount < groupSettings.min_amount)
        // eslint-disable-next-line no-throw-literal
        throw {
          amount: t('option_groups.error.max_less_then_min'),
        };
      if (
        groupSettings.required &&
        (groupSettings.max_amount === '0' || groupSettings.min_amount === '0')
      )
        // eslint-disable-next-line no-throw-literal
        throw {
          amount: t('option_groups.error.required_group_min'),
        };
      await saveMethod();
      history.push(`/restaurant/${slug}/product/${productId}`);
    } catch (e) {
      console.error(e);
      setErrors(e);
    }
  };

  const handleToggleClick = () => {
    const newMax =
      groupSettings.max_amount === 0 ? 1 : groupSettings.max_amount;
    const newMin =
      groupSettings.min_amount === 0 ? 1 : groupSettings.min_amount;
    setGroupSettings({
      ...groupSettings,
      required: !groupSettings.required,
      min_amount: newMin,
      max_amount: newMax,
    });
  };
  const handleInputChange = (field, value) => {
    let newValue = value;
    if (value.length > 1 && value[0] === '0')
      newValue = value.slice(1, value.length);
    setGroupSettings({
      ...groupSettings,
      [field]: newValue,
    });
  };
  return (
    <div className="page option-groups">
      <Header
        title={t('option_groups.title')}
        onLeftIconClick={handleBackClick}
        leftIcon="back"
      />
      <div
        data-aos="fade-up"
        data-aos-delay="400"
        className="option-groups__body"
      >
        <p className="option-groups__hint">
          {t('option_groups.settings_creation_hint')}
        </p>
        <div className="option-groups__content">
          <div className="option-group__name">
            <p>{currentGroup.name}</p>
            <Icon
              onClick={handleBlockClick}
              name={`${!isOpened ? 'topCircleArrow' : 'downCircleArrow'}`}
            />
            <Icon
              style={{ pointerEvents: !currentGroup.name ? 'none' : 'unset' }}
              name="pen"
              className="option-group__pen-icon"
              onClick={() =>
                history.push(
                  `/restaurant/${slug}/product/${productId}/option-groups/${groupId}`
                )
              }
            />
          </div>
        </div>
        <div
          className={`"option-group__options-list ${
            !isOpened
              ? 'option-group__options-list_show'
              : 'option-group__options-list_hide'
          }`}
        >
          {currentGroup.options?.map((option) => (
            <div className="option-group__option" key={option.id}>
              <p>{option.name}</p>
              <p>{`${option.price} ₴`}</p>
            </div>
          ))}
        </div>
        <div className="option-group__settings">
          <div className="option-group__settings-container">
            <Toggle
              label={t('option_groups.settings_required')}
              checked={groupSettings.required}
              onChange={handleToggleClick}
            />
            <Info name="required_group" />
          </div>
          <div className="option-group__settings-block">
            <div className="option-group__settings-block-title">
              {t('option_groups.amount_hint')} <Info name="options_amount" />
            </div>
            <div className="option-group__settings-input-container">
              <Input
                placeholder={t('option_groups.min_placeholder')}
                value={groupSettings.min_amount}
                onChange={(value) => handleInputChange('min_amount', value)}
                type="number"
                className="option-group__settings-input"
              />
              <Input
                placeholder={t('option_groups.max_placeholder')}
                value={groupSettings.max_amount}
                onChange={(value) => handleInputChange('max_amount', value)}
                type="number"
                className="option-group__settings-input"
              />
            </div>
            {errors?.amount && (
              <p className="input__error-message option-group__settings-error">
                {errors.amount}
              </p>
            )}
          </div>
        </div>

        {errors.non_field_errors && (
          <div className="error-box error-box_center">
            <p>{errors.non_field_errors}</p>
          </div>
        )}

        <Button
          className="option-groups__save-btn"
          onClick={handleSettingsSave}
        >
          {t('option_groups.save')}
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  restaurant: state.restaurant.restaurantData,
});

export default connect(mapStateToProps)(OptionGroupSettings);
