import { get } from '../utils/axios';

const settingsApi = {
  getSystemSettings: async () => {
    try {
      const response = await get(`/settings/`, null, true);
      return response.data;
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },
};

export default settingsApi;
