import { ADD_PRODUCT, REMOVE_PRODUCT, UPDATE_CART } from './types';

const initialState = {
  total: 0,
  products: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_CART:
      return {
        ...state,
        ...action.payload.cart,
      };
    case ADD_PRODUCT:
      return {
        ...state,
        products: [...state.products, action.payload.product],
      };
    case REMOVE_PRODUCT:
      return state;
    default:
      return state;
  }
}
