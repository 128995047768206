import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Icon from './common/Icon';

const ModifyMenuControls = ({ options }) => {
  const [showList, setShowList] = useState(false);

  const onOptionClick = (option) => {
    setShowList(false);
    option.onClick();
  };

  return (
    <div className="modify-menu-controls animate__animated animate__fadeInLeft ">
      <Icon
        onClick={() => setShowList(!showList)}
        name={showList ? 'close' : 'plus'}
      />
      <div
        className={`modify-menu-controls__list ${
          showList
            ? 'modify-menu-controls__list_show'
            : 'modify-menu-controls__list_hide'
        }`}
      >
        {options.map((option) => (
          <div
            key={option.name}
            className={`modify-menu-controls__list-item animate__animated ${
              showList ? 'animate__fadeInUp' : 'animate__fadeOutDown'
            }`}
            onClick={() => onOptionClick(option)}
          >
            {option.name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ModifyMenuControls;

ModifyMenuControls.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      onClick: PropTypes.func,
    })
  ),
};

ModifyMenuControls.defaultProps = {
  options: [
    {
      name: '',
      onClick: () => {},
    },
  ],
};
