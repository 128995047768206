import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { show } from 'redux-modal';
import Header from '../../components/Header';
import Button from '../../components/common/Button';
import Input from '../../components/common/Input';
import Loader from '../../components/Loader';
import Info from '../../components/common/Info';
import optionsAPI from '../../api/options';
import settingsApi from '../../api/settings';
import ContentLanguageSelector from '../../components/common/ContentLanguageSelector';
import useRestaurantControls from '../../hooks/useRestaurantControls';
import { setEditedProduct } from '../../redux/reducers/restaurant/actions';

const OptionModify = ({
  location,
  restaurant,
  show,
  editedProduct,
  setEditedProduct,
}) => {
  const [option, setOption] = useState({
    name: '',
    price: '',
    max_quantity: '',
  });
  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const isEditing = Boolean(location?.state?.id);
  const [languageOptions, setLanguages] = useState([]);
  const [currentLang, setCurrentLang] = useState(
    window.localStorage.getItem('language')
      ? window.localStorage.getItem('language')
      : 'en'
  );

  useEffect(() => {
    settingsApi.getSystemSettings().then((data) => {
      const options = data.languages
        .filter((language) => restaurant?.languages.includes(language.code))
        .map((language) => {
          return {
            label: language.full,
            value: language.short,
            id: language.code,
            onClick: () => {
              setCurrentLang(language.code);
              window.localStorage.setItem('language', language.code);
            },
          };
        });
      if (options.length > 0 && options.length < 2) {
        setCurrentLang(options[0].id);
        window.localStorage.setItem('language', options[0].id);
      }
      setLanguages(options);
    });
  }, [restaurant?.languages]);

  const { isShowControls } = useRestaurantControls(restaurant);

  useEffect(() => {
    if (location?.state?.id) {
      optionsAPI.get(location?.state?.id).then((response) => {
        setOption(response.data);
      });
    }
    // eslint-disable-next-line
  }, []);

  const handleNameChange = (value) => {
    setOption({
      ...option,
      [`${isEditing ? `name_${currentLang}` : 'name'}`]: value,
    });
  };

  const handlePriceChange = (value) => {
    if (value === '' || !Number.isNaN(Number(value))) {
      setErrors({ product: null });
      setOption({ ...option, price: value });
    } else {
      setErrors({ price: 'Значення має бути числом (з точкою)' });
    }
  };
  const handleMaxQuantityChange = (value) => {
    setOption({ ...option, max_quantity: value });
  };

  const saveClickHandler = (e) => {
    e.preventDefault();
    setErrors({});
    setLoading(true);
    const serverData = { restaurant: restaurant.id, id: option.id };
    if (isEditing) {
      serverData[`name_${currentLang}`] = option[`name_${currentLang}`];
    } else {
      serverData.name = option.name;
    }
    serverData.price = option.price;
    serverData.max_quantity = option.max_quantity;

    optionsAPI[isEditing ? 'patch' : 'create'](serverData)
      .then((data) => {
        if (!isEditing && editedProduct.id) {
          editedProduct.options.push(data.data);
          setEditedProduct({ ...editedProduct });
        }
        history.goBack();
      })
      .catch((e) => {
        console.error(e);
        setErrors(e);
        setLoading(false);
      });
  };

  if (loading || languageOptions.length < 1) {
    return <Loader />;
  }

  console.log(option);

  return (
    <div className="product page">
      <Header
        title={isEditing ? t('options.edit') : t('options.add_option')}
        rightIcon="close"
        onRightIconClick={() => history.goBack()}
      />
      <div data-aos="fade-up" data-aos-delay="400" className="product__content">
        {option?.external_name && (
          <p className="product__hint">{option?.external_name}</p>
        )}
        <div className="language-block language-block__options-block">
          <ContentLanguageSelector
            options={languageOptions}
            value={
              languageOptions.find((option) => option.id === currentLang).value
            }
            isShowControls={isShowControls}
            label="option_translation"
            show={show}
            use
          />
        </div>
        <form onSubmit={saveClickHandler} className="product__form">
          <Input
            multiline
            value={isEditing ? option[`name_${currentLang}`] : option.name}
            required
            name={t('options.name_placeholder')}
            placeholder={t('options.name_placeholder')}
            onChange={handleNameChange}
            error={errors.name}
          />
          <Input
            unit="₴"
            value={option.price}
            required
            type="number"
            placeholder={t('options.price_placeholder')}
            name="price"
            onChange={handlePriceChange}
            error={errors.price}
          />

          <div className="product__form__maxQuantity">
            <Input
              value={option.max_quantity}
              type="number"
              placeholder={t('options.max_amount_placeholder')}
              name="maxQuantity"
              onChange={handleMaxQuantityChange}
              error={errors.max_quantity}
            />
            <Info
              name="maxQuantity"
              containerClassName="product__form__maxQuantity-info"
            />
          </div>

          <div className="product__button-block">
            {errors.non_field_errors && (
              <div className="error-box error-box_center">
                <p>{errors.non_field_errors}</p>
              </div>
            )}
            {errors.error && (
              <div className="error-box error-box_center">
                <p>{errors.error}</p>
              </div>
            )}

            <Button
              disabled={option.name.length === 0 || option.price.length === 0}
              className="product__button"
              type="submit"
            >
              {t('options.add_option_button')}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isShowLoader: state.restaurant.isLoading,
  restaurant: state.restaurant.restaurantData,
  editedProduct: state.restaurant.editedProduct,
});

export default connect(mapStateToProps, { show, setEditedProduct })(
  OptionModify
);
