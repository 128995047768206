import React, { useEffect, useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from '../components/common/Button';
import Icon from '../components/common/Icon';
import Header from '../components/Header';
import { showConfirmPhoneModal } from '../redux/reducers/auth/actions';
import restaurantsAPI from '../api/restaurants';
import OptionGroup from '../components/OptionGroup';
import Input from '../components/common/Input';
import productAPI from '../api/product';

const OptionGroupsList = ({ restaurant }) => {
  const history = useHistory();
  const [optionGroups, setOptionGroups] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [filteredGroups, setFilteredGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState('');
  const { slug, productId } = useParams();
  const { t } = useTranslation();

  const handleBackClick = () => {
    history.goBack();
  };

  const currentLang = window.localStorage.getItem('language');

  const handleUpdateOptionGroups = async () => {
    const { data } = await restaurantsAPI.getRestaurantOptionGroups(
      restaurant.id,
      currentLang
    );
    if (productId) {
      const groupsInProduct = [];
      const response = await productAPI.get(productId);
      if (response.data.option_group_settings.length > 0) {
        response.data.option_group_settings.map((settings) =>
          groupsInProduct.push(settings.group.id)
        );
      }
      const filteredGroups = data.filter(
        (group) => !groupsInProduct.includes(group.id)
      );
      setOptionGroups(filteredGroups);
      setFilteredGroups(filteredGroups);
    } else {
      setOptionGroups(data);
      setFilteredGroups(data);
    }
  };
  // restaurantAPI.getRestaurantOptionGroups
  useEffect(() => {
    if (restaurant?.id) {
      handleUpdateOptionGroups().catch((e) => {
        console.error(e);
        if (e.code === 403) {
          showConfirmPhoneModal();
        }
      });
    }
    // eslint-disable-next-line
  }, [restaurant, showConfirmPhoneModal]);

  const handleSearch = (val) => {
    setSearchValue(val);
    const availableGroups = optionGroups.filter((group) =>
      group.name.toLowerCase().includes(val.toLowerCase())
    );
    setFilteredGroups(availableGroups);
  };
  const handleRadioButtonClick = (id) => {
    setSelectedGroup(id);
  };
  const handleDeleteClick = async (id) => {
    try {
      await restaurantsAPI.deleteRestaurantOptionGroup(id);
      await handleUpdateOptionGroups();
    } catch (e) {
      console.error(e);
    }
  };
  const handleEditClick = (id) => {
    history.push(`/restaurant/${slug}/option-groups/${id}`);
  };

  return (
    <div className="page option-groups">
      <Header
        title={t('option_groups.title')}
        onLeftIconClick={handleBackClick}
        leftIcon="back"
      />
      <div
        data-aos="fade-up"
        data-aos-delay="400"
        className="option-groups__body"
      >
        <div className="option-groups__content">
          <Input
            leftIcon="search"
            placeholder={t('option_groups.search_placeholder')}
            onChange={handleSearch}
            value={searchValue}
          />
        </div>

        <div
          className="option-groups__link"
          onClick={() =>
            history.push(`/restaurant/${slug}/option-groups/create`)
          }
        >
          <Icon name="plus" />
          {t('option_groups.add')}
        </div>

        <form>
          <div className="option-groups__list">
            {filteredGroups.map((group) => (
              <OptionGroup
                key={group.id}
                group={group}
                handleRadioButtonClick={handleRadioButtonClick}
                selectedGroup={selectedGroup}
                productId={productId}
                handleEditClick={handleEditClick}
                handleDeleteClick={handleDeleteClick}
              />
            ))}
          </div>
        </form>

        <div
          className="option-groups__link"
          onClick={() =>
            history.push(
              `/restaurant/${slug}/${
                productId ? `product/${productId}/` : ''
              }option-groups/create`
            )
          }
        >
          <Icon name="plus" />
          {t('option_groups.add')}
        </div>

        {productId && (
          <Button
            className="option-groups__save-btn"
            type="submit"
            onClick={() =>
              history.push(
                `/restaurant/${slug}/product/${productId}/option-groups/${selectedGroup}/settings`
              )
            }
          >
            {t('option_groups.select')}
          </Button>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  restaurant: state.restaurant.restaurantData,
});

export default connect(mapStateToProps)(OptionGroupsList);
