import React from 'react';
import PropTypes from 'prop-types';
import Icon from './common/Icon';
import { srcSet } from '../utils/files';

const CartOptionsHeader = ({
  className,
  product,
  leftIcon,
  rightIcon,
  onLeftIconClick,
  onRightIconClick,
}) => {
  return (
    <header
      data-aos-id="cart-options-header"
      data-aos="fade-up"
      className={`cart-options-header ${className || ''}`}
    >
      <div className="cart-options-header__content">
        {product.image && (
          <img
            className="cart-options-header__background-image"
            srcSet={srcSet(product.image)}
            alt={product.name}
          />
        )}

        {leftIcon && (
          <Icon
            className="cart-options-header__left-icon"
            name="close"
            onClick={onLeftIconClick}
          />
        )}

        {rightIcon && (
          <Icon
            className="cart-options-header__right-icon"
            name={rightIcon}
            onClick={onRightIconClick}
          />
        )}

        <div
          className={`cart-options-header__product ${
            !product.image && 'cart-options-header__product_no-image'
          }`}
        >
          <p className="cart-options-header__product-name">{product.name}</p>
          <p className="cart-options-header__product-info">
            {product.description}
          </p>
          <p className="cart-options-header__product-info">{`${product.weight} ${product.unit}`}</p>
        </div>
      </div>
    </header>
  );
};

export default CartOptionsHeader;

CartOptionsHeader.defaultProps = {
  className: '',
  product: {
    image: '',
    name: '',
    description: '',
    weight: '',
    unit: '',
  },
  leftIcon: null,
  rightIcon: null,
  onLeftIconClick: () => {},
  onRightIconClick: () => {},
};

CartOptionsHeader.PropsTypes = {
  className: PropTypes.string,
  product: PropTypes.shape({
    image: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    weight: PropTypes.string,
    unit: PropTypes.string,
  }),
  leftIcon: PropTypes.string,
  rightIcon: PropTypes.string,
  onLeftIconClick: PropTypes.func,
  onRightIconClick: PropTypes.func,
};
