import * as types from './types';

const initialState = {
  token: localStorage.getItem('token'),
  refresh: localStorage.getItem('refreshToken'),
  isAuthenticated: null,
  isLoading: false,
  user: {
    email: '',
  },
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case types.LOGIN_SUCCESS:
      localStorage.setItem('token', action.payload.access);
      localStorage.setItem('refreshToken', action.payload.refresh);
      return {
        ...state,
        token: action.payload.access,
        refresh: action.payload.refresh,
        isAuthenticated: true,
        isLoading: false,
      };
    case types.SET_USER_DATA:
      return {
        ...state,
        user: action.payload.user,
      };
    case types.AUTH_ERROR:
    case types.LOGIN_FAIL:
    case types.LOGOUT_SUCCESS:
    case types.REGISTER_FAIL:
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      return {
        ...state,
        token: null,
        user: null,
        isAuthenticated: false,
        isLoading: false,
      };
    case types.REFRESH_TOKEN:
      localStorage.setItem('token', action.payload.token);
      return {
        ...state,
        token: action.payload.token,
        isAuthenticated: true,
        isLoading: false,
      };

    default:
      return state;
  }
}
