import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import Button from '../components/common/Button';
import { isMobile } from '../redux/reducers/ui/selectors';

const PageNotFound = () => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <div className="page-not-found">
      <div className="page-not-found__content">
        <h1>404</h1>
        <h2>{t('404.text')}</h2>
        <Button onClick={() => history.push('/')}>{t('404.main')}</Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isMobile: isMobile(state),
});

export default connect(mapStateToProps, null)(PageNotFound);
