import { post, get } from '../utils/axios';

const cartAPI = {
  get: async () => {
    try {
      return await get(`/cart/`);
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },

  addProduct: async (id, quantity = 1, options = []) => {
    try {
      return await post(`/cart/add_product/`, { id, quantity, options });
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },

  addLine: async (lineId, quantity = 1) => {
    try {
      return await post(`/cart/add_product/`, { line_id: lineId, quantity });
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },

  removeProduct: async (id, quantity = 1) => {
    try {
      return await post(`/cart/remove_product/`, { id, quantity });
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },

  removeLine: async (lineId, quantity = 1) => {
    try {
      return await post(`/cart/remove_product/`, { line_id: lineId, quantity });
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },
};

export default cartAPI;
