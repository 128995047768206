import React from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Header from '../components/Header';
import { totalCartPrice } from '../redux/reducers/cart/selectors';
import Button from '../components/common/Button';
import { decrementLine, incrementLine } from '../redux/reducers/cart/actions';
import Counter from '../components/common/Counter';

const Order = ({ products, total, addProduct, removeProduct }) => {
  const history = useHistory();
  const { slug } = useParams();
  const { t } = useTranslation();

  const handleGoToCheckout = () => {
    history.push(`/restaurant/${slug}/checkout`);
  };

  const handleRemoveProduct = (line_id) => {
    removeProduct(line_id).then(({ products }) => {
      if (products.length === 0) {
        history.push(`/restaurant/${slug}/`);
      }
    });
  };

  return (
    <div className="page order">
      <Header
        title={t('order.title')}
        leftIcon="back"
        onLeftIconClick={() => history.goBack()}
      />
      <div className="order__content" data-aos="fade-up">
        <div className="order__product-list">
          {products.map((line) => (
            <div key={line.line_id} className="order__product order-product">
              <h2 className="order-product__name">{line.product.name}</h2>
              <p className="order-product__description">
                {line.product.description}
              </p>
              <p className="order-product__weight">
                {`${line.product.weight} ${line.product.unit}`}
              </p>
              <div className="order-product__option-list">
                {line.options &&
                  line.options.map((item) => (
                    <div className="order-product__option">
                      <p>
                        {item.quantity < 10
                          ? `0${item.quantity}x`
                          : `${item.quantity}x`}
                      </p>
                      <p className="order-product__option-name">{`${item.option.name} (${item.price}.00 ₴)`}</p>
                    </div>
                  ))}
              </div>
              <div className="order-product__summary">
                <p>{line.total} ₴</p>

                <Counter
                  value={line.quantity}
                  handleIncrement={() => addProduct(line.line_id)}
                  handleDecrement={() => handleRemoveProduct(line.line_id)}
                />
              </div>
            </div>
          ))}
        </div>
        <div className="order__summary">
          <p className="order__summary-text">{t('order.total')}</p>
          <p className="order__summary-price">{total} ₴</p>
        </div>
        <Button onClick={handleGoToCheckout} className="order__button">
          {t('order.make')}
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  products: state.cart.products,
  total: totalCartPrice(state),
});

export default connect(mapStateToProps, {
  addProduct: incrementLine,
  removeProduct: decrementLine,
})(Order);
