import { useState } from 'react';

export default function useCollapse(initialOpenValue = false) {
  const [isOpened, setOpen] = useState(initialOpenValue);

  const toggleCollapse = () => {
    setOpen(!isOpened);
  };

  return {
    isOpened,
    toggleCollapse,
  };
}
