import React, { useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { show } from 'redux-modal';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import restaurantsAPI from '../../api/restaurants';
import Icon from '../../components/common/Icon';
import Info from '../../components/common/Info';
import Footer from '../../components/Footer';
import Loader from '../../components/Loader';
import { fetchCart } from '../../redux/reducers/cart/actions';
import RestaurantHeader from '../../components/RestaurantHeader';
import {
  checkInByTable,
  createMenuApi,
  fetchRestaurantFromApi,
  fetchRestaurantMenusFromApi,
  removeMenuApi,
  updateMenuApi,
  updateRestaurantApi,
} from '../../redux/reducers/restaurant/actions';
import Input from '../../components/common/Input';
import RestaurantMenuItem from './RestaurantMenuItem';
import useRestaurantControls from '../../hooks/useRestaurantControls';
import RestaurantPreviewAndQRBlock from '../../components/RestaurantPreviewAndQRBlock';
import RestaurantSubscriptionsBlock from '../../components/RestaurantSubscriptionsBlock';
import { showConfirmPhoneModal } from '../../redux/reducers/auth/actions';
import {
  getRestaurantSubscriptionState,
  getRestaurantSubscriptionText,
} from '../RestaurantsList';
import settingsApi from '../../api/settings';
import { loadScript } from '../../utils/loadScripts';
import menuAPI from '../../api/menu';

const Restaurant = ({
  restaurant,
  restaurantMenus,
  fetchRestaurantMenusFromApi,
  updateRestaurantApi,
  createMenuApi,
  removeMenuApi,
  updateMenuApi,
  isShowLoader,
  checkInByTable,
  errors,
  fetchRestaurantFromApi,
  show,
}) => {
  const [previewRestaurantText, setPreviewRestaurantText] = useState('');
  const [showAddMenuField, setShowAddMenuField] = useState(false);
  const [languageOptions, setLanguages] = useState([]);
  const { t, i18n } = useTranslation();
  const [currentLang, setCurrentLang] = useState(
    window.localStorage.getItem('language')
      ? window.localStorage.getItem('language')
      : 'en'
  );
  // preview - it's when user press Preview button
  const {
    isPreviewMode,
    isShowControls,
    showConfirmPhoneModal,
  } = useRestaurantControls(restaurant);

  const setLng = (lngCode) => {
    setCurrentLang(lngCode);
    window.localStorage.setItem('language', lngCode);
  };

  useEffect(() => {
    if (restaurant?.slug && !isShowControls) {
      fetchRestaurantFromApi(restaurant?.slug, currentLang);
    }
  }, [
    currentLang,
    fetchRestaurantFromApi,
    i18n.language,
    isShowControls,
    restaurant?.slug,
  ]);

  const menus = useMemo(() => {
    return isShowControls
      ? restaurantMenus
      : restaurantMenus?.filter((menu) => menu.is_active);
  }, [isShowControls, restaurantMenus]);

  useEffect(() => {
    if (restaurant?.slug) {
      const i18LngCode = i18n.language.split('-')[0];
      if (!isShowControls) {
        if (restaurant.languages.includes(i18LngCode)) {
          setLng(i18LngCode);
        } else {
          switch (i18LngCode) {
            case 'en': {
              if (restaurant.languages.includes('uk')) {
                setLng('uk');
              } else setLng('ru');
              break;
            }
            case 'uk': {
              if (restaurant.languages.includes('en')) {
                setLng('en');
              } else setLng('ru');
              break;
            }
            case 'ru': {
              if (restaurant.languages.includes('uk')) {
                setLng('uk');
              } else setLng('en');
              break;
            }
            default: {
              if (restaurant.languages.includes('uk')) {
                setLng('uk');
              } else if (restaurant.languages.includes('en')) {
                setLng('en');
              } else setLng('ru');
              break;
            }
          }
        }
      }
    }
  }, [i18n.language, isShowControls, restaurant?.languages, restaurant?.slug]);

  useEffect(() => {
    settingsApi.getSystemSettings().then((data) => {
      const options = data.languages
        .filter((language) => restaurant?.languages.includes(language.code))
        .map((language) => {
          return {
            label: language.full,
            value: language.short,
            id: language.code,
            onClick: () => {
              setCurrentLang(language.code);
              window.localStorage.setItem('language', language.code);
            },
          };
        });
      if (options.length > 0 && options.length < 2) {
        setCurrentLang(options[0].id);
        window.localStorage.setItem('language', options[0].id);
      }
      setLanguages(options);
    });
  }, [i18n.language, restaurant?.languages]);

  const history = useHistory();
  const location = useLocation();
  const { slug } = useParams();

  useEffect(() => {
    // When user scan qr code
    if (restaurant?.id && location.search) {
      const query = queryString.parse(location.search);
      if (query.t) {
        restaurantsAPI
          .createNewSession(restaurant.id, query.t)
          .then(() => {
            checkInByTable();
          })
          .catch((e) => {
            console.error(e);
            if (e.code === 403) {
              showConfirmPhoneModal();
            } else if (e.code === 423) {
              history.replace('/');
            } else history.replace('/scan-again');
          });
        // eslint-disable-next-line no-underscore-dangle
      } else if (query._ga) {
        history.replace(location.pathname);
      }
    }
    // eslint-disable-next-line
  }, [restaurant?.id, showConfirmPhoneModal, location]);

  useEffect(() => {
    if (restaurant) {
      setPreviewRestaurantText(restaurant[`preview_${currentLang}`]);
    }
  }, [restaurant, currentLang]);

  if (isShowLoader) {
    return <Loader />;
  }

  if (!restaurant) {
    return null;
  }

  if (restaurant) {
    const title = document.getElementsByTagName('title')[0];
    title.innerHTML =
      restaurant?.seo?.meta_title !== ''
        ? restaurant?.seo?.meta_title
        : 'Menu Menu';

    const description = document.getElementsByName('description')[0];
    description.content =
      restaurant?.seo?.meta_description !== ''
        ? restaurant?.seo?.meta_description
        : 'Web site for creating restaurants menu';

    const metaKeyword = document.createElement('meta');
    metaKeyword.name = 'keywords';
    metaKeyword.id = 'keywords-seo';
    metaKeyword.content = restaurant?.seo?.meta_keywords;
    if (
      !document.getElementById('keywords-seo') &&
      restaurant?.seo?.meta_keywords !== ''
    )
      document.head.insertBefore(metaKeyword, description);
  }

  if (restaurant && (!isShowControls || isPreviewMode)) {
    const googleTagManager = document.createElement('script');
    googleTagManager.id = 'google-tag-manager';
    googleTagManager.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer', '${restaurant?.analytics?.google_tag_manager}');`;

    const googleAnalytics = document.createElement('script');
    googleAnalytics.id = 'google-analytics';
    googleAnalytics.innerHTML =
      ' window.dataLayer = window.dataLayer || [];\n' +
      '  function gtag(){window.dataLayer.push(arguments);}\n' +
      "  gtag('js', new Date());\n" +
      '\n' +
      `  gtag('config', ${restaurant?.analytics?.google_analytics});`;

    const facebookPixel = document.createElement('script');
    facebookPixel.id = 'facebook-pixel';
    facebookPixel.innerHTML =
      '!function(f,b,e,v,n,t,s)\n' +
      '  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?\n' +
      '  n.callMethod.apply(n,arguments):n.queue.push(arguments)};\n' +
      "  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';\n" +
      '  n.queue=[];t=b.createElement(e);t.async=!0;\n' +
      '  t.src=v;s=b.getElementsByTagName(e)[0];\n' +
      "  s.parentNode.insertBefore(t,s)}(window, document,'script',\n" +
      `  'https://connect.facebook.net/en_US/fbevents.js');\n` +
      `  fbq('init', ${restaurant?.analytics?.facebook_pixel});\n` +
      "  fbq('track', 'PageView');";

    if (!document.getElementById('google-analytics'))
      loadScript(
        `https://www.googletagmanager.com/gtag/js?id=${restaurant?.analytics?.google_analytics}`
      );

    if (!document.getElementById('google-tag-manager'))
      document.head.appendChild(googleTagManager);
    if (!document.getElementById('google-analytics'))
      document.head.appendChild(googleAnalytics);
    if (!document.getElementById('facebook-pixel'))
      document.head.appendChild(facebookPixel);
  } else {
    if (document.getElementById('google-tag-manager'))
      document.getElementById('google-tag-manager').remove();
    if (document.getElementById('google-analytics'))
      document.getElementById('google-analytics').remove();
    if (document.getElementById('facebook-pixel'))
      document.getElementById('facebook-pixel').remove();
  }

  const handleUpdateRestaurantField = (name, value) => {
    const data = {
      id: restaurant.id,
    };
    data[`${name}_${currentLang}`] = value;
    updateRestaurantApi(data);
  };

  const handleRemoveRestaurantMenuItem = (menu) => {
    // restaurant.menu = restaurant.menu.filter((item) => item.id !== menu.id);

    removeMenuApi(menu.id);
  };

  /**
   * Event handlers
   */
  const onPreviewChange = (value) => {
    setPreviewRestaurantText(value);
  };

  const onPreviewRestaurantTextBlur = () => {
    handleUpdateRestaurantField(`preview`, previewRestaurantText);
  };

  const onAddMenu = () => {
    setShowAddMenuField(true);
  };

  const onDeleteMenu = (menu) => {
    Swal.fire({
      heightAuto: false,
      title: `${t('restaurant_page.menu_remove_message')} ${menu.name}?`,
      showCancelButton: true,
      confirmButtonText: t('swal.yes'),
      cancelButtonText: t('swal.no'),
      showClass: {
        popup: 'animate__animated modalFadeInUp',
      },
      hideClass: {
        popup: 'animate__animated modalFadeOutDown',
      },
      preConfirm: () => {
        handleRemoveRestaurantMenuItem(menu);
      },
    });
  };

  const onMenuNameChange = (menuName, menu) => {
    if (!menu) {
      createMenuApi(menuName, restaurant.id);
    } else {
      const data = {
        id: menu.id,
        [`name_${currentLang}`]: menuName,
      };
      updateMenuApi(data);
    }

    setShowAddMenuField(false);
  };

  const onMenuClick = (menuId) => {
    history.push(
      `/restaurant/${slug}/menu/${menuId}${isPreviewMode ? '/preview' : ''}`
    );
  };

  const onPreviewClick = () => {
    history.push(`/restaurant/${slug}/preview`);
  };

  const handleBackClick = () => {
    history.push(`/restaurants`);
  };

  const onMenuHideClick = async (menu, event) => {
    if (event) event.stopPropagation();
    const dataCategory = {
      id: menu.id,
      is_active: !menu.is_active,
    };
    try {
      await menuAPI.patch(dataCategory);
      fetchRestaurantMenusFromApi(restaurant?.slug, currentLang);
    } catch (e) {
      console.error(e);
      if (e.code === 403) {
        showConfirmPhoneModal();
      }
    }
  };

  const goToSubscriptionsBlock = () => {
    // find need category with data-category attribute
    const subscriptionBlock = document.querySelector(
      `.restaurant__subscription`
    );

    subscriptionBlock.scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    });
  };

  if (languageOptions.length < 1) {
    return <Loader />;
  }

  return (
    <div className="restaurant page">
      <RestaurantHeader
        restaurant={restaurant}
        onBackClick={handleBackClick}
        isPreviewMode={isPreviewMode}
        isShowControls={isShowControls}
        languageOpt={{
          options: languageOptions,
          value: languageOptions.find((option) => option.id === currentLang)
            .value,
          isShowControls,
          show,
          label: 'menu_translation',
          use: true,
        }}
        restaurantPage
      />
      <div className="restaurant__upper-controls">
        {isShowControls && (
          <div
            className={`restaurants-list-item__subscription-info restaurants-list-item__subscription-info_${getRestaurantSubscriptionState(
              restaurant
            )}`}
          >
            {getRestaurantSubscriptionText(restaurant, t)}
            {!restaurant.subscription.is_expired &&
              restaurant.subscription.is_trial && (
                <span
                  className="restaurant__scroll-to-pay restaurant__scroll-to-pay_green"
                  onClick={goToSubscriptionsBlock}
                >
                  {t('restaurant_page.pay_for_subscription')}
                </span>
              )}
            {restaurant.subscription.is_expired && (
              <div
                className="restaurant__scroll-to-pay"
                onClick={goToSubscriptionsBlock}
              >
                {t('restaurant_page.pay_for_subscription')}
              </div>
            )}
            <Input
              className="restaurant__preview-text-area"
              placeholder={t('restaurant_page.restaurant_about_placeholder')}
              value={previewRestaurantText}
              onBlur={onPreviewRestaurantTextBlur}
              onChange={onPreviewChange}
              multiline
              error={errors.preview}
            />
          </div>
        )}
        {!isShowControls && (
          <p className="restaurant-info__preview">{restaurant.preview}</p>
        )}
      </div>
      <div className="restaurant__content">
        {errors?.image && (
          <div className="error-box error-box_center">
            {errors.image.content && <p>{errors.image.content}</p>}
            {errors.image.filename && <p>{errors.image.filename}</p>}
          </div>
        )}
        <div className="restaurant__menu">
          <ul className="restaurant__menu-list">
            {menus.map((menu) => (
              <RestaurantMenuItem
                key={menu.id}
                menu={menu}
                isShowControls={isShowControls}
                handleDelete={onDeleteMenu}
                onClick={() => onMenuClick(menu.id)}
                onApplyChanges={(name) => onMenuNameChange(name, menu)}
                onMenuHideClick={onMenuHideClick}
                currentLang={currentLang}
              />
            ))}
            {showAddMenuField && (
              <RestaurantMenuItem
                isShowControls={isShowControls}
                isEdit={showAddMenuField}
                closeAddMenu={() => {
                  setShowAddMenuField(false);
                }}
                handleDelete={onDeleteMenu}
                onApplyChanges={onMenuNameChange}
              />
            )}
          </ul>
          {isShowControls && (
            <div
              onClick={onAddMenu}
              className="restaurant__link restaurant__menu-link"
            >
              <Icon name="plus" /> {t('restaurant_page.menu_add')}
            </div>
          )}
        </div>

        {isShowControls ? (
          <>
            <div className="restaurant__option">
              <Info name="options" className="restaurant__option-info" />
              <Link
                className="restaurant__accent-text"
                to={`/restaurant/${slug}/options`}
              >
                {t('restaurant_page.options')}
              </Link>
            </div>
            <div className="restaurant__option">
              <Info name="option_group" className="restaurant__option-info" />
              <Link
                className="restaurant__accent-text"
                to={`/restaurant/${slug}/option-groups`}
              >
                {t('restaurant_page.option_groups')}
              </Link>
            </div>

            <RestaurantPreviewAndQRBlock
              restaurant={restaurant}
              onPreviewClick={onPreviewClick}
            />
            {(restaurant.subscription.is_trial ||
              restaurant.subscription.is_expired) && (
              <RestaurantSubscriptionsBlock
                className="restaurant__subscription"
                subscription={restaurant.subscription}
              />
            )}
          </>
        ) : (
          <div className="restaurant__info restaurant-info">
            <div className="restaurant-info__elements">
              {restaurant.address && (
                <div className="restaurant-info__element">
                  <Icon name="placeMarker" />
                  <p>{restaurant.address}</p>
                </div>
              )}
              {restaurant.phone && (
                <div className="restaurant-info__element">
                  <Icon name="phone" />
                  <a href={`tel:${restaurant.phone}`}>{restaurant.phone}</a>
                </div>
              )}
              <div className="restaurant-info__element">
                <Icon name="timetable" />
                <div>
                  {restaurant.schedule.map((line) => (
                    <p key={line}>{line}</p>
                  ))}
                </div>
              </div>
              {restaurant.wifi.name !== '' && (
                <div className="restaurant-info__element">
                  <Icon name="wifi" />
                  <div>
                    {restaurant.wifi?.name && <p>{restaurant.wifi.name}</p>}
                    {restaurant.wifi?.password && (
                      <p>{restaurant.wifi.password}</p>
                    )}
                  </div>
                </div>
              )}
              {restaurant.social_media?.instagram?.url && (
                <div className="restaurant-info__element">
                  <Icon name="instagram" />
                  <a
                    href={restaurant.social_media.instagram.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Instagram
                  </a>
                </div>
              )}
              {restaurant.social_media?.facebook?.url && (
                <div className="restaurant-info__element">
                  <Icon name="facebook" />
                  <a
                    href={restaurant.social_media.facebook.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Facebook
                  </a>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({
  errors: state.restaurant.fieldErrors,
  isShowLoader: state.restaurant.isLoading,
  restaurant: state.restaurant.restaurantData,
  restaurantMenus: state.restaurant.restaurantMenus,
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, {
  fetchCart,
  updateRestaurantApi,
  createMenuApi,
  removeMenuApi,
  updateMenuApi,
  checkInByTable,
  showConfirmPhoneModal,
  fetchRestaurantFromApi,
  fetchRestaurantMenusFromApi,
  show,
})(Restaurant);
