import React, { useEffect, useState } from 'react';

import { connectModal } from 'redux-modal';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Modal from '../common/Modal';
import settingsApi from '../../api/settings';
import Button from '../common/Button';
import Checkbox from '../common/Checkbox';
import { updateRestaurantApi } from '../../redux/reducers/restaurant/actions';

export const MODAL_NAME = 'restaurant-languages-modal';

const RestaurantLanguagesModal = ({
  show,
  handleHide,
  restaurant,
  updateRestaurantApi,
}) => {
  const { t } = useTranslation();
  const [systemLanguages, setSystemLanguages] = useState([]);
  const [restaurantLanguages, setRestaurantLanguages] = useState(
    restaurant.languages
  );

  useEffect(() => {
    settingsApi
      .getSystemSettings()
      .then((data) => setSystemLanguages(data.languages));
  }, []);

  const isLanguageEnabled = (language) =>
    restaurantLanguages.includes(language);

  const handleLanguageClick = (changedLanguage) => {
    if (isLanguageEnabled(changedLanguage)) {
      if (restaurantLanguages.length !== 1) {
        const newLanguages = restaurantLanguages.filter(
          (language) => language !== changedLanguage
        );
        setRestaurantLanguages(newLanguages);
      }
    } else {
      const newLanguages = restaurantLanguages.concat(changedLanguage);
      setRestaurantLanguages(newLanguages);
    }
  };

  const handleSaveLanguages = async () => {
    try {
      const data = {
        id: restaurant.id,
        languages: restaurantLanguages,
      };
      await updateRestaurantApi(data);
      handleHide();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Modal
      isOpen={show}
      onClose={handleHide}
      title={t('country_and_languages.content_translation')}
    >
      <div
        data-aos="fade-up"
        data-aos-delay="400"
        className="restaurant-opportunity-modal__body"
      >
        <div className="restaurant-opportunity-modal__list">
          {systemLanguages.map((language) => (
            <div className="restaurant-opportunity-modal__item">
              <Checkbox
                checked={isLanguageEnabled(language.code)}
                onChange={() => handleLanguageClick(language.code)}
              />
              <div className="restaurant-opportunity-modal__item_temp-text-class">
                {language.full}
              </div>
            </div>
          ))}
        </div>
      </div>

      <Button
        className="product-options__save-btn"
        type="submit"
        onClick={handleSaveLanguages}
      >
        {t('country_and_languages.add')}
      </Button>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  restaurant: state.restaurant.restaurantData,
});

export default connectModal({ name: MODAL_NAME, destroyOnHide: true })(
  connect(mapStateToProps, { updateRestaurantApi })(RestaurantLanguagesModal)
);

RestaurantLanguagesModal.defaultProps = {
  restaurantId: '',
};
