import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as modal } from 'redux-modal';
import auth from './auth/reducer';
import error from './error/reducer';
import cart from './cart/reducer';
import routerHelper from './routerHelper/reducer';
import restaurant from './restaurant/reducer';
import ui from './ui/reducer';

export default (history) =>
  combineReducers({
    auth,
    error,
    ui,
    cart,
    restaurant,
    modal,
    routerHelper,
    router: connectRouter(history),
  });
