import React, { useState } from 'react';

import { connectModal } from 'redux-modal';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Modal from '../common/Modal';
import Button from '../common/Button';
import InputMask, { PHONE_MASK } from '../common/InputMask';

export const MODAL_NAME = 'confirm-phone-modal';

const ConfirmPhone = ({
  show,
  onPhoneConfirm,
  onCodeConfirm,
  handleHide,
  onlyCode,
  id,
  recipient,
}) => {
  const [phone, setPhone] = useState('');
  const [code, setCode] = useState('');
  const [errors, setErrors] = useState({});
  const [confirmData, setConfirmData] = useState({
    id,
    recipient,
  });
  const [waitingForCode, setWaitingForCode] = useState(onlyCode);
  const { t } = useTranslation();

  const handleChangePhone = (event) => {
    if (event.target.value) {
      setPhone(event.target.value.replace(/ /g, ''));
    }
  };

  const handleChangeCode = (event) => {
    if (event.target.value) {
      setCode(event.target.value.replace(/ /g, ''));
    }
  };

  const handleSubmitClick = async (e) => {
    try {
      e.preventDefault();
      if (waitingForCode) {
        await onCodeConfirm(confirmData.id, code);
        handleHide();
      } else {
        const data = await onPhoneConfirm(phone);
        setConfirmData(data);
        setWaitingForCode(true);
      }
    } catch (e) {
      console.error(e);
      if (e?.response?.data) {
        setErrors(e.response.data);
      }
    }
  };

  return (
    <Modal isOpen={show} onClose={handleHide} title={t('mobile_confirm.title')}>
      <form
        onSubmit={handleSubmitClick}
        data-aos="fade-up"
        data-aos-delay="400"
        className="confirm-phone-modal__body"
      >
        {waitingForCode ? (
          <>
            <p className="confirm-phone-modal__text">
              {`${t('mobile_confirm.sent_message')} ${
                confirmData.recipient
              }.\n ${t('mobile_confirm.enter_code_message')}`}
            </p>

            <InputMask
              mask="9 9 9 9"
              onChange={handleChangeCode}
              value={code}
              error={errors.sms_code}
              name="code"
              placeholder={t('mobile_confirm.sms_code_placeholder')}
            />
          </>
        ) : (
          <>
            <p className="confirm-phone-modal__text">
              {t('mobile_confirm.text')}
            </p>
            <InputMask
              mask={PHONE_MASK}
              onChange={handleChangePhone}
              value={phone}
              type="tel"
              error={errors.phone}
              name="phone"
              placeholder={t('mobile_confirm.phone_placeholder')}
            />
          </>
        )}

        <Button className="product-options__save-btn" type="submit">
          {t('login.sign_in')}
        </Button>
      </form>
    </Modal>
  );
};

export default connectModal({ name: MODAL_NAME, destroyOnHide: true })(
  ConfirmPhone
);

ConfirmPhone.propTypes = {
  onPhoneConfirm: PropTypes.func,
  onCodeConfirm: PropTypes.func,
  onlyCode: PropTypes.bool,
  id: PropTypes.string,
  recipient: PropTypes.string,
};

ConfirmPhone.defaultProps = {
  onPhoneConfirm: () => Promise.resolve(),
  onCodeConfirm: () => Promise.resolve(),
  onlyCode: false,
  id: '',
  recipient: '',
};
