import { put, del, patch, post } from '../utils/axios';

const categoriesAPI = {
  create: async (name, menu) => {
    try {
      return await post('/categories/', { name, menu }, null, true);
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },
  update: async (data) => {
    try {
      return await put(`/categories/${data.id}/`, data, null, true);
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },

  patch: async (data, currentLang) => {
    try {
      return await patch(
        `/categories/${data.id}/`,
        data,
        null,
        true,
        currentLang
      );
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },

  remove: async (id) => {
    try {
      return await del(`/categories/${id}/`, null, true, true);
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },
  /**
   * DnD
   * @param id
   * @param origin - old index
   * @param destination - new index
   * @returns {Promise<*>}
   */
  move: async (id, origin, destination) => {
    try {
      return await put(
        `/categories/${id}/move/`,
        { origin, destination },
        null,
        true
      );
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },
};

export default categoriesAPI;
