import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import infoModals from './modals/info';

const InfoModal = ({ onClick, infoDirectionalTop, infoPosition, name }) => {
  if (infoModals[name] === undefined) {
    throw new Error(`${name} - not found`);
  }

  const { t } = useTranslation();
  const [positionModal, setPositionModal] = useState({});
  useEffect(() => {
    const bottomModal = window.innerHeight - infoPosition.top;
    const gap = 12;

    const style = !infoDirectionalTop
      ? {
          top: infoPosition.bottom + gap,
        }
      : { bottom: bottomModal + gap };

    setPositionModal(style);
  }, [infoPosition, infoDirectionalTop]);

  return (
    <div className="info__background" onClick={onClick}>
      <div className={`info__wrapper-modal `}>
        <div className="info__modal" style={positionModal}>
          <div className="info__title">{t(`info.${name}_title`)}</div>
          <div className="info__text">{t(`info.${name}_text`)}</div>
        </div>
      </div>
    </div>
  );
};

export default InfoModal;
