import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../components/common/Button';
import checkoutAPI from '../../api/checkout';

const SubscriptionError = () => {
  const history = useHistory();
  const { paymentId } = useParams();
  const [restaurant, setRestaurant] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    if (paymentId)
      checkoutAPI.paymentCallback(paymentId).then((data) => {
        setRestaurant(data.restaurant);
      });
  }, [paymentId]);

  return (
    <div className="page">
      <div className="subscription-result__content">
        <div className="subscription-result__title subscription-result__title_error">
          {t('subscription_result.error_title')}
        </div>
        <div className="subscription-result__description">
          {t('subscription_result.error_text')}
        </div>
        <span
          role="img"
          aria-label="exclamation_mark"
          className="subscription-result__emoji"
        >
          ❗
        </span>
      </div>
      <Button
        className="subscription-result__button"
        onClick={() =>
          paymentId
            ? history.replace(`/restaurant/${restaurant.slug}/`)
            : history.goBack()
        }
      >
        {t('subscription_result.error_button')}
      </Button>
    </div>
  );
};

export default SubscriptionError;
