import React, { useState } from 'react';
import Icon from './Icon';

const EditableLabel = ({
  Label,
  Input,
  handleApplyChanges,
  handleDeleteClick,
  value,
}) => {
  const [isEdit, toggleEdit] = useState(false);

  const handleApplyClick = () => {
    handleApplyChanges();
    if (value !== '') {
      toggleEdit(!isEdit);
    }
  };

  return (
    <div className="editable-label">
      <div className="editable-label__component">{isEdit ? Input : Label}</div>
      {isEdit ? (
        <Icon
          className="editable-label__icon editable-label__icon_apply "
          onClick={handleApplyClick}
          name="apply"
        />
      ) : (
        <>
          <Icon
            className="editable-label__icon"
            onClick={() => toggleEdit(!isEdit)}
            name="pen"
          />
          {handleDeleteClick && (
            <Icon
              onClick={handleDeleteClick}
              className="editable-label__icon"
              name="delete"
            />
          )}
        </>
      )}
    </div>
  );
};

export default EditableLabel;
