import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Select from './common/Select';

const IikoPaymentBlock = ({
  iikoPayments,
  iikoPaymentTypeChange,
  iikoTerminalGroupChange,
  iikoData,
  name,
  type,
}) => {
  const { t } = useTranslation();
  const terminalGroupOptions = useMemo(() => {
    return iikoPayments.find(
      (paymentType) => paymentType.id === iikoData?.payment_types[type]
    )?.terminal_groups;
  }, [iikoData?.payment_types, iikoPayments, type]);

  return (
    <div className="iiko-payments-block">
      <div className="iiko-payments-block__title">{name}</div>
      <div className="iiko-payments-block__content">
        <Select
          options={iikoPayments.map((paymentType) => ({
            id: paymentType.id,
            name: paymentType.name,
          }))}
          label={t('pos_system.payment_types')}
          valueId={iikoData?.payment_types[type]}
          onSelect={(option) => iikoPaymentTypeChange(type, option.id)}
        />
        <Select
          options={terminalGroupOptions}
          onSelect={(option) => iikoTerminalGroupChange(type, option.id)}
          valueId={iikoData?.terminal_groups[type]}
          label={t('pos_system.terminal_groups')}
        />
      </div>
    </div>
  );
};

export default IikoPaymentBlock;
