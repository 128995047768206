import PropTypes from 'prop-types';
import React, { useState } from 'react';

const Tabs = ({ tabs, children, onTabChange }) => {
  const [activeTabInx, setActiveTabInx] = useState(0);

  const getContent = (index) => {
    const notNullChildren = children.filter((item) => item !== null);
    if (notNullChildren[index]) {
      return notNullChildren[index];
    }
    return null;
  };

  const handleTabClick = (index) => {
    setActiveTabInx(index);
    onTabChange(index);
  };

  return (
    <div className="tabs">
      <div className="tabs__header">
        {tabs.map((tabName, index) => (
          <div
            key={tabName}
            onClick={() => handleTabClick(index)}
            className={`tabs__header-tab ${
              activeTabInx === index ? 'tabs__header-tab_active' : ''
            }`}
          >
            {tabName}
          </div>
        ))}
      </div>

      <div className="tabs__content">{getContent(activeTabInx)}</div>
    </div>
  );
};

export default Tabs;

Tabs.PropsTypes = {
  tabs: PropTypes.array,
  onTabChange: PropTypes.func,
};

Tabs.defaultProps = {
  tabs: ['Login', 'Register'],
  onTabChange: () => {},
};
