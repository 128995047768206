import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DropdownMenu from '../../components/common/DropdownMenu';
import Icon from '../../components/common/Icon';

const ProductOptionGroupsList = ({
  option_group_settings,
  handleDeleteSettings,
}) => {
  const [collapsedGroups, setCollapsedGroups] = useState([]);
  const { slug } = useParams();
  const { productId } = useParams();
  const history = useHistory();
  const { t } = useTranslation();

  const handleGroupCollapse = (id) => {
    if (collapsedGroups.includes(id)) {
      setCollapsedGroups((data) =>
        data.filter((categoryId) => categoryId !== id)
      );
    } else setCollapsedGroups([...collapsedGroups, id]);
  };

  const isGroupCollapsed = (id) => collapsedGroups.includes(id);
  return (
    <div>
      <div className="product__option-groups-list">
        {option_group_settings.map((option_group_setting) => (
          <div
            className="product__option-groups-list__item"
            key={option_group_setting.id}
          >
            <div
              className="product__option-groups-list__title"
              onClick={() => handleGroupCollapse(option_group_setting.group.id)}
            >
              <p>{option_group_setting.group.name}</p>
              <Icon
                onClick={() =>
                  handleGroupCollapse(option_group_setting.group.id)
                }
                name={`${
                  isGroupCollapsed(option_group_setting.group.id)
                    ? 'topCircleArrow'
                    : 'downCircleArrow'
                }`}
                className="product__option-groups-list__title__icon"
              />
              <DropdownMenu
                options={[
                  {
                    label: t('option_groups.edit'),
                    onClick: () =>
                      history.push(
                        `/restaurant/${slug}/product/${productId}/option-groups/${option_group_setting.group.id}`
                      ),
                  },
                  {
                    label: t('option_groups.edit_settings'),
                    onClick: () =>
                      history.push(
                        `/restaurant/${slug}/product/${productId}/option-groups/${option_group_setting.group.id}/settings/${option_group_setting.id}`
                      ),
                  },
                  {
                    label: t('option_groups.remove_from_product'),
                    onClick: () =>
                      handleDeleteSettings(option_group_setting.id),
                  },
                ]}
                className="product__option-groups-list__title__icon"
              />
            </div>
            <div
              className={`"option-group__options-list ${
                isGroupCollapsed(option_group_setting.group.id)
                  ? 'option-group__options-list_show'
                  : 'option-group__options-list_hide'
              }`}
            >
              {option_group_setting.group.options?.map((option) => (
                <div className="option-group__option">
                  <p>{option.name}</p>
                  <p>{`${option.price} ₴`}</p>
                </div>
              ))}
            </div>
            <div />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductOptionGroupsList;
