import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { show } from 'redux-modal';
import Header from '../components/Header';
import { isMobile } from '../redux/reducers/ui/selectors';
import Icon from '../components/common/Icon';
import Info from '../components/common/Info';
import settingsApi from '../api/settings';
import { MODAL_NAME as RESTAURANT_LANGUAGES_MODAL } from '../components/modals/RestaurantLanguagesModal';

const Localization = ({ restaurant, show }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [languages, setLanguages] = useState([]);
  const handleBackClick = () => {
    history.goBack();
  };

  useEffect(() => {
    settingsApi
      .getSystemSettings()
      .then((data) => setLanguages(data.languages));
  }, []);

  const handleAddLanguageClick = () => {
    show(RESTAURANT_LANGUAGES_MODAL);
  };

  return (
    <div className="page localization">
      <Header
        title={t('country_and_languages.title')}
        onLeftIconClick={handleBackClick}
        leftIcon="back"
      />
      <div data-aos="fade-left" className="localization____content">
        {/* Accordion */}
        <div className="localization__language-settings-list">
          <div className="localization__language-settings-list-header">
            <Icon name="language" />
            <div className="localization__language-settings-list-title">
              {t('country_and_languages.language')}
            </div>
            <Icon name="moveDown" />
          </div>
          <div className="localization__language-settings-list-content">
            <div className="localization__language-settings-list-item">
              <div className="localization__language-settings-list-item-header">
                <div className="localization__language-settings-list-item-title">
                  {t('country_and_languages.content_translation_text')}
                </div>
                <Info name="delivery" />
              </div>
              <div className="localization__content-languages">
                {languages
                  .filter((language) =>
                    restaurant.languages.includes(language.code)
                  )
                  .map((language) => (
                    <div className="localization__content-languages-item">
                      {language.full}
                    </div>
                  ))}
              </div>
              <div
                className="localization__add-content-languages"
                onClick={handleAddLanguageClick}
              >
                <Icon name="plus" />
                <p>{t('country_and_languages.add_language')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isMobile: isMobile(state),
  restaurant: state.restaurant.restaurantData,
});

export default connect(mapStateToProps, { show })(Localization);
