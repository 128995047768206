import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { show } from 'redux-modal';
import { useTranslation } from 'react-i18next';
import Header from '../../components/Header';
import { totalCartPrice } from '../../redux/reducers/cart/selectors';
import Button from '../../components/common/Button';
import checkoutAPI from '../../api/checkout';
import {
  decrementLine,
  fetchCart,
  incrementLine,
} from '../../redux/reducers/cart/actions';
import Loader from '../../components/Loader';
import Tabs from '../../components/common/Tabs';
import Icon from '../../components/common/Icon';
import Select from '../../components/common/Select';
import Input from '../../components/common/Input';
import InputMask, { PHONE_MASK } from '../../components/common/InputMask';
import GooglePlacesAutocomplete from '../../components/common/GooglePlacesAutocomplete';
import { MODAL_NAME as CONFIRM_PHONE_MODAL } from '../../components/modals/ConfirmPhone';
import CheckoutProductList from '../../components/CheckoutProductList';
import TextArea from '../../components/common/TextArea';
import useRestaurantControls from '../../hooks/useRestaurantControls';
import { loadScript } from '../../utils/loadScripts';

const Checkout = ({
  products,
  total,
  show,
  fetchCart,
  incrementProductLine,
  decrementProductLine,
  restaurant,
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  const { slug } = useParams();

  const [checkoutData, setCheckoutData] = useState({});
  const [tabsHeader, setTabsHeader] = useState({});
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const { isPreviewMode, isShowControls } = useRestaurantControls(restaurant);

  // Fields
  const [checkoutType, setCheckoutType] = useState(null);
  const [paymentType, setPaymentType] = useState(null);
  const [date, setDate] = useState(null);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [smsCode, setSmsCode] = useState(null);
  const [phoneValidationId, setPhoneValidationId] = useState(null);
  const [comment, setComment] = useState('');
  const [address, setAddress] = useState('');
  const [payment_payload, setPaymentPayload] = useState('');

  useEffect(() => {
    setLoading(true);
    checkoutAPI
      .getCheckoutData()
      .then(({ data }) => {
        setCheckoutData(data);
        let tabs = {};
        if (data.dine_in) {
          tabs = { ...tabs, dine_in: t('checkout.tab_dinein') };
        }
        if (data.takeaway) {
          tabs = { ...tabs, takeaway: t('checkout.tab_takeaway') };
        }
        if (data.delivery) {
          tabs = { ...tabs, delivery: t('checkout.tab_delivery') };
        }
        setTabsHeader(tabs);
        setCheckoutType(Object.keys(tabs)[0]);
        setLoading(false);
      })
      .catch((e) => {
        if (e?.code === 400) {
          history.push(`/restaurant/${slug}`);
        }
      });
    // eslint-disable-next-line
  }, []);

  const makeCheckoutAPI = () => {
    switch (checkoutType) {
      case 'dine_in': {
        return checkoutAPI.dineIn({
          payment_type: paymentType,
        });
      }
      case 'takeaway': {
        return checkoutAPI.takeaway({
          payment_type: paymentType,
          date,
          name,
          phone,
          phone_validation_id: phoneValidationId,
          phone_validation_sms_code: smsCode,
          comment,
        });
      }
      case 'delivery': {
        return checkoutAPI.delivery({
          payment_type: paymentType,
          date,
          name,
          address,
          phone,
          phone_validation_id: phoneValidationId,
          phone_validation_sms_code: smsCode,
          comment,
        });
      }
      default:
        console.error('method not defined');
    }
    return null;
  };

  const handlePaymentTypeSelect = (option) => {
    setErrors({ ...errors, payment_type: '' });
    setPaymentType(option.id);
  };

  const handleDateSelect = (option) => {
    setErrors({ ...errors, date: '' });
    setDate(option.id);
  };

  const handleNameChange = (value) => {
    setName(value);
  };

  const handleChangePhone = (event) => {
    if (event.target.value) {
      setErrors({ ...errors, phone: '' });
      setPhone(event.target.value.replace(/ /g, ''));
    }
  };

  const handleCodeConfirm = (id, code) => {
    console.log(id, code);
    setPhoneValidationId(id);
    setSmsCode(code);
  };

  const handleValidatePhone = async () => {
    if (checkoutData.is_order_phone_validation) {
      try {
        const { data } = await checkoutAPI.validatePhone(phone);
        show(CONFIRM_PHONE_MODAL, {
          onCodeConfirm: handleCodeConfirm,
          onlyCode: true,
          ...data,
        });
      } catch (e) {
        setErrors(e);
      }
    }
  };

  const handleChangeComment = (value) => {
    setComment(value);
  };

  const handleAddressChange = (value) => {
    setErrors({ ...errors, address: '' });
    setAddress(value);
  };

  const handleValidateAddress = async () => {
    if (address !== '') {
      try {
        const { data } = await checkoutAPI.validateAddress(address);
        setCheckoutData((checkoutData) => ({
          ...checkoutData,
          [checkoutType]: {
            ...checkoutData[checkoutType],
            message: data.message,
          },
        }));
      } catch (e) {
        setErrors(e);
      }
    }
  };

  const handleTabChange = (index) => {
    setCheckoutType(Object.keys(tabsHeader)[index]);
  };

  const handleCheckout = async () => {
    setErrors({});
    try {
      const { data: checkoutResponse } = await makeCheckoutAPI();
      console.log(checkoutResponse);
      // if thank_you_page property exist in response show thank u page
      if (checkoutResponse.thank_you_page) {
        history.replace({
          pathname: '/order-success',
          state: {
            ...checkoutResponse,
          },
        });
        fetchCart();
      }
      // if payment_payload property exist in response show PORTMONE modal
      if (checkoutResponse.payment_process) {
        setPaymentPayload(checkoutResponse.payment_payload);
        document.getElementById('portmone_submit').submit();
      }
    } catch (e) {
      console.error(e);
      setErrors(e);
      if (e.code === 403) {
        try {
          const { data } = await checkoutAPI.validatePhone(phone);
          show(CONFIRM_PHONE_MODAL, {
            onCodeConfirm: handleCodeConfirm,
            onlyCode: true,
            ...data,
          });
        } catch (e) {
          setErrors(e);
        }
      }
    }
  };

  if (loading) {
    return <Loader />;
  }
  if (restaurant) {
    const title = document.getElementsByTagName('title')[0];
    if (restaurant?.seo?.meta_title !== '')
      title.innerHTML = restaurant?.seo?.meta_title;

    const description = document.getElementsByName('description')[0];
    if (restaurant?.seo?.meta_description !== '')
      description.content = restaurant?.seo?.meta_description;

    const metaKeyword = document.createElement('meta');
    metaKeyword.name = 'keywords';
    metaKeyword.id = 'keywords-seo';
    metaKeyword.content = restaurant?.seo?.meta_keywords;
    if (
      !document.getElementById('keywords-seo') &&
      restaurant?.seo?.meta_keywords !== ''
    )
      document.head.insertBefore(metaKeyword, description);
  }

  if (restaurant && (!isShowControls || isPreviewMode)) {
    const googleTagManager = document.createElement('script');
    googleTagManager.id = 'google-tag-manager';
    googleTagManager.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer', '${restaurant?.analytics?.google_tag_manager}');`;

    const googleAnalytics = document.createElement('script');
    googleAnalytics.id = 'google-analytics';
    googleAnalytics.innerHTML =
      ' window.dataLayer = window.dataLayer || [];\n' +
      '  function gtag(){window.dataLayer.push(arguments);}\n' +
      "  gtag('js', new Date());\n" +
      '\n' +
      `  gtag('config', ${restaurant?.analytics?.google_analytics});`;

    const facebookPixel = document.createElement('script');
    facebookPixel.id = 'facebook-pixel';
    facebookPixel.innerHTML =
      '!function(f,b,e,v,n,t,s)\n' +
      '  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?\n' +
      '  n.callMethod.apply(n,arguments):n.queue.push(arguments)};\n' +
      "  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';\n" +
      '  n.queue=[];t=b.createElement(e);t.async=!0;\n' +
      '  t.src=v;s=b.getElementsByTagName(e)[0];\n' +
      "  s.parentNode.insertBefore(t,s)}(window, document,'script',\n" +
      `  'https://connect.facebook.net/en_US/fbevents.js');\n` +
      `  fbq('init', ${restaurant?.analytics?.facebook_pixel});\n` +
      "  fbq('track', 'PageView');";

    if (!document.getElementById('google-analytics'))
      loadScript(
        `https://www.googletagmanager.com/gtag/js?id=${restaurant?.analytics?.google_analytics}`
      );

    if (!document.getElementById('google-tag-manager'))
      document.head.appendChild(googleTagManager);
    if (!document.getElementById('google-analytics'))
      document.head.appendChild(googleAnalytics);
    if (!document.getElementById('facebook-pixel'))
      document.head.appendChild(facebookPixel);
  } else {
    if (document.getElementById('google-tag-manager'))
      document.getElementById('google-tag-manager').remove();
    if (document.getElementById('google-analytics'))
      document.getElementById('google-analytics').remove();
    if (document.getElementById('facebook-pixel'))
      document.getElementById('facebook-pixel').remove();
  }

  return (
    <div className="page checkout">
      <Header
        title={t('checkout.order_title')}
        leftIcon="back"
        onLeftIconClick={() => history.goBack()}
      />

      <div className="checkout__content" data-aos="fade-up">
        <Tabs onTabChange={handleTabChange} tabs={Object.values(tabsHeader)}>
          {/* DINE IN TAB */}
          {checkoutData.dine_in && (
            <div key="dine-in" className="checkout__form">
              {checkoutData.dine_in.message && (
                <div className="checkout__message-text">
                  {checkoutData.dine_in.message}
                </div>
              )}
              <div className="checkout__component-with-icon">
                <Icon name="wallet" />
                <Select
                  label={t('checkout.choose_payment_type')}
                  options={checkoutData.dine_in.payment_types.map((type) => ({
                    id: type.name,
                    name: type.label,
                  }))}
                  valueId={paymentType}
                  onSelect={handlePaymentTypeSelect}
                  error={errors.payment_type}
                />
              </div>
              <div className="checkout__message-text">{`${t(
                'checkout.dine_in_table'
              )} ${checkoutData.dine_in.table}`}</div>
            </div>
          )}

          {/* TAKEAWAY TAB */}
          {checkoutData.takeaway && (
            <div key="takeaway" className="checkout__form">
              {checkoutData.takeaway.message && (
                <div className="checkout__message-text">
                  {checkoutData.takeaway.message}
                </div>
              )}
              <div className="checkout__component-with-icon">
                <Icon name="name" />
                <Input
                  value={name}
                  onChange={handleNameChange}
                  placeholder={t('checkout.name_placeholder')}
                  error={errors.name}
                />
              </div>
              <div className="checkout__component-with-icon">
                <Icon name="phone" />
                <InputMask
                  mask={PHONE_MASK}
                  onChange={handleChangePhone}
                  value={phone}
                  type="tel"
                  onBlur={handleValidatePhone}
                  error={errors.phone}
                  name="phone"
                  placeholder={t('checkout.phone_placeholder')}
                />
              </div>

              <TextArea
                className="mb-6 mt-3 test"
                value={comment}
                onChange={handleChangeComment}
                label={t('checkout.comment_placeholder')}
              />

              <div className="checkout__component-with-icon">
                <Icon name="wallet" />
                <Select
                  label={t('checkout.choose_payment_type')}
                  options={checkoutData.takeaway.payment_types.map((type) => ({
                    id: type.name,
                    name: type.label,
                  }))}
                  valueId={paymentType}
                  onSelect={handlePaymentTypeSelect}
                  error={errors.payment_type}
                />
              </div>
              <div className="checkout__component-with-icon">
                <Icon name="deliveryTime" />
                <Select
                  label={t('checkout.delivery_time_text')}
                  options={checkoutData.takeaway.available_order_dates.map(
                    (date) => ({ id: date, name: date })
                  )}
                  valueId={date}
                  onSelect={handleDateSelect}
                  error={errors.date}
                />
              </div>
            </div>
          )}

          {/* DELIVERY TAB */}
          {checkoutData.delivery && (
            <div key="delivery" className="checkout__form">
              {checkoutData.delivery.message && (
                <div className="checkout__message-text">
                  {checkoutData.delivery.message}
                </div>
              )}
              <div className="checkout__component-with-icon">
                <Icon name="name" />
                <Input
                  value={name}
                  onChange={handleNameChange}
                  placeholder={t('checkout.name_placeholder')}
                />
              </div>
              <div className="checkout__component-with-icon">
                <Icon name="phone" />
                <InputMask
                  mask={PHONE_MASK}
                  onChange={handleChangePhone}
                  value={phone}
                  type="tel"
                  error={errors.phone}
                  name="phone"
                  placeholder={t('checkout.phone_placeholder')}
                />
              </div>

              <div className="checkout__component-with-icon">
                <Icon name="placeMarker" />
                <GooglePlacesAutocomplete
                  onBlur={handleValidateAddress}
                  onChange={handleAddressChange}
                  value={address.formatted_address || address}
                  name="address"
                  placeholder={t('checkout.address_placeholder')}
                  error={errors.address}
                />
              </div>

              <TextArea
                className="mb-6 mt-3"
                value={comment}
                onChange={handleChangeComment}
                label={t('checkout.comment_placeholder')}
              />

              <div className="checkout__component-with-icon">
                <Icon name="wallet" />
                <Select
                  label={t('checkout.choose_payment_type')}
                  options={checkoutData.delivery.payment_types.map((type) => ({
                    id: type.name,
                    name: type.label,
                  }))}
                  valueId={paymentType}
                  onSelect={handlePaymentTypeSelect}
                  error={errors.payment_type}
                />
              </div>
              <div className="checkout__component-with-icon">
                <Icon name="deliveryTime" />
                <Select
                  label={t('checkout.delivery_time_text')}
                  options={checkoutData.delivery.available_order_dates.map(
                    (date) => ({ id: date, name: date })
                  )}
                  valueId={date}
                  onSelect={handleDateSelect}
                  error={errors.date}
                />
              </div>
            </div>
          )}
        </Tabs>

        <div className="checkout__order">
          <h3 className="checkout-order__title">{t('order.title')}</h3>

          <CheckoutProductList
            className="checkout-order__product-list"
            products={products}
            incrementLine={incrementProductLine}
            decrementLine={decrementProductLine}
          />
        </div>

        <div className="checkout__total">
          <p>{t('order.total')}</p>
          <p>{total} ₴</p>
        </div>

        {errors.non_field_errors && (
          <p className="error-box error-box_center">
            {errors.non_field_errors}
          </p>
        )}

        <form
          action="https://www.portmone.com.ua/gateway/"
          method="post"
          style={{ visibility: 'hidden' }}
          id="portmone_submit"
        >
          <input
            type="hidden"
            name="bodyRequest"
            value={JSON.stringify(payment_payload)}
          />
          <input type="hidden" name="typeRequest" value="json" />
          <input type="submit" value="Оплатити через Portmone.com" />
        </form>

        <Button onClick={handleCheckout} className="checkout__button">
          {t('checkout.make_order')}
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  products: state.cart.products,
  total: totalCartPrice(state),
  restaurant: state.restaurant.restaurantData,
});

export default connect(mapStateToProps, {
  fetchCart,
  show,
  incrementProductLine: incrementLine,
  decrementProductLine: decrementLine,
})(Checkout);
