import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Header from '../../components/Header';
import Input from '../../components/common/Input';
import { isMobile } from '../../redux/reducers/ui/selectors';
import Select from '../../components/common/Select';

const ProfileInfo = ({ isMobile, userEmail }) => {
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const languageOptions = [
    {
      onClick: () => i18n.changeLanguage('en-US'),
      value: 'Eng',
      id: 'en-US',
      name: 'English',
    },
    {
      onClick: () => i18n.changeLanguage('uk-UA'),
      value: 'Укр',
      id: 'uk-UA',
      name: 'Українська',
    },
    {
      onClick: () => i18n.changeLanguage('ru-RU'),
      value: 'Рус',
      id: 'ru-RU',
      name: 'Русский',
    },
  ];

  const languageDropdownValue = languageOptions.find(
    (option) => option.id === i18n.language
  )
    ? languageOptions.find((option) => option.id === i18n.language)
    : languageOptions.find((option) => option.id === 'en-US');
  return (
    <div className="page profile">
      <Header
        rightIcon="close"
        onRightIconClick={() => history.goBack()}
        title={isMobile && t('profile.header')}
        logo={!isMobile}
      />
      <div data-aos="fade-left" className="profile-account__content">
        <h1 className="profile-account__title">{t('profile.title')}</h1>
        <div className="profile-account__links">
          <Input value={userEmail} />

          <div className="iiko-payments-block language">
            <div className="iiko-payments-block__title">
              {t('profile.language')}
            </div>
            <div className="iiko-payments-block__content">
              <Select
                options={languageOptions}
                valueId={languageDropdownValue.id}
                onSelect={(option) => i18n.changeLanguage(option.id)}
              />
            </div>
          </div>
          <Link to="/password-change" className="profile-account__link">
            {t('profile.reset_password')}
          </Link>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isMobile: isMobile(state),
  userEmail: state.auth.user.email,
});

export default connect(mapStateToProps, null)(ProfileInfo);
