import React from 'react';
import Counter from './common/Counter';

const CheckoutProductList = ({
  className,
  products,
  incrementLine,
  decrementLine,
}) => {
  return (
    <div className={className}>
      <div className="checkout-order__product-list">
        {products.map((item) => (
          <div
            key={item.line_id}
            className="checkout-order__product checkout-order-product"
          >
            <div className="checkout-order-product__row">
              <p className="checkout-order-product__quantity">
                {`${item.quantity} x`}
              </p>
              <p className="checkout-order-product__name">
                {item.product.name}
              </p>
              <p className="checkout-order-product__price">
                {`${item.total} ₴`}
              </p>
            </div>
            {item.options.map((optionLine) => (
              <div
                key={optionLine.option_id}
                className="checkout-order-product__row"
              >
                <p className="checkout-order-product__quantity checkout-order-product__option">
                  {`${optionLine.quantity} x`}
                </p>
                <p className="checkout-order-product__name">
                  {optionLine.option.name}
                </p>
              </div>
            ))}
            <div className="checkout-order-product__row mt-5">
              <span />
              <span />
              <Counter
                value={item.quantity}
                handleIncrement={() => incrementLine(item.line_id)}
                handleDecrement={() => decrementLine(item.line_id)}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CheckoutProductList;
