import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Input from './Input';
import Icon from './Icon';

const Autocomplete = ({
  placeholder,
  value,
  options,
  onChange,
  onSelect,
  error,
  label,
  addDisabled,
  currentLang,
}) => {
  const [isOpen, toggleDropdown] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const ref = useRef();

  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      if (isOpen)
        setInputValue(
          value[`name_${currentLang}`] ? value[`name_${currentLang}`] : value
        );
      toggleDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  });

  useEffect(() => {
    setInputValue(value.name ? value[`name_${currentLang}`] : value);
  }, [currentLang, value]);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  const onInputChange = (val) => {
    setInputValue(val);
    onChange(val);
    const availableOptions = options.filter((option) =>
      option[`name_${currentLang}`].toLowerCase().includes(val.toLowerCase())
    );
    setFilteredOptions(availableOptions);
    if (val) {
      toggleDropdown(true);
    } else {
      toggleDropdown(false);
    }
  };

  const onDropdownSelect = (option) => {
    onSelect(option);
    setInputValue(option[`name_${currentLang}`]);
    toggleDropdown(false);
  };

  const onAddNewValue = () => {
    onSelect(inputValue);
    toggleDropdown(false);
  };

  const onArrowDropdownClick = () => {
    if (!isOpen) {
      setFilteredOptions(options);
    }
    toggleDropdown(!isOpen);
  };

  const isAddButtonDisabled = () => {
    return (
      (inputValue &&
        filteredOptions.some(
          (option) =>
            inputValue.toLowerCase() ===
            option[`name_${currentLang}`].toLowerCase()
        )) ||
      inputValue === ''
    );
  };

  return (
    <div ref={ref} className="autocomplete">
      <Input
        className="autocomplete__input"
        value={inputValue}
        onChange={onInputChange}
        error={error}
        placeholder={placeholder}
        rightIcon={
          // eslint-disable-next-line no-nested-ternary
          filteredOptions.length !== 0
            ? isOpen
              ? 'topArrow'
              : 'downArrow'
            : null
        }
        rightIconClick={() => onArrowDropdownClick()}
      />
      <div
        className={`autocomplete__dropdown ${
          isOpen ? 'autocomplete__dropdown_open' : ''
        }`}
      >
        {filteredOptions.map((option) => (
          <div
            className={`autocomplete__option ${
              option[`name_${currentLang}`] === inputValue
                ? 'autocomplete__option_active'
                : ''
            }`}
            key={option.id}
            onClick={
              option[`name_${currentLang}`] === inputValue
                ? () => toggleDropdown(false)
                : () => onDropdownSelect(option)
            }
          >
            {option[`name_${currentLang}`]}
          </div>
        ))}
        {!addDisabled && (
          <div
            className={`autocomplete__add-button ${
              isAddButtonDisabled() ? '' : 'autocomplete__add-button_active'
            }`}
            onClick={isAddButtonDisabled() ? () => {} : onAddNewValue}
          >
            <Icon name="dropdown-plus" withHover={!isAddButtonDisabled()} />
            <p className="autocomplete__add-button_text">{label}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Autocomplete;

Autocomplete.PropsTypes = {
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  value: PropTypes.oneOf([PropTypes.shape, PropTypes.string]),
  options: PropTypes.array,
  error: PropTypes.string,
};

Autocomplete.defaultProps = {
  onChange: () => {},
  onSelect: () => {},
  value: '',
  options: [],
  error: '',
};
