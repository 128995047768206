import RestaurantModify from '../pages/Restaurant/RestaurantModify';
import RestaurantsList from '../pages/RestaurantsList';
import Start from '../pages/Start';
import Restaurant from '../pages/Restaurant/Restaurant';
import ProductModify from '../pages/Product/ProductModify';
import ProfileInfo from '../pages/User/ProfileInfo';
import PageNotFound from '../pages/PageNotFound';
import EmailConfirm from '../pages/EmailConfirm';
import PasswordReset from '../pages/PasswordReset';
import PasswordChange from '../pages/PasswordChange';
import QRCode from '../pages/QRCode';
import EnterWithEmail from '../pages/EnterWithEmail';
import OptionModify from '../pages/Product/OptionModify';
import RestaurantMenu from '../pages/RestaurantMenu';
import Contacts from '../pages/Contacts';
import Staff from '../pages/ControlCenter/Staff';
import StaffModify from '../pages/ControlCenter/Staff/StaffModify';
import RestaurantOptions from '../pages/RestaurantOptions';
import PosSystems from '../pages/PosSystems';
import Localization from '../pages/Localization';
import Checkout from '../pages/Checkout';
import Order from '../pages/Order';
import ThankYouPage from '../pages/ThankYouPage';
import SendFeedback from '../pages/SendFeedback';
import SubscriptionSuccess from '../pages/SubscriptionResults/SubscriptionSuccess';
import SubscriptionError from '../pages/SubscriptionResults/SubscriptionError';
import OptionsGroupList from '../pages/OptionGroupsList';
import OptionsGroupCreate from '../pages/OptionGroupCreate';
import OptionsGroupSettings from '../pages/OptionGroupSettings';
import ControlCenter from '../pages/ControlCenter/ControlCenter';
import SeoManagement from '../pages/SeoManagement';

const common = [
  {
    name: 'PageNotFound',
    path: '/page-not-found',
    component: PageNotFound,
    isPrivate: false,
    exact: true,
  },

  {
    name: 'EnterWithEmail',
    path: '/enter-with-email',
    component: EnterWithEmail,
    isPrivate: false,
    exact: true,
  },
  {
    name: 'EmailConfirmCode',
    path: '/verification-email/confirm/:code',
    component: EmailConfirm,
    isPrivate: false,
    exact: true,
  },
  {
    name: 'EmailConfirm',
    path: '/verification-email/confirm',
    component: EmailConfirm,
    isPrivate: false,
    exact: true,
  },

  {
    name: 'PasswordResetCode',
    path: '/reset-password/:code',
    component: PasswordReset,
    isPrivate: false,
    exact: true,
  },
  {
    name: 'PasswordReset',
    path: '/reset-password',
    component: PasswordReset,
    isPrivate: false,
    exact: true,
  },
  {
    name: 'Start',
    path: '/',
    component: Start,
    isPrivate: false,
    exact: true,
  },
];

const profile = [
  {
    name: 'PasswordChange',
    path: `/password-change`,
    component: PasswordChange,
    isPrivate: true,
    exact: true,
  },
  {
    name: 'ProfileInfo',
    path: `/profile/info`,
    component: ProfileInfo,
    isPrivate: true,
    exact: true,
  },
];

const restaurant = [
  {
    name: 'RestaurantsList',
    path: `/restaurants`,
    component: RestaurantsList,
    isPrivate: true,
    exact: true,
  },
  {
    name: 'RestaurantCreate',
    path: `/restaurant/create`,
    component: RestaurantModify,
    isPrivate: true,
    exact: true,
  },
  {
    name: 'Restaurant',
    path: `/restaurant/:slug`,
    component: Restaurant,
    isPrivate: false,
    exact: true,
  },
  {
    name: 'RestaurantPreview',
    path: `/restaurant/:slug/preview`,
    component: Restaurant,
    isPrivate: true,
    exact: true,
  },
  {
    name: 'RestaurantEdit', // TODO maybe remove
    path: `/restaurant/:slug/edit`,
    component: RestaurantModify,
    isPrivate: true,
    exact: true,
  },
  {
    name: 'Contacts',
    path: `/restaurant/:slug/contacts`,
    component: Contacts,
    isPrivate: true,
    exact: true,
  },
  {
    name: 'QRCode',
    path: `/restaurant/:slug/qr-code`,
    component: QRCode,
    isPrivate: true,
    exact: true,
  },
  {
    name: 'SubscriptionSuccess',
    path: `/restaurant/:slug/subscription/success`,
    component: SubscriptionSuccess,
    isPrivate: true,
    exact: true,
  },
  {
    name: 'SubscriptionError',
    path: `/restaurant/:slug/subscription/error`,
    component: SubscriptionError,
    isPrivate: true,
    exact: true,
  },
  {
    name: 'SendFeedback',
    path: `/restaurant/:slug/feedback`,
    component: SendFeedback,
    isPrivate: false,
    exact: true,
  },
  {
    name: 'PosSystems',
    path: `/restaurant/:slug/pos-system`,
    component: PosSystems,
    isPrivate: true,
    exact: true,
  },
  {
    name: 'PosSystems',
    path: `/restaurant/:slug/seo`,
    component: SeoManagement,
    isPrivate: true,
    exact: true,
  },
  {
    name: 'PosSystems',
    path: `/restaurant/:slug/localization`,
    component: Localization,
    isPrivate: true,
    exact: true,
  },
  {
    name: 'OptionGroupsList',
    path: `/restaurant/:slug/option-groups/`,
    component: OptionsGroupList,
    isPrivate: true,
    exact: true,
  },
  {
    name: 'OptionsGroupCreate',
    path: `/restaurant/:slug/option-groups/create`,
    component: OptionsGroupCreate,
    isPrivate: true,
    exact: true,
  },
  {
    name: 'OptionsGroupCreate',
    path: `/restaurant/:slug/option-groups/:groupId`,
    component: OptionsGroupCreate,
    isPrivate: true,
    exact: true,
  },
];

const menu = [
  {
    name: 'RestaurantMenu',
    path: `/restaurant/:slug/menu/:menuId`,
    component: RestaurantMenu,
    isPrivate: false,
    exact: true,
  },
  {
    name: 'RestaurantMenuPreview',
    path: `/restaurant/:slug/menu/:menuId/preview`,
    component: RestaurantMenu,
    isPrivate: true,
    exact: true,
  },
];

const controlCenter = [
  {
    name: 'Staff',
    path: `/restaurant/:slug/control-center/staff`,
    component: Staff,
    isPrivate: true,
    exact: true,
  },
  {
    name: 'StaffCreate',
    path: `/restaurant/:slug/control-center/staff/create`,
    component: StaffModify,
    isPrivate: true,
    exact: true,
  },
  {
    name: 'StaffEdit',
    path: `/restaurant/:slug/control-center/staff/:workerId`,
    component: StaffModify,
    isPrivate: true,
    exact: true,
  },
  {
    name: 'RestaurantControlCenter',
    path: `/restaurant/:slug/control-center`,
    component: ControlCenter,
    isPrivate: true,
    exact: true,
  },
];

const product = [
  {
    name: 'ProductCreate',
    path: `/restaurant/:slug/product/create`,
    component: ProductModify,
    isPrivate: true,
    exact: true,
  },
  {
    name: 'ProductModify',
    path: `/restaurant/:slug/product/:productId`,
    component: ProductModify,
    isPrivate: true,
    exact: true,
  },
  {
    name: 'OptionGroupsList',
    path: `/restaurant/:slug/product/:productId/option-groups/`,
    component: OptionsGroupList,
    isPrivate: true,
    exact: true,
  },
  {
    name: 'OptionsGroupCreate',
    path: `/restaurant/:slug/product/:productId/option-groups/create`,
    component: OptionsGroupCreate,
    isPrivate: true,
    exact: true,
  },
  {
    name: 'OptionsGroupCreate',
    path: `/restaurant/:slug/product/:productId/option-groups/:groupId`,
    component: OptionsGroupCreate,
    isPrivate: true,
    exact: true,
  },
  {
    name: 'OptionsGroupSettings',
    path: `/restaurant/:slug/product/:productId/option-groups/:groupId/settings`,
    component: OptionsGroupSettings,
    isPrivate: true,
    exact: true,
  },
  {
    name: 'OptionsGroupSettings',
    path: `/restaurant/:slug/product/:productId/option-groups/:groupId/settings/:settingId`,
    component: OptionsGroupSettings,
    isPrivate: true,
    exact: true,
  },
];

const options = [
  {
    name: 'RestaurantOptions',
    path: `/restaurant/:slug/options`,
    component: RestaurantOptions,
    isPrivate: true,
    exact: true,
  },
  {
    name: 'OptionCreate',
    path: `/restaurant/:slug/option/create`,
    component: OptionModify,
    isPrivate: true,
    exact: true,
  },
  {
    name: 'OptionEdit',
    path: `/restaurant/:slug/option/edit`,
    component: OptionModify,
    isPrivate: true,
    exact: true,
  },
];

const order = [
  {
    name: 'Order',
    path: `/restaurant/:slug/order`,
    component: Order,
    isPrivate: false,
    exact: true,
  },
  {
    name: 'Checkout',
    path: `/restaurant/:slug/checkout`,
    component: Checkout,
    isPrivate: false,
    exact: true,
  },
  {
    name: 'ThankYouPage',
    path: `/order-success/:paymentId`,
    component: ThankYouPage,
    isPrivate: false,
    exact: true,
  },
  {
    name: 'ThankYouPage',
    path: `/order-failure/:paymentId`,
    component: SubscriptionError,
    isPrivate: false,
    exact: true,
  },
  {
    name: 'ThankYouPage',
    path: `/order-success`,
    component: ThankYouPage,
    isPrivate: false,
    exact: true,
  },
];

export default [
  ...restaurant,
  ...controlCenter,
  ...menu,
  ...product,
  ...options,
  ...profile,
  ...order,
  ...common,
];
