import React from 'react';
import * as Sentry from '@sentry/browser';

class ErrorBoundary extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error,
      errorInfo,
    });
    // Log in Sentry servers
    Sentry.captureException(new Error(error));
    // eslint-disable-next-line no-console
    console.error(error);
    console.info(errorInfo);
  }

  render() {
    const { error, errorInfo } = this.state;
    // eslint-disable-next-line react/prop-types
    const { children } = this.props;
    if (errorInfo) {
      // Error path
      return (
        <div className="error">
          <h1 className="error__header">Помилка</h1>
          <details className="error__content">
            {error && error.toString()}
            <br />
            {errorInfo.componentStack}
          </details>
        </div>
      );
    }
    // Normally, just render children
    return children;
  }
}

export default ErrorBoundary;
