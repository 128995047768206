import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import Icon from './common/Icon';
import { toggleSideMenu } from '../redux/reducers/ui/actions';

const Header = ({
  className,
  logo,
  title,
  children,
  isAuthenticated,
  style = {},
  leftIcon,
  rightIcon,
  showSideMenu,
  onLeftIconClick,
  onRightIconClick,
  toggleSideMenu,
  onClick,
}) => {
  return (
    <header
      data-aos-id="app-header"
      data-aos="fade-up"
      style={style}
      className={`app-header ${className || ''}`}
      onClick={onClick}
    >
      <div className="app-header__container">
        {leftIcon && (
          <Icon
            className="app-header__left-icon"
            name={leftIcon}
            onClick={onLeftIconClick}
          />
        )}
        {title && <h1 className="app-header__title">{title}</h1>}
        <Link className="app-header__title" to="/">
          {logo && !title && (
            <h1>
              <Icon name="logo" />
            </h1>
          )}
        </Link>
        {rightIcon && !showSideMenu && (
          <Icon
            className="app-header__right-icon"
            name={rightIcon}
            onClick={onRightIconClick}
          />
        )}

        {isAuthenticated && showSideMenu && (
          <Icon
            className="app-header__right-icon"
            name="menu"
            onClick={toggleSideMenu}
          />
        )}
        {children}
      </div>
    </header>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { toggleSideMenu })(Header);

Header.defaultProps = {
  isAuthenticated: false,
  className: null,
  logo: false,
  showSideMenu: false,
  title: '',
  backgroundImage: null,
  children: null,
  leftIcon: null,
  rightIcon: null,
  onLeftIconClick: () => {},
  onRightIconClick: () => {},
};

Header.PropsTypes = {
  className: PropTypes.string,
  isAuthenticated: PropTypes.boolean,
  logo: PropTypes.boolean,
  title: PropTypes.string,
  backgroundImage: PropTypes.string,
  showBack: PropTypes.boolean,
  showSideMenu: PropTypes.boolean,
  children: PropTypes.node,
  leftIcon: PropTypes.string,
  rightIcon: PropTypes.string,
  onLeftIconClick: PropTypes.func,
  onRightIconClick: PropTypes.func,
};
