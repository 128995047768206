const initialState = {
  location: {},
  action: null,
  isFirstRendering: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case '@router/LOCATION_CHANGE':
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
