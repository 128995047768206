import { CHANGE_WIDTH, FIRST_LOAD_COMPLETED, TOGGLE_SIDE_MENU } from './types';

const initialState = {
  width: window.innerWidth,
  firstLoadCompleted: false,
  showSideMenu: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_WIDTH:
      return {
        ...state,
        width: action.payload.width,
      };
    case FIRST_LOAD_COMPLETED:
      return {
        ...state,
        firstLoadCompleted: true,
      };
    case TOGGLE_SIDE_MENU:
      return {
        ...state,
        showSideMenu: !state.showSideMenu,
      };
    default:
      return state;
  }
}
