import React from 'react';

import { connectModal } from 'redux-modal';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Modal from '../common/Modal';
import Footer from '../Footer';
import { logout } from '../../redux/reducers/auth/actions';

export const MODAL_NAME = 'restaurant-properties-modal';

const RestaurantPropertiesModal = ({
  show,
  handleHide,
  logout,
  restaurant,
}) => {
  const { t } = useTranslation();

  const LINKS = [
    {
      name: t('side_menu.restaurant_menu'),
      to: `/restaurant/${restaurant.slug}`,
    },
    {
      name: t('side_menu.restaurant_options'),
      to: `/restaurant/${restaurant.slug}/options`,
    },
    {
      name: t('side_menu.localization'),
      to: `/restaurant/${restaurant.slug}/localization`,
    },
    {
      name: t('side_menu.contacts'),
      to: `/restaurant/${restaurant.slug}/contacts`,
    },
    {
      name: t('side_menu.pos_systems'),
      to: `/restaurant/${restaurant.slug}/pos-system`,
    },
    {
      name: t('side_menu.control_center'),
      to: `/restaurant/${restaurant.slug}/control-center`,
    },
    {
      name: t('seo.title'),
      to: `/restaurant/${restaurant.slug}/seo`,
    },
  ];

  const handleLogoutClick = () => {
    logout();
    handleHide();
  };

  return (
    <Modal isOpen={show} onClose={handleHide} title={restaurant.name}>
      <div
        data-aos="fade-up"
        data-aos-delay="400"
        className="restaurant-opportunity-modal__body"
      >
        <div className="restaurant-opportunity-modal__list">
          {LINKS.map((link) => (
            <Link
              to={link.to}
              onClick={handleHide}
              key={link.to}
              className="restaurant-opportunity-modal__item"
            >
              {link.name}
            </Link>
          ))}
        </div>

        <div className="restaurant-opportunity-modal__links">
          <Link
            onClick={handleHide}
            className="restaurant-opportunity-modal__link"
            to="/restaurants"
          >
            {t('side_menu.restaurants')}
          </Link>
          <Link
            onClick={handleHide}
            className="restaurant-opportunity-modal__link"
            to="/profile/info"
          >
            {t('side_menu.profile')}
          </Link>
          <Link
            onClick={handleLogoutClick}
            className="restaurant-opportunity-modal__link"
            to="/"
          >
            {t('side_menu.logout')}
          </Link>
        </div>

        <Footer className="mt-5" />
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  restaurant: state.restaurant.restaurantData,
});

export default connectModal({ name: MODAL_NAME, destroyOnHide: true })(
  connect(mapStateToProps, { logout })(RestaurantPropertiesModal)
);

RestaurantPropertiesModal.defaultProps = {
  restaurantId: '',
};
