import { del, get, post, put, patch } from '../utils/axios';

const restaurantsAPI = {
  create: async (data) => {
    try {
      return await post('/restaurants/', data, null, true);
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },

  update: async (data) => {
    try {
      return await put(`/restaurants/${data.id}/`, data, null, true);
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },

  patch: async (data) => {
    try {
      return await patch(`/restaurants/${data.id}/`, data, null, true);
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },

  get: async (slug, language) => {
    try {
      return await get(`/restaurants/${slug}/`, null, true, language);
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },

  getList: async () => {
    try {
      return await get('/restaurants/', null, true);
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },

  getOptions: async (id, lang) => {
    try {
      return await get(`/restaurants/${id}/options/`, null, true, lang);
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },

  getTakeAwayDelivery: async (id) => {
    try {
      return await get(`/restaurants/${id}/takeaway_delivery/`, null, true);
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },

  remove: async (id) => {
    try {
      return await del(`/restaurants/${id}/`, null, true, true);
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },

  getCategories: async (id) => {
    try {
      return await get(`/restaurants/${id}/categories/`, null, true);
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },

  generateQRcode: async (id, quantity) => {
    try {
      return await post(
        `/restaurants/${id}/generate_qrcode/`,
        { quantity },
        null,
        true
      );
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },

  getMenuById: async (menuId, isPreview = false, language) => {
    // remove Auth for preview mode
    try {
      return isPreview
        ? await get(`/menu/${menuId}/`, null, false, language)
        : await get(`/menu/${menuId}/`, null, true, language);
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },

  getContacts: async (id) => {
    try {
      return await get(`/restaurants/${id}/contacts/`, null, true);
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },

  getStaff: async (id) => {
    try {
      return await get(`/restaurants/${id}/staff/`, null, true);
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },

  getIikoOrganizations: async (data) => {
    try {
      return await post(
        `/restaurants/get_iiko_organizations/`,
        data,
        null,
        true
      );
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },

  syncMenu: async (id) => {
    try {
      return await post(`/restaurants/${id}/sync_menu/`, null, null);
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },

  getIikoPayments: async (id) => {
    try {
      return await get(
        `/restaurants/${id}/get_iiko_payment_types/`,
        null,
        true
      );
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },

  getExtra: async (id) => {
    try {
      return await get(`/restaurants/${id}/extra/`, null, true);
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },
  getRestaurantOptionGroups: async (id) => {
    try {
      return await get(`/restaurants/${id}/option_groups/`, null, true);
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },
  getRestaurantOptionGroup: async (restaurantId, groupId, lang) => {
    try {
      return await get(`/option-groups/${groupId}`, null, true, lang);
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },
  deleteRestaurantOptionGroup: async (groupId) => {
    try {
      return await del(`/option-groups/${groupId}`, null, null, true);
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },
  getOptionGroupSettings: async (settingsId, currentLang) => {
    try {
      return await get(
        `/option-groups-settings/${settingsId}`,
        null,
        true,
        currentLang
      );
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },

  createOptionGroupSettings: async (data) => {
    try {
      return await post(`/option-groups-settings/`, data, null, true);
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },

  updateOptionGroupSettings: async (id, data) => {
    try {
      return await patch(`/option-groups-settings/${id}/`, data, null, true);
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },

  deleteOptionGroupSettings: async (id) => {
    try {
      return await del(`/option-groups-settings/${id}/`, null, null, true);
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },

  getOptionGroup: async (id, currentLang) => {
    try {
      return await get(`/option-groups/${id}/`, null, true, currentLang);
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },
  createRestaurantOptionGroup: async (restaurant, name, options) => {
    try {
      return await post(
        `/option-groups/`,
        { restaurant, name, options },
        null,
        true
      );
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },
  updateRestaurantOptionGroup: async (
    restaurant,
    name,
    options,
    groupId,
    lang
  ) => {
    try {
      return await patch(
        `/option-groups/${groupId}`,
        { restaurant, [`name_${lang}`]: name, options },
        null,
        true
      );
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },

  createNewSession: async (restaurantsId, tableId) => {
    try {
      return await post(`/restaurants/${restaurantsId}/new_session_by_table/`, {
        id: tableId,
      });
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },
};

export default restaurantsAPI;
