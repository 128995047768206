import categoriesAPI from '../api/categories';
import restaurantsAPI from '../api/restaurants';
import productAPI from '../api/product';

export default function useCategoryOrProductMove(
  menuId,
  setMenu,
  showConfirmPhoneModal,
  menuLang
) {
  const handleCategoryOrProductMove = async (
    list,
    itemId,
    sourceIndex,
    api,
    upDirection = true
  ) => {
    // cant move first to up or last to down
    if (upDirection && !sourceIndex) return;
    if (!upDirection && sourceIndex === list.length) return;

    try {
      const destinationIndex = upDirection ? sourceIndex - 1 : sourceIndex + 1;
      switch (api) {
        case 'product':
          await productAPI.move(itemId, sourceIndex, destinationIndex);
          break;
        case 'category':
          await categoriesAPI.move(itemId, sourceIndex, destinationIndex);
          break;
        default:
          return;
      }

      const { data } = await restaurantsAPI.getMenuById(
        menuId,
        false,
        menuLang
      );
      setMenu(data);
    } catch (e) {
      if (e.code === 403) {
        showConfirmPhoneModal();
      }
    }
  };
  return { handleCategoryOrProductMove };
}
