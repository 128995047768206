import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from '../../components/common/Button';
import Input from '../../components/common/Input';
import restaurantsAPI from '../../api/restaurants';
import { fetchRestaurantFromApi } from '../../redux/reducers/restaurant/actions';
import { showConfirmPhoneModal } from '../../redux/reducers/auth/actions';
import RestaurantHeader from '../../components/RestaurantHeader';
import Footer from '../../components/Footer';

const RestaurantModify = ({
  fetchRestaurantFromApi,
  showConfirmPhoneModal,
}) => {
  const [restaurant, setRestaurant] = useState({
    name: '',
  });
  const [errors, setErrors] = useState({});
  const [background, setBackground] = useState('');
  const [logo, setLogo] = useState('');
  const history = useHistory();
  const { t } = useTranslation();

  const handleSaveClick = async (e) => {
    e.preventDefault();
    setErrors({});
    try {
      const newRestaurant = { name: restaurant.name };

      if (background) {
        newRestaurant.image = background;
      }

      if (logo) {
        newRestaurant.logo = logo;
      }
      const { data } = await restaurantsAPI.create(newRestaurant);
      await fetchRestaurantFromApi(data.slug);
      history.push({
        pathname: `/restaurant/${data.slug}`,
      });
    } catch (e) {
      setErrors(e);
      if (e.code === 403) {
        showConfirmPhoneModal();
      }
    }
  };

  const handleNameChange = (value) => {
    setRestaurant({ ...restaurant, name: value });
  };

  const handleBackClick = () => {
    history.push({
      pathname: '/restaurants',
      state: { showEmptyList: true },
    });
  };

  return (
    <div className="page restaurant-modify">
      <RestaurantHeader
        restaurant={restaurant}
        onBackClick={handleBackClick}
        isPreviewMode={false}
        isShowControls
        isNew
        callback={{ setBackground, setLogo }}
      />
      <form
        data-aos="fade-up"
        data-aos-delay="400"
        className="restaurant-modify__body"
        onSubmit={handleSaveClick}
      >
        <Input
          error={errors.name}
          value={restaurant.name}
          onChange={handleNameChange}
          placeholder={t('restaurant_create.name_placeholder')}
        />
        <Button
          disabled={restaurant.name.length === 0}
          className="restaurant-modify__save-btn"
          type="submit"
        >
          {t('restaurant_create.add_restaurant')}
        </Button>
      </form>
      <Footer />
    </div>
  );
};

export default connect(null, { fetchRestaurantFromApi, showConfirmPhoneModal })(
  RestaurantModify
);
