import React, { useEffect, useState } from 'react';

import { Link, useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { show } from 'redux-modal';
import { useTranslation } from 'react-i18next';
import Button from '../components/common/Button';
import Icon from '../components/common/Icon';
import Header from '../components/Header';
import { showConfirmPhoneModal } from '../redux/reducers/auth/actions';
import restaurantsAPI from '../api/restaurants';
import Input from '../components/common/Input';
import ProductOption from '../components/ProductOption';
import settingsApi from '../api/settings';
import ContentLanguageSelector from '../components/common/ContentLanguageSelector';
import Loader from '../components/Loader';
import useRestaurantControls from '../hooks/useRestaurantControls';

const OptionGroupCreate = ({ restaurant, show }) => {
  const history = useHistory();
  const [restaurantOptions, setRestaurantOptions] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [optionGroupName, setOptionGroupName] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [errors, setErrors] = useState({});
  const { slug, groupId, productId } = useParams();
  const { t } = useTranslation();
  const [languageOptions, setLanguages] = useState([]);
  const [currentLang, setCurrentLang] = useState(
    window.localStorage.getItem('language')
      ? window.localStorage.getItem('language')
      : 'en'
  );

  useEffect(() => {
    settingsApi.getSystemSettings().then((data) => {
      const options = data.languages
        .filter((language) => restaurant?.languages.includes(language.code))
        .map((language) => {
          return {
            label: language.full,
            value: language.short,
            id: language.code,
            onClick: () => {
              setCurrentLang(language.code);
              window.localStorage.setItem('language', language.code);
            },
          };
        });
      if (options.length > 0 && options.length < 2) {
        setCurrentLang(options[0].id);
        window.localStorage.setItem('language', options[0].id);
      }
      setLanguages(options);
    });
  }, [restaurant?.languages]);

  const handleBackClick = () => {
    history.goBack();
  };

  const handleUpdateOptions = async () => {
    const { data } = await restaurantsAPI.getOptions(
      restaurant.id,
      currentLang
    );
    setRestaurantOptions(data);
    setFilteredOptions(data);
  };
  // restaurantAPI.getRestaurantOptionGroups
  useEffect(() => {
    if (restaurant?.id) {
      handleUpdateOptions().catch((e) => {
        console.error(e);
        if (e.code === 403) {
          showConfirmPhoneModal();
        }
      });
    }
    // eslint-disable-next-line
    }, [restaurant, showConfirmPhoneModal, currentLang]);

  const handleGetGroupData = async () => {
    const { data } = await restaurantsAPI.getRestaurantOptionGroup(
      restaurant.id,
      groupId,
      currentLang
    );
    setOptionGroupName(data[`name_${currentLang}`]);
    setSelectedOptions(data.options.map((option) => option.id));
  };
  // restaurantAPI.getRestaurantOptionGroups
  useEffect(() => {
    if (restaurant?.id && groupId) {
      handleGetGroupData().catch((e) => {
        console.error(e);
        if (e.code === 403) {
          showConfirmPhoneModal();
        }
      });
    }
    // eslint-disable-next-line
    }, [restaurant, showConfirmPhoneModal, currentLang]);

  const saveButtonDisabled = () =>
    optionGroupName === '' || selectedOptions.length < 2;
  const handleSearch = (val) => {
    setSearchValue(val);
    const availableOptions = restaurantOptions.filter((group) =>
      group.name.toLowerCase().includes(val.toLowerCase())
    );
    filteredOptions(availableOptions);
  };
  const handleNameChange = (val) => {
    setErrors({ ...errors, name: '' });
    setOptionGroupName(val);
  };

  const saveMethod = () => {
    if (groupId) {
      return restaurantsAPI.updateRestaurantOptionGroup(
        restaurant.id,
        optionGroupName,
        selectedOptions,
        groupId,
        currentLang
      );
    }
    return restaurantsAPI.createRestaurantOptionGroup(
      restaurant.id,
      optionGroupName,
      selectedOptions
    );
  };

  const handleSave = async () => {
    try {
      setErrors({});
      const { data } = await saveMethod();
      if (groupId || !productId) {
        history.goBack();
      } else {
        history.push(
          `/restaurant/${slug}/product/${productId}/option-groups/${data.id}/settings`
        );
      }
    } catch (e) {
      console.error(e);
      setErrors(e);
    }
  };

  const checkIsSelectedOption = (option) => {
    const existedOption = selectedOptions.find((item) => item === option.id);
    return !!existedOption;
  };

  const handleOptionChange = (option) => {
    const changedOption = selectedOptions.find((item) => item === option.id);

    if (changedOption) {
      const newOptions = selectedOptions.filter((item) => item !== option.id);
      setSelectedOptions(newOptions);
    } else {
      const newOptions = selectedOptions.concat(option.id);
      setSelectedOptions(newOptions);
    }
  };
  const { isShowControls } = useRestaurantControls(restaurant);

  if (languageOptions.length < 1) return <Loader />;

  return (
    <div className="page option-groups">
      <Header
        title={t('option_groups.title')}
        onLeftIconClick={handleBackClick}
        leftIcon="back"
      />
      <div
        data-aos="fade-up"
        data-aos-delay="400"
        className="option-groups__body"
      >
        <div className="language-block language-block__options-groups">
          <ContentLanguageSelector
            options={languageOptions}
            value={
              languageOptions.find((option) => option.id === currentLang).value
            }
            isShowControls={isShowControls}
            label="option_group_translation"
            show={show}
            use
          />
        </div>
        <p className="option-groups__hint">
          {t('option_groups.creation_hint')}
        </p>
        <div className="option-groups__content">
          <Input
            placeholder={t('option_groups.name_placeholder')}
            onChange={handleNameChange}
            value={optionGroupName}
            required
            error={errors.name}
            type="text"
          />
          <Input
            leftIcon="search"
            placeholder={t('option_groups.search_placeholder')}
            onChange={handleSearch}
            value={searchValue}
          />
        </div>
        <Link
          className="product-options__link"
          to={`/restaurant/${slug}/option/create`}
        >
          <Icon name="plus" />
          {t('options.add_options')}
        </Link>

        <div className="product-options__list">
          {restaurantOptions.map((option) => (
            <ProductOption
              key={option.id}
              option={option}
              isProductSelect
              checkIsSelectedOption={checkIsSelectedOption}
              handleOptionChange={handleOptionChange}
            />
          ))}
        </div>

        <Link
          className="product-options__link"
          to={`/restaurant/${slug}/option/create`}
        >
          <Icon name="plus" />
          {t('options.add_options')}
        </Link>

        {errors.non_field_errors && (
          <div className="error-box error-box_center">
            <p>{errors.non_field_errors}</p>
          </div>
        )}

        <Button
          className="option-groups__save-btn"
          type="submit"
          onClick={handleSave}
          disabled={saveButtonDisabled()}
        >
          {t('option_groups.save')}
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  restaurant: state.restaurant.restaurantData,
});

export default connect(mapStateToProps, { show })(OptionGroupCreate);
