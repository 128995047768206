import React from 'react';

const dropdown = {
  'dropdown-plus': (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 0V6H0V8H6V14H8V8H14V6H8V0H6Z"
        fill="#8B8592"
      />
    </svg>
  ),
  card: (
    <svg
      width="34"
      height="34"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 34 34"
    >
      <g>
        <path
          id="svg_1"
          fill="#8B8592"
          d="m25,9l-16,0c-1.1,0 -2,0.9 -2,2l0,12c0,1.1 0.9,2 2,2l16,0c1.1,0 2,-0.9 2,-2l0,-12c0,-1.1 -0.9,-2 -2,-2zm0,14l-16,0l0,-7l16,0l0,7zm-16,-10l0,-2l16,0l0,2l-16,0z"
        />
      </g>
    </svg>
  ),
  pen: (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="17" cy="17" r="16.5" fill="white" stroke="white" />
      <path
        d="M20.3104 10.8102C19.9186 10.4184 19.2829 10.4199 18.8929 10.8136L9.28958 20.5075C9.10407 20.6948 9 20.9477 9 21.2113V23.9998C9 24.5521 9.44772 24.9998 10 24.9998H12.7885C13.0521 24.9998 13.3051 24.8957 13.4923 24.7102L23.1862 15.1069C23.5799 14.7169 23.5814 14.0812 23.1896 13.6894L20.3104 10.8102ZM12.4 22.9998H11V21.5998L19.6 12.9998L21 14.3998L12.4 22.9998ZM26 10.8998C26.3866 11.2864 26.3866 11.9132 26 12.2998C25.6134 12.6864 24.9866 12.6864 24.6 12.2998L21.7 9.39981C21.3134 9.01321 21.3134 8.3864 21.7 7.9998C22.0866 7.61321 22.7134 7.61321 23.1 7.9998L26 10.8998Z"
        fill="#8B8592"
      />
    </svg>
  ),
  hide: (
    <svg
      width="24"
      height="18"
      viewBox="0 0 24 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.0002 9.00005C17.0002 9.20005 17.0002 9.50005 16.9002 9.70005L14.1002 6.90005L11.3002 4.10005C11.5002 4.00005 11.8002 4.00005 12.0002 4.00005C14.8002 4.00005 17.0002 6.20005 17.0002 9.00005ZM12.0002 1.00005C11.4611 1.00005 10.9219 1.04809 10.3828 1.1324C9.62421 1.25104 9.38677 2.18663 9.92971 2.72956C10.2126 3.01242 10.6421 3.09455 11.0388 3.0428C11.3665 3.00005 11.7012 3.00005 12.0002 3.00005C16.0002 3.00005 19.6002 5.40005 21.2002 9.00005C20.8437 9.7844 20.3855 10.5179 19.8618 11.1643C19.5177 11.5891 19.5136 12.2134 19.9002 12.6C20.2868 12.9866 20.9173 12.989 21.2659 12.5678C22.0632 11.6045 22.698 10.4997 23.1703 9.33323C23.2571 9.11889 23.2609 8.87964 23.1797 8.66315C21.4832 4.14311 17.075 1.00005 12.0002 1.00005ZM20.5002 16.1C20.8868 16.4866 20.8868 17.1134 20.5002 17.5C20.1136 17.8866 19.4868 17.8866 19.1002 17.5L17.4002 15.8C15.7002 16.5 13.9002 17 12.0002 17C6.92063 17 2.50901 13.8511 0.816002 9.32433C0.737886 9.11546 0.738611 8.88508 0.818013 8.67669C1.64256 6.51275 3.0898 4.63403 4.9002 3.30005L3.5002 1.90005C3.1136 1.51345 3.1136 0.886648 3.5002 0.500049C3.88679 0.113449 4.5136 0.11345 4.9002 0.500049L20.5002 16.1ZM9.3002 7.70005C9.1002 8.10005 9.0002 8.50005 9.0002 9.00005C9.0002 10.6 10.4002 12 12.0002 12C12.5002 12 12.9002 11.9 13.3002 11.7L9.3002 7.70005ZM15.8002 14.2L14.7002 13.1C14.0002 13.7 13.0002 14 12.0002 14C9.2002 14 7.0002 11.8 7.0002 9.00005C7.0002 8.00005 7.3002 7.00005 7.8002 6.20005L6.4002 4.80005C4.9002 5.80005 3.6002 7.30005 2.9002 9.00005C4.5002 12.6 8.1002 15 12.1002 15C13.3002 15 14.6002 14.7 15.8002 14.2Z"
        fill="#8B8592"
      />
    </svg>
  ),

  delete: (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="17" cy="17" r="16.5" fill="white" stroke="white" />
      <path
        d="M21 14V24H13V14H21ZM23 13C23 12.4477 22.5523 12 22 12H12C11.4477 12 11 12.4477 11 13V24C11 25.1 11.9 26 13 26H21C22.1 26 23 25.1 23 24V13ZM24 10C24 9.44772 23.5523 9 23 9H20.4L19.6929 8.29289C19.5054 8.10536 19.251 8 18.9858 8H15.0142C14.749 8 14.4946 8.10536 14.3071 8.29289L13.6 9H11C10.4477 9 10 9.44772 10 10C10 10.5523 10.4477 11 11 11H14.4L15.4 10H18.6L19.6 11H23C23.5523 11 24 10.5523 24 10Z"
        fill="#8B8592"
      />
    </svg>
  ),
  change: (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9995 24.9999C18.0995 24.9999 19.9995 24.2999 21.4995 23.0999C21.9995 22.6999 21.9995 21.9999 21.5995 21.5999C21.1995 21.1999 20.6995 21.1999 20.2995 21.4999C19.0995 22.4999 17.5995 22.9999 15.9995 22.9999C12.8995 22.9999 10.1995 20.9999 9.29955 18.1999L10.5995 18.9999C11.1995 19.1999 11.7995 18.9999 11.9995 18.4999C12.2995 17.9999 12.0995 17.3999 11.5995 17.0999L8.09955 15.0999C7.59955 14.7999 6.99955 14.9999 6.69955 15.4999L4.69955 18.9999C4.39955 19.4999 4.59955 20.0999 5.09955 20.3999C5.59955 20.6999 6.19955 20.4999 6.49955 19.9999L7.29955 18.5999C8.49955 22.2999 11.8995 24.9999 15.9995 24.9999Z"
        fill="#8B8592"
      />
      <path
        d="M26.7999 11.6C26.2999 11.3 25.6999 11.5 25.3999 12L24.5999 13.4C23.4999 9.7 20.0999 7 15.9999 7C13.8999 7 11.9999 7.7 10.4999 8.9C9.99987 9.3 9.99987 10 10.3999 10.4C10.7999 10.8 11.2999 10.8 11.6999 10.5C12.8999 9.5 14.3999 9 15.9999 9C19.0999 9 21.7999 11 22.6999 13.8L21.3999 13C20.7999 12.8 20.1999 13 19.9999 13.5C19.6999 14 19.8999 14.6 20.3999 14.9L23.8999 16.9C24.3999 17.2 24.9999 17 25.2999 16.5L27.2999 13C27.3999 12.4 27.2999 11.8 26.7999 11.6Z"
        fill="#8B8592"
      />
    </svg>
  ),
};

export default dropdown;
