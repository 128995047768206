import { get, post, put, del, patch } from '../utils/axios';

const productAPI = {
  create: async (data) => {
    try {
      return await post('/products/', data, null, true);
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },

  update: async (data) => {
    try {
      return await put(`/products/${data.id}/`, data, null, true);
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },

  patch: async ({ id, ...data }) => {
    try {
      return await patch(`/products/${id}/`, data, null, true);
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },

  get: async (id, language) => {
    try {
      return await get(`/products/${id}/`, null, true, language);
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },

  remove: async (id) => {
    try {
      return await del(`/products/${id}/`, null, true, true);
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },

  getUnits: async () => {
    try {
      return await get(`/units/`, null, true);
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },

  /**
   * DnD
   * @param id
   * @param origin - old index
   * @param destination - new index
   * @returns {Promise<*>}
   */
  move: async (id, origin, destination) => {
    try {
      return await put(
        `/products/${id}/move/`,
        { origin, destination },
        null,
        true
      );
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },

  updateOptions: async (id, options) => {
    try {
      return await put(`/products/${id}/update_options/`, options, null, true);
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },
};

export default productAPI;
