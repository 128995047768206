import { showConfirmPhoneModal } from '../reducers/auth/actions';
import { UPDATE_RESTAURANT_REQUEST } from '../reducers/restaurant/types';

const confirmPhoneMiddleware = (store) => (next) => (action) => {
  const result = next(action);
  if (action.type === UPDATE_RESTAURANT_REQUEST) {
    const { auth } = store.getState();
    if (auth.user?.phone_verification_required) {
      store.dispatch(showConfirmPhoneModal());
    } else {
      next(action);
    }
  }
  return result;
};
export default confirmPhoneMiddleware;
