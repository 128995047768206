import { fetchCart } from '../reducers/cart/actions';
import { fetchRestaurantFromApi } from '../reducers/restaurant/actions';

const isExistInPathnameRegExp = /\/restaurant\//gi;
const getRestaurantSlugFromPathname = (pathname) => {
  if (isExistInPathnameRegExp.test(pathname)) {
    const pathArray = pathname.split('/');
    const indexRestaurantStr = pathArray.indexOf('restaurant');
    if (indexRestaurantStr !== -1) {
      return pathArray[indexRestaurantStr + 1];
    }
    return null;
  }
  return null;
};

const routerMiddleware = (store) => (next) => (action) => {
  const result = next(action);
  if (action.type === '@@router/LOCATION_CHANGE') {
    const pathnameRestaurantSlug = getRestaurantSlugFromPathname(
      action.payload.location.pathname
    );
    if (pathnameRestaurantSlug) {
      const { restaurant } = store.getState();

      if (
        restaurant.restaurantData?.slug !== pathnameRestaurantSlug &&
        pathnameRestaurantSlug !== 'create'
      ) {
        store.dispatch(fetchRestaurantFromApi(pathnameRestaurantSlug));
        store.dispatch(fetchCart());
      }
    }
  }
  return result;
};

export default routerMiddleware;
