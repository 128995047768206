import React from 'react';
import STROKE_COLOR from './colors';

const common = {
  close: (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="17" cy="17" r="16.5" fill="white" stroke={STROKE_COLOR} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 11.4L22.6 10L17 15.6L11.4 10L10 11.4L15.6 17L10 22.6L11.4 24L17 18.4L22.6 24L24 22.6L18.4 17L24 11.4Z"
        fill="#8B8592"
      />
    </svg>
  ),
  back: (
    <svg
      width="34"
      height="34"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="19.5" fill="white" stroke={STROKE_COLOR} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.4 15.4L22 14L16 20L22 26L23.4 24.6L18.8 20L23.4 15.4Z"
        fill="#8B8592"
      />
    </svg>
  ),
  more: (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="17" cy="17" r="16.5" fill="white" stroke={STROKE_COLOR} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 13C18.1 13 19 12.1 19 11C19 9.9 18.1 9 17 9C15.9 9 15 9.9 15 11C15 12.1 15.9 13 17 13Z"
        fill="#8B8592"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 15C15.9 15 15 15.9 15 17C15 18.1 15.9 19 17 19C18.1 19 19 18.1 19 17C19 15.9 18.1 15 17 15Z"
        fill="#8B8592"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 21C15.9 21 15 21.9 15 23C15 24.1 15.9 25 17 25C18.1 25 19 24.1 19 23C19 21.9 18.1 21 17 21Z"
        fill="#8B8592"
      />
    </svg>
  ),
  marker: (
    <svg
      width="18"
      height="13"
      viewBox="0 0 18 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.6 10.1996L2.1 6.69961C1.7134 6.31301 1.0866 6.31301 0.7 6.69961C0.313401 7.08621 0.313401 7.71301 0.7 8.09961L4.89289 12.2925C5.28342 12.683 5.91658 12.683 6.30711 12.2925L16.9 1.69961C17.2866 1.31301 17.2866 0.686209 16.9 0.299609C16.5134 -0.08699 15.8866 -0.0869898 15.5 0.29961L5.6 10.1996Z"
        fill="#8B8592"
      />
    </svg>
  ),
  menu: (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="17" cy="17" r="16.5" fill="white" stroke={STROKE_COLOR} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 23H26V21H8V23Z"
        fill="#8B8592"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 18H26V16H8V18Z"
        fill="#8B8592"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 11V13H26V11H8Z"
        fill="#8B8592"
      />
    </svg>
  ),

  photo: (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="17" cy="17" r="16.5" fill="white" stroke={STROKE_COLOR} />
      <path
        d="M18.2 10L19.6 11.4L20.2 12H21H23V22H11V12H13H13.8L14.4 11.4L15.8 10H18.2ZM19 8H15L13 10H9V24H25V10H21L19 8ZM17 21C14.8 21 13 19.2 13 17C13 14.8 14.8 13 17 13C19.2 13 21 14.8 21 17C21 19.2 19.2 21 17 21ZM17 15C15.9 15 15 15.9 15 17C15 18.1 15.9 19 17 19C18.1 19 19 18.1 19 17C19 15.9 18.1 15 17 15Z"
        fill="#8B8592"
      />
    </svg>
  ),
  plus: (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="17" cy="17" r="16.5" fill="white" stroke="#20CA68" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 9C17.5523 9 18 9.44772 18 10V16H24C24.5523 16 25 16.4477 25 17C25 17.5523 24.5523 18 24 18H18V24C18 24.5523 17.5523 25 17 25C16.4477 25 16 24.5523 16 24V18H10C9.44772 18 9 17.5523 9 17C9 16.4477 9.44772 16 10 16H16V10C16 9.44772 16.4477 9 17 9Z"
        fill="#20CA68"
      />
    </svg>
  ),
  new_plus: (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0C8.55229 0 9 0.447715 9 1V7H15C15.5523 7 16 7.44772 16 8C16 8.55229 15.5523 9 15 9H9V15C9 15.5523 8.55229 16 8 16C7.44772 16 7 15.5523 7 15V9H1C0.447715 9 0 8.55229 0 8C0 7.44772 0.447715 7 1 7H7V1C7 0.447715 7.44772 0 8 0Z"
        fill="#8B8592"
      />
    </svg>
  ),
  minus: (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="17" cy="17" r="16.5" fill="white" stroke={STROKE_COLOR} />
      <rect x="10" y="16" width="14" height="2" fill="#8B8592" />
    </svg>
  ),
  moveTop: (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="17" cy="17" r="16.5" fill="white" stroke={STROKE_COLOR} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 13L11 19L12.4 20.4L17 15.8L21.6 20.4L23 19L17 13Z"
        fill="#8B8592"
      />
    </svg>
  ),
  moveDown: (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="17" cy="17" r="16.5" fill="white" stroke={STROKE_COLOR} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.6 13.5996L17 18.1996L12.4 13.5996L11 14.9996L17 20.9996L23 14.9996L21.6 13.5996Z"
        fill="#8B8592"
      />
    </svg>
  ),

  eye: (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="17" cy="17" r="16.5" fill="white" stroke={STROKE_COLOR} />
      <path
        d="M22 17C22 17.2 22 17.5 21.9 17.7L19.1 14.9L16.3 12.1C16.5 12 16.8 12 17 12C19.8 12 22 14.2 22 17ZM17 8.99999C15.9 8.99999 14.8 9.19999 13.7 9.49999L15.4 11.2C15.9 11 16.5 11 17 11C21 11 24.6 13.4 26.2 17C25.7 18.1 25 19.1 24.2 19.9L25.6 21.3C26.8 20.1 27.6999 18.6 28.2999 17C26.6999 12.3 22.2 8.99999 17 8.99999ZM26.2 24.8L24.7999 26.2L22.4 23.8C20.7 24.5 18.9 25 17 25C11.8 25 7.29995 21.7 5.69995 17C6.49995 14.7 7.99995 12.7 9.89995 11.3L7.79995 9.19999L9.19995 7.79999L26.2 24.8ZM14.3 15.7C14.1 16.1 14 16.5 14 17C14 18.6 15.4 20 17 20C17.5 20 17.9 19.9 18.3 19.7L14.3 15.7ZM20.8 22.2L19.7 21.1C19 21.7 18 22 17 22C14.2 22 12 19.8 12 17C12 16 12.3 15 12.8 14.2L11.4 12.8C9.89995 13.8 8.59995 15.3 7.89995 17C9.49995 20.6 13.1 23 17.1 23C18.3 23 19.6 22.7 20.8 22.2Z"
        fill="#8B8592"
      />
    </svg>
  ),
  ring: (
    <svg
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="21" cy="21" r="20.5" fill="#20CA68" stroke="#20CA68" />
      <path
        opacity="0.8"
        d="M28.7695 20.6453C28.039 19.7923 27.7071 19.0531 27.7071 17.7972V17.3701C27.7071 15.7335 27.3304 14.6791 26.5115 13.6246C25.2493 11.987 23.1244 11 21.0442 11H20.9558C18.9194 11 16.8611 11.9417 15.577 13.5128C14.7133 14.5884 14.2929 15.6882 14.2929 17.3701V17.7972C14.2929 19.0531 13.9828 19.7923 13.2305 20.6453C12.6769 21.2738 12.5 22.0815 12.5 22.9557C12.5 23.8309 12.7872 24.6598 13.3637 25.3336C14.116 26.1413 15.1785 26.6569 16.2638 26.7466C17.835 26.9258 19.4063 26.9933 21.0005 26.9933C22.5937 26.9933 24.165 26.8805 25.7372 26.7466C26.8215 26.6569 27.884 26.1413 28.6363 25.3336C29.2118 24.6598 29.5 23.8309 29.5 22.9557C29.5 22.0815 29.3231 21.2738 28.7695 20.6453Z"
        fill="white"
      />
      <path
        d="M23.0086 28.2284C22.5087 28.1216 19.4625 28.1216 18.9626 28.2284C18.5353 28.3271 18.0731 28.5567 18.0731 29.0602C18.098 29.5406 18.3792 29.9647 18.7688 30.2336L18.7678 30.2346C19.2717 30.6273 19.8631 30.8771 20.4822 30.9668C20.8122 31.0121 21.1481 31.0101 21.49 30.9668C22.1082 30.8771 22.6995 30.6273 23.2034 30.2346L23.2024 30.2336C23.592 29.9647 23.8733 29.5406 23.8981 29.0602C23.8981 28.5567 23.436 28.3271 23.0086 28.2284Z"
        fill="white"
      />
    </svg>
  ),
  filledRing: (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0C6.7 0 4 2.7 4 6V9C4 11.8 2.4 14.2 0 15.3V17H20V15.3C17.6 14.2 16 11.8 16 9V6C16 2.7 13.3 0 10 0ZM6.6 19C7.3 20.2 8.6 21 10.1 21C11.5 21 12.8 20.2 13.5 19H6.6Z"
        fill="white"
      />
    </svg>
  ),
  cart: (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="33"
        height="33"
        rx="16.5"
        fill="#20CA68"
        stroke="#20CA68"
      />
      <path
        opacity="0.8"
        d="M21.6203 27H12.3797C9.68923 27 7.5 24.8311 7.5 22.1646V16.8354C7.5 14.1689 9.68923 12 12.3797 12H21.6203C24.3108 12 26.5 14.1689 26.5 16.8354V22.1646C26.5 24.8311 24.3108 27 21.6203 27Z"
        fill="white"
      />
      <path
        d="M20.7551 15C20.344 15 20.0103 14.6763 20.0103 14.2775V11.3569C20.0103 9.75111 18.6635 8.44491 17.0089 8.44491C16.2472 8.44491 15.4477 8.7416 14.8786 9.28778C14.3085 9.83588 13.9927 10.5651 13.9897 11.3434V14.2775C13.9897 14.6763 13.656 15 13.2449 15C12.8337 15 12.5 14.6763 12.5 14.2775V11.3569C12.505 10.173 12.9777 9.08067 13.8298 8.26285C14.681 7.44311 15.7814 7.03179 17.0119 7C19.4849 7 21.5 8.95449 21.5 11.3569V14.2775C21.5 14.6763 21.1663 15 20.7551 15Z"
        fill="white"
      />
    </svg>
  ),
  circleCart: (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="17" cy="17" r="16.5" fill="#20CA68" stroke="#20CA68" />
      <path
        d="M22.9 14.0939L22.3 10.0522L22 8.03131H12L11.7 10.0522L11.2 14.0939H8.30005L8.60005 16.1148L9.70005 24.1983L10 26.2192H24L24.3 24.1983L25.4 16.1148L25.7001 14.0939H22.9ZM13.8 10.0522H20.3L20.9 14.0939H13.2L13.8 10.0522Z"
        fill="white"
      />
    </svg>
  ),

  profile: (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="17" cy="17" r="16.5" fill="white" stroke={STROKE_COLOR} />
      <path
        d="M16.997 20.1749C12.684 20.1749 9 20.8549 9 23.5749C9 26.2959 12.661 26.9999 16.997 26.9999C21.31 26.9999 24.994 26.3209 24.994 23.5999C24.994 20.8789 21.334 20.1749 16.997 20.1749Z"
        fill="#160B24"
      />
      <path
        opacity="0.4"
        d="M16.9971 17.584C19.9351 17.584 22.2891 15.229 22.2891 12.292C22.2891 9.355 19.9351 7 16.9971 7C14.0601 7 11.7051 9.355 11.7051 12.292C11.7051 15.229 14.0601 17.584 16.9971 17.584Z"
        fill="#160B24"
      />
    </svg>
  ),
  link: (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="#20CA68" />
      <path
        d="M7.9 16C7.9 14.29 9.29 12.9 11 12.9H14.05C14.5747 12.9 15 12.4747 15 11.95C15 11.4253 14.5747 11 14.05 11H11C8.24 11 6 13.24 6 16C6 18.76 8.24 21 11 21H14.05C14.5747 21 15 20.5747 15 20.05C15 19.5253 14.5747 19.1 14.05 19.1H11C9.29 19.1 7.9 17.71 7.9 16ZM12 16C12 16.5523 12.4477 17 13 17H19C19.5523 17 20 16.5523 20 16C20 15.4477 19.5523 15 19 15H13C12.4477 15 12 15.4477 12 16ZM21 11H17.95C17.4253 11 17 11.4253 17 11.95C17 12.4747 17.4253 12.9 17.95 12.9H21C22.71 12.9 24.1 14.29 24.1 16C24.1 17.71 22.71 19.1 21 19.1H17.95C17.4253 19.1 17 19.5253 17 20.05C17 20.5747 17.4253 21 17.95 21H21C23.76 21 26 18.76 26 16C26 13.24 23.76 11 21 11Z"
        fill="white"
      />
    </svg>
  ),
  copy: (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="17" cy="17" r="16.5" fill="white" stroke="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 7C21 6.44772 20.5523 6 20 6H9C7.895 6 7 6.895 7 8V21C7 21.5523 7.44772 22 8 22C8.55228 22 9 21.5523 9 21V8H20C20.5523 8 21 7.55228 21 7ZM24 10H13C11.895 10 11 10.896 11 12V26C11 27.104 11.895 28 13 28H24C25.104 28 26 27.104 26 26V12C26 10.896 25.104 10 24 10ZM13 12H24V26H13V12Z"
        fill="#8B8592"
      />
    </svg>
  ),
  placeMarker: (
    <svg
      width="14"
      height="20"
      viewBox="0 0 14 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.53162 0.936771C5.71648 -0.332726 8.40203 -0.310537 10.5664 0.994894C12.7095 2.32691 14.012 4.70418 13.9998 7.26144C13.95 9.80194 12.5533 12.19 10.8075 14.0361C9.79983 15.1064 8.67261 16.0528 7.44884 16.856C7.32281 16.9289 7.18475 16.9777 7.04148 17C6.9036 16.9941 6.76932 16.9534 6.65075 16.8814C4.78243 15.6746 3.14333 14.134 1.81233 12.334C0.698587 10.8314 0.0658345 9.01601 0 7.13442C-0.00144584 4.57225 1.34676 2.20627 3.53162 0.936771ZM4.79416 8.19478C5.16168 9.10084 6.02918 9.69184 6.9916 9.69185C7.6221 9.69638 8.22819 9.44383 8.67481 8.99048C9.12143 8.53712 9.37148 7.92064 9.36925 7.27838C9.37261 6.29804 8.79546 5.41231 7.90726 5.03477C7.01907 4.65723 5.99504 4.86235 5.31332 5.55435C4.63159 6.24635 4.42664 7.28872 4.79416 8.19478Z"
        fill="#20CA68"
      />
      <ellipse opacity="0.4" cx="7" cy="19" rx="5" ry="1" fill="#20CA68" />
    </svg>
  ),
  phone: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M12.4183 3.48994C11.9422 3.402 11.505 3.70579 11.4144 4.17048C11.3238 4.63516 11.6285 5.08884 12.0916 5.17978C13.4859 5.4516 14.5624 6.53085 14.8353 7.92989V7.93089C14.913 8.33362 15.2675 8.62641 15.6759 8.62641C15.7306 8.62641 15.7854 8.62142 15.8412 8.61143C16.3043 8.51849 16.609 8.0658 16.5184 7.60012C16.1111 5.51055 14.5027 3.89666 12.4183 3.48994Z"
        fill="#20CA68"
      />
      <path
        opacity="0.4"
        d="M12.3561 0.00792976C12.133 -0.0240483 11.9089 0.0419064 11.7307 0.183809C11.5474 0.32771 11.4329 0.535567 11.408 0.768408C11.3552 1.23908 11.6948 1.66479 12.1649 1.71776C15.4065 2.07951 17.9262 4.60477 18.2907 7.85654C18.3395 8.29224 18.705 8.62101 19.1412 8.62101C19.174 8.62101 19.2059 8.61902 19.2388 8.61502C19.4668 8.59004 19.67 8.47711 19.8134 8.29724C19.9558 8.11736 20.0206 7.89351 19.9947 7.66467C19.5405 3.60746 16.4005 0.45862 12.3561 0.00792976Z"
        fill="#20CA68"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.03174 10.9724C13.0208 14.9604 13.9258 10.3467 16.4656 12.8848C18.9143 15.3328 20.3216 15.8232 17.2192 18.9247C16.8306 19.237 14.3616 22.9943 5.6846 14.3197C-2.99348 5.644 0.761585 3.17244 1.07397 2.78395C4.18387 -0.326154 4.66586 1.08938 7.11449 3.53733C9.65436 6.0765 5.04266 6.98441 9.03174 10.9724Z"
        fill="#20CA68"
      />
    </svg>
  ),

  timetable: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 16.87V9.25699H21V16.931C21 20.07 19.0241 22 15.8628 22H8.12733C4.99561 22 3 20.03 3 16.87ZM7.95938 14.41C7.50494 14.431 7.12953 14.07 7.10977 13.611C7.10977 13.151 7.46542 12.771 7.91987 12.75C8.36443 12.75 8.72997 13.101 8.73985 13.55C8.7596 14.011 8.40395 14.391 7.95938 14.41ZM12.0198 14.41C11.5653 14.431 11.1899 14.07 11.1701 13.611C11.1701 13.151 11.5258 12.771 11.9802 12.75C12.4248 12.75 12.7903 13.101 12.8002 13.55C12.82 14.011 12.4643 14.391 12.0198 14.41ZM16.0505 18.09C15.596 18.08 15.2305 17.7 15.2305 17.24C15.2206 16.78 15.5862 16.401 16.0406 16.391H16.0505C16.5148 16.391 16.8902 16.771 16.8902 17.24C16.8902 17.71 16.5148 18.09 16.0505 18.09ZM11.1701 17.24C11.1899 17.7 11.5653 18.061 12.0198 18.04C12.4643 18.021 12.82 17.641 12.8002 17.181C12.7903 16.731 12.4248 16.38 11.9802 16.38C11.5258 16.401 11.1701 16.78 11.1701 17.24ZM7.09989 17.24C7.11965 17.7 7.49506 18.061 7.94951 18.04C8.39407 18.021 8.74973 17.641 8.72997 17.181C8.72009 16.731 8.35456 16.38 7.90999 16.38C7.45554 16.401 7.09989 16.78 7.09989 17.24ZM15.2404 13.601C15.2404 13.141 15.596 12.771 16.0505 12.761C16.4951 12.761 16.8507 13.12 16.8705 13.561C16.8804 14.021 16.5247 14.401 16.0801 14.41C15.6257 14.42 15.2503 14.07 15.2404 13.611V13.601Z"
        fill="#20CA68"
      />
      <path
        opacity="0.4"
        d="M3.00342 9.2569C3.01626 8.6699 3.06566 7.5049 3.15852 7.1299C3.63273 5.0209 5.24304 3.6809 7.54491 3.4899H16.456C18.7381 3.6909 20.3682 5.0399 20.8424 7.1299C20.9343 7.4949 20.9837 8.6689 20.9965 9.2569H3.00342Z"
        fill="#20CA68"
      />
      <path
        d="M8.30489 6.59C8.73958 6.59 9.06559 6.261 9.06559 5.82V2.771C9.06559 2.33 8.73958 2 8.30489 2C7.8702 2 7.54419 2.33 7.54419 2.771V5.82C7.54419 6.261 7.8702 6.59 8.30489 6.59Z"
        fill="#20CA68"
      />
      <path
        d="M15.695 6.59C16.1198 6.59 16.4557 6.261 16.4557 5.82V2.771C16.4557 2.33 16.1198 2 15.695 2C15.2603 2 14.9343 2.33 14.9343 2.771V5.82C14.9343 6.261 15.2603 6.59 15.695 6.59Z"
        fill="#20CA68"
      />
    </svg>
  ),
  wifi: (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        d="M19.8001 4.5L17.5001 7.8C15.4001 6.2 12.8001 5.2 10.0001 5.2C7.20007 5.2 4.60007 6.2 2.50007 7.8L0.200068 4.5C-0.0999319 4 6.81281e-05 3.3 0.400068 3C3.20007 1.1 6.40007 0 10.0001 0C13.6001 0 16.8001 1.1 19.6001 3C20.0001 3.3 20.1001 4 19.8001 4.5Z"
        fill="#20CA68"
      />
      <path
        d="M17.5001 7.80001L11.6001 16.3C10.0001 18.6 10.0001 18.6 8.5001 16.3L2.6001 7.80001C4.6001 6.10001 7.2001 5.20001 10.0001 5.20001C12.8001 5.20001 15.4001 6.10001 17.5001 7.80001Z"
        fill="#20CA68"
      />
    </svg>
  ),
  instagram: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#20CA68"
      />
      <rect x="4" y="4" width="16" height="16" rx="4" fill="#20CA68" />
      <circle cx="12" cy="12" r="4" fill="#C4C4C4" />
    </svg>
  ),
  facebook: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 12C24 17.9 19.7 22.9 14 23.8V15.3H16.7L17.2 12H14V9.7C14 8.7 14.3 8 15.8 8H17.3V4.9C16.4 4.8 15.5 4.6 14.6 4.6C11.9 4.6 9.9 6.3 9.9 9.3V12H7V15.3H10V23.8C4.3 22.9 0 17.9 0 12C0 5.4 5.4 0 12 0C18.6 0 24 5.4 24 12Z"
        fill="#20CA68"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 9.69998V12H17.2L16.7 15.3H14V23.8C13.3 23.9 12.7 24 12 24C11.3 24 10.7 23.9 10 23.8V15.3H7V12H10V9.29998C10 6.29998 11.9 4.59998 14.7 4.59998C15.6 4.59998 16.5 4.69998 17.4 4.89998V7.99998H15.9C14.3 7.99998 14 8.69998 14 9.69998Z"
        fill="#20CA68"
      />
    </svg>
  ),
  info: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM11 15H9V9H11V15ZM11 7H9V5H11V7Z"
        fill="#8B8592"
      />
    </svg>
  ),
  language: (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.87 13.07L9.33 10.56L9.36 10.53C11.1 8.59 12.34 6.36 13.07 4H16V2H9V0H7V2H0V3.99H11.17C10.5 5.92 9.44 7.75 8 9.35C7.07 8.32 6.3 7.19 5.69 6H3.69C4.42 7.63 5.42 9.17 6.67 10.56L1.58 15.58L3 17L8 12L11.11 15.11L11.87 13.07ZM17.5 8H15.5L11 20H13L14.12 17H18.87L20 20H22L17.5 8ZM14.88 15L16.5 10.67L18.12 15H14.88Z"
        fill="#20CA68"
      />
    </svg>
  ),
};

export default common;
