import { del, put, post, get } from '../utils/axios';

const staffAPI = {
  get: async (id) => {
    try {
      return await get(`/restaurant-staff/${id}/`, null, true);
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },

  create: async (data) => {
    try {
      return await post(`/restaurant-staff/`, data, null, true);
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },
  update: async (data) => {
    try {
      return await put(`/restaurant-staff/${data.id}/`, data, null, true);
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },

  remove: async (id) => {
    try {
      return await del(`/restaurant-staff/${id}/`, null, true, true);
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },
};

export default staffAPI;
