import React from 'react';
import { connect } from 'react-redux';
import ErrorBoundary from '../ErrorBoundary';
import Router from './Router';
import StartLoader from './StartLoader';
import { firstLoadComplete } from '../redux/reducers/ui/actions';
import { isFirstLoadCompleted } from '../redux/reducers/ui/selectors';
import modals from './modals';
import SideMenu from './SideMenu';

const Root = ({ loadFinished, isAuthenticated }) => {
  if (isAuthenticated && loadFinished) {
    const amoSocialButton = document.createElement('script');
    amoSocialButton.innerHTML =
      "(function(a,m,o,c,r,m){a[m]={id:\"54135\",hash:\"f1fe2992361ee97415d1782ad40986172cf85d48ebbabbef7d20e420c16087c5\",locale:\"ru\",inline:false,setMeta:function(p){this.params=(this.params||[]).concat([p])}};a[o]=a[o]||function(){(a[o].q=a[o].q||[]).push(arguments)};var d=a.document,s=d.createElement('script');s.async=true;s.id=m+'_script';s.src='https://gso.amocrm.ru/js/button.js?1629876652';d.head&&d.head.appendChild(s)}(window,0,'amoSocialButton',0,0,'amo_social_button'));";
    document.head.appendChild(amoSocialButton);
  }

  return loadFinished ? (
    <div className="app-content">
      <ErrorBoundary>
        <Router />
        {modals}
        <div id="background-fade" />
      </ErrorBoundary>
      <SideMenu />
    </div>
  ) : (
    <StartLoader />
  );
};

const mapStateToProps = (state) => ({
  loadFinished:
    process.env.NODE_ENV === 'development' ? true : isFirstLoadCompleted(state),
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { firstLoadComplete })(Root);
