import React from 'react';
import Icon from './Icon';
import AnimateNumber from './AnimateNumber';

const Counter = ({
  className,
  value,
  handleIncrement,
  handleDecrement,
  disableIncrement,
}) => {
  const handlePlusButtonClick = (e) => {
    e.stopPropagation();
    handleIncrement();
  };

  const handleMinusButtonClick = (e) => {
    e.stopPropagation();
    handleDecrement();
  };

  return (
    <div className={`counter ${className || ''}`}>
      <div
        className={`counter__content ${
          value > 0 ? 'counter__content_open' : ''
        }`}
      >
        {value > 0 ? (
          <>
            <Icon onClick={handleMinusButtonClick} name="minus" />
            <AnimateNumber value={value} />
            {!disableIncrement && (
              <Icon onClick={handlePlusButtonClick} name="plus" />
            )}
          </>
        ) : (
          <Icon onClick={handlePlusButtonClick} name="plus" />
        )}
      </div>
    </div>
  );
};

export default Counter;
