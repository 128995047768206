import React from 'react';

const Button = ({ className, secondary, ...props }) => (
  <div className={`button__wrap ${className || ''}`}>
    <button
      type="button"
      className={`${secondary ? 'secondary_button' : 'button'}`}
      {...props}
    />
  </div>
);

export default Button;
