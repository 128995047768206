import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/browser';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import { show } from 'redux-modal';
import RestaurantHeader from '../../components/RestaurantHeader';
import { fetchCart } from '../../redux/reducers/cart/actions';
import { updateRestaurantApi } from '../../redux/reducers/restaurant/actions';
import Loader from '../../components/Loader';
import AdminRestaurantMenu from './AdminRestaurantMenu';
import useRestaurantControls from '../../hooks/useRestaurantControls';
import UserRestaurantMenu from './UserRestaurantMenu';
import Footer from '../../components/Footer';
import ModifyMenuControls from '../../components/ModifyMenuControls';
import restaurantsAPI from '../../api/restaurants';
import categoriesAPI from '../../api/categories';
import productAPI from '../../api/product';
import useCategoryOrProductMove from '../../hooks/useCategoryOrProductMove';
import { showConfirmPhoneModal } from '../../redux/reducers/auth/actions';
import settingsApi from '../../api/settings';
import ContentLanguageSelector from '../../components/common/ContentLanguageSelector';
import { loadScript } from '../../utils/loadScripts';

const RestaurantMenu = ({
  restaurant,
  isShowLoader,
  showConfirmPhoneModal,
  show,
}) => {
  const { slug, menuId } = useParams();
  const [menu, setMenu] = useState(null);
  const [showAddCategoryField, setShowAddCategoryField] = useState(false);
  const { t, i18n } = useTranslation();

  const history = useHistory();
  const { isPreviewMode, isShowControls } = useRestaurantControls(restaurant);

  const [currentLang, setCurrentLang] = useState(
    window.localStorage.getItem('language')
      ? window.localStorage.getItem('language')
      : 'en'
  );
  const [languageOptions, setLanguages] = useState([]);

  const setLng = (lngCode) => {
    setCurrentLang(lngCode);
    window.localStorage.setItem('language', lngCode);
  };

  const { handleCategoryOrProductMove } = useCategoryOrProductMove(
    menuId,
    setMenu,
    showConfirmPhoneModal,
    currentLang
  );

  useEffect(() => {
    if (restaurant?.slug) {
      const i18LngCode = i18n.language.split('-')[0];
      if (!isShowControls) {
        if (restaurant.languages.includes(i18LngCode)) {
          setLng(i18LngCode);
        } else {
          switch (i18LngCode) {
            case 'en': {
              if (restaurant.languages.includes('uk')) {
                setLng('uk');
              } else setLng('ru');
              break;
            }
            case 'uk': {
              if (restaurant.languages.includes('en')) {
                setLng('en');
              } else setLng('ru');
              break;
            }
            case 'ru': {
              if (restaurant.languages.includes('uk')) {
                setLng('uk');
              } else setLng('en');
              break;
            }
            default: {
              if (restaurant.languages.includes('uk')) {
                setLng('uk');
              } else if (restaurant.languages.includes('en')) {
                setLng('en');
              } else setLng('ru');
              break;
            }
          }
        }
      }
    }
  }, [i18n.language, isShowControls, restaurant?.languages, restaurant?.slug]);

  useEffect(() => {
    settingsApi.getSystemSettings().then((data) => {
      const options = data.languages
        .filter((language) => restaurant?.languages.includes(language.code))
        .map((language) => {
          return {
            label: language.full,
            value: language.short,
            id: language.code,
            onClick: () => {
              setCurrentLang(language.code);
              window.localStorage.setItem('language', language.code);
            },
          };
        });
      if (options.length > 0 && options.length < 2) {
        setCurrentLang(options[0].id);
        window.localStorage.setItem('language', options[0].id);
      }
      setLanguages(options);
    });
  }, [i18n.language, restaurant?.languages]);

  useEffect(() => {
    // remove Auth for preview mode
    restaurantsAPI
      .getMenuById(menuId, isPreviewMode, currentLang)
      .then(({ data }) => {
        setMenu(data);
      })
      .catch((e) => {
        if (e.code === 404) {
          history.replace(`/restaurant/${slug}`);
        }
        if (e.code === 403) {
          showConfirmPhoneModal();
        }
        if (e.code === 423) {
          history.replace(`/restaurant/${slug}/feedback`);
        }
        console.error(e);
      });
  }, [
    history,
    menuId,
    slug,
    showConfirmPhoneModal,
    isPreviewMode,
    currentLang,
  ]);

  const handleAddCategoryClick = () => {
    setShowAddCategoryField(true);
  };

  const handleAddCategory = async (categoryName) => {
    setShowAddCategoryField(false);

    try {
      await categoriesAPI.create(categoryName, menu.id);
      const { data } = await restaurantsAPI.getMenuById(menuId);
      setMenu(data);
    } catch (e) {
      console.error(e);
      if (e.code === 403) {
        showConfirmPhoneModal();
      }
    }
  };

  const handleCategoryNameChange = async (categoryName, categoryId) => {
    const dataCategory = {
      id: categoryId,
      [`name_${currentLang}`]: categoryName,
    };
    try {
      await categoriesAPI.patch(dataCategory, currentLang);
      const { data } = await restaurantsAPI.getMenuById(menuId);
      setMenu(data);
    } catch (e) {
      console.error(e);
      if (e.code === 403) {
        showConfirmPhoneModal();
      }
    }
  };

  const handleCategoryDelete = (category) => {
    Swal.fire({
      heightAuto: false,
      title: `${t('restaurant_menu_page.category_remove_message')} “${
        category.name
      }”`,
      showCancelButton: true,
      confirmButtonText: t('swal.yes'),
      cancelButtonText: t('swal.no'),
      showClass: {
        popup: 'animate__animated modalFadeInUp',
      },
      hideClass: {
        popup: 'animate__animated modalFadeOutDown',
      },
      preConfirm: async () => {
        try {
          await categoriesAPI.remove(category.id);
          setMenu((data) => ({
            ...data,
            categories: data.categories.filter(
              (dataCategory) => dataCategory.id !== category.id
            ),
          }));
        } catch (e) {
          console.error(e);
          if (e.code === 403) {
            showConfirmPhoneModal();
          }
        }
      },
    });
  };

  const handleCategoryHideUpdate = async (category) => {
    const dataCategory = {
      id: category.id,
      [`name_${currentLang}`]: category[`name_${currentLang}`],
      is_active: !category.is_active,
    };
    try {
      await categoriesAPI.patch(dataCategory);
      const { data } = await restaurantsAPI.getMenuById(menuId);
      setMenu(data);
    } catch (e) {
      console.error(e);
      if (e.code === 403) {
        showConfirmPhoneModal();
      }
    }
  };

  const handleProductHideUpdate = async (product) => {
    try {
      // eslint-disable-next-line no-param-reassign
      product.is_active = !product.is_active;
      await productAPI.patch({ id: product.id, is_active: product.is_active });
      // little hack for rerender page
      setMenu({
        ...menu,
      });
    } catch (e) {
      console.error(e);
      if (e.code === 403) {
        showConfirmPhoneModal();
      }
      Sentry.captureException(e);
    }
  };

  const handleProductDelete = (product) => {
    Swal.fire({
      heightAuto: false,
      title: `${t('restaurant_menu_page.product_remove_message')} - “${
        product.name
      }”?`,
      showCancelButton: true,
      confirmButtonText: t('swal.yes'),
      cancelButtonText: t('swal.no'),
      showClass: {
        popup: 'animate__animated modalFadeInUp',
      },
      hideClass: {
        popup: 'animate__animated modalFadeOutDown',
      },
      preConfirm: () =>
        productAPI.remove(product.id).catch((error) => {
          console.error(error);
          if (error.code === 403) {
            showConfirmPhoneModal();
          }
        }),
    }).then((data) => {
      if (data.value) {
        restaurantsAPI.getMenuById(menuId).then(({ data }) => setMenu(data));
      }
    });
  };

  const handleCategorySecondMerchantUse = async (category) => {
    const dataCategory = {
      id: category.id,
      [`name_${currentLang}`]: category[`name_${currentLang}`],
      is_active: category.is_active,
      use_secondary_merchant: !category.use_secondary_merchant,
    };
    try {
      await categoriesAPI.patch(dataCategory);
      const { data } = await restaurantsAPI.getMenuById(menuId);
      setMenu(data);
    } catch (e) {
      console.error(e);
      if (e.code === 403) {
        showConfirmPhoneModal();
      }
    }
  };
  const handleAddProductClick = () => {
    history.push({
      pathname: `/restaurant/${slug}/product/create`,
      state: { menu: menuId },
    });
  };

  const handleBackClick = () => {
    history.push(`/restaurant/${slug}`);
  };

  if (!menu || isShowLoader || languageOptions.length < 1) {
    return <Loader />;
  }
  if (restaurant) {
    const title = document.getElementsByTagName('title')[0];
    if (restaurant?.seo?.meta_title !== '')
      title.innerHTML = restaurant?.seo?.meta_title;

    const description = document.getElementsByName('description')[0];
    if (restaurant?.seo?.meta_description !== '')
      description.content = restaurant?.seo?.meta_description;

    const metaKeyword = document.createElement('meta');
    metaKeyword.name = 'keywords';
    metaKeyword.id = 'keywords-seo';
    metaKeyword.content = restaurant?.seo?.meta_keywords;
    if (
      !document.getElementById('keywords-seo') &&
      restaurant?.seo?.meta_keywords !== ''
    )
      document.head.insertBefore(metaKeyword, description);
  }

  if (restaurant && (!isShowControls || isPreviewMode)) {
    const googleTagManager = document.createElement('script');
    googleTagManager.id = 'google-tag-manager';
    googleTagManager.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer', '${restaurant?.analytics?.google_tag_manager}');`;

    const googleAnalytics = document.createElement('script');
    googleAnalytics.id = 'google-analytics';
    googleAnalytics.innerHTML =
      ' window.dataLayer = window.dataLayer || [];\n' +
      '  function gtag(){window.dataLayer.push(arguments);}\n' +
      "  gtag('js', new Date());\n" +
      '\n' +
      `  gtag('config', ${restaurant?.analytics?.google_analytics});`;

    const facebookPixel = document.createElement('script');
    facebookPixel.id = 'facebook-pixel';
    facebookPixel.innerHTML =
      '!function(f,b,e,v,n,t,s)\n' +
      '  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?\n' +
      '  n.callMethod.apply(n,arguments):n.queue.push(arguments)};\n' +
      "  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';\n" +
      '  n.queue=[];t=b.createElement(e);t.async=!0;\n' +
      '  t.src=v;s=b.getElementsByTagName(e)[0];\n' +
      "  s.parentNode.insertBefore(t,s)}(window, document,'script',\n" +
      `  'https://connect.facebook.net/en_US/fbevents.js');\n` +
      `  fbq('init', ${restaurant?.analytics?.facebook_pixel});\n` +
      "  fbq('track', 'PageView');";

    if (!document.getElementById('google-analytics'))
      loadScript(
        `https://www.googletagmanager.com/gtag/js?id=${restaurant?.analytics?.google_analytics}`
      );

    if (!document.getElementById('google-tag-manager'))
      document.head.appendChild(googleTagManager);
    if (!document.getElementById('google-analytics'))
      document.head.appendChild(googleAnalytics);
    if (!document.getElementById('facebook-pixel'))
      document.head.appendChild(facebookPixel);
  } else {
    if (document.getElementById('google-tag-manager'))
      document.getElementById('google-tag-manager').remove();
    if (document.getElementById('google-analytics'))
      document.getElementById('google-analytics').remove();
    if (document.getElementById('facebook-pixel'))
      document.getElementById('facebook-pixel').remove();
  }

  // if (!menu.categories || menu.categories.length === 0) {
  //   return <Redirect to={`/restaurant/${slug}/product/create`} />;
  // }

  return (
    <div className="restaurant-menu">
      <RestaurantHeader
        onBackClick={handleBackClick}
        restaurant={restaurant}
        className="restaurant-menu__header"
        isPreviewMode={isPreviewMode}
        isShowControls={isShowControls}
      />

      <div className="language-block">
        <ContentLanguageSelector
          options={languageOptions}
          value={
            languageOptions.find((option) => option.id === currentLang).value
          }
          isShowControls={isShowControls}
          show={show}
          use
          label="menu_translation"
        />
      </div>
      {isShowControls ? (
        <AdminRestaurantMenu
          menu={menu}
          restaurant={restaurant}
          onCategoryAdd={handleAddCategory}
          onCategoryDelete={handleCategoryDelete}
          onCategoryNameChange={handleCategoryNameChange}
          onCategoryHideUpdate={handleCategoryHideUpdate}
          onCategoryOrProductMove={handleCategoryOrProductMove}
          onCategorySecondMerchantUse={handleCategorySecondMerchantUse}
          onProductHideUpdate={handleProductHideUpdate}
          onProductDelete={handleProductDelete}
          showAddCategoryField={showAddCategoryField}
          setShowAddCategoryField={setShowAddCategoryField}
          currentLang={currentLang}
        />
      ) : (
        <UserRestaurantMenu menu={menu} />
      )}

      {isShowControls && (
        <div className="restaurant-menu__plus-button">
          <ModifyMenuControls
            options={[
              {
                name: t('restaurant_menu_page.category_add'),
                onClick: handleAddCategoryClick,
              },
              {
                name: t('restaurant_menu_page.product_add'),
                onClick: handleAddProductClick,
              },
            ]}
          />
        </div>
      )}
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({
  isShowLoader: state.restaurant.isLoading,
  restaurant: state.restaurant.restaurantData,
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, {
  fetchCart,
  updateRestaurantApi,
  showConfirmPhoneModal,
  show,
})(RestaurantMenu);
