import React, { useState, useRef } from 'react';
import FadeBackground from '../FadeBackground';
import Icon from './Icon';
import InfoModal from './InfoModal';

const Info = ({ name, className, containerClassName }) => {
  const [info, toggleInfo] = useState(false);
  const [infoPosition, setInfoPosition] = useState({});
  const [infoDirectionalTop, setInfoDirectionalTop] = useState(false);

  const refInfoIcon = useRef();

  const handleClick = () => {
    const [{ top, bottom }] = refInfoIcon.current.getClientRects();

    setInfoPosition({ top, bottom });

    // change position depending on position button on screen (top VS bottom)
    if (window.innerHeight - top > window.innerHeight / 2) {
      setInfoDirectionalTop(false);
    } else {
      setInfoDirectionalTop(true);
    }

    toggleInfo(!info);
  };
  return (
    <>
      <div ref={refInfoIcon} className={`info-icon ${containerClassName}`}>
        <Icon onClick={handleClick} name="info" className={className} />
      </div>
      {info && (
        <FadeBackground>
          <InfoModal
            onClick={handleClick}
            infoPosition={infoPosition}
            infoDirectionalTop={infoDirectionalTop}
            name={name}
          />
        </FadeBackground>
      )}
    </>
  );
};

export default Info;
