export const FETCH_RESTAURANT_REQUEST = 'RESTAURANT/FETCH_RESTAURANT_REQUEST';
export const FETCH_RESTAURANT_SUCCESS = 'RESTAURANT/FETCH_RESTAURANT_SUCCESS';
export const FETCH_RESTAURANT_MENUS = 'RESTAURANT/FETCH_RESTAURANT_MENUS';
export const FETCH_RESTAURANT_ERROR = 'RESTAURANT/FETCH_RESTAURANT_ERROR';

export const UPDATE_RESTAURANT_REQUEST = 'RESTAURANT/UPDATE_RESTAURANT_REQUEST';

export const SET_RESTAURANT = 'RESTAURANT/SET_RESTAURANT';
export const SET_FIELD_ERRORS = 'RESTAURANT/SET_FIELD_ERRORS';
export const CHECK_IN_BY_TABLE = 'RESTAURANT/CHECK_IN_BY_TABLE';
export const SET_EDITED_PRODUCT = 'RESTAURANT/SET_EDITED_PRODUCT';

export const ADD_RESTAURANT_MENU_ITEM = 'RESTAURANT/ADD_RESTAURANT_MENU_ITEM';
export const UPDATE_RESTAURANT_MENU_ITEM =
  'RESTAURANT/UPDATE_RESTAURANT_MENU_ITEM';
export const REMOVE_RESTAURANT_MENU_ITEM =
  'RESTAURANT/REMOVE_RESTAURANT_MENU_ITEM';
