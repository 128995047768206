import React from 'react';

const checkout = {
  wallet: (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9964 5.37513H15.7618C13.7911 5.37859 12.1947 6.93514 12.1911 8.85657C12.1884 10.7823 13.7867 12.3458 15.7618 12.3484H20V12.6543C20 16.0136 17.9636 18 14.5173 18H5.48356C2.03644 18 0 16.0136 0 12.6543V5.33786C0 1.97862 2.03644 0 5.48356 0H14.5138C17.96 0 19.9964 1.97862 19.9964 5.33786V5.37513ZM4.73956 5.36733H10.3796H10.3831H10.3902C10.8124 5.36559 11.1538 5.03019 11.152 4.61765C11.1502 4.20598 10.8053 3.87318 10.3831 3.87491H4.73956C4.32 3.87664 3.97956 4.20858 3.97778 4.61852C3.976 5.03019 4.31733 5.36559 4.73956 5.36733Z"
        fill="#20CA68"
      />
      <path
        opacity="0.4"
        d="M14.0374 9.29666C14.2465 10.2478 15.0805 10.9171 16.0326 10.8997H19.2825C19.6787 10.8997 20 10.5716 20 10.166V7.63448C19.9991 7.22982 19.6787 6.90086 19.2825 6.89999H15.9561C14.8731 6.90347 13.9983 7.80244 14 8.91027C14 9.03994 14.0128 9.16961 14.0374 9.29666Z"
        fill="#20CA68"
      />
      <circle cx="16" cy="8.89999" r="1" fill="#20CA68" />
    </svg>
  ),

  name: (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.997 13.1749C3.684 13.1749 0 13.8549 0 16.5749C0 19.2959 3.661 19.9999 7.997 19.9999C12.31 19.9999 15.994 19.3209 15.994 16.5999C15.994 13.8789 12.334 13.1749 7.997 13.1749Z"
        fill="#20CA68"
      />
      <path
        opacity="0.4"
        d="M7.997 10.584C10.935 10.584 13.289 8.229 13.289 5.292C13.289 2.355 10.935 0 7.997 0C5.06 0 2.705 2.355 2.705 5.292C2.705 8.229 5.06 10.584 7.997 10.584Z"
        fill="#20CA68"
      />
    </svg>
  ),

  deliveryTime: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.9998 9.9999C19.9998 15.5239 15.5228 19.9999 9.9998 19.9999C4.4768 19.9999 -0.000200272 15.5239 -0.000200272 9.9999C-0.000200272 4.4779 4.4768 -9.91821e-05 9.9998 -9.91821e-05C15.5228 -9.91821e-05 19.9998 4.4779 19.9998 9.9999Z"
        fill="#20CA68"
      />
      <path
        d="M13.5735 13.8144C13.4425 13.8144 13.3105 13.7804 13.1895 13.7094L9.2635 11.3674C9.0375 11.2314 8.8985 10.9864 8.8985 10.7224V5.6754C8.8985 5.2614 9.2345 4.9254 9.6485 4.9254C10.0625 4.9254 10.3985 5.2614 10.3985 5.6754V10.2964L13.9585 12.4194C14.3135 12.6324 14.4305 13.0924 14.2185 13.4484C14.0775 13.6834 13.8285 13.8144 13.5735 13.8144Z"
        fill="#A6EAC3"
      />
    </svg>
  ),
};

export default checkout;
