import React from 'react';

import { connectModal } from 'redux-modal';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Modal from '../common/Modal';
import Footer from '../Footer';
import RestaurantMenuItem from '../../pages/Restaurant/RestaurantMenuItem';
import useRestaurantControls from '../../hooks/useRestaurantControls';
import UserBurgerMenuHeader from '../UserBurgerMenuHeader';

export const MODAL_NAME = 'user-burger-menu';

const UserBurgerMenu = ({ show, handleHide, restaurant }) => {
  const history = useHistory();
  // preview - it's when user press Preview button
  const { isPreviewMode } = useRestaurantControls(restaurant);
  const { t } = useTranslation();

  const onMenuClick = (menuId) => {
    history.push(
      `/restaurant/${restaurant.slug}/menu/${menuId}${
        isPreviewMode ? '/preview' : ''
      }`
    );
    handleHide();
  };

  const handleRightIconClick = () => {
    handleHide();
  };

  return (
    <Modal
      isOpen={show}
      customHeader={
        <UserBurgerMenuHeader
          title={restaurant.name}
          onRightIconClick={handleRightIconClick}
        />
      }
    >
      <div
        data-aos="fade-up"
        data-aos-delay="400"
        className="user-burgermenu-modal__body"
      >
        {/* <div className="user-burgermenu-modal__link user-burgermenu-modal__link-top">
          <Link
            className="restaurant__accent-text"
            to={`/restaurant/${restaurant.slug}/`}
          >
            Перевірити зону доставки
          </Link>
        </div> */}
        <div className="user-burgermenu-modal__menu">
          <ul className="restaurant__menu-list">
            {restaurant.menu
              .filter((menu) => menu.is_active)
              .map((menu) => (
                <RestaurantMenuItem
                  key={menu.id}
                  menu={menu}
                  onClick={() => onMenuClick(menu.id)}
                />
              ))}
          </ul>
          <div className="user-burgermenu-modal__link user-burgermenu-modal__link-bottom">
            <Link
              onClick={handleHide}
              className="restaurant__accent-text"
              to={`/restaurant/${restaurant.slug}/`}
            >
              {t('404.main')}
            </Link>
          </div>
        </div>
        <Footer />
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  restaurant: state.restaurant.restaurantData,
});

export default connectModal({ name: MODAL_NAME, destroyOnHide: true })(
  connect(mapStateToProps)(UserBurgerMenu)
);

UserBurgerMenu.defaultProps = {
  restaurant: {
    name: '',
    menu: [],
  },
};
