import React from 'react';

const restaurant = {
  link: (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="white" />
      <path
        d="M7.9 16C7.9 14.29 9.29 12.9 11 12.9H14.05C14.5747 12.9 15 12.4747 15 11.95C15 11.4253 14.5747 11 14.05 11H11C8.24 11 6 13.24 6 16C6 18.76 8.24 21 11 21H14.05C14.5747 21 15 20.5747 15 20.05C15 19.5253 14.5747 19.1 14.05 19.1H11C9.29 19.1 7.9 17.71 7.9 16ZM12 16C12 16.5523 12.4477 17 13 17H19C19.5523 17 20 16.5523 20 16C20 15.4477 19.5523 15 19 15H13C12.4477 15 12 15.4477 12 16ZM21 11H17.95C17.4253 11 17 11.4253 17 11.95C17 12.4747 17.4253 12.9 17.95 12.9H21C22.71 12.9 24.1 14.29 24.1 16C24.1 17.71 22.71 19.1 21 19.1H17.95C17.4253 19.1 17 19.5253 17 20.05C17 20.5747 17.4253 21 17.95 21H21C23.76 21 26 18.76 26 16C26 13.24 23.76 11 21 11Z"
        fill="#8B8592"
      />
    </svg>
  ),
  copy: (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="17" cy="17" r="16.5" fill="white" stroke="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 7C21 6.44772 20.5523 6 20 6H9C7.895 6 7 6.895 7 8V21C7 21.5523 7.44772 22 8 22C8.55228 22 9 21.5523 9 21V8H20C20.5523 8 21 7.55228 21 7ZM24 10H13C11.895 10 11 10.896 11 12V26C11 27.104 11.895 28 13 28H24C25.104 28 26 27.104 26 26V12C26 10.896 25.104 10 24 10ZM13 12H24V26H13V12Z"
        fill="#20CA68"
      />
    </svg>
  ),
  placeMarker: (
    <svg
      width="14"
      height="20"
      viewBox="0 0 14 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.53162 0.936771C5.71648 -0.332726 8.40203 -0.310537 10.5664 0.994894C12.7095 2.32691 14.012 4.70418 13.9998 7.26144C13.95 9.80194 12.5533 12.19 10.8075 14.0361C9.79983 15.1064 8.67261 16.0528 7.44884 16.856C7.32281 16.9289 7.18475 16.9777 7.04148 17C6.9036 16.9941 6.76932 16.9534 6.65075 16.8814C4.78243 15.6746 3.14333 14.134 1.81233 12.334C0.698587 10.8314 0.0658345 9.01601 0 7.13442C-0.00144584 4.57225 1.34676 2.20627 3.53162 0.936771ZM4.79416 8.19478C5.16168 9.10084 6.02918 9.69184 6.9916 9.69185C7.6221 9.69638 8.22819 9.44383 8.67481 8.99048C9.12143 8.53712 9.37148 7.92064 9.36925 7.27838C9.37261 6.29804 8.79546 5.41231 7.90726 5.03477C7.01907 4.65723 5.99504 4.86235 5.31332 5.55435C4.63159 6.24635 4.42664 7.28872 4.79416 8.19478Z"
        fill="#20CA68"
      />
      <ellipse opacity="0.4" cx="7" cy="19" rx="5" ry="1" fill="#20CA68" />
    </svg>
  ),
  phone: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M12.4183 3.48994C11.9422 3.402 11.505 3.70579 11.4144 4.17048C11.3238 4.63516 11.6285 5.08884 12.0916 5.17978C13.4859 5.4516 14.5624 6.53085 14.8353 7.92989V7.93089C14.913 8.33362 15.2675 8.62641 15.6759 8.62641C15.7306 8.62641 15.7854 8.62142 15.8412 8.61143C16.3043 8.51849 16.609 8.0658 16.5184 7.60012C16.1111 5.51055 14.5027 3.89666 12.4183 3.48994Z"
        fill="#20CA68"
      />
      <path
        opacity="0.4"
        d="M12.3561 0.00792976C12.133 -0.0240483 11.9089 0.0419064 11.7307 0.183809C11.5474 0.32771 11.4329 0.535567 11.408 0.768408C11.3552 1.23908 11.6948 1.66479 12.1649 1.71776C15.4065 2.07951 17.9262 4.60477 18.2907 7.85654C18.3395 8.29224 18.705 8.62101 19.1412 8.62101C19.174 8.62101 19.2059 8.61902 19.2388 8.61502C19.4668 8.59004 19.67 8.47711 19.8134 8.29724C19.9558 8.11736 20.0206 7.89351 19.9947 7.66467C19.5405 3.60746 16.4005 0.45862 12.3561 0.00792976Z"
        fill="#20CA68"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.03174 10.9724C13.0208 14.9604 13.9258 10.3467 16.4656 12.8848C18.9143 15.3328 20.3216 15.8232 17.2192 18.9247C16.8306 19.237 14.3616 22.9943 5.6846 14.3197C-2.99348 5.644 0.761585 3.17244 1.07397 2.78395C4.18387 -0.326154 4.66586 1.08938 7.11449 3.53733C9.65436 6.0765 5.04266 6.98441 9.03174 10.9724Z"
        fill="#20CA68"
      />
    </svg>
  ),

  timetable: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 16.87V9.25699H21V16.931C21 20.07 19.0241 22 15.8628 22H8.12733C4.99561 22 3 20.03 3 16.87ZM7.95938 14.41C7.50494 14.431 7.12953 14.07 7.10977 13.611C7.10977 13.151 7.46542 12.771 7.91987 12.75C8.36443 12.75 8.72997 13.101 8.73985 13.55C8.7596 14.011 8.40395 14.391 7.95938 14.41ZM12.0198 14.41C11.5653 14.431 11.1899 14.07 11.1701 13.611C11.1701 13.151 11.5258 12.771 11.9802 12.75C12.4248 12.75 12.7903 13.101 12.8002 13.55C12.82 14.011 12.4643 14.391 12.0198 14.41ZM16.0505 18.09C15.596 18.08 15.2305 17.7 15.2305 17.24C15.2206 16.78 15.5862 16.401 16.0406 16.391H16.0505C16.5148 16.391 16.8902 16.771 16.8902 17.24C16.8902 17.71 16.5148 18.09 16.0505 18.09ZM11.1701 17.24C11.1899 17.7 11.5653 18.061 12.0198 18.04C12.4643 18.021 12.82 17.641 12.8002 17.181C12.7903 16.731 12.4248 16.38 11.9802 16.38C11.5258 16.401 11.1701 16.78 11.1701 17.24ZM7.09989 17.24C7.11965 17.7 7.49506 18.061 7.94951 18.04C8.39407 18.021 8.74973 17.641 8.72997 17.181C8.72009 16.731 8.35456 16.38 7.90999 16.38C7.45554 16.401 7.09989 16.78 7.09989 17.24ZM15.2404 13.601C15.2404 13.141 15.596 12.771 16.0505 12.761C16.4951 12.761 16.8507 13.12 16.8705 13.561C16.8804 14.021 16.5247 14.401 16.0801 14.41C15.6257 14.42 15.2503 14.07 15.2404 13.611V13.601Z"
        fill="#20CA68"
      />
      <path
        opacity="0.4"
        d="M3.00342 9.2569C3.01626 8.6699 3.06566 7.5049 3.15852 7.1299C3.63273 5.0209 5.24304 3.6809 7.54491 3.4899H16.456C18.7381 3.6909 20.3682 5.0399 20.8424 7.1299C20.9343 7.4949 20.9837 8.6689 20.9965 9.2569H3.00342Z"
        fill="#20CA68"
      />
      <path
        d="M8.30489 6.59C8.73958 6.59 9.06559 6.261 9.06559 5.82V2.771C9.06559 2.33 8.73958 2 8.30489 2C7.8702 2 7.54419 2.33 7.54419 2.771V5.82C7.54419 6.261 7.8702 6.59 8.30489 6.59Z"
        fill="#20CA68"
      />
      <path
        d="M15.695 6.59C16.1198 6.59 16.4557 6.261 16.4557 5.82V2.771C16.4557 2.33 16.1198 2 15.695 2C15.2603 2 14.9343 2.33 14.9343 2.771V5.82C14.9343 6.261 15.2603 6.59 15.695 6.59Z"
        fill="#20CA68"
      />
    </svg>
  ),
  wifi: (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        d="M19.8001 4.5L17.5001 7.8C15.4001 6.2 12.8001 5.2 10.0001 5.2C7.20007 5.2 4.60007 6.2 2.50007 7.8L0.200068 4.5C-0.0999319 4 6.81281e-05 3.3 0.400068 3C3.20007 1.1 6.40007 0 10.0001 0C13.6001 0 16.8001 1.1 19.6001 3C20.0001 3.3 20.1001 4 19.8001 4.5Z"
        fill="#20CA68"
      />
      <path
        d="M17.5001 7.80001L11.6001 16.3C10.0001 18.6 10.0001 18.6 8.5001 16.3L2.6001 7.80001C4.6001 6.10001 7.2001 5.20001 10.0001 5.20001C12.8001 5.20001 15.4001 6.10001 17.5001 7.80001Z"
        fill="#20CA68"
      />
    </svg>
  ),
  instagram: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#20CA68"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 4C5.79086 4 4 5.79086 4 8V16C4 18.2091 5.79086 20 8 20H16C18.2091 20 20 18.2091 20 16V8C20 5.79086 18.2091 4 16 4H8ZM18 7C18 7.55228 17.5523 8 17 8C16.4477 8 16 7.55228 16 7C16 6.44772 16.4477 6 17 6C17.5523 6 18 6.44772 18 7ZM16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12Z"
        fill="#20CA68"
      />
    </svg>
  ),
  facebook: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 12C24 17.9 19.7 22.9 14 23.8V15.3H16.7L17.2 12H14V9.7C14 8.7 14.3 8 15.8 8H17.3V4.9C16.4 4.8 15.5 4.6 14.6 4.6C11.9 4.6 9.9 6.3 9.9 9.3V12H7V15.3H10V23.8C4.3 22.9 0 17.9 0 12C0 5.4 5.4 0 12 0C18.6 0 24 5.4 24 12Z"
        fill="#20CA68"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 9.7V12H17.2L16.7 15.3H14V23.8C13.3 23.9 12.7 24 12 24C11.3 24 10.7 23.9 10 23.8V15.3H7V12H10V9.3C10 6.3 11.9 4.6 14.7 4.6C15.6 4.6 16.5 4.7 17.4 4.9V8H15.9C14.3 8 14 8.7 14 9.7Z"
        fill="#20CA68"
      />
    </svg>
  ),
};

export default restaurant;
