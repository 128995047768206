import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Header from '../components/Header';
import Input from '../components/common/Input';
import Button from '../components/common/Button';
import authAPI from '../api/auth';
import { passwordResetComplete } from '../redux/reducers/auth/actions';

const PasswordReset = ({ passwordResetComplete }) => {
  const [email, setEmail] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [isShowInfoMessage, setShowInfoMessage] = useState(false);
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const { code } = useParams();
  const { t } = useTranslation();

  const submitRecovery = () => {
    setErrors({});
    authAPI
      .passwordReset(email)
      .then(() => {
        setShowInfoMessage(true);
      })
      .catch((error) => {
        const { data } = error.response;
        setErrors(data);
        console.error(error);
      });
  };

  const submitPasswordResetComplete = () => {
    setErrors({});
    passwordResetComplete(code, password1, password2)
      .then(() => {
        history.push('/');
      })
      .catch((error) => {
        setErrors(error);
        console.error(error);
      });
  };

  const renderResetCompleteBlock = () => {
    return (
      <>
        <Input
          className="password-reset__input"
          type="password"
          name="password1"
          placeholder={t('password_reset.password1')}
          value={password1}
          onChange={(value) => setPassword1(value)}
          error={errors.password1}
        />
        <Input
          className="password-reset__input"
          type="password"
          name="password2"
          placeholder={t('password_reset.password1')}
          value={password2}
          onChange={(value) => setPassword2(value)}
          error={errors.password2}
        />
        {errors.verification_code && (
          <div className="error-box">{errors.verification_code}</div>
        )}
        <Button
          disabled={password1 === '' || password1 !== password2}
          onClick={submitPasswordResetComplete}
        >
          {t('password_reset.change')}
        </Button>
      </>
    );
  };

  const renderResetBlock = () => {
    return isShowInfoMessage ? (
      <h1 className="password-reset__message">
        На вашу пошту відправлено лист з інструкцією.
      </h1>
    ) : (
      <>
        <h1 className="password-reset__message">Відновлення паролю</h1>
        <Input
          className="password-reset__input"
          type="email"
          name="email"
          placeholder="Email"
          value={email}
          onChange={(value) => setEmail(value)}
          error={errors.email}
        />
        <Button onClick={submitRecovery}>Підтвердити</Button>
      </>
    );
  };

  return (
    <div className="page password-reset">
      <Header logo leftIcon="back" onLeftIconClick={() => history.goBack()} />
      <div
        data-aos="fade-up"
        data-aos-delay="300"
        className="password-reset__content"
      >
        {code ? renderResetCompleteBlock() : renderResetBlock()}
      </div>
    </div>
  );
};

export default connect(null, { passwordResetComplete })(PasswordReset);
