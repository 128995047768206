import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Input from '../../components/common/Input';
import Button from '../../components/common/Button';
import { register } from '../../redux/reducers/auth/actions';

const SignUp = ({ register }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const { t } = useTranslation();

  const submitHandler = (e) => {
    e.preventDefault();
    setErrors({});
    register({ email, password, password2 })
      .then((response) => {
        window.dataLayer.push({
          event: 'registration',
          eventCategory: 'success',
          eventContent: 'email',
        });
        history.push({
          pathname: '/verification-email/confirm',
          state: { message: response.message },
        });
      })
      .catch((error) => {
        setErrors(error);
        console.error(error);
      });
  };

  return (
    <form
      onSubmit={submitHandler}
      className="sign-up__form"
      data-aos="fade-up"
      data-aos-delay="0"
    >
      <Input
        className="sign-up__form-elem"
        name="email"
        type="email"
        placeholder={t('login.email_placeholder')}
        value={email}
        onChange={(value) => setEmail(value)}
        error={errors.email}
      />
      <Input
        className="sign-up__form-elem"
        type="password"
        name="password"
        placeholder={t('login.password_placeholder')}
        value={password}
        onChange={(value) => setPassword(value)}
        error={errors.password1}
      />
      <Input
        className="sign-up__form-elem"
        type="password"
        name="password2"
        placeholder={t('login.password_repeat_placeholder')}
        value={password2}
        onChange={(value) => setPassword2(value)}
        error={errors.password2}
      />
      <Button
        disabled={email.length === 0 || password.length === 0}
        className="sign-up__button"
        type="submit"
      >
        {t('login.sign_up')}
      </Button>
    </form>
  );
};

export default connect(null, { register })(SignUp);
