import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import RestaurantPreviewAndQRBlock from '../../components/RestaurantPreviewAndQRBlock';
import DropdownMenu from '../../components/common/DropdownMenu';
import RestaurantMenuProduct from '../../components/RestaurantMenuProduct';
import Icon from '../../components/common/Icon';
import RestaurantMenuEditableLabel from '../../components/RestaurantMenuEditableLabel';
import { isMobile } from '../../redux/reducers/ui/selectors';

const AdminRestaurantMenu = ({
  menu,
  restaurant,
  onCategoryAdd,
  onCategoryDelete,
  onCategoryNameChange,
  onCategorySecondMerchantUse,
  onCategoryHideUpdate,
  onCategoryOrProductMove,
  onProductDelete,
  onProductHideUpdate,
  showAddCategoryField,
  setShowAddCategoryField,
  isMobile,
  currentLang,
}) => {
  const history = useHistory();
  const { slug, menuId } = useParams();
  const { t } = useTranslation();

  // TODO get from hook! move into splitted component!!!!
  const [colapsedCategories, setColapsedCategories] = useState([]);
  const newCategoryRef = useRef();

  useEffect(() => {
    if (showAddCategoryField) {
      newCategoryRef.current.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      });
    }
  }, [showAddCategoryField]);

  /*
   * WORK WITH CATEGORY
   */
  const handleCategoryAddClick = (value) => {
    onCategoryAdd(value);
  };

  const handleCategoryNameChangeClick = (categoryName, categoryId) => {
    onCategoryNameChange(categoryName, categoryId);
  };

  const handleCategoryDeleteClick = (category) => {
    onCategoryDelete(category);
  };

  const handleCategoryHideClick = (category) => {
    onCategoryHideUpdate(category);
  };

  const handleCategoryOrProductMoveClick = (
    categoryOrProductList,
    itemId,
    sourceIndex,
    api,
    upDirection = true
  ) => {
    onCategoryOrProductMove(
      categoryOrProductList,
      itemId,
      sourceIndex,
      api,
      upDirection
    );
  };

  /*
   * WORK WITH PRODUCT
   */
  const handleProductEditClick = (product) => {
    history.push(`/restaurant/${slug}/product/${product.id}`);
  };

  const handleDeleteEditClick = (product) => {
    onProductDelete(product);
  };

  const handleProductHideClick = (product) => {
    onProductHideUpdate(product);
  };

  const handlePreviewClick = () => {
    history.push(`/restaurant/${slug}/menu/${menuId}/preview`);
  };

  const handleAddProductToCategoryClick = (category) => {
    history.push({
      pathname: `/restaurant/${slug}/product/create`,
      state: { category: category.id, menu: menuId },
    });
  };

  const handleAddProductClick = () => {
    history.push({
      pathname: `/restaurant/${slug}/product/create`,
      state: { menu: menuId },
    });
  };

  const isProductsInCategory = (category) => category.products.length !== 0;

  const isCategoryNoCollapsed = (category) =>
    !colapsedCategories.includes(category.id);

  useEffect(() => {
    setColapsedCategories(
      menu.categories
        .filter((category) => !isProductsInCategory(category))
        .map((category) => category.id)
    );
  }, [menu.categories]);

  const handleCategoryCollapse = (category) => {
    if (colapsedCategories.includes(category.id)) {
      setColapsedCategories((data) =>
        data.filter((categoryId) => categoryId !== category.id)
      );
    } else setColapsedCategories([...colapsedCategories, category.id]);
  };

  const handleSecondaryMerchantUse = (category) =>
    onCategorySecondMerchantUse(category);

  return (
    <div className="restaurant-menu__content">
      <h2 className="restaurant-menu__menu-name">{menu.name}</h2>
      <div className="restaurant-menu__category-list">
        {menu.categories &&
          menu.categories.map((category, index, categories) => (
            <div className="restaurant-menu__category" key={category.id}>
              <RestaurantMenuEditableLabel
                onClick={() => handleCategoryCollapse(category)}
                isProductsInCategory={isProductsInCategory(category)}
                className="restaurant-menu__category-name"
                item={category}
                isShowControls
                handleDelete={() => handleCategoryDeleteClick(category)}
                handleAddProduct={() =>
                  handleAddProductToCategoryClick(category)
                }
                isSecondaryMerchantAvailable={
                  restaurant.is_secondary_merchant_available
                }
                handleHide={() => handleCategoryHideClick(category)}
                handleMoveUp={() =>
                  handleCategoryOrProductMoveClick(
                    categories,
                    category.id,
                    index,
                    'category'
                  )
                }
                handleMoveDown={() =>
                  handleCategoryOrProductMoveClick(
                    categories,
                    category.id,
                    index,
                    'category',
                    false
                  )
                }
                onApplyChanges={(name) =>
                  handleCategoryNameChangeClick(name, category.id)
                }
                handleSecondaryMerchantUse={() =>
                  handleSecondaryMerchantUse(category)
                }
                handleCollapse={() => handleCategoryCollapse(category)}
                isNoCollapsed={isCategoryNoCollapsed(category)}
                showCollapseIcon={isProductsInCategory(category)}
                currentLang={currentLang}
              />

              <div
                className={`restaurant-menu__category-products-list ${
                  isCategoryNoCollapsed(category)
                    ? 'restaurant-menu__category-products-list_show'
                    : 'restaurant-menu__category-products-list_hide'
                }`}
              >
                {isProductsInCategory(category) ? (
                  category.products.map((product, index, products) => (
                    <RestaurantMenuProduct
                      key={product.id}
                      product={product}
                      isMobile={isMobile}
                      handleProductEditClick={() =>
                        handleProductEditClick(product)
                      }
                    >
                      <div className="restaurant-menu__product-icons">
                        {!product.is_active && (
                          <Icon
                            name="eye"
                            onClick={() => onProductHideUpdate(product)}
                          />
                        )}
                        <DropdownMenu
                          options={[
                            {
                              label: t('restaurant_menu_page.product_edit'),
                              icon: 'pen',
                              onClick: () => handleProductEditClick(product),
                            },
                            {
                              icon: 'topCircleArrow',
                              label: t('restaurant_menu_page.move_up'),
                              onClick: () =>
                                handleCategoryOrProductMoveClick(
                                  products,
                                  product.id,
                                  index,
                                  'product'
                                ),
                            },
                            {
                              icon: 'downCircleArrow',
                              label: t('restaurant_menu_page.move_down'),
                              onClick: () =>
                                handleCategoryOrProductMoveClick(
                                  products,
                                  product.id,
                                  index,
                                  'product',
                                  false
                                ),
                            },
                            {
                              label: product.is_active
                                ? t('restaurant_menu_page.product_hide')
                                : t('restaurant_menu_page.product_show'),
                              icon: 'hide',
                              onClick: () => handleProductHideClick(product),
                            },
                            {
                              label: t('restaurant_menu_page.product_remove'),
                              icon: 'delete',
                              onClick: () => handleDeleteEditClick(product),
                            },
                          ]}
                        />
                      </div>
                    </RestaurantMenuProduct>
                  ))
                ) : (
                  <p className="restaurant-menu__category-emptytext">
                    {t('restaurant_menu_page.empty_category')}
                  </p>
                )}
              </div>
            </div>
          ))}

        {showAddCategoryField && (
          <RestaurantMenuEditableLabel
            className="restaurant-menu__create-category-label"
            ref={newCategoryRef}
            isShowControls
            isEdit={showAddCategoryField}
            onCloseEdit={() => {
              setShowAddCategoryField(false);
            }}
            onApplyChanges={handleCategoryAddClick}
          />
        )}
        <div
          onClick={() => setShowAddCategoryField(true)}
          className="restaurant__link restaurant-menu__link restaurant-menu__link_first"
        >
          <Icon name="plus" /> {t('restaurant_menu_page.category_add')}
        </div>
        <div
          onClick={() => handleAddProductClick()}
          className="restaurant__link restaurant-menu__link"
        >
          <Icon name="plus" /> {t('restaurant_menu_page.product_add')}
        </div>
      </div>

      <div className="restaurant-menu__restaurant-preview-qr-block">
        <RestaurantPreviewAndQRBlock
          className="mx-4"
          restaurant={restaurant}
          onPreviewClick={handlePreviewClick}
        />
      </div>
    </div>
  );
};

AdminRestaurantMenu.defaultProps = {
  menu: [],
  restaurant: [],
  onCategoryAdd: () => {},
  onCategoryDelete: () => {},
  onCategoryNameChange: () => {},
  onCategoryHideUpdate: () => {},
  onCategoryOrProductMove: () => {},
  onProductDelete: () => {},
  onProductHideUpdate: () => {},
  showAddCategoryField: false,
  setShowAddCategoryField: () => {},
};

AdminRestaurantMenu.PropsTypes = {
  menu: PropTypes.arr,
  restaurant: PropTypes.arr,
  onCategoryAdd: PropTypes.func,
  onCategoryDelete: PropTypes.func,
  onCategoryNameChange: PropTypes.func,
  onCategoryHideUpdate: PropTypes.func,
  onCategoryOrProductMove: PropTypes.func,
  onProductDelete: PropTypes.func,
  onProductHideUpdate: PropTypes.func,
  showAddCategoryField: PropTypes.boolean,
  setShowAddCategoryField: PropTypes.func,
};

const mapStateToProps = (state) => ({
  errors: state.restaurant.fieldErrors,
  isShowLoader: state.restaurant.isLoading,
  restaurant: state.restaurant.restaurantData,
  isAuthenticated: state.auth.isAuthenticated,
  isMobile: isMobile(state),
});

export default connect(mapStateToProps, null)(AdminRestaurantMenu);
