import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Checkbox from './common/Checkbox';
import DropdownMenu from './common/DropdownMenu';
import Icon from './common/Icon';

const ProductOption = ({
  option,
  isRestaurantOptions,
  onEditClick,
  onDeleteClick,
  onHideClick,
  isProductSelect,
  handleOptionChange,
  checkIsSelectedOption,
}) => {
  const { t } = useTranslation();
  return (
    <div className="product-option">
      <div className="product-option__name">
        <p>{option.name}</p>
      </div>
      <div className="product-option__bottom-line">
        <div className="product-option__price">
          <p>{`${option.price} ₴`}</p>
        </div>
        <div className="product-option__remove">
          {option.is_active ? (
            ''
          ) : (
            <Icon name="eye" className="product-option__hide" />
          )}
          {isProductSelect && (
            <Checkbox
              checked={checkIsSelectedOption(option)}
              onChange={() => handleOptionChange(option)}
            />
          )}
          {isRestaurantOptions ? (
            <DropdownMenu
              options={[
                {
                  label: `${
                    option.is_active ? t('options.hide') : t('options.show')
                  }`,
                  onClick: () => onHideClick(option),
                },
                {
                  label: t('options.edit'),
                  onClick: () => onEditClick(option),
                },
                {
                  label: t('options.remove'),
                  onClick: () => onDeleteClick(option),
                },
              ]}
            />
          ) : (
            !isProductSelect && (
              <Icon
                name="delete"
                onClick={() => handleOptionChange(option)}
                className="product-option__delete"
              />
            )
          )}
        </div>
      </div>
    </div>
  );
};

ProductOption.PropsTypes = {
  option: PropTypes.shape({
    name: PropTypes.string,
    price: PropTypes.number,
    is_active: PropTypes.bool,
  }),
  isRestaurantOptions: PropTypes.bool,
  handleAdditionChange: PropTypes.func,
  checkIsSelectedOption: PropTypes.func,
};

ProductOption.defaultProps = {
  option: {
    name: '',
    price: 1,
    is_active: true,
  },
  isRestaurantOptions: false,
  handleAdditionChange: () => {},
  checkIsSelectedOption: () => {},
};

export default ProductOption;
