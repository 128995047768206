import React from 'react';

const Toggle = ({ label, checked, onChange, className, ...rest }) => (
  <div className={`toggle-block ${className}`}>
    <label className="toggle">
      <input
        {...rest}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        className="toggle__input"
      />
      <span className="slider round" />
    </label>
    <label className={`toggle-label${checked ? '_active' : ''}`}>{label}</label>
  </div>
);

export default Toggle;

Toggle.defaultProps = {
  checked: false,
  label: '',
  className: '',
  onChange: () => {},
};
