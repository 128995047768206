import React from 'react';
import { useTranslation } from 'react-i18next';
import Info from './common/Info';
import IikoPaymentsBlock from './IikoPaymentsBlock';

const IikoPaymentsSync = (props) => {
  const { t } = useTranslation();
  return (
    <div className="iiko-payments-sync__container">
      <div className="iiko-payments-sync__title">
        {t('pos_system.sync_payments')}
        <Info name="iiko_payments" className="iiko-payments-sync__info" />
      </div>
      <IikoPaymentsBlock
        {...props}
        name={t('pos_system.cash_payment_name')}
        type="cash"
      />
      <IikoPaymentsBlock
        {...props}
        name={t('pos_system.terminal_payment_name')}
        type="terminal"
      />
      <IikoPaymentsBlock
        {...props}
        name={t('pos_system.card_payment_name')}
        type="card"
      />
    </div>
  );
};

export default IikoPaymentsSync;
