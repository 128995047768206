import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Icon from './common/Icon';
import { logout } from '../redux/reducers/auth/actions';
import { toggleSideMenu } from '../redux/reducers/ui/actions';

const SideMenu = ({ className, logout, isOpen, toggleSideMenu }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const handleLogoutClick = () => {
    toggleSideMenu();
    logout();
    history.push('/');
  };
  return (
    <div className={`menu ${className || ''}`}>
      <div
        className={`menu__container ${isOpen ? 'menu__container_open' : ''}`}
      >
        {isOpen && (
          <>
            <Icon
              className="menu__close-icon"
              onClick={toggleSideMenu}
              name="close"
            />
            <div className="menu__content">
              <Link data-aos-delay="200" data-aos="fade-left" to="/">
                <Icon withHover={false} name="logo" />
              </Link>
              <div className="menu__list">
                <Link
                  data-aos-delay="300"
                  data-aos="fade-left"
                  onClick={toggleSideMenu}
                  to="/restaurants"
                  className="menu__item"
                >
                  {t('side_menu.restaurants')}
                </Link>
                <Link
                  data-aos-delay="400"
                  data-aos="fade-left"
                  onClick={toggleSideMenu}
                  to="/profile/info"
                  className="menu__item"
                >
                  {t('side_menu.profile')}
                </Link>
              </div>
              <div
                data-aos-delay="400"
                data-aos="fade-left"
                className="menu__footer"
              >
                <button type="button" onClick={handleLogoutClick}>
                  {t('side_menu.logout')}
                </button>
                <p>
                  Product by <a href="https://fitel.oi">fitel.io</a>
                </p>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isOpen: state.ui.showSideMenu,
});

export default connect(mapStateToProps, { logout, toggleSideMenu })(SideMenu);

SideMenu.defaultProps = {
  className: '',
};
