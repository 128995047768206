import { post } from '../utils/axios';

const feedbackAPI = {
  send: async (data) => {
    try {
      return await post(`/feedback/`, data, true);
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },
};

export default feedbackAPI;
