import React from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from '../../components/common/Button';
import Icon from '../../components/common/Icon';
import { isMobile } from '../../redux/reducers/ui/selectors';
import SideMenu from '../../components/SideMenu';

const EmptyList = ({ isMobile }) => {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <div className="restaurants-list-empty">
      <SideMenu className="restaurants-list-empty__menu" />
      <div data-aos="fade-down" className="restaurants-list-empty__content">
        <Icon name="logo" />
        <h3 className="restaurants-list-empty__title">
          {t('restaurants_list_empty.title')}
        </h3>
        <p className="restaurants-list-empty__text">
          {t('restaurants_list_empty.text')}
        </p>
        <Button
          className="restaurants-list-empty__button"
          onClick={() => history.push('/restaurant/create')}
        >
          {t('restaurants_list_empty.create')}
        </Button>
      </div>

      <svg
        className="restaurants-list-empty__content-bg"
        width="1148"
        height="1148"
        viewBox="0 0 1148 1148"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          // rotatable circle
          opacity="0.5"
          cx="599.5"
          cy="605.5"
          r={isMobile ? '544' : '419'}
          stroke="#9AADF1"
          strokeWidth="2"
        />
        <circle
          cx="574"
          cy="574"
          r={isMobile ? '544' : '419'}
          stroke="#20CA68"
          opacity="0.5"
          strokeWidth="3"
        />

        <defs>
          <filter
            id="filter0_d"
            x="0"
            y="0"
            width="1148"
            height="1148"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="15" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isMobile: isMobile(state),
});

export default connect(mapStateToProps, null)(EmptyList);
