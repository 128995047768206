import React from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Icon from './common/Icon';
import { totalCartCount } from '../redux/reducers/cart/selectors';

const CartWidget = ({ productCount }) => {
  const history = useHistory();
  const { slug } = useParams();

  const handleGoToOrder = () => {
    history.push(`/restaurant/${slug}/checkout`);
  };

  return (
    <div
      onClick={handleGoToOrder}
      className={`cart-widget  ${!productCount ? 'cart-widget_empty' : ''}`}
    >
      <Icon withHover={false} name="cart" />
      <span className="cart-widget__count">
        {productCount < 10 ? `0${productCount}` : productCount}
      </span>
    </div>
  );
};

const mapStateToProps = (state) => ({
  productCount: totalCartCount(state),
});

export default connect(mapStateToProps)(CartWidget);
