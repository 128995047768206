import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { show } from 'redux-modal';
import { useTranslation } from 'react-i18next';
import Modal from '../../components/common/Modal';
import Header from '../../components/Header';
import Button from '../../components/common/Button';
import Input from '../../components/common/Input';
import productAPI from '../../api/product';
import TextArea from '../../components/common/TextArea';
import Autocomplete from '../../components/common/Autocomplete';
import restaurantsAPI from '../../api/restaurants';
import { isMobile } from '../../redux/reducers/ui/selectors';
import Select from '../../components/common/Select';
import Loader from '../../components/Loader';
import { convertFileToBase64, getImage, srcSet } from '../../utils/files';
import Icon from '../../components/common/Icon';
import ProductOption from '../../components/ProductOption';
import menuAPI from '../../api/menu';
import DropdownMenu from '../../components/common/DropdownMenu';
import Info from '../../components/common/Info';
import OptionsList from './OptionsList';
import ProductOptionGroupsList from './ProductOptionGroupsList';
import { showConfirmPhoneModal } from '../../redux/reducers/auth/actions';
import { setEditedProduct } from '../../redux/reducers/restaurant/actions';
import settingsApi from '../../api/settings';
import useRestaurantControls from '../../hooks/useRestaurantControls';
import ContentLanguageSelector from '../../components/common/ContentLanguageSelector';

const ProductModify = ({
  location,
  restaurant,
  restaurantLoading,
  isMobile,
  showConfirmPhoneModal,
  setEditedProduct,
  editedProduct,
  show,
}) => {
  const DEFAULT_PRODUCT_STATE = {
    name: '',
    weight: '',
    price: '',
    unit: null,
    description: '',
    category: '',
    menu: '',
    options: [],
    option_groups: [],
  };
  const productImageRef = useRef();

  const [units, setUnits] = useState([]);

  const [product, setProduct] = useState({
    ...editedProduct,
    category: location?.state?.category
      ? location?.state?.category
      : editedProduct.category,
    menu: location?.state?.menu ? location?.state?.menu : editedProduct.menu,
  });

  const [categories, setCategories] = useState([]);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [modalOptionsIsOpen, setModalOptionsIsOpen] = useState(false);
  const [restaurantOptions, setRestaurantOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [optionGroupSettings, setOptionGroupSettings] = useState([]);
  const dropdownRef = useRef();
  const [languageOptions, setLanguages] = useState([]);
  const [currentLang, setCurrentLang] = useState(
    window.localStorage.getItem('language')
      ? window.localStorage.getItem('language')
      : 'en'
  );

  useEffect(() => {
    settingsApi.getSystemSettings().then((data) => {
      const options = data.languages
        .filter((language) => restaurant?.languages.includes(language.code))
        .map((language) => {
          return {
            label: language.full,
            value: language.short,
            id: language.code,
            onClick: () => {
              setCurrentLang(language.code);
              window.localStorage.setItem('language', language.code);
            },
          };
        });
      if (options.length > 0 && options.length < 2) {
        setCurrentLang(options[0].id);
        window.localStorage.setItem('language', options[0].id);
      }
      setLanguages(options);
    });
  }, [restaurant?.languages]);

  const { t } = useTranslation();
  const history = useHistory();
  const { slug } = useParams();
  const { productId } = useParams();
  const isEditing = Boolean(productId);
  const { isShowControls } = useRestaurantControls(restaurant);

  useEffect(() => {
    // Using an IIFE
    (async () => {
      setLoading(true);
      if (isEditing) {
        try {
          const { data } = await productAPI.get(productId, currentLang);
          setProduct(data);
          if (!editedProduct.id) setEditedProduct(data);
          if (!editedProduct.id) {
            setSelectedOptions(data.options);
          } else setSelectedOptions(editedProduct.options);
          setOptionGroupSettings(data.option_group_settings);
        } catch (e) {
          console.error(e);
          if (e.code === 403) {
            showConfirmPhoneModal();
          }
        }
      }

      try {
        const { data } = await productAPI.getUnits();
        setUnits(data);
        if (!isEditing) {
          setProduct({ ...product, unit: data[0].id });
        }
      } catch (e) {
        console.error(e);
        if (e.code === 403) {
          showConfirmPhoneModal();
        }
      }
      setLoading(false);
    })();
    // eslint-disable-next-line
  }, [isEditing, productId, currentLang]);

  // Fetch available option for restaurant
  useEffect(() => {
    if (restaurant) {
      restaurantsAPI.getOptions(restaurant.id).then(({ data }) => {
        setRestaurantOptions(data);
      });
    }
  }, [restaurant]);

  // Update product menu property (change id -> { id, name })
  useEffect(() => {
    if (restaurant && product) {
      const productMenu = restaurant.menu.find(
        (menu) => menu.id === product.menu
      );
      if (productMenu) {
        setProduct({ ...product, menu: productMenu });
      }
    }
  }, [restaurant, product]);

  // fetch categories for each time when menu select change
  useEffect(() => {
    if (product?.menu?.id) {
      menuAPI.get(product.menu.id).then(({ data }) => {
        setCategories(data.categories);
      });
    }
  }, [product?.menu?.id]);

  useEffect(() => {
    const productCategory = categories.find(
      (category) => category.id === product.category
    );
    if (productCategory) {
      setProduct({ ...product, category: productCategory });
    }
  }, [categories, product]);

  const handleClick = (e) => {
    if (productId) {
      dropdownRef.current.handleDropdownClick(e);
    }
  };

  const handleNameChange = (value) => {
    setProduct({
      ...product,
      [`${isEditing ? `name_${currentLang}` : 'name'}`]: value,
    });
    setEditedProduct(product);
  };

  const handleWeightChange = (value) => {
    setErrors({ weight: null });
    setProduct({ ...product, weight: value });
    setEditedProduct(product);
  };

  const handleUnitChange = (value) => {
    setProduct({ ...product, unit: value.id });
    setEditedProduct(product);
  };

  const handlePriceChange = (value) => {
    setErrors({ product: null });
    setProduct({ ...product, price: value });
    setEditedProduct(product);
  };

  const handleImageChange = async (e) => {
    const [img] = Array.from(e.target.files);
    if (img) {
      const base64 = await convertFileToBase64(img);
      setProduct({
        ...product,
        image: { filename: img.name, content: base64 },
      });
      setEditedProduct(product);
    }
  };

  const handleOpenImageFileDialog = () => {
    if (productImageRef.current) {
      // imitation on input click
      productImageRef.current.click();
    }
  };

  const handleEmptyHeaderClick = () => {
    if (!product.image) handleOpenImageFileDialog();
  };

  const handleImageRemove = () => {
    Swal.fire({
      heightAuto: false,
      title: t('product_edit.image_remove_message'),
      showCancelButton: true,
      confirmButtonText: t('swal.yes'),
      showClass: {
        popup: 'animate__animated modalFadeInUp',
      },
      hideClass: {
        popup: 'animate__animated modalFadeOutDown',
      },
      cancelButtonText: t('swal.no'),
    }).then((data) => {
      if (data.value) {
        setProduct({
          ...product,
          image: null,
        });
        setEditedProduct(product);
      }
    });
  };

  const handleDescriptionChange = (value) => {
    setProduct({
      ...product,
      [`${isEditing ? `description_${currentLang}` : 'description'}`]: value,
    });
    setEditedProduct(product);
  };

  const handleCategorySelect = (category) => {
    setProduct({ ...product, category });
    setEditedProduct(product);
  };

  const handleMenuSelect = (menu) => {
    setProduct({ ...product, menu });
    setEditedProduct(product);
  };

  const handleSaveOptionsClick = () => {
    setSelectedOptions(selectedOptions);
    setEditedProduct({ ...editedProduct, options: selectedOptions });
    setModalOptionsIsOpen(false);
  };

  const handleSaveClick = async (e) => {
    e.preventDefault();
    setErrors({});
    setLoading(true);
    setEditedProduct(DEFAULT_PRODUCT_STATE);
    const serverData = {};

    if (isEditing) {
      serverData[`name_${currentLang}`] = product[`name_${currentLang}`];
      serverData[`description_${currentLang}`] =
        product[`description_${currentLang}`];
    } else {
      serverData.name = product.name;
      serverData.description = product.description;
    }
    serverData.image = product.image;
    serverData.id = product.id;
    serverData.price = product.price;

    serverData.restaurant = restaurant.id;
    serverData.category = product.category.id;
    serverData.menu = product.menu.id;
    serverData.unit = product.unit;
    serverData.weight = product.weight;

    if (product.price === '') serverData.price = 0;

    if (product.unit?.id) {
      serverData.unit = product.unit.id;
    }
    if (serverData?.image !== null) {
      if (!serverData?.image?.content && !serverData?.image?.filename) {
        delete serverData.image;
      }
    }

    try {
      const { data } = await productAPI[isEditing ? 'patch' : 'create']({
        ...serverData,
        is_active: true,
      });

      const serverOptions = selectedOptions.map((option) => ({
        option: option.id,
      }));

      await productAPI.updateOptions(data.id, serverOptions);
      history.push({
        pathname: `/restaurant/${slug}/menu/${product.menu.id}`,
      });
    } catch (e) {
      console.error(e);
      if (e.code === 403) {
        showConfirmPhoneModal();
      }
      setErrors(e);
      setLoading(false);
    }
  };

  const checkIsSelectedOption = (option) => {
    const existedOption = selectedOptions.find((item) => item.id === option.id);
    return !!existedOption;
  };

  const handleOptionChange = (option) => {
    const changedOption = selectedOptions.find((item) => item.id === option.id);

    if (changedOption) {
      const newOptions = selectedOptions.filter(
        (item) => item.id !== option.id
      );
      setSelectedOptions(newOptions);
    } else {
      const newOptions = selectedOptions.concat(option);
      setSelectedOptions(newOptions);
    }
  };

  const handleDeleteSettings = async (id) => {
    try {
      await restaurantsAPI.deleteOptionGroupSettings(id);
      const { data } = await productAPI.get(productId);
      setOptionGroupSettings(data.option_group_settings);
    } catch (e) {
      console.error(e);
      if (e.code === 403) {
        showConfirmPhoneModal();
      }
    }
  };

  let saveDisable = true;

  const selectedUnitName = units.find((unit) => unit.id === product.unit)?.name;

  if (loading || restaurantLoading || languageOptions.length < 1) {
    return <Loader />;
  }

  if (isEditing) {
    if (product[`name_${currentLang}`] !== null) {
      if (product[`name_${currentLang}`].length !== 0) saveDisable = false;
    } else saveDisable = true;
  } else if (product.name !== null) {
    if (product.name.length !== 0) saveDisable = false;
  } else saveDisable = true;

  const handleCloseClick = (event) => {
    event.stopPropagation();
    setEditedProduct(DEFAULT_PRODUCT_STATE);
    history.push(
      product.menu?.id
        ? `/restaurant/${slug}/menu/${product.menu.id}`
        : `/restaurant/${slug}/`
    );
  };

  return (
    <div className="product page">
      <Header className="product__header" onClick={handleEmptyHeaderClick}>
        <div className="product__image">
          {product.image ? (
            <img
              src={getImage(product.image)}
              srcSet={srcSet(product.image)}
              alt={product.name}
            />
          ) : (
            <div className="restaurant-header__background-image restaurant-header__background-image_empty">
              <p>{t('restaurant_header.image_add_hint')}</p>
              <p>{t('product_edit.image_placeholder_hint')}</p>
            </div>
          )}
          <input
            onChange={handleImageChange}
            type="file"
            id="restaurant-image"
            ref={productImageRef}
            style={{ display: 'none' }}
          />
        </div>
        <DropdownMenu
          className="product__image-dropdown"
          options={[
            {
              icon: 'change',
              label: `${
                product.image
                  ? t('product_edit.image_change')
                  : t('product_edit.image_add')
              }`,
              onClick: handleOpenImageFileDialog,
            },
            {
              icon: 'delete',
              label: t('product_edit.image_remove'),
              onClick: () => handleImageRemove('image'),
            },
          ]}
        />
        <Icon
          name="close"
          className="product__close"
          onClick={handleCloseClick}
        />
      </Header>
      <div data-aos="fade-up" data-aos-delay="400" className="product__content">
        {errors.image && (
          <div className="error-box error-box_center">
            {errors.image.content && <p>{errors.image.content}</p>}
            {errors.image.filename && <p>{errors.image.filename}</p>}
          </div>
        )}

        <div className="language-block">
          <ContentLanguageSelector
            options={languageOptions}
            value={
              languageOptions.find((option) => option.id === currentLang).value
            }
            isShowControls={isShowControls}
            label="product_translation"
            show={show}
          />
        </div>

        <form onSubmit={handleSaveClick} className="product__form">
          <Input
            multiline
            value={isEditing ? product[`name_${currentLang}`] : product.name}
            required
            name="name"
            placeholder={t('product_edit.name_input_placeholder')}
            onChange={handleNameChange}
            error={errors.name}
          />
          <Input
            unit="₴"
            value={product.price}
            type="number"
            placeholder={t('product_edit.price_input_placeholder')}
            name="price"
            onChange={handlePriceChange}
            error={errors.price}
          />
          <div className="product__form-weight-block">
            <Input
              value={product.weight}
              unit={selectedUnitName}
              className="product__form-weight"
              name="weight"
              type="text"
              placeholder={t('product_edit.weight_input_placeholder')}
              onChange={handleWeightChange}
              error={errors.weight}
            />
            <Select
              className="product__form-unit"
              valueId={product.unit}
              onSelect={handleUnitChange}
              options={units}
              error={errors.unit}
            />
          </div>
          <Autocomplete
            options={restaurant.menu}
            value={product.menu}
            currentLang={currentLang}
            placeholder={t('product_edit.menu_input_placeholder')}
            label={t('product_edit.menu_input_label')}
            onSelect={handleMenuSelect}
            error={errors.menu}
            addDisabled
          />
          <Autocomplete
            options={categories}
            value={product.category}
            currentLang={currentLang}
            cat
            placeholder={t('product_edit.category_input_placeholder')}
            label={t('product_edit.category_input_label')}
            onSelect={handleCategorySelect}
            error={errors.category}
          />
          <TextArea
            value={
              isEditing
                ? product[`description_${currentLang}`]
                : product.description
            }
            onChange={handleDescriptionChange}
            label={t('product_edit.description_input_placeholder')}
          />
        </form>
        <div
          className={`product__options ${
            !productId && 'product__options_no-groups'
          }`}
        >
          <div data-aos="fade-up" data-aos-delay="200">
            {product?.option_group_settings && (
              <ProductOptionGroupsList
                option_group_settings={optionGroupSettings}
                handleDeleteSettings={handleDeleteSettings}
              />
            )}
            <div className="product__options-create">
              {!isMobile && (
                <Info
                  name={productId ? 'option_group' : 'option_group_disabled'}
                  containerClassName="product__options-info"
                />
              )}
              <div
                onClick={handleClick}
                className={`product__link ${
                  !productId && 'product__link_disabled'
                }`}
              >
                {productId ? (
                  <DropdownMenu
                    ref={dropdownRef}
                    icon="plus"
                    options={[
                      {
                        label: t('product_edit.create_option_groups'),
                        onClick: () =>
                          history.push(
                            `/restaurant/${slug}/product/${productId}/option-groups/create`
                          ),
                      },
                      {
                        label: t('product_edit.add_option_groups'),
                        onClick: () =>
                          history.push(
                            `/restaurant/${slug}/product/${productId}/option-groups/`
                          ),
                      },
                    ]}
                  />
                ) : (
                  <Icon
                    name="plus"
                    className="product__options-create_disabled"
                  />
                )}
                <p>{t('product_edit.add_option_groups_title')}</p>
              </div>
              {isMobile && (
                <Info
                  name={productId ? 'option_group' : 'option_group_disabled'}
                  containerClassName="product__options-info"
                />
              )}
            </div>
            {selectedOptions.length === 0 ? (
              <div className="product__options-create">
                {!isMobile && (
                  <Info
                    name="options"
                    containerClassName="product__options-info"
                  />
                )}
                <div
                  onClick={() => setModalOptionsIsOpen(true)}
                  className="product__link"
                >
                  <Icon name="plus" />
                  <p>{t('product_edit.add_options_title')}</p>
                </div>
                {isMobile && (
                  <Info
                    name="options"
                    containerClassName="product__options-info"
                  />
                )}
              </div>
            ) : (
              <OptionsList
                selectedOption={selectedOptions}
                handleOptionChange={handleOptionChange}
                checkIsSelectedOption={checkIsSelectedOption}
                setModalOptionsIsOpen={setModalOptionsIsOpen}
              />
            )}
          </div>
        </div>

        <div className="product__button-block">
          {errors.non_field_errors && (
            <div className="error-box error-box_center">
              <p>{errors.non_field_errors}</p>
            </div>
          )}
          {(errors.is_active || errors.error) && (
            <div className="error-box error-box_center">
              <p>{errors.is_active ?? errors.error}</p>
            </div>
          )}

          <Button
            disabled={saveDisable}
            className="product__button"
            onClick={handleSaveClick}
          >
            {t('product_edit.save')}
          </Button>
        </div>
      </div>

      <Modal
        isOpen={modalOptionsIsOpen}
        onClose={() => setModalOptionsIsOpen(false)}
        title={t('options.modal_title')}
      >
        <div
          data-aos="fade-up"
          data-aos-delay="400"
          className="product-options__body"
        >
          <Link
            key="new-option"
            className="product-options__link"
            to={`/restaurant/${slug}/option/create`}
          >
            <Icon name="plus" />
            {t('options.add_options')}
          </Link>

          <div className="product-options__list">
            {restaurantOptions.map((option) => (
              <ProductOption
                key={option.id}
                option={option}
                isProductSelect
                checkIsSelectedOption={checkIsSelectedOption}
                handleOptionChange={handleOptionChange}
              />
            ))}
          </div>

          <Link
            key="new-option"
            className="product-options__link"
            to={`/restaurant/${slug}/option/create`}
          >
            <Icon name="plus" />
            {t('options.add_options')}
          </Link>

          <Button
            onClick={handleSaveOptionsClick}
            className="product-options__save-btn"
            type="submit"
          >
            {t('product_edit.select_options')}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isMobile: isMobile(state),
  restaurant: state.restaurant.restaurantData,
  editedProduct: state.restaurant.editedProduct,
  restaurantLoading: state.restaurant.isLoading,
});

export default connect(mapStateToProps, {
  showConfirmPhoneModal,
  show,
  setEditedProduct,
})(ProductModify);
