import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { show } from 'redux-modal';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Header from '../../components/Header';
import Loader from '../../components/Loader';
import { updateRestaurantApi } from '../../redux/reducers/restaurant/actions';
import { isMobile } from '../../redux/reducers/ui/selectors';
import { showConfirmPhoneModal } from '../../redux/reducers/auth/actions';
import { MODAL_NAME as RESTAURANT_PROPERTIES_MODAL } from '../../components/modals/RestaurantPropertiesModal';
import Icon from '../../components/common/Icon';
import restaurantsAPI from '../../api/restaurants';
import Settings from './Setting';
import RestaurantDelivery from './RestaurantDelivery';
import Button from '../../components/common/Button';

const DEFAULT_EXTRA_STATE = {
  delivery: {
    is_delivery: false,
    is_takeaway: false,
    is_taxi_delivery: false,
    takeaway: {
      date_delay_min: 0,
      date_offset_min: 0,
      note_text: 'Заберіть замовлення вчасно щоб не схололо',
    },
    delivery: {
      date_delay_min: 0,
      date_offset_min: 0,
      note_text:
        'Орієнтовний час доставки 90 хв. Не хвилюйтеся ви отримаєте смачні та гарячі страви',
    },
    taxi_delivery: {
      date_delay_min: 0,
      date_offset_min: 0,
      note_text:
        "Доставка здійснюється кур'єрською службою таксі. Вартість доставки оцінюється окремо.",
    },
    delivery_zones: [],
  },
  settings: {
    dine_in: {
      note_text: 'The waiter will bring your order after payment',
      date_delay_min: 0,
      date_offset_min: 0,
    },
    is_dine_in: false,
    is_call_waiter: false,
    is_feedback: false,
    is_payment: false,
    payment_types: {},
  },
};

const ControlCenter = ({
  restaurantLoading,
  show,
  restaurant,
  updateRestaurantApi,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const handleGoBack = () => {
    history.push(`/restaurant/${restaurant.slug}`);
  };
  const handleMenuClick = () => {
    show(RESTAURANT_PROPERTIES_MODAL);
  };

  const [staffData, setStaffData] = useState([]);
  const [colapsedControls, setColapsedControls] = useState([]);
  const [extraData, setExtraData] = useState(DEFAULT_EXTRA_STATE);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (restaurant?.id) {
      Promise.all([
        restaurantsAPI.getExtra(restaurant.id),
        restaurantsAPI.getStaff(restaurant.id),
      ])
        .then(([extraResponse, staffResponse]) => {
          setExtraData((extraData) => ({
            ...extraData,
            settings: extraResponse.data,
          }));
          setStaffData(staffResponse.data);
          setLoading(false);
        })
        .catch((e) => {
          console.error(e);
          if (e.code === 403) {
            showConfirmPhoneModal();
          }
        });
    }
    // eslint-disable-next-line
    }, [restaurant?.id, showConfirmPhoneModal]);

  const handleSaveClick = async () => {
    const { payment_types, ...booleanValues } = extraData.settings;

    const serverData = {
      ...extraData.delivery,
      ...booleanValues,
      payment_types: Object.entries(payment_types).reduce(
        (acc, [typeName, paymentType]) => {
          acc[typeName] = paymentType.is_active;
          return acc;
        },
        {}
      ),
      payment_providers: Object.values(payment_types).reduce(
        (acc, paymentType) => {
          if (paymentType.providers && paymentType.is_active) {
            return Object.entries(paymentType.providers).reduce(
              (acc, [provideName, provider]) => {
                acc[provideName] = {
                  merchant_id: provider.merchant_id,
                  login: provider.login,
                  password: provider.password,
                };
                if (provider.secondary_merchant_enabled) {
                  acc[provideName].secondary_merchant_enabled =
                    provider.secondary_merchant_enabled;
                  acc[provideName].secondary_merchant_id =
                    provider.secondary_merchant_id;
                  acc[provideName].secondary_login = provider.secondary_login;
                  acc[provideName].secondary_password =
                    provider.secondary_password;
                }
                return acc;
              },
              {}
            );
          }
          return acc;
        },
        {}
      ),
      id: restaurant.id,
    };
    try {
      await updateRestaurantApi(serverData);
      history.push(`/restaurant/${restaurant.slug}`);
    } catch (e) {
      // TODO CHECK
      setErrors(e);
    }
  };

  const isControlCenterDisabled = () => staffData.length === 0;

  const isControlCollapsed = (control) => {
    return colapsedControls.includes(control);
  };

  const handleControlCollapse = (control) => {
    if (colapsedControls.includes(control)) {
      setColapsedControls((data) =>
        data.filter((collapsedControle) => collapsedControle !== control)
      );
    } else setColapsedControls([...colapsedControls, control]);
  };

  const handleChangeSettingsData = (newExtraData) => {
    setExtraData({ ...extraData, settings: newExtraData });
  };

  if (loading || restaurantLoading) {
    return <Loader />;
  }

  return (
    <div className="page control-center">
      <Header
        title={t('control_center.title')}
        rightIcon="menu"
        leftIcon="back"
        onRightIconClick={handleMenuClick}
        onLeftIconClick={handleGoBack}
      />
      <div className="control-center-content">
        <div
          className="control-center__staff"
          onClick={() =>
            history.push(`/restaurant/${restaurant.slug}/control-center/staff`)
          }
        >
          <p className="control-center__staff-text">
            {t('control_center.staff_title')}
          </p>
          <Icon
            name="back"
            className="control-center__staff-icon"
            onClick={() =>
              history.push(
                `/restaurant/${restaurant.slug}/control-center/staff`
              )
            }
          />
        </div>
        {isControlCenterDisabled() && (
          <p className="control-center__no-staff">
            {t('control_center.disabled_tip')}
          </p>
        )}
        <div
          className={`control-center__list ${
            isControlCenterDisabled() ? 'control-center__list_disabled' : ''
          }`}
        >
          <div className="control-center__control">
            <div
              className="control-center__control-list-item"
              onClick={() => handleControlCollapse('settings')}
            >
              <p className="control-center__list-item-name">
                {t('control_center.settings.title')}
              </p>
              <Icon
                onClick={() => handleControlCollapse('settings')}
                name={`${
                  isControlCollapsed('settings')
                    ? 'downCircleArrow'
                    : 'topCircleArrow'
                }`}
                className="control-center__list-item-icon control-center__list-item-icon_arrow"
              />
            </div>
            <div
              className={`control-center__list-item-content ${
                isControlCollapsed('settings')
                  ? 'control-center__list-item-content_hide'
                  : 'control-center__list-item-content_show'
              }`}
            >
              <Settings
                settingsData={extraData.settings}
                setSettingsData={handleChangeSettingsData}
                errors={errors}
                setErrors={setErrors}
                controlCenterDisabled={isControlCenterDisabled()}
              />
            </div>
          </div>
          <div className="control-center__control">
            <div
              className="control-center__control-list-item"
              onClick={() => handleControlCollapse('delivery')}
            >
              <p className="control-center__list-item-name">
                {t('control_center.delivery.title')}
              </p>
              <Icon
                onClick={() => handleControlCollapse('delivery')}
                name={`${
                  isControlCollapsed('delivery')
                    ? 'downCircleArrow'
                    : 'topCircleArrow'
                }`}
                className="control-center__list-item-icon control-center__list-item-icon_arrow"
              />
            </div>
            <div
              className={`control-center__list-item-content ${
                isControlCollapsed('delivery')
                  ? 'control-center__list-item-content_hide'
                  : 'control-center__list-item-content_show'
              }`}
            >
              <RestaurantDelivery
                takeAwayDeliveryData={extraData.delivery}
                setTakeAwayDeliveryData={(newExtraData) => {
                  setExtraData((extraData) => ({
                    ...extraData,
                    delivery: newExtraData,
                  }));
                }}
                controlCenterDisabled={isControlCenterDisabled()}
              />
            </div>
          </div>
        </div>
        <Button className="settings__button" onClick={handleSaveClick}>
          {t('contacts.save')}
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  errors: state.restaurant.fieldErrors,
  restaurant: state.restaurant.restaurantData,
  restaurantLoading: state.restaurant.isLoading,
  isMobile: isMobile(state),
});

export default connect(mapStateToProps, {
  updateRestaurantApi,
  showConfirmPhoneModal,
  show,
})(ControlCenter);
