import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Header from '../components/Header';
import Button from '../components/common/Button';
import Input from '../components/common/Input';
import feedbackAPI from '../api/feedback';
import TextArea from '../components/common/TextArea';
import InputMask, { PHONE_MASK } from '../components/common/InputMask';

const SendFeedback = ({ restaurant }) => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [text, setText] = useState('');
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_KEY}`;
    document.head.appendChild(script);
    // eslint-disable-next-line consistent-return
    return function clearRecapcha() {
      const captchaElement = document.querySelector('.grecaptcha-badge');
      if (!captchaElement) {
        return captchaElement;
      }
      captchaElement.parentNode.removeChild(captchaElement);
    };
  }, []);

  const getCaptcha = () => {
    return new Promise((resolve, reject) => {
      window.grecaptcha.ready(() =>
        window.grecaptcha
          .execute(process.env.REACT_APP_RECAPTCHA_KEY, { action: 'login' })
          .then((captcha) => {
            resolve(captcha);
          })
          .catch((error) => reject(error))
      );
    });
  };

  const saveClickHandler = (e) => {
    e.preventDefault();
    setErrors({});

    getCaptcha().then((recaptcha) => {
      feedbackAPI
        .send({ name, phone, text, recaptcha, restaurant })
        .then(() => history.goBack())
        .catch((e) => {
          setErrors(e);
        });
    });
  };

  const changePhone = (event) => {
    setPhone(event.target.value.replace(/ /g, ''));
  };

  return (
    <div className="page feedback" data-aos="fade-up" data-aos-delay="400">
      <Header
        title={t('feedback.title')}
        rightIcon="close"
        onRightIconClick={() => history.goBack()}
      />
      <form className="feedback__body" onSubmit={saveClickHandler}>
        <Input
          required
          error={errors.name}
          value={name}
          onChange={(value) => setName(value)}
          placeholder={t('feedback.name_placeholder')}
        />

        <InputMask
          mask={PHONE_MASK}
          onChange={changePhone}
          value={phone}
          required
          type="tel"
          error={errors.phone}
          name="phone"
          placeholder={t('feedback.phone_placeholder')}
        />

        <TextArea
          name="text"
          value={text}
          placeholder={t('feedback.text_placeholder')}
          onChange={(value) => setText(value)}
          error={errors.text}
        />
        <p className="feedback__text" />
        <Button
          disabled={name.length === 0}
          className="feedback__save-btn"
          type="submit"
        >
          {t('feedback.send')}
        </Button>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  restaurant: state.restaurant?.restaurantData?.id,
});

export default connect(mapStateToProps, null)(SendFeedback);
