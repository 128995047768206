import { post } from '../utils/axios';

const serviceAPI = {
  requestCardBill: async () => {
    try {
      return await post(`/service/request_card_bill/`, null, null, true);
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },

  requestCashBill: async () => {
    try {
      return await post(`/service/request_cash_bill/`, null, null, true);
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },

  requestWaiter: async () => {
    try {
      return await post(`/service/request_waiter/`, null, null, true);
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },
};

export default serviceAPI;
