import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import autosize from 'autosize';
import { useTranslation } from 'react-i18next';
import Icon from './Icon';

const Input = ({
  className,
  rightIcon,
  rightIconClick,
  leftIcon,
  leftIconClick,
  rightSecondIcon,
  rightSecondIconClick,
  placeholder,
  required,
  unit,
  multiline,
  ...props
}) => {
  const inputRef = useRef(null);
  const spanWidthRef = useRef(null);
  const [type, setType] = useState(props.type);
  const [unitOffset, setUnitOffset] = useState(0);
  const [unitVisible, setUnitVisibility] = useState(!!unit);
  const { t } = useTranslation();

  const updateUnitWidth = () => {
    if (spanWidthRef.current) {
      spanWidthRef.current.innerText = inputRef.current.value;
      if (inputRef.current.offsetWidth < spanWidthRef.current.offsetWidth * 2) {
        setUnitVisibility(false);
      } else {
        setUnitVisibility(true);
      }
      setUnitOffset(spanWidthRef.current.offsetWidth);
    }
  };

  useEffect(() => {
    updateUnitWidth();
    if (multiline) {
      autosize(inputRef.current);
    }
  });

  const onChange = ({ target }) => {
    props.onChange(target.value);
    updateUnitWidth();
  };

  const isEmptyValue = () => {
    return !(inputRef.current && inputRef.current.value.trim());
  };

  return (
    <div
      className={`input ${props.error ? 'input__error' : ''} ${
        className || ''
      }`}
    >
      <div className="input__wrapper">
        {unitVisible && (props.value || props.value === 0) && (
          <>
            <span ref={spanWidthRef} className="input__unit-width" />
            <div
              style={{ left: `${unitOffset + 5}px` }}
              className="input__unit-text"
            >
              {unit}
            </div>
          </>
        )}
        {leftIcon && (
          <Icon
            className="input__icon"
            style={{
              pointerEvents:
                rightIcon === 'apply' && isEmptyValue() ? 'none' : 'unset',
            }}
            name={leftIcon}
            onClick={leftIconClick}
          />
        )}
        <div className="input__field">
          {multiline ? (
            <textarea
              rows={1}
              ref={inputRef}
              className="input__component input__component-text-area"
              {...props}
              required
              id={props.name}
              onChange={onChange}
              placeholder={placeholder}
            />
          ) : (
            <input
              ref={inputRef}
              className={`input__component  ${className || ''} ${
                leftIcon && 'input__component_moved-right'
              }`}
              autoComplete="off"
              {...props}
              onChange={onChange}
              type={type}
              placeholder={placeholder}
            />
          )}
          {placeholder && (
            <label
              htmlFor={props.name}
              className={`input__label ${
                leftIcon && 'input__label_moved-right'
              }`}
            >
              {placeholder}
            </label>
          )}
        </div>
        <div className="input__icons">
          {required && !props.value && props.value !== 0 && (
            <span className="input__required-text">{t('required_input')}</span>
          )}
          {props.type === 'password' && (
            <Icon
              className="input__icon"
              name={type === 'text' ? 'hidePassword' : 'showPassword'}
              onClick={() => setType(type === 'text' ? 'password' : 'text')}
            />
          )}
          {rightIcon && (
            <Icon
              className="input__icon"
              style={{
                pointerEvents:
                  rightIcon === 'apply' && isEmptyValue() ? 'none' : 'unset',
              }}
              name={rightIcon}
              onClick={rightIconClick}
            />
          )}
          {rightSecondIcon && (
            <Icon
              className="input__icon"
              name={rightSecondIcon}
              onClick={rightSecondIconClick}
            />
          )}
        </div>
      </div>

      {props.error && <p className="input__error-message">{props.error}</p>}
    </div>
  );
};

export default Input;

Input.PropsTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.oneOf([PropTypes.string, PropTypes.array]),
  type: PropTypes.string,
  unit: PropTypes.string,
  className: PropTypes.string,
  rightIcon: PropTypes.string,
  rightIconClick: PropTypes.func,
  rightSecondIcon: PropTypes.string,
  rightSecondIconClick: PropTypes.func,
  multiline: PropTypes.bool,
};

Input.defaultProps = {
  onChange: () => {},
  value: '',
  placeholder: '',
  error: null,
  type: 'text',
  unit: null,
  name: 'input',
  className: null,
  required: false,
  rightIcon: null,
  rightSecondIcon: null,
  multiline: false,
  rightIconClick: () => {},
  rightSecondIconClick: () => {},
};
