import React from 'react';
import PropTypes from 'prop-types';
import Icon from './common/Icon';

const UserBurgerMenuHeader = ({ title, className, onRightIconClick }) => {
  return (
    <header
      data-aos-id="restaurant-header"
      data-aos="fade-up"
      className={`restaurant-header ${className || ''}`}
    >
      <div className="restaurant-header__content">
        <div className="restaurant-header__right-icons">
          <Icon onClick={onRightIconClick} name="close" className="ml-2" />
        </div>

        <div className="restaurant-header__title">
          <h2 className="restaurant__title">{title}</h2>
        </div>
      </div>
    </header>
  );
};

export default UserBurgerMenuHeader;

UserBurgerMenuHeader.defaultProps = {
  className: null,
  title: '',
  onRightIconClick: () => {},
};

UserBurgerMenuHeader.PropsTypes = {
  className: null,
  title: PropTypes.string,
  onRightIconClick: PropTypes.func,
};
