import React from 'react';

const Checkbox = ({ checked, onChange, className }) => {
  return (
    <input
      type="checkbox"
      className={`checkbox ${className}`}
      checked={checked}
      onChange={onChange}
    />
  );
};

export default Checkbox;
