import React from 'react';
import { useHistory, Redirect, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { confirmEmail } from '../redux/reducers/auth/actions';

const EmailConfirm = ({ location, confirmEmail, error }) => {
  const { code } = useParams();
  const history = useHistory();
  const { t } = useTranslation();

  if (code && !error) {
    confirmEmail(code, history);
  }
  if (!location.state && !error && !code) {
    return <Redirect to="/" />;
  }

  return (
    <div className="page email-confirm">
      <Header logo />
      <div
        data-aos="fade-up"
        data-aos-delay="300"
        className="email-confirm__content"
      >
        <div className="email-confirm__icon" />
        <h1>{t('email_confirm.text')}</h1>
        <div className="email-confirm__message">{`${t(
          'email_confirm.message'
        )} ${
          location.state?.message?.split(' ')[
            location.state?.message?.split(' ').length - 1
          ]
        }`}</div>
      </div>
      <Footer noLogo />
    </div>
  );
};

const mapStateToProps = (state) => ({
  error: state.error.id === 'CONFIRM_EMAIL_FAIL' ? state.error.msg : null,
});

export default connect(mapStateToProps, { confirmEmail })(EmailConfirm);
