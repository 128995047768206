import React from 'react';
import useCollapse from '../hooks/useCollapsed';
import { srcSet } from '../utils/files';

const RestaurantMenuProduct = ({
  className,
  product,
  children,
  isMobile,
  handleProductEditClick,
  isUser,
}) => {
  const { isOpened, toggleCollapse } = useCollapse(false);

  const onBlockClick = () => {
    if (handleProductEditClick) {
      handleProductEditClick();
    } else toggleCollapse();
  };

  const getContentDesktopContent = () => {
    return (
      <>
        <div
          className={`restaurant-menu-product__name ${
            !isOpened ? 'restaurant-menu-product__name_reduction' : ''
          }`}
        >
          {product.name}
        </div>
        {product.description && (
          <p
            className={`restaurant-menu-product__description ${
              !isOpened ? 'restaurant-menu-product__description_reduction' : ''
            }`}
          >
            {product.description}
          </p>
        )}
        {isUser
          ? product.weight_unit && (
              <p className="restaurant-menu-product__description">
                {`${product.weight_unit}`}
              </p>
            )
          : product.weight && (
              <p className="restaurant-menu-product__description">
                {`${product.weight} ${product.unit}`}
              </p>
            )}
        <div className="restaurant-menu-product__footer">
          <p className="restaurant-menu-product__price">
            {product.price ? `${product.price} ₴` : ''}
          </p>
          {children}
        </div>
      </>
    );
  };

  const getMobileContent = (id) => {
    const element = document.getElementById(id);
    const isWeightShown = () => {
      if (element) {
        const lineHeight = Number.parseInt(
          getComputedStyle(element).lineHeight.split('px')[0],
          10
        );
        const height = element.offsetHeight;
        return height <= lineHeight;
      }
      return false;
    };
    return (
      <>
        <div
          id={id}
          className={`restaurant-menu-product__name ${
            !isOpened
              ? `restaurant-menu-product__name_text-concat ${
                  !product.description
                    ? 'restaurant-menu-product__name_text-concat-line3'
                    : 'restaurant-menu-product__name_text-concat-line2'
                }`
              : ''
          }`}
        >
          {product.name}
        </div>
        {product.description && (
          <p
            className={`restaurant-menu-product__description ${
              !isOpened ? 'restaurant-menu-product__description_reduction' : ''
            }`}
          >
            {product.description}
          </p>
        )}
        {isUser
          ? product.weight_unit &&
            (!product.description || isOpened || isWeightShown()) && (
              <p className="restaurant-menu-product__description">
                {`${product.weight_unit}`}
              </p>
            )
          : product.weight &&
            (!product.description || isOpened || isWeightShown()) && (
              <p className="restaurant-menu-product__description">
                {`${product.weight} ${product.unit}`}
              </p>
            )}
        <div className="restaurant-menu-product__footer">
          <p className="restaurant-menu-product__price">
            {product.price ? `${product.price} ₴` : ''}
          </p>
          {children}
        </div>
      </>
    );
  };

  return (
    <div
      onClick={onBlockClick}
      className={`restaurant-menu-product ${className} ${
        product.image && 'restaurant-menu-product_with-image'
      }`}
    >
      <div className="restaurant-menu-product__wrapper">
        {product.image && (
          <div className="restaurant-menu-product__image-wrapper">
            <img
              className="restaurant-menu-product__image"
              srcSet={srcSet(product.image)}
              alt={product.name}
            />
          </div>
        )}
        <div className="restaurant-menu-product__content">
          {isMobile ? getMobileContent(product.id) : getContentDesktopContent()}
        </div>
      </div>
    </div>
  );
};

export default RestaurantMenuProduct;

RestaurantMenuProduct.defaultProps = {
  className: '',
  product: {
    name: '',
    description: '',
    price: '',
  },
};
