import { post, put } from '../utils/axios';

const authAPI = {
  /**
   * Retrieve auth token by pair of email & password or phone & password.
   * If authentication was successful, but recaptcha score is below required level,
   * this endpoint will return response with the status code 206 (PARTIAL CONTENT) which means that additional
   * verification is required. Site will sent random generated code to user's phone and return UUID in response.
   * To complete authentication, verification_code must be sent to login-auth-code endpoint.
   * Also, recaptcha parameter is optional and, if not provided, will always trigger 2FA process.
   * @param data
   * @return {Promise<any>}
   */
  login: (data) => {
    return post(`/accounts/auth/login/`, data);
  },

  /**
   * Authenticate or register with a Google OAuth2.
   * @param data
   * @return {Promise<any>}
   */
  googleLogin: (data) => {
    return post(`/accounts/auth/google_oauth2/`, data);
  },

  /**
   * Authenticate or register with a Facebook account.
   * @param data
   * @return {Promise<any>}
   */
  facebookLogin: (data) => {
    return post(`/accounts/auth/facebook/`, data);
  },

  /**
   * Registration is a two step process:
   *   A registration request is sent. Backend creates user account, sends email confirmation url.
   *   The phone number is confirmed via the code that was sent to the client’s phone, and the id that was received
   *   by the frontend in the response on previous step. If the code was entered correctly, the backend will return
   *   the access token (as in the usual login authentication) to access the profile.
   * @param email
   * @param password
   * @return {Promise<any>}
   */
  register: ({ email, password, password2 }) => {
    return post(`/accounts/registration/register/`, {
      email,
      password1: password,
      password2,
    });
  },

  /**
   * Confirm email address after registration.
   * @param code
   * @return {Promise<any>}
   */
  confirmEmail: (code) => {
    return post(`/accounts/registration/confirm-email/`, {
      verification_code: code,
    });
  },

  /**
   * Authenticate by pair of ID (from login endpoint) and code from SMS.
   * @param code
   * @return {Promise<any>}
   */
  confirmEmail2FA: (code) => {
    return post(`/accounts/auth/login-auth-code/`, {
      verification_code: code,
    });
  },

  /**
   * Reset user password.
   * @param email
   * @return {Promise<any>}
   */
  passwordReset: (email) => {
    return post(`/accounts/reset-password/`, {
      email,
    });
  },

  /**
   * Complete reset user password.
   * @param code
   * @param password1
   * @param password2
   * @return {Promise<any>}
   */
  passwordResetComplete: (code, password1, password2) => {
    return post(`/accounts/reset-password-complete/`, {
      verification_code: code,
      password1,
      password2,
    });
  },
  /**
   * Change user password.
   * @param oldPassword
   * @param password1
   * @param password2
   * @return {Promise<any>}
   */
  passwordChange: (oldPassword, password1, password2) => {
    return post(
      `/accounts/change-password/`,
      {
        old_password: oldPassword,
        new_password1: password1,
        new_password2: password2,
      },
      null,
      true
    );
  },

  changePhone: (phone) => {
    return put(
      `/accounts/change-phone/`,
      {
        phone,
      },
      null,
      true
    );
  },

  confirmPhone: (id, code) => {
    return put(
      `/accounts/confirm-phone/`,
      {
        id,
        sms_code: code,
      },
      null,
      true
    );
  },
};

export default authAPI;
