import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Icon from './common/Icon';
import { isMobile } from '../redux/reducers/ui/selectors';

const Footer = ({ className, isMobile, noLogo }) => {
  const [logo, setLogo] = useState('logo');

  useEffect(() => (isMobile ? setLogo('logoMobile') : setLogo('logo')), [
    isMobile,
  ]);

  return (
    <div
      className={`app-footer ${
        className || ''
      } animate__animated  animate__fadeInUp animate__delay-1s`}
    >
      <div className="app-footer__content">
        {!noLogo && (
          <a href="https://menumenu.us">
            <Icon withHover={false} name={logo} />
          </a>
        )}
        <p>
          Product by <a href="https://fitel.io">fitel.io</a>{' '}
        </p>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isMobile: isMobile(state),
});

export default connect(mapStateToProps, null)(Footer);

Footer.defaultProps = {
  className: '',
};
