// показывает контролы для добавления в корзину толькое если
// 1 выбрано один из вариантов is_delivery или is_takeaway
// 2 если он зачекинился в ресторане и создал сессию  ив ресторане есть признак is_dine_in
export const isShowCheckoutControls = (state) => {
  const { restaurant } = state;
  const { restaurantData, isCheckInByTable } = restaurant;
  const isDineInAvailable = restaurantData.is_dine_in && isCheckInByTable;

  return (
    isDineInAvailable ||
    restaurantData.is_delivery ||
    restaurantData.is_takeaway
  );
};

export default isShowCheckoutControls;
