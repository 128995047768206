import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Input from '../components/common/Input';
import Button from '../components/common/Button';
import { isMobile } from '../redux/reducers/ui/selectors';
import { passwordChange } from '../redux/reducers/auth/actions';

const PasswordChange = ({ passwordChange, isMobile }) => {
  const [oldPassword, setOldPassword] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const { t } = useTranslation();

  const submitPasswordChange = () => {
    setErrors({});
    passwordChange(oldPassword, password1, password2)
      .then(() => {
        history.push('/');
      })
      .catch((error) => {
        setErrors(error);
        console.error(error);
      });
  };

  return (
    <div className="page password-reset">
      <Header
        logo
        rightIcon="close"
        onRightIconClick={() => history.goBack()}
      />
      <div
        data-aos="fade-up"
        data-aos-delay="300"
        className="password-reset__content"
      >
        <h1 className="password-reset__message">
          {t('password_reset.change_title')}
        </h1>
        <Input
          className="password-reset__input"
          type="password"
          name="oldPassword"
          placeholder={t('password_reset.old_password')}
          value={oldPassword}
          onChange={(value) => setOldPassword(value)}
          error={errors.old_password}
        />
        <Input
          className="password-reset__input"
          type="password"
          name="password1"
          placeholder={t('password_reset.password1')}
          value={password1}
          onChange={(value) => setPassword1(value)}
          error={errors.new_password1}
        />
        <Input
          className="password-reset__input"
          type="password"
          name="password2"
          placeholder={t('password_reset.password2')}
          value={password2}
          onChange={(value) => setPassword2(value)}
          error={errors.new_password2}
        />
        <Button
          disabled={password1 === '' || password1 !== password2}
          onClick={submitPasswordChange}
        >
          {t('password_reset.change')}
        </Button>
      </div>
      {isMobile && <Footer />}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isMobile: isMobile(state),
});

export default connect(mapStateToProps, { passwordChange })(PasswordChange);
