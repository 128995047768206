import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isMobile } from '../redux/reducers/ui/selectors';
import Icon from '../components/common/Icon';
import SignIn from './SignIn';
import Tabs from '../components/common/Tabs';
import SignUp from './SignUp';

const EnterWithEmail = ({ isMobile }) => {
  const history = useHistory();
  const [logo, setLogo] = useState('logo');
  const { t } = useTranslation();

  useEffect(() => (isMobile ? setLogo('logoMobile') : setLogo('logo')), [
    isMobile,
  ]);

  return (
    <div data-aos="fade-up" className="page enter-with-email">
      <Icon
        className="app-header__left-icon"
        name="back"
        onClick={() => history.push('/')}
      />
      <Icon className="enter-with-email__logo" name={logo} withHover={false} />

      <div className="enter-with-email__content" data-aos="fade-up">
        <Tabs tabs={[t('login.sign_in_tabname'), t('login.sign_up_tabname')]}>
          <SignIn />
          <SignUp />
        </Tabs>
      </div>
      <footer className="start__footer">
        <p>
          {t('login.privacy_rules_hint')}{' '}
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://api.fitel.io/media/fitelio/uploads/3289f89b-1ec1-4d41-b3a8-8b7d8d2ea672/%D0%9F%D0%BE%D0%BB%D1%96%D1%82%D0%B8%D0%BA%D0%B0_%D0%BF%D1%80%D0%B8%D0%B2%D0%B0%D1%82%D0%BD%D0%BE%D1%81%D1%82%D1%96.PROCESSING_POLICY.pdf"
          >
            {t('login.usage_rules')}
          </a>{' '}
          {t('login.and')}{' '}
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://api.fitel.io/media/fitelio/uploads/3289f89b-1ec1-4d41-b3a8-8b7d8d2ea672/%D0%9F%D0%BE%D0%BB%D1%96%D1%82%D0%B8%D0%BA%D0%B0_%D0%BF%D1%80%D0%B8%D0%B2%D0%B0%D1%82%D0%BD%D0%BE%D1%81%D1%82%D1%96.PROCESSING_POLICY.pdf"
          >
            {t('login.privacy_policy')}
          </a>
        </p>
      </footer>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isMobile: isMobile(state),
});
export default connect(mapStateToProps, null)(EnterWithEmail);
