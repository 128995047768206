import React from 'react';

const social = {
  fb: (
    <svg
      width="65"
      height="65"
      viewBox="0 0 65 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle r="32.5" transform="matrix(1 0 0 -1 32.5 32.5)" fill="#20CA68" />
      <path
        d="M38.2552 29.3749H34.5833V27.2916C34.5833 26.2166 34.6708 25.5395 36.2114 25.5395H38.1573V22.227C37.2104 22.1291 36.2583 22.0812 35.3052 22.0833C32.4791 22.0833 30.4166 23.8093 30.4166 26.978V29.3749H27.2916V33.5416L30.4166 33.5406V42.9166H34.5833V33.5385L37.777 33.5374L38.2552 29.3749Z"
        fill="white"
      />
    </svg>
  ),

  apple: (
    <svg
      width="65"
      height="65"
      viewBox="0 0 65 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle r="32.5" transform="matrix(1 0 0 -1 32.5 32.5)" fill="#20CA68 " />
      <path
        d="M36.7969 21.0417C35.5958 21.1115 34.217 21.7806 33.3972 22.6327C32.6566 23.4077 32.0722 24.5587 32.3108 25.6722C33.616 25.7066 34.9261 25.0202 35.7104 24.1524C36.4427 23.341 36.9979 22.2084 36.7969 21.0417ZM36.8681 25.6702C34.9837 25.6702 34.1958 26.8278 32.8906 26.8278C31.5479 26.8278 30.3205 25.7434 28.6955 25.7434C26.4851 25.7445 23.125 27.792 23.125 32.616C23.125 37.0045 27.1017 41.875 29.3465 41.875C30.7101 41.8886 31.0406 41.0177 32.8906 41.0083C34.7427 40.9948 35.1424 41.8865 36.508 41.875C38.0455 41.8636 39.2462 40.174 40.1233 38.8355C40.7525 37.8771 41.0111 37.3879 41.4986 36.3045C37.8809 35.3879 37.1611 29.5538 41.4986 28.3476C40.6799 26.9507 38.29 25.6702 36.8681 25.6702Z"
        fill="white"
      />
    </svg>
  ),

  google: (
    <svg
      width="65"
      height="65"
      viewBox="0 0 65 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle r="32.5" transform="matrix(1 0 0 -1 32.5 32.5)" fill="#20CA68 " />
      <path
        d="M32.5033 22.5C26.9791 22.5 22.5 26.9775 22.5 32.5C22.5 38.0225 26.9791 42.5 32.5033 42.5C40.8449 42.5 42.7242 34.7558 41.9417 30.8333H40.8333H38.9437H32.5V34.1667H38.9486C38.2073 37.0402 35.605 39.1667 32.5 39.1667C28.8183 39.1667 25.8333 36.1817 25.8333 32.5C25.8333 28.8183 28.8183 25.8333 32.5 25.8333C34.1742 25.8333 35.6993 26.4548 36.8701 27.474L39.2383 25.1074C37.4599 23.4874 35.0974 22.5 32.5033 22.5Z"
        fill="white"
      />
    </svg>
  ),
};

export default social;
