import React, { useEffect, useState } from 'react';

import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Input from '../../components/common/Input';
import Button from '../../components/common/Button';
import { login } from '../../redux/reducers/auth/actions';

const SignIn = ({ login }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_KEY}`;
    document.head.appendChild(script);
    // eslint-disable-next-line consistent-return
    return function clearRecapcha() {
      const captchaElement = document.querySelector('.grecaptcha-badge');
      if (!captchaElement) {
        return captchaElement;
      }
      captchaElement.parentNode.removeChild(captchaElement);
    };
  }, []);

  const getCaptcha = () => {
    return new Promise((resolve, reject) => {
      window.grecaptcha.ready(() =>
        window.grecaptcha
          .execute(process.env.REACT_APP_RECAPTCHA_KEY, { action: 'login' })
          .then((captcha) => {
            resolve(captcha);
          })
          .catch((error) => reject(error))
      );
    });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setErrors({});
    getCaptcha().then((recaptcha) => {
      login({ login: email, password, recaptcha }, history)
        .then((response) => {
          window.dataLayer.push({
            event: 'sign_in',
            eventCategory: 'success',
            eventContent: 'email',
          });
          if (response.status === 206) {
            history.push({
              pathname: '/email-confirm',
              state: { message: response.data.message },
            });
          } else {
            history.push('/');
          }
        })
        .catch((error) => {
          setErrors(error);
          console.error(error);
        });
    });
  };
  return (
    <form
      data-aos="fade-up"
      data-aos-delay="0"
      className="sign-in__form"
      onSubmit={submitHandler}
    >
      <Input
        className="sign-in__form-elem"
        type="email"
        name="email"
        placeholder="Email"
        value={email}
        onChange={(value) => setEmail(value)}
        error={errors.login}
      />
      <Input
        className="sign-in__form-elem"
        type="password"
        name="password"
        placeholder={t('login.password_placeholder')}
        value={password}
        onChange={(value) => setPassword(value)}
        error={errors.password || errors.recaptcha}
      />
      <Link to="/reset-password" className="sign-in__form-forgot-pass">
        {t('login.reset_password')}
      </Link>
      <Button
        disabled={email.length === 0}
        className="sign-in__button"
        type="submit"
      >
        {t('login.sign_in')}
      </Button>
    </form>
  );
};

export default connect(null, { login })(SignIn);
