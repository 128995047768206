import React from 'react';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';
import AOS from 'aos';
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from 'react-hot-loader/root';
import './scss/index.scss';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import configureStore from './redux/store';
import { checkAuth } from './redux/reducers/auth/actions';
import { changeAppWidth } from './redux/reducers/ui/actions';
import Root from './components/Root';
import { fetchCart } from './redux/reducers/cart/actions';

const createHistory =
  process.env.NODE_ENV === 'development'
    ? createBrowserHistory
    : createBrowserHistory;

const history =
  module.hot && module.hot.data && module.hot.data.history
    ? module.hot.data.history
    : createHistory();

const store = configureStore(history);

window.addEventListener('resize', () => {
  store.dispatch(changeAppWidth(window.innerWidth));
});

function App() {
  if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      environment: process.env.NODE_ENV,
      maxBreadcrumbs: 2,
    });
  }

  AOS.init({
    duration: 800,
    once: true,
    offset: -120,
  });

  store.dispatch(checkAuth());
  store.dispatch(fetchCart());

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Root />
      </ConnectedRouter>
    </Provider>
  );
}

if (module.hot) {
  module.hot.dispose((data) => {
    // eslint-disable-next-line no-param-reassign
    data.store = store;
    // eslint-disable-next-line no-param-reassign
    data.history = history;
  });
}

export default process.env.NODE_ENV === 'development' ? hot(App) : App;
