import React, { useEffect, useState } from 'react';
import { Redirect, Link, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Header from '../../components/Header';
import restaurantsAPI from '../../api/restaurants';
import EmptyList from './EmptyList';
import DropdownMenu from '../../components/common/DropdownMenu';
import Icon from '../../components/common/Icon';
import Footer from '../../components/Footer';
import Loader from '../../components/Loader';
import { showConfirmPhoneModal } from '../../redux/reducers/auth/actions';

export const getRestaurantSubscriptionState = (restaurant) => {
  if (
    restaurant.subscription.is_expired ||
    (restaurant.subscription.is_expired && restaurant.subscription.is_trial)
  )
    return 'expired';
  if (restaurant.subscription.is_trial) return 'trial';
  return 'active';
};

export const getRestaurantSubscriptionText = (restaurant, t) => {
  if (restaurant.subscription.is_expired && !restaurant.subscription.is_trial)
    return `${t('restaurants_list.subscription_expired')} ${
      restaurant.subscription.end_date
    }`;
  if (restaurant.subscription.is_expired && restaurant.subscription.is_trial)
    return t('restaurants_list.trial_subscription_expired');
  if (restaurant.subscription.is_trial)
    return `${t('restaurants_list.trial_subscription')} ${
      restaurant.subscription.end_date
    }`;
  return `${t('restaurants_list.subscription_active')} ${
    restaurant.subscription.end_date
  }`;
};

const RestaurantsList = ({ location, showConfirmPhoneModal }) => {
  const history = useHistory();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  const animationListener = ({ detail }) => {
    detail.classList.add('no-translate');
  };

  const title = document.getElementsByTagName('title')[0];
  title.innerHTML = 'Menu Menu';

  const description = document.getElementsByName('description')[0];
  description.content = 'Web site for creating restaurants menu';

  useEffect(() => {
    document.addEventListener('aos:in:restaurant-item', animationListener);
    (async () => {
      try {
        setLoading(true);
        const { data } = await restaurantsAPI.getList();
        setList(data);
      } catch (e) {
        console.error(e);
        if (e.code === 403) {
          showConfirmPhoneModal();
        }
      }
      setLoading(false);
    })();
    return () => {
      document.removeEventListener('aos:in:restaurant-item', animationListener);
    };
    // eslint-disable-next-line
  }, [showConfirmPhoneModal]);

  const onItemClick = (slug) => {
    history.push(`/restaurant/${slug}`);
  };

  const onDeleteClick = async (restaurant) => {
    Swal.fire({
      heightAuto: false,
      title: `${t('restaurants_list.restaurant_remove_message')} ${
        restaurant.name
      }`,
      showCancelButton: true,
      confirmButtonText: t('swal.yes'),
      cancelButtonText: t('swal.no'),
      showClass: {
        popup: 'animate__animated modalFadeInUp',
      },
      hideClass: {
        popup: 'animate__animated modalFadeOutDown',
      },
      preConfirm: () =>
        restaurantsAPI.remove(restaurant.id).catch((error) => {
          console.error(error);
          if (error.code === 403) {
            showConfirmPhoneModal();
          }
        }),
    }).then(async () => {
      const { data } = await restaurantsAPI.getList();
      setList(data);
    });
  };

  if (loading) {
    return <Loader />;
  }

  if (list.length === 0) {
    if (location.state?.showEmptyList) {
      return <EmptyList />;
    }
    return <Redirect to="/restaurant/create" />;
  }

  return (
    <div className="restaurants-list page">
      <Header title={t('restaurants_list.title')} showSideMenu />

      <div
        data-aos="fade-left"
        data-aos-delay={300}
        className="restaurants-list__content"
      >
        <div className="restaurants-list__list">
          {/* eslint-disable-next-line no-unused-vars */}
          {list.map((restaurant, index) => (
            <div
              // data-aos="fade-left"
              // data-aos-delay={300 + 100 * index}
              // data-aos-offset="-1200"
              onClick={() => onItemClick(restaurant.slug)}
              key={restaurant.id}
              data-aos-id="restaurant-item"
              className="restaurants-list__item restaurants-list-item"
            >
              <div>
                <div className="restaurants-list-item__name">
                  {restaurant.name}
                </div>
                <div
                  className={`restaurants-list-item__subscription-info restaurants-list-item__subscription-info_${getRestaurantSubscriptionState(
                    restaurant
                  )}`}
                >
                  {getRestaurantSubscriptionText(restaurant, t)}
                </div>
              </div>
              <DropdownMenu
                className="restaurants-list-item__menu"
                options={[
                  {
                    icon: 'pen',
                    label: t('restaurants_list.restaurant_edit'),
                    onClick: () => onItemClick(restaurant.id),
                  },
                  {
                    icon: 'delete',
                    label: t('restaurants_list.restaurant_remove'),
                    onClick: () => onDeleteClick(restaurant),
                  },
                ]}
              />
            </div>
          ))}
        </div>
        <Link className="restaurant__link" to="/restaurant/create">
          <Icon name="plus" />
          {t('restaurants_list.restaurant_add')}
        </Link>
      </div>
      <Footer />
    </div>
  );
};

export default connect(null, { showConfirmPhoneModal })(RestaurantsList);
