import React from 'react';

import CartProductOptionsModal, {
  MODAL_NAME as CART_PRODUCT_OPTIONS_MODAL,
} from './CartProductOptions';
import RestaurantPropertiesModal, {
  MODAL_NAME as RESTAURANT_PROPERTIES_MODAL,
} from './RestaurantPropertiesModal';
import RestaurantLanguagesModal, {
  MODAL_NAME as RESTAURANT_LANGUAGES_MODAL,
} from './RestaurantLanguagesModal';
import ConfirmPhone, {
  MODAL_NAME as CONFIRM_PHONE_MODAL,
} from './ConfirmPhone';
import UserBurgerMenu, {
  MODAL_NAME as USER_BURGER_MENU,
} from './UserBurgerMenu';

export default [
  <CartProductOptionsModal key={CART_PRODUCT_OPTIONS_MODAL} />,
  <RestaurantPropertiesModal key={RESTAURANT_PROPERTIES_MODAL} />,
  <ConfirmPhone key={CONFIRM_PHONE_MODAL} />,
  <UserBurgerMenu key={USER_BURGER_MENU} />,
  <RestaurantLanguagesModal key={RESTAURANT_LANGUAGES_MODAL} />,
];
