import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from './common/Button';
import Icon from './common/Icon';

const RestaurantPreviewAndQRBlock = ({ restaurant, onPreviewClick }) => {
  const { slug } = useParams();
  const history = useHistory();
  const { t } = useTranslation();

  const handleGenerateQRCodeClick = () => {
    history.push(`/restaurant/${restaurant.slug}/qr-code`);
  };
  return (
    <div className="restaurant__preview-qr-block restaurant-preview-qr-block">
      <Button onClick={onPreviewClick}>
        {t('restaurant_page.preview_qr_block.preview_button')}
      </Button>
      <p className="restaurant-preview-qr-block__text">
        {t('restaurant_page.preview_qr_block.preview_text')}
      </p>

      <p className="restaurant__accent-text">
        {t('restaurant_page.preview_qr_block.restaurant_link_title')}
      </p>

      <div className="restaurant__restaurant-link-block">
        <Icon name="link" withHover={false} />
        <p>{`${window.location.origin}/restaurant/${slug}`}</p>
        <CopyToClipboard text={`${window.location.origin}/restaurant/${slug}`}>
          <Icon name="copy" withHover />
        </CopyToClipboard>
      </div>

      <Button onClick={handleGenerateQRCodeClick}>
        {t('restaurant_page.preview_qr_block.qr_button')}
      </Button>
      <p className="restaurant-preview-qr-block__text">
        {t('restaurant_page.preview_qr_block.qr_text')}
      </p>
    </div>
  );
};

export default RestaurantPreviewAndQRBlock;
