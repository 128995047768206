import { withRouter, Route, Switch, Redirect } from 'react-router-dom';
import React, { useEffect } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import PrivateRoute from './PrivateRoute';
import routing from '../config/routing';

const getRoutes = () => {
  return routing.map(({ name, path, component, isPrivate, exact }) => {
    const RootElement = isPrivate.private ? PrivateRoute : Route;
    return React.createElement(RootElement, {
      key: name,
      path,
      component,
      exact,
    });
  });
};

const Router = ({ location }) => {
  const animationListener = ({ detail }) => {
    detail.classList.add('no-translate');
  };

  useEffect(() => {
    document.addEventListener('aos:in:app-header', animationListener);
    return () => {
      document.removeEventListener('aos:in:app-header', animationListener);
    };
  }, []);

  const scrollTop = () => {
    if (document.querySelector('.app-content')) {
      document.querySelector('.app-content').scrollTop = 0;
    }
  };

  return (
    <TransitionGroup component={null}>
      <CSSTransition
        key={location.key}
        classNames="fade"
        timeout={{ enter: 600, exit: 600 }}
        onExited={scrollTop}
      >
        <section className="route-section">
          <div className="route-section__content">
            <Switch location={location}>
              {/* <Redirect from="/:url*(?+)" to={location.pathname} /> */}
              {getRoutes()}
              {/* TODO run throw all routes and map to pages in config */}
              {/* <Route path="/enter-with-email" component={EnterWithEmail} /> */}
              {/* <Route path="/email-confirm/:code" component={EmailConfirm2FA} /> */}
              {/* <Route path="/email-confirm" component={EmailConfirm2FA} /> */}
              {/* <Route */}
              {/*  path="/verification-email/confirm/:code" */}
              {/*  component={EmailConfirm} */}
              {/* /> */}
              {/* <Route */}
              {/*  path="/verification-email/confirm" */}
              {/*  component={EmailConfirm} */}
              {/* /> */}
              {/* <Route path="/reset-password/:code" component={PasswordReset} /> */}
              {/* <Route path="/reset-password" component={PasswordReset} /> */}
              {/* <PrivateRoute */}
              {/* path="/restaurant/create" */}
              {/* component={RestaurantModify} */}
              {/* /> */}
              {/* <PrivateRoute */}
              {/* path="/password-change" */}
              {/* component={PasswordChange} */}
              {/* /> */}
              {/* <PrivateRoute */}
              {/*  path="/restaurant/:id/product/create" */}
              {/*  component={ProductModify} */}
              {/* /> */}
              {/* <PrivateRoute */}
              {/*  path="/restaurant/:id/contacts" */}
              {/*  component={RestaurantContactsModify} */}
              {/* /> */}
              {/* <PrivateRoute */}
              {/*  path="/restaurant/:id/option/create" */}
              {/*  component={OptionModify} */}
              {/* /> */}
              {/* <PrivateRoute */}
              {/*  path="/restaurant/:id/option/edit" */}
              {/*  component={OptionModify} */}
              {/* /> */}
              {/* <PrivateRoute */}
              {/*  path="/restaurant/:id/product/:productId/additions" */}
              {/*  component={ProductOptions} */}
              {/* /> */}
              {/* <PrivateRoute */}
              {/*  path="/restaurant/:id/product/:productId" */}
              {/*  component={ProductModify} */}
              {/* /> */}
              {/* <PrivateRoute */}
              {/*  path="/restaurant/:id/worker/create" */}
              {/*  component={StaffModify} */}
              {/* /> */}
              {/* <PrivateRoute */}
              {/*  path="/restaurant/:id/worker/:workerId" */}
              {/*  component={StaffModify} */}
              {/* /> */}
              {/* <PrivateRoute exact path="/qr-code/:id/" component={QRCode} /> */}
              {/* <PrivateRoute */}
              {/*  path="/restaurant/:id/edit" */}
              {/*  component={RestaurantModify} */}
              {/* /> */}
              {/* <Route path="/restaurant/:id/checkout" component={Checkout} /> */}
              {/* <Route path="/feedback" component={SendFeedback} /> */}
              {/* <PrivateRoute */}
              {/*  exact */}
              {/*  path="/restaurant/:slug/preview" */}
              {/*  component={Restaurant} */}
              {/* /> */}
              {/* <Route path="/restaurant/:slug" component={Restaurant} /> */}
              {/* <PrivateRoute */}
              {/*  exact */}
              {/*  path={`/restaurants`} */}
              {/*  component={RestaurantsList} */}
              {/* /> */}
              {/* <PrivateRoute */}
              {/*  exact */}
              {/*  path="/profile/info" */}
              {/*  component={ProfileInfo} */}
              {/* /> */}
              {/* <Route exact path="/scan-again" component={ScanAgain} /> */}
              {/* <Route exact path="/order-success" component={ThankYouPage} /> */}
              {/* <Route path="/page-not-found" component={PageNotFound} /> */}
              {/* <Route exact path="/" component={Start} /> */}
              <Redirect to="/page-not-found" />
            </Switch>
          </div>
        </section>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default withRouter(Router);
