import React, {
  forwardRef,
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
} from 'react';
import Icon from './Icon';
import FadeBackground from '../FadeBackground';

const DropdownMenu = ({ className, options, icon, value }, ref) => {
  const [show, toggleMenu] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({});
  const [menuDirectionalTop, setMenuDirectionalTop] = useState(false);
  const [menuDirectionalLeft, setMenuDirectionalLeft] = useState(false);
  const Ref = useRef();
  // TODO fix position on resize event

  const handleClick = () => toggleMenu(false);

  const handleToggleMenu = (e) => {
    e.stopPropagation();
    const [
      { top: menuTop, right: menuRight, left: menuLeft },
    ] = Ref.current.getClientRects();
    // checking is dropdown will be opening
    if (!show) {
      setDropdownPosition({
        right: value
          ? window.innerWidth - menuLeft
          : window.innerWidth - menuRight,
        top: menuTop,
      });
    }
    // change position depending on position button on screen (top VS bottom)
    if (window.innerHeight - menuTop > window.innerHeight / 2) {
      setMenuDirectionalTop(false);
    } else {
      setMenuDirectionalTop(true);
    }
    if (menuRight > window.innerWidth / 2) {
      setMenuDirectionalLeft(false);
    } else {
      setMenuDirectionalLeft(true);
    }
    toggleMenu(!show);
  };

  useImperativeHandle(ref, () => ({
    handleDropdownClick: (e) => {
      handleToggleMenu(e);
    },
  }));

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  });

  const handleOptionClick = (e, option) => {
    e.stopPropagation();
    toggleMenu(false);
    option.onClick();
  };

  return (
    <div ref={Ref} className={`dropdown-menu ${className || ''}`}>
      {!icon && !value && (
        <Icon
          onClick={handleToggleMenu}
          className="dropdown-menu__icon"
          name={show ? 'close' : 'more'}
        />
      )}
      {value && (
        <div onClick={handleToggleMenu} className="dropdown-menu__label">
          {value.value}
        </div>
      )}
      {icon && (
        <Icon
          onClick={handleToggleMenu}
          className="dropdown-menu__icon"
          name={show ? 'close' : icon}
        />
      )}
      {show && (
        <FadeBackground className="dropdown-menu__background">
          <div style={{ position: 'absolute', ...dropdownPosition }}>
            {!value && (
              <Icon
                onClick={handleToggleMenu}
                className="dropdown-menu__icon"
                name="close"
              />
            )}
            <div
              className={`dropdown-menu__dropdown ${
                show ? 'dropdown-menu__dropdown_open' : ''
              } ${
                menuDirectionalTop && ' dropdown-menu__dropdown_top-direction'
              }
              ${
                menuDirectionalLeft && ' dropdown-menu__dropdown_left-direction'
              }
              ${value && 'dropdown-menu__dropdown_language'}`}
            >
              {options.map((option) => (
                <div
                  key={option.label}
                  onClick={(e) => handleOptionClick(e, option)}
                  className="dropdown-menu__option"
                >
                  {option.icon && (
                    <Icon
                      className="dropdown-menu__option-icon"
                      name={option.icon}
                    />
                  )}
                  {option.label}
                </div>
              ))}
            </div>
          </div>
        </FadeBackground>
      )}
    </div>
  );
};

export default forwardRef(DropdownMenu);

DropdownMenu.defaultProps = {
  options: [],
  className: '',
};
