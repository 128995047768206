import { useEffect } from 'react';
import { createPortal } from 'react-dom';

const FadeBackground = ({ children, className }) => {
  const element = document.createElement('div');

  useEffect(() => {
    if (className) {
      element.classList.add(className);
    }
    const bgrFade = document.getElementById('background-fade');
    bgrFade.append(element);
    return () => {
      bgrFade.removeChild(element);
    };
    // eslint-disable-next-line
  }, [className]);

  return createPortal(children, element);
};

export default FadeBackground;
