import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import { show } from 'redux-modal';
import { useTranslation } from 'react-i18next';
import ImageHeader from './common/ImageHeader';
import { convertFileToBase64, srcSet } from '../utils/files';
import { MODAL_NAME as RESTAURANT_PROPERTIES_MODAL } from './modals/RestaurantPropertiesModal';
import Input from './common/Input';
import EditableLabel from './common/EditableLabel';
import Icon from './common/Icon';
import DropdownMenu from './common/DropdownMenu';
import { updateRestaurantApi } from '../redux/reducers/restaurant/actions';
import ContentLanguageSelector from './common/ContentLanguageSelector';

const RestaurantHeader = ({
  restaurant,
  className,
  updateRestaurantApi,
  show,
  onBackClick,
  isShowControls,
  isNew,
  callback,
  languageOpt,
  restaurantPage,
}) => {
  const [titleValue, setTitle] = useState(restaurant.name);
  const [background, setBackground] = useState('');
  const [logo, setLogo] = useState('');
  const [errors, setErrors] = useState({});
  const { t, i18n } = useTranslation();
  const Ref = useRef();

  const history = useHistory();

  const restaurantImageRef = useRef();
  const restaurantLogoRef = useRef();

  const handleUpdateRestaurantField = (name, value) => {
    const data = {
      id: restaurant.id,
    };
    data[name] = value;
    updateRestaurantApi(data).catch((e) => {
      setErrors(e);
    });
  };

  const handleRestaurantNameChange = (value) => {
    handleUpdateRestaurantField('name', value);
  };
  const handleTitleChange = (value) => {
    setErrors({});
    setTitle(value);
  };

  const handleImageChange = async (field, e) => {
    const [img] = Array.from(e.target.files);
    if (img) {
      const base64 = await convertFileToBase64(img);
      if (isNew) {
        if (field === 'logo') {
          setLogo(base64);
          callback.setLogo({
            filename: img.name,
            content: base64,
          });
        } else {
          setBackground(base64);
          callback.setBackground({
            filename: img.name,
            content: base64,
          });
        }
      } else {
        try {
          await handleUpdateRestaurantField(field, {
            filename: img.name,
            content: base64,
          });
        } catch (e) {
          console.error(e);
        }
      }
    }
  };

  const handleImageRemove = (field) => {
    Swal.fire({
      heightAuto: false,
      title: t('restaurant_header.image_remove_text'),
      showCancelButton: true,
      confirmButtonText: t('swal.yes'),
      cancelButtonText: t('swal.no'),
      showClass: {
        popup: 'animate__animated modalFadeInUp',
      },
      hideClass: {
        popup: 'animate__animated modalFadeOutDown',
      },
      preConfirm: () => handleUpdateRestaurantField(field, null),
    });
  };

  const handleOpenBackgroundFileDialog = () => {
    if (restaurantImageRef.current) {
      // imitation on input click
      restaurantImageRef.current.click();
    }
  };

  const handleOpenLogoFileDialog = () => {
    if (restaurantLogoRef.current) {
      // imitation on input click
      restaurantLogoRef.current.click();
    }
  };

  const handleEmptyBackgroundClick = () => {
    if (!restaurant.image) handleOpenBackgroundFileDialog();
  };

  const handleEmptyLogoClick = () => {
    if (!restaurant.logo) handleOpenLogoFileDialog();
  };

  const handleBurgerMenuClick = () => {
    show(RESTAURANT_PROPERTIES_MODAL);
  };

  const handleBackClick = () => {
    if (onBackClick) {
      onBackClick();
    } else {
      history.goBack();
    }
  };

  /**
   * UI elements generator
   */
  const languageOptions = [
    {
      onClick: () => i18n.changeLanguage('en-US'),
      value: 'Eng',
      id: 'en-US',
      label: 'English',
    },
    {
      onClick: () => i18n.changeLanguage('uk-UA'),
      value: 'Укр',
      id: 'uk-UA',
      label: 'Українська',
    },
    {
      onClick: () => i18n.changeLanguage('ru-RU'),
      value: 'Рус',
      id: 'ru-RU',
      label: 'Русский',
    },
  ];

  const languageDropdownValue = languageOptions.find(
    (option) => option.id === i18n.language
  )
    ? languageOptions.find((option) => option.id === i18n.language)
    : languageOptions.find((option) => option.id === 'en-US');

  const getLeftHeaderIcons = () => (
    <>
      {!restaurantPage && <Icon name="back" onClick={handleBackClick} />}
      {!isShowControls && (
        <div
          className="language-block language-block_header"
          onClick={(e) => Ref.current.handleDropdownClick(e)}
        >
          <DropdownMenu
            options={languageOptions}
            value={languageDropdownValue}
            ref={Ref}
            view
          />
        </div>
      )}
    </>
  );

  const getRightHeaderIcons = () => (
    <DropdownMenu
      options={[
        {
          icon: 'change',
          label: t(
            restaurant.image
              ? 'restaurant_header.change_image'
              : 'restaurant_header.add_image'
          ),
          onClick: handleOpenBackgroundFileDialog,
        },
        {
          icon: 'delete',
          label: t('restaurant_header.image_remove'),
          onClick: () => handleImageRemove('image'),
        },
      ]}
    />
  );

  const getLogoHeaderIcon = () => {
    return (
      <DropdownMenu
        options={[
          {
            icon: 'change',
            label: t(
              restaurant.logo
                ? 'restaurant_header.logo_change'
                : 'restaurant_header.logo_add'
            ),
            onClick: handleOpenLogoFileDialog,
          },
          {
            icon: 'delete',
            label: t('restaurant_header.logo_remove'),
            onClick: () => handleImageRemove('logo'),
          },
        ]}
      />
    );
  };

  return (
    <header
      data-aos-id="restaurant-header"
      data-aos="fade-up"
      className={`restaurant-header ${className || ''}`}
    >
      <div className="restaurant-header__content">
        <div className="restaurant-header__left-icons">
          {getLeftHeaderIcons()}
        </div>

        {!isNew && (
          <div className="restaurant-header__right-icons">
            {isShowControls && getRightHeaderIcons()}
            {isShowControls && (
              <Icon
                onClick={handleBurgerMenuClick}
                name="menu"
                className="ml-2"
              />
            )}
          </div>
        )}

        {restaurant.image && (
          <img
            className="restaurant-header__background-image"
            srcSet={srcSet(restaurant.image)}
            alt={restaurant.name}
          />
        )}
        {background && (
          <img
            className="restaurant-header__background-image"
            src={background}
            alt={restaurant.name}
          />
        )}
        {isShowControls && !restaurant.image && !background && (
          <div
            className="restaurant-header__background-image restaurant-header__background-image_empty"
            onClick={handleEmptyBackgroundClick}
          >
            <p>{t('restaurant_header.image_add_hint')}</p>
            <p>{t('restaurant_header.image_recommended_size')}</p>
          </div>
        )}
        {(isShowControls || restaurant.logo) && (
          <div
            className={`restaurant-header__logo ${
              isShowControls || restaurant.image
                ? 'restaurant-header__logo_with-bg-image'
                : ''
            }`}
          >
            <ImageHeader
              image={restaurant.logo ? restaurant.logo : logo}
              showEmptyImage={isShowControls}
              icon={isShowControls && !isNew && getLogoHeaderIcon()}
              onClick={handleEmptyLogoClick}
              text={t('restaurant_header.logo')}
            />
          </div>
        )}
        {isShowControls && languageOpt && (
          <div className="language-block">
            <ContentLanguageSelector {...languageOpt} />
          </div>
        )}
        <div className="restaurant-header__title">
          {isShowControls && !isNew ? (
            <EditableLabel
              handleApplyChanges={() => handleRestaurantNameChange(titleValue)}
              Label={<h2 className="restaurant__title">{titleValue}</h2>}
              value={titleValue}
              Input={
                <Input
                  onChange={handleTitleChange}
                  placeholder={t('restaurant_create.name_placeholder')}
                  value={titleValue}
                  error={errors.name}
                />
              }
            />
          ) : (
            <h2
              className={`restaurant__title ${
                !restaurant.logo &&
                !restaurant.image &&
                'restaurant__title_no-images'
              }`}
            >
              {titleValue}
            </h2>
          )}
        </div>
      </div>

      {/* FOR SELECTING RESTAURANT BACKGROUND IMAGE */}
      {isShowControls && (
        <input
          onChange={(e) => handleImageChange('image', e)}
          type="file"
          id="restaurant-image"
          ref={restaurantImageRef}
          style={{ display: 'none' }}
        />
      )}
      {/* FOR SELECTING RESTAURANT LOGO IMAGE */}
      {isShowControls && (
        <input
          onChange={(e) => handleImageChange('logo', e)}
          type="file"
          id="restaurant-logo"
          ref={restaurantLogoRef}
          style={{ display: 'none' }}
        />
      )}
    </header>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, {
  updateRestaurantApi,
  show,
})(RestaurantHeader);

RestaurantHeader.defaultProps = {
  className: null,
  restaurant: {
    name: '',
    logo: {},
    image: {},
  },
};

RestaurantHeader.PropsTypes = {
  className: null,
  restaurant: PropTypes.shape({}),
};
