import React, { useEffect, useState } from 'react';
import { show } from 'redux-modal';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Header from '../components/Header';
import Loader from '../components/Loader';
import { updateRestaurantApi } from '../redux/reducers/restaurant/actions';
import { isMobile } from '../redux/reducers/ui/selectors';
import { showConfirmPhoneModal } from '../redux/reducers/auth/actions';
import Input from '../components/common/Input';
import Button from '../components/common/Button';
import { MODAL_NAME as RESTAURANT_PROPERTIES_MODAL } from '../components/modals/RestaurantPropertiesModal';

const SeoManagement = ({
  restaurant,
  showLoader,
  isMobile,
  updateRestaurantApi,
  show,
  showConfirmPhoneModal,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [seoData, setSeoData] = useState({
    meta_title: '',
    meta_description: '',
    meta_keywords: '',
  });
  const [analyticsData, setAnalyticsData] = useState({
    google_tag_manager: '',
    google_analytics: '',
    facebook_pixel: '',
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setSeoData({ ...restaurant?.seo });
    setAnalyticsData({ ...restaurant?.analytics });
  }, [restaurant?.analytics, restaurant?.seo]);

  const handleSeoFieldChange = (name, value) => {
    setSeoData({ ...seoData, [name]: value });
  };
  const handleAnalyticsFieldChange = (name, value) => {
    setAnalyticsData({ ...analyticsData, [name]: value });
  };

  const handleGoBack = () => {
    history.goBack();
  };

  const handleMenuClick = () => {
    show(RESTAURANT_PROPERTIES_MODAL);
  };

  const handleSave = async () => {
    setErrors({});
    try {
      await updateRestaurantApi({
        id: restaurant?.id,
        seo: seoData,
        analytics: analyticsData,
      });
      history.push(`/restaurant/${restaurant.slug}`);
    } catch (error) {
      if (error.code === 403) {
        showConfirmPhoneModal();
      } else {
        setErrors({ ...error });
        console.error(error);
      }
    }
  };

  if (showLoader) {
    return <Loader />;
  }

  return (
    <div className="page seo">
      <Header
        title={t('seo.title')}
        rightIcon="menu"
        leftIcon="back"
        onRightIconClick={handleMenuClick}
        onLeftIconClick={handleGoBack}
      />
      <div className="seo__content">
        <div className="seo__list">
          <Input
            value={seoData.meta_title}
            placeholder={t('seo.meta_title')}
            onChange={(value) => handleSeoFieldChange('meta_title', value)}
            error={errors?.meta_title}
          />
          <Input
            value={seoData.meta_description}
            placeholder={t('seo.meta_description')}
            onChange={(value) =>
              handleSeoFieldChange('meta_description', value)
            }
            error={errors?.meta_description}
          />
          <Input
            value={seoData.meta_keywords}
            placeholder={t('seo.meta_keywords')}
            onChange={(value) => handleSeoFieldChange('meta_keywords', value)}
            error={errors?.meta_keywords}
          />
        </div>
        <div className="seo__list">
          <Input
            value={analyticsData.google_tag_manager}
            placeholder={t('seo.google_tag_manager')}
            onChange={(value) =>
              handleAnalyticsFieldChange('google_tag_manager', value)
            }
            error={errors?.google_tag_manager}
          />
          <Input
            value={analyticsData.google_analytics}
            placeholder={t('seo.google_analytics')}
            onChange={(value) =>
              handleAnalyticsFieldChange('google_analytics', value)
            }
            error={errors?.google_analytics}
          />
          <Input
            value={analyticsData.facebook_pixel}
            placeholder={t('seo.facebook_pixel')}
            onChange={(value) =>
              handleAnalyticsFieldChange('facebook_pixel', value)
            }
            error={errors?.facebook_pixel}
          />
        </div>
        <Button
          className={`contacts__form-${
            isMobile ? `mobile__button` : `desktop__button`
          }`}
          type="submit"
          onClick={() => handleSave()}
        >
          {t('contacts.save')}
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  errors: state.restaurant.fieldErrors,
  restaurant: state.restaurant.restaurantData,
  showLoader: state.restaurant.isLoading,
  isMobile: isMobile(state),
});

export default connect(mapStateToProps, {
  updateRestaurantApi,
  show,
  showConfirmPhoneModal,
})(SeoManagement);
