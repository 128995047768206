import { post, get } from '../utils/axios';

const checkoutAPI = {
  getCheckoutData: async () => {
    try {
      return await get(`/checkout/`);
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },

  dineIn: async (data) => {
    try {
      return await post(`/checkout/dine-in/`, data);
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },

  takeaway: async (data) => {
    try {
      return await post(`/checkout/takeaway/`, data);
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },

  delivery: async (data) => {
    try {
      return await post(`/checkout/delivery/`, data);
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },

  paymentCallback: async (payment_id) => {
    try {
      const response = await post(`/payment/${payment_id}/process/`);
      return response.data;
    } catch (e) {
      console.error(e);
      if (e?.response?.data) {
        return Promise.reject(e.response.data);
      }
      return Promise.reject(e);
    }
  },

  validateAddress: async (address) => {
    try {
      return await post('/checkout/validate-address/', { address });
    } catch (e) {
      console.error(e);
      if (e?.response?.data) {
        return Promise.reject(e.response.data);
      }
      return Promise.reject(e);
    }
  },

  validatePhone: async (phone) => {
    try {
      return await post('/checkout/validate-phone/', { phone });
    } catch (e) {
      console.error(e);
      if (e?.response?.data) {
        return Promise.reject(e.response.data);
      }
      return Promise.reject(e);
    }
  },
};

export default checkoutAPI;
