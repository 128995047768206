import decodeJwt from 'jwt-decode';
import * as Sentry from '@sentry/browser';
import { show } from 'redux-modal';
import { push } from 'connected-react-router';
import { refreshToken } from '../../../utils/axios';
import {
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  REFRESH_TOKEN,
  REGISTER_SUCCESS,
  SET_USER_DATA,
} from './types';
import authAPI from '../../../api/auth';
import { returnErrors } from '../error/actions';

export const logout = () => {
  return {
    type: LOGOUT_SUCCESS,
  };
};

export const refreshJWTToken = (token) => {
  return {
    type: REFRESH_TOKEN,
    payload: { token },
  };
};

/**
 * MIDDLEWARE
 */

export const showConfirmPhoneModal = () => async (dispatch) => {
  dispatch(
    show('confirm-phone-modal', {
      onPhoneConfirm: async (phone) => {
        const { data } = await authAPI.changePhone(phone);
        return data;
      },
      onCodeConfirm: async (id, code) => {
        const { data } = await authAPI.confirmPhone(id, code);
        dispatch({
          type: LOGIN_SUCCESS,
          payload: data,
        });
        const tokenData = decodeJwt(data.access);
        // eslint-disable-next-line no-use-before-define
        dispatch(setUserData(tokenData.user_data));
        dispatch(push('/'));
        return data;
      },
    })
  );
};

export const setUserData = (user) => async (dispatch) => {
  Sentry.configureScope((scope) => {
    scope.setUser(user);
  });
  dispatch({
    type: SET_USER_DATA,
    payload: { user },
  });
  if (user.phone_verification_required) {
    dispatch(showConfirmPhoneModal());
  }
};

export const login = (data, history) => async (dispatch) => {
  try {
    const response = await authAPI.login(data);

    if (response.status === 206) {
      return Promise.resolve(response);
    }
    if (response.data.access) {
      history.push('/');
    }

    dispatch({
      type: LOGIN_SUCCESS,
      payload: response.data,
    });
    const tokenData = decodeJwt(response.data.access);
    dispatch(setUserData(tokenData.user_data));
    return Promise.resolve(response.data);
  } catch (err) {
    console.error(err);
    dispatch(
      returnErrors(err.response.data, err.response.status, 'LOGIN_FAIL')
    );
    const { data } = err.response;
    return Promise.reject(data);
  }
};

export const socialLogin = (type, token) => async (dispatch) => {
  try {
    let response = null;
    window.dataLayer.push({
      event: 'sign_in',
      eventCategory: 'success',
      eventContent: type,
    });
    switch (type) {
      case 'google':
        response = await authAPI.googleLogin({ access_token: token });
        break;
      case 'facebook':
        response = await authAPI.facebookLogin({ access_token: token });
        break;
      default:
        throw new Error('Type not defined');
    }
    dispatch({
      type: LOGIN_SUCCESS,
      payload: response.data,
    });
    const tokenData = decodeJwt(response.data.access);
    dispatch(setUserData(tokenData.user_data));
    return Promise.resolve(response.data);
  } catch (err) {
    console.error(err);
    dispatch(
      returnErrors(err.response.data, err.response.status, 'LOGIN_FAIL')
    );
    const { data } = err.response;
    return Promise.reject(data);
  }
};

export const register = (data) => async (dispatch) => {
  try {
    const response = await authAPI.register(data);
    dispatch({
      type: REGISTER_SUCCESS,
      payload: response.data,
    });
    return Promise.resolve(response.data);
  } catch (err) {
    console.error(err);
    dispatch(
      returnErrors(err.response.data, err.response.status, 'REGISTER_FAIL')
    );
    const { data } = err.response;
    return Promise.reject(data);
  }
};

export const confirmEmail = (code, history) => async (dispatch) => {
  try {
    const response = await authAPI.confirmEmail(code);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: response.data,
    });
    const tokenData = decodeJwt(response.data.access);
    await dispatch(setUserData(tokenData.user_data));
    history.push('/');
    return Promise.resolve();
  } catch (err) {
    console.error(err);
    dispatch(
      returnErrors(err.response.data, err.response.status, 'CONFIRM_EMAIL_FAIL')
    );
    const { data } = err.response;
    return Promise.reject(data);
  }
};

export const confirmEmail2FA = (code, history) => async (dispatch) => {
  try {
    const response = await authAPI.confirmEmail2FA(code);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: response.data,
    });
    const tokenData = decodeJwt(response.data.access);
    dispatch(setUserData(tokenData.user_data));
    history.push('/');
    return Promise.resolve();
  } catch (err) {
    console.error(err);
    dispatch(
      returnErrors(
        err.response.data,
        err.response.status,
        'CONFIRM_EMAIL2FA_FAIL'
      )
    );
    const { data } = err.response;
    return Promise.reject(data);
  }
};

export const passwordResetComplete = (code, password1, password2) => async (
  dispatch
) => {
  try {
    const response = await authAPI.passwordResetComplete(
      code,
      password1,
      password2
    );
    dispatch({
      type: LOGIN_SUCCESS,
      payload: response.data,
    });
    const tokenData = decodeJwt(response.data.access);
    dispatch(setUserData(tokenData.user_data));
    return Promise.resolve();
  } catch (err) {
    console.error(err);
    const { data } = err.response;
    return Promise.reject(data);
  }
};

/*
 * Change old password
 * @param oldPassword
 * @param password1
 * @param password2
 * @return {Promise<any>}
 */
export const passwordChange = (oldPassword, password1, password2) => async (
  dispatch
) => {
  try {
    const response = await authAPI.passwordChange(
      oldPassword,
      password1,
      password2
    );
    dispatch({
      type: LOGIN_SUCCESS,
      payload: response.data,
    });
    const tokenData = decodeJwt(response.data.access);
    dispatch(setUserData(tokenData.user_data));
    return Promise.resolve();
  } catch (err) {
    console.error(err);
    const { data } = err.response;
    return Promise.reject(data);
  }
};

export const checkAuth = () => async (dispatch, getState) => {
  const { token, refresh } = getState().auth;
  if (token && refresh) {
    try {
      const { exp, user_data } = decodeJwt(token);
      if (exp > new Date().getTime() / 1000 - 10) {
        dispatch(setUserData(user_data));
        return dispatch(refreshJWTToken(token));
      }
      const { access } = await refreshToken();
      const tokenData = decodeJwt(access);
      dispatch(setUserData(tokenData.user_data));
      return dispatch(refreshJWTToken(access));
    } catch (err) {
      console.error(err);
      dispatch(logout());
      const { data } = err.response;
      return Promise.reject(data);
    }
  }
  return null;
};
