import React, { useEffect, useState, useRef } from 'react';
import propTypes from 'prop-types';

const CategoriesLinksList = ({ categories }) => {
  const ref = useRef();
  const ref2 = useRef();
  const [top, toggleTop] = useState(false);
  const [activeCategoryIndex, setActiveCategoryIndex] = useState(0);
  const [listWidthNormal, setListWidthNormal] = useState(true);

  const handleCategoryLinkScroll = () => {
    if (ref.current && ref.current) {
      const { top: topCategoryLinks } = ref.current.getBoundingClientRect();

      if (topCategoryLinks < 1) {
        toggleTop(true);
      } else {
        toggleTop(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener(
      'scroll',
      () => {
        handleCategoryLinkScroll();
      },
      true
    );

    return () => {
      window.removeEventListener(
        'scroll',
        () => {
          handleCategoryLinkScroll();
        },
        true
      );
    };
  }, []);

  useEffect(() => {
    const headerWidth = Number.parseInt(
      getComputedStyle(ref2.current).inlineSize.split('px')[0],
      10
    );
    const children = [...ref2.current.children];
    let childrenWidth = 0;
    children.map(
      // eslint-disable-next-line no-return-assign
      (child) =>
        (childrenWidth += Number.parseInt(
          getComputedStyle(child).inlineSize.split('px')[0],
          10
        ))
    );
    setListWidthNormal(childrenWidth < headerWidth);
  }, []);

  const handleCategoryLinkClick = (categoryName, index) => {
    setActiveCategoryIndex(index);

    // find need category with data-category attribute
    const category = document.querySelector(
      `[data-category="${categoryName}-${index}"]`
    );

    category.scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    });
  };

  const showCategoriesList = () =>
    categories.map((category, index) => (
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
      <li
        key={category.id}
        className={`category-list__item ${
          index === activeCategoryIndex && 'category-list__item-active'
        }`}
        onClick={() => handleCategoryLinkClick(category.name, index)}
      >
        {category.name}
      </li>
    ));

  return (
    <>
      <ul
        ref={ref}
        style={{ opacity: top ? 0 : 1 }}
        className={`category-list ${
          top ? 'category-list_hide' : 'category-list_show'
        }`}
      >
        {showCategoriesList()}
      </ul>
      <ul
        ref={ref2}
        style={{ opacity: top ? 1 : 0 }}
        className={`category-list category-list__on-top ${
          top ? 'category-list_show' : 'category-list_hide'
        } ${listWidthNormal ? '' : 'category-list__on-top-fromStart'}`}
      >
        {showCategoriesList()}
      </ul>
    </>
  );
};

CategoriesLinksList.defaultProps = {
  categories: [],
};

CategoriesLinksList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  categories: propTypes.array,
};
export default CategoriesLinksList;
