import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../components/common/Button';

const SubscriptionSuccess = () => {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <div className="page">
      <div className="subscription-result__content">
        <div className="subscription-result__title">
          {t('subscription_result.success_title')}
        </div>
        <div className="subscription-result__description">
          {t('subscription_result.success_text')}
        </div>
        <span
          role="img"
          aria-label="confetti"
          className="subscription-result__emoji"
        >
          🎉
        </span>
      </div>
      <Button
        className="subscription-result__button"
        onClick={() => history.push(`/`)}
      >
        {t('subscription_result.success_button')}
      </Button>
    </div>
  );
};

export default SubscriptionSuccess;
