import { ADD_PRODUCT, REMOVE_PRODUCT, UPDATE_CART } from './types';
import cartAPI from '../../../api/cart';
import { showConfirmPhoneModal } from '../auth/actions';

export const addProduct = (product) => {
  return {
    type: ADD_PRODUCT,
    payload: { product },
  };
};

export const removeProduct = (product) => {
  return {
    type: REMOVE_PRODUCT,
    payload: { product },
  };
};

export const updateCart = (cart) => {
  return {
    type: UPDATE_CART,
    payload: { cart },
  };
};

/**
 * MIDDLEWARE
 */

export const fetchCart = () => async (dispatch) => {
  try {
    const { data } = await cartAPI.get();
    dispatch(updateCart(data));
    console.log(data);
    return Promise.resolve(data);
  } catch (err) {
    console.error(err);
    dispatch(showConfirmPhoneModal());
    return Promise.reject(err);
  }
};

export const addProductWithOptionsApi = (productId, options) => async (
  dispatch
) => {
  try {
    const { data } = await cartAPI.addProduct(productId, 1, options);
    dispatch(updateCart(data.cart));
    return Promise.resolve(data.line_id);
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

export const incrementProduct = (id) => async (dispatch) => {
  try {
    const { data } = await cartAPI.addProduct(id);
    dispatch(updateCart(data.cart));
    return Promise.resolve(data.line_id);
  } catch (err) {
    console.error(err);
    dispatch(showConfirmPhoneModal());
    return Promise.reject(err);
  }
};

export const incrementLine = (lineId) => async (dispatch) => {
  try {
    const { data } = await cartAPI.addLine(lineId);
    dispatch(updateCart(data.cart));
    return Promise.resolve(data.line_id);
  } catch (err) {
    console.error(err);
    dispatch(showConfirmPhoneModal());
    return Promise.reject(err);
  }
};

export const decrementProduct = (id) => async (dispatch) => {
  try {
    const { data } = await cartAPI.removeProduct(id);
    dispatch(updateCart(data));
    return Promise.resolve(data);
  } catch (err) {
    console.error(err);
    dispatch(showConfirmPhoneModal());
    return Promise.reject(err);
  }
};

export const decrementLine = (lineId) => async (dispatch) => {
  try {
    const { data } = await cartAPI.removeLine(lineId);
    dispatch(updateCart(data));
    return Promise.resolve(data);
  } catch (err) {
    console.error(err);
    dispatch(showConfirmPhoneModal());
    return Promise.reject(err);
  }
};
