import React, { useEffect, useState } from 'react';
import { show } from 'redux-modal';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Header from '../components/Header';
import Icon from '../components/common/Icon';
import Loader from '../components/Loader';
import restaurantsAPI from '../api/restaurants';
import { updateRestaurantApi } from '../redux/reducers/restaurant/actions';
import { MODAL_NAME as RESTAURANT_PROPERTIES_MODAL } from '../components/modals/RestaurantPropertiesModal';
import DropdownMenu from '../components/common/DropdownMenu';
import Button from '../components/common/Button';
import Modal from '../components/common/Modal';
import Input from '../components/common/Input';
import { isMobile } from '../redux/reducers/ui/selectors';
import GooglePlacesAutocomplete from '../components/common/GooglePlacesAutocomplete';
import InputMask, { PHONE_MASK } from '../components/common/InputMask';
import Toggle from '../components/common/Toggle';
import Select from '../components/common/Select';
import { showConfirmPhoneModal } from '../redux/reducers/auth/actions';

const TIME_OPTIONS = [
  { id: '00:00', name: '00:00' },
  { id: '01:00', name: '01:00' },
  { id: '02:00', name: '02:00' },
  { id: '03:00', name: '03:00' },
  { id: '04:00', name: '04:00' },
  { id: '05:00', name: '05:00' },
  { id: '06:00', name: '06:00' },
  { id: '07:00', name: '07:00' },
  { id: '08:00', name: '08:00' },
  { id: '09:00', name: '09:00' },
  { id: '10:00', name: '10:00' },
  { id: '11:00', name: '11:00' },
  { id: '12:00', name: '12:00' },
  { id: '13:00', name: '13:00' },
  { id: '14:00', name: '14:00' },
  { id: '15:00', name: '15:00' },
  { id: '16:00', name: '16:00' },
  { id: '17:00', name: '17:00' },
  { id: '18:00', name: '18:00' },
  { id: '19:00', name: '19:00' },
  { id: '20:00', name: '20:00' },
  { id: '21:00', name: '21:00' },
  { id: '22:00', name: '22:00' },
  { id: '23:00', name: '23:00' },
];

const INITIAL_CONTACT_DATA = {
  address: '',
  phone: '',
  schedule: {
    preview: '',
  },
  social_media: {
    facebook: {
      name: '',
      url: '',
    },
    instagram: {
      name: '',
      url: '',
    },
  },
  wifi: {
    name: '',
    password: '',
  },
};

const Contacts = ({
  restaurant,
  showLoader,
  isMobile,
  show,
  updateRestaurantApi,
  showConfirmPhoneModal,
}) => {
  const history = useHistory();
  const [contactsData, setContactsData] = useState(INITIAL_CONTACT_DATA);
  const [modalEditContactIsOpen, setModalEditContactIsOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [editedContact, setEditedContact] = useState('address');
  const { t } = useTranslation();

  useEffect(() => {
    if (restaurant?.id) {
      restaurantsAPI
        .getContacts(restaurant.id)
        .then(({ data }) => {
          setContactsData(data);
        })
        .catch((e) => {
          console.error(e);
          if (e.code === 403) {
            showConfirmPhoneModal();
          }
        });
    }
    // eslint-disable-next-line
  }, [restaurant, showConfirmPhoneModal]);

  const handleMenuClick = () => {
    show(RESTAURANT_PROPERTIES_MODAL);
  };

  const getModalText = () => {
    switch (editedContact) {
      case 'address':
        return t('contacts.address_title');
      case 'phone':
        return t('contacts.phone_title');
      case 'schedule':
        return t('contacts.schedule_title');
      case 'social_media.facebook':
        return t('contacts.facebook_title');
      case 'social_media.instagram':
        return t('contacts.instagram_title');
      case 'wifi':
        return t('contacts.wifi_title');
      default:
        return null;
    }
  };

  const handleScheduleChange = (nameOfDay, fieldName, value) => {
    setContactsData((contactsData) => {
      const newState = { ...contactsData };
      newState.schedule[nameOfDay][fieldName] = value;
      return newState;
    });
  };

  const handlePhoneChange = (event) => {
    if (event.target.value) {
      setContactsData((contactData) => ({
        ...contactData,
        phone: event.target.value.replace(/ /g, ''),
      }));
    }
  };

  const handleContactFieldsChange = (value) => {
    const fields = editedContact.split('.');
    const newContactsData = contactsData;
    switch (fields[0]) {
      case 'address':
        newContactsData[`${editedContact}`] = value;
        break;
      case 'social_media':
        newContactsData[`${fields[0]}`][`${fields[1]}`].name = value;
        break;
      default:
        break;
    }
    setContactsData({ ...newContactsData });
  };

  const handleWifiNameChange = (value) => {
    const newContactsData = contactsData;
    newContactsData.wifi.name = value;
    setContactsData({ ...newContactsData });
  };

  const handleWifiPasswordChange = (value) => {
    const newContactsData = contactsData;
    newContactsData.wifi.password = value;
    setContactsData({ ...newContactsData });
  };

  const getModalTemplate = () => {
    const fields = editedContact.split('.');
    switch (fields[0]) {
      case 'address': {
        return (
          <GooglePlacesAutocomplete
            value={
              contactsData.address.formatted_address || contactsData.address
            }
            name="address"
            placeholder={getModalText()}
            onChange={handleContactFieldsChange}
            error={errors.error || errors[fields[0]]}
          />
        );
      }
      case 'phone':
        return (
          <InputMask
            value={contactsData.phone}
            required
            type="tel"
            mask={PHONE_MASK}
            placeholder={getModalText()}
            onChange={handlePhoneChange}
            error={errors.error || errors[fields[0]]}
          />
        );
      case 'social_media': {
        const socialError = errors.social_media
          ? errors.social_media[fields[1]].name
          : '';
        return (
          <Input
            value={contactsData.social_media[fields[1]].name}
            required
            placeholder={getModalText()}
            onChange={handleContactFieldsChange}
            error={errors.error || socialError}
          />
        );
      }
      case 'wifi':
        return (
          <>
            <Input
              value={contactsData.wifi.name}
              required
              placeholder={t('contacts.wifi_name_placeholder')}
              onChange={handleWifiNameChange}
              error={errors.error || errors.wifi?.name}
            />
            <Input
              value={contactsData.wifi.password}
              placeholder={t('contacts.wifi_password_placeholder')}
              onChange={handleWifiPasswordChange}
              error={errors.wifi?.password}
            />
          </>
        );
      case 'schedule': {
        return (
          <div className="contacts-schedule">
            {Object.entries(contactsData.schedule)
              .filter(([key]) => key.includes('day'))
              .map(([key, value]) => (
                <div className="contacts-schedule__item" key={key}>
                  <Toggle
                    label={value.name}
                    checked={value.is_active}
                    onChange={() =>
                      handleScheduleChange(key, 'is_active', !value.is_active)
                    }
                  />
                  <Select
                    options={TIME_OPTIONS}
                    valueId={value.opening}
                    onSelect={(option) =>
                      handleScheduleChange(key, 'opening', option.name)
                    }
                    error={errors.error}
                  />
                  <Select
                    className="ml-1"
                    options={TIME_OPTIONS}
                    valueId={value.closing}
                    onSelect={(option) =>
                      handleScheduleChange(key, 'closing', option.name)
                    }
                    error={errors.error}
                  />
                </div>
              ))}
            <div className="contacts-schedule__preview">
              {/* TODO PREVIEW */}
            </div>
          </div>
        );
      }
      default:
        return <div />;
    }
  };

  const handleGoBack = () => {
    history.goBack();
  };

  const handleCloseModal = () => {
    setErrors({});
    restaurantsAPI
      .getContacts(restaurant.id)
      .then(({ data }) => {
        setContactsData(data);
        setModalEditContactIsOpen(false);
      })
      .catch((e) => {
        console.error(e);
        if (e.code === 403) {
          showConfirmPhoneModal();
        }
        setModalEditContactIsOpen(false);
      });
  };

  const handleSaveContactField = async (e) => {
    const fields = editedContact.split('.');
    e.preventDefault();
    setErrors({});

    let serverData = {};

    switch (fields[0]) {
      case 'address':
      case 'phone': {
        serverData[fields[0]] = contactsData[fields[0]];
        break;
      }
      case 'social_media': {
        switch (fields[1]) {
          case 'instagram': {
            serverData.social_media = {
              instagram: {
                name: contactsData.social_media.instagram.name,
                url: `https://instagram.com/${contactsData.social_media.instagram.name}`,
              },
            };
            break;
          }
          case 'facebook': {
            serverData.social_media = {
              facebook: {
                name: contactsData.social_media.facebook.name,
                url: `https://facebook.com/${contactsData.social_media.facebook.name}`,
              },
            };
            break;
          }
          default:
            break;
        }
        break;
      }
      case 'wifi': {
        serverData.wifi = { ...contactsData.wifi };
        break;
      }
      case 'schedule': {
        serverData.schedule = { ...contactsData.schedule };
        break;
      }
      default:
        serverData = { ...contactsData };
    }
    try {
      const data = await updateRestaurantApi({
        id: restaurant.id,
        ...serverData,
      });
      setModalEditContactIsOpen(false);

      if (data.schedule) {
        setContactsData((contactsData) => {
          const newState = { ...contactsData };
          newState.schedule.preview = data.schedule;
          return newState;
        });
      }
    } catch (error) {
      setErrors({ ...error });
      console.error(error);
    }
  };

  const isSubmitDisabled = () => {
    const fields = editedContact.split('.');
    switch (fields[0]) {
      case 'address':
        return contactsData[fields[0]] === '';
      case 'phone': {
        return (
          contactsData[fields[0]] === '' ||
          contactsData[fields[0]].trim().length < 13
        );
      }
      case 'social_media':
        return contactsData.social_media[fields[1]].name === '';
      case 'wifi':
        return contactsData.wifi.name === '';
      default:
        return false;
    }
  };

  const getAddressText = () => {
    if (contactsData.address.formatted_address) {
      return contactsData.address.formatted_address;
    }
    if (contactsData.address) {
      return contactsData.address;
    }
    return t('contacts.address_add');
  };

  const handleOpenModal = (contactName) => {
    setModalEditContactIsOpen(true);
    setEditedContact(contactName);
  };

  const handleContactItemClick = (contactName) => {
    const fields = contactName.split('.');
    switch (fields[0]) {
      case 'address':
      case 'phone':
      case 'schedule': {
        if (contactsData[fields[0]] === '') handleOpenModal(fields[0]);
        break;
      }
      case 'social_media': {
        if (contactsData[fields[0]][fields[1]].name === '')
          handleOpenModal(`${fields[0]}.${fields[1]}`);
        break;
      }
      case 'wifi': {
        if (contactsData[fields[0]].name === '') handleOpenModal(fields[0]);
        break;
      }
      default:
        return null;
    }
    return null;
  };

  if (showLoader) {
    return <Loader />;
  }

  return (
    <div className="page contacts">
      <Header
        title={t('contacts.title')}
        rightIcon="menu"
        leftIcon="back"
        onRightIconClick={handleMenuClick}
        onLeftIconClick={handleGoBack}
      />
      <div className="contacts__content">
        <div className="contacts__list">
          <div
            className={`contacts__item contacts-item ${
              contactsData.address === '' && 'contacts-item_empty'
            }`}
            onClick={() => handleContactItemClick('address')}
          >
            <Icon className="contacts-item__icon" name="placeMarker" />
            <p className="contacts-item__text">{getAddressText()}</p>
            <DropdownMenu
              className="contacts-item__more-icon"
              options={[
                {
                  label: t('contacts.address_edit'),
                  onClick: () => handleOpenModal('address'),
                },
              ]}
            />
          </div>

          <div
            className={`contacts__item contacts-item ${
              contactsData.phone === '' && 'contacts-item_empty'
            }`}
            onClick={() => handleContactItemClick('phone')}
          >
            <Icon className="contacts-item__icon" name="phone" />
            <p className="contacts-item__text">
              {contactsData.phone
                ? contactsData.phone
                : t('contacts.phone_add')}
            </p>
            <DropdownMenu
              className="contacts-item__more-icon"
              options={[
                {
                  label: t('contacts.phone_edit'),
                  onClick: () => handleOpenModal('phone'),
                },
              ]}
            />
          </div>

          <div
            className={`contacts__item contacts-item ${
              contactsData.schedule.preview === '' && 'contacts-item_empty'
            }`}
            onClick={() => handleContactItemClick('schedule')}
          >
            <Icon className="contacts-item__icon" name="timetable" />
            <div className="contacts-item__text">
              {contactsData.schedule.preview
                ? contactsData.schedule.preview.map((line) => (
                    <p key={line}>{line}</p>
                  ))
                : t('contacts.schedule_add')}
            </div>
            <DropdownMenu
              className="contacts-item__more-icon"
              options={[
                {
                  label: t('contacts.schedule_edit'),
                  onClick: () => handleOpenModal('schedule'),
                },
              ]}
            />
          </div>
          <div
            className={`contacts__item contacts-item ${
              contactsData.social_media.instagram.name === '' &&
              'contacts-item_empty'
            }`}
            onClick={() => handleContactItemClick('social_media.instagram')}
          >
            <Icon className="contacts-item__icon" name="instagram" />
            <p className="contacts-item__text">
              {contactsData.social_media.instagram.name
                ? contactsData.social_media.instagram.name
                : t('contacts.instagram_add')}
            </p>
            <DropdownMenu
              className="contacts-item__more-icon"
              options={[
                {
                  label: t('contacts.instagram_edit'),
                  onClick: () => handleOpenModal('social_media.instagram'),
                },
              ]}
            />
          </div>
          <div
            className={`contacts__item contacts-item ${
              contactsData.social_media.facebook.name === '' &&
              'contacts-item_empty'
            }`}
            onClick={() => handleContactItemClick('social_media.facebook')}
          >
            <Icon className="contacts-item__icon" name="facebook" />
            <p className="contacts-item__text">
              {contactsData.social_media.facebook.name
                ? contactsData.social_media.facebook.name
                : t('contacts.facebook_add')}
            </p>
            <DropdownMenu
              className="contacts-item__more-icon"
              options={[
                {
                  label: t('contacts.facebook_edit'),
                  onClick: () => handleOpenModal('social_media.facebook'),
                },
              ]}
            />
          </div>
          <div
            className={`contacts__item contacts-item ${
              contactsData.wifi.name === '' && 'contacts-item_empty'
            }`}
            onClick={() => handleContactItemClick('wifi')}
          >
            <Icon className="contacts-item__icon" name="wifi" />
            <p className="contacts-item__text">
              {contactsData.wifi.name
                ? contactsData.wifi.name
                : t('contacts.wifi_add')}
            </p>
            <DropdownMenu
              className="contacts-item__more-icon"
              options={[
                {
                  label: t('contacts.wifi_edit'),
                  onClick: () => handleOpenModal('wifi'),
                },
              ]}
            />
          </div>
        </div>
      </div>

      <Modal
        isOpen={modalEditContactIsOpen}
        onClose={handleCloseModal}
        title={getModalText()}
      >
        <form
          onSubmit={handleSaveContactField}
          className={`contacts__form-${isMobile ? `mobile` : `desktop`}`}
        >
          <div
            className={`contacts__form-${
              isMobile ? `mobile__content` : `desktop__content`
            }`}
          >
            {getModalTemplate()}
          </div>
          <Button
            disabled={isSubmitDisabled()}
            className={`contacts__form-${
              isMobile ? `mobile__button` : `desktop__button`
            }`}
            type="submit"
          >
            {t('contacts.save')}
          </Button>
        </form>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  errors: state.restaurant.fieldErrors,
  restaurant: state.restaurant.restaurantData,
  showLoader: state.restaurant.isLoading,
  isMobile: isMobile(state),
});

export default connect(mapStateToProps, {
  updateRestaurantApi,
  show,
  showConfirmPhoneModal,
})(Contacts);
