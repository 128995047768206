import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isMobile } from '../redux/reducers/ui/selectors';
import Button from '../components/common/Button';
import checkoutAPI from '../api/checkout';

const ThankYouPage = ({ location, restaurant }) => {
  const history = useHistory();
  const { paymentId } = useParams();
  const { t } = useTranslation();

  const [data, setData] = useState(location?.state);
  const [restaurantData, setRestaurantData] = useState(restaurant);

  useEffect(() => {
    if (paymentId)
      checkoutAPI.paymentCallback(paymentId).then((data) => {
        setData(data);
        setRestaurantData(data.restaurant);
      });
  }, [paymentId]);

  if (!paymentId && !location.state) {
    // return null;
  }

  if (!paymentId && !restaurant) {
    // return null;
  }

  const getOrderTypeText = () => {
    switch (data?.order_type) {
      case 'takeaway':
        return t('thx_page.seconde_line.takeaway');
      case 'delivery':
        return t('thx_page.seconde_line.delivery');
      default:
        return t('thx_page.seconde_line.dine_in');
    }
  };

  const getOrderTypeDescription = () => {
    switch (data?.order_type) {
      case 'dine-in':
        return (
          <div className="thank-you-page__info">
            <div>{`${t('thx_page.dine_in_text')} ${data?.table}`}</div>
            <div>{t('thx_page.dine_in_info')}</div>
          </div>
        );
      case 'takeaway':
      case 'delivery':
        return (
          <div className="thank-you-page__info">
            <div>{t('thx_page.address')}</div>
            <div>{restaurantData?.address}</div>
            <div>{`${t('thx_page.delivery_time')} ${data?.order_date}`}</div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="thank-you-page">
      <div className="thank-you-page__content">
        <h1>{restaurantData?.name}</h1>
        <h2>{t('thx_page.thx')}</h2>
        <h2>{t('thx_page.first_line')}</h2>
        <h2>{getOrderTypeText()}</h2>
        <div
          className={`thank-you-page__icon thank-you-page__icon_${data?.order_type}`}
        />
        <p>{`№ ${data?.order_id}`}</p>
        {getOrderTypeDescription()}
        <Button
          onClick={() => history.push(`/restaurant/${restaurantData.slug}`)}
          className="thank-you-page__button"
        >
          {t('thx_page.main_page')}
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isMobile: isMobile(state),
  restaurant: state?.restaurant?.restaurantData,
});

export default connect(mapStateToProps, null)(ThankYouPage);
