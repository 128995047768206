import { push, replace } from 'connected-react-router';
import {
  FETCH_RESTAURANT_SUCCESS,
  FETCH_RESTAURANT_REQUEST,
  UPDATE_RESTAURANT_REQUEST,
  FETCH_RESTAURANT_MENUS,
  SET_RESTAURANT,
  SET_FIELD_ERRORS,
  ADD_RESTAURANT_MENU_ITEM,
  UPDATE_RESTAURANT_MENU_ITEM,
  REMOVE_RESTAURANT_MENU_ITEM,
  CHECK_IN_BY_TABLE,
  SET_EDITED_PRODUCT,
} from './types';
import restaurantsAPI from '../../../api/restaurants';
import menuAPI from '../../../api/menu';
import { showConfirmPhoneModal } from '../auth/actions';

export const setRestaurant = (restaurant) => {
  return {
    type: SET_RESTAURANT,
    payload: { restaurant },
  };
};

export const fetchRestaurantSuccess = (restaurant) => {
  return {
    type: FETCH_RESTAURANT_SUCCESS,
    payload: { restaurant },
  };
};

export const fetchRestaurantMenus = (menus) => {
  return {
    type: FETCH_RESTAURANT_MENUS,
    payload: { menus },
  };
};

export const setErrors = (errors) => {
  return {
    type: SET_FIELD_ERRORS,
    payload: { errors },
  };
};

export const setEditedProduct = (product) => {
  return {
    type: SET_EDITED_PRODUCT,
    payload: { product },
  };
};

export const addRestaurantMenuItem = ({ id, name }) => {
  return {
    type: ADD_RESTAURANT_MENU_ITEM,
    payload: { id, name },
  };
};

export const updateRestaurantMenuItem = (data) => {
  return {
    type: UPDATE_RESTAURANT_MENU_ITEM,
    payload: { data },
  };
};

export const removeRestaurantMenuItem = (id) => {
  return {
    type: REMOVE_RESTAURANT_MENU_ITEM,
    payload: { id },
  };
};

export const checkInByTable = () => ({
  type: CHECK_IN_BY_TABLE,
});

/**
 * MIDDLEWARE
 */

export const fetchRestaurantFromApi = (slug, language) => async (dispatch) => {
  dispatch({
    type: FETCH_RESTAURANT_REQUEST,
  });
  try {
    const { data } = await restaurantsAPI.get(slug, language);
    dispatch(fetchRestaurantSuccess(data));
    return data;
  } catch (err) {
    console.error(err);
    dispatch(setErrors(err));
    if (err.code === 404) {
      dispatch(push('/page-not-found'));
    }
    if (err.code === 403) {
      dispatch(showConfirmPhoneModal());
    }
    if (err.code === 423) {
      // TODO WE DOESNT HAVE RESTAURANT ID FOR FEEDBACK AND WE CAN`T SEND FEEDBACK TO SERVER WITHOUT ID
      dispatch(replace(`/restaurant/${slug}/feedback`));
    }
    return Promise.reject(err);
  }
};

export const fetchRestaurantMenusFromApi = (slug, language) => async (
  dispatch
) => {
  try {
    const { data } = await restaurantsAPI.get(slug, language);
    dispatch(fetchRestaurantMenus(data.menu));
    return data;
  } catch (err) {
    console.error(err);
    dispatch(setErrors(err));
    if (err.code === 404) {
      dispatch(push('/page-not-found'));
    }
    if (err.code === 403) {
      dispatch(showConfirmPhoneModal());
    }
    if (err.code === 423) {
      // TODO WE DOESNT HAVE RESTAURANT ID FOR FEEDBACK AND WE CAN`T SEND FEEDBACK TO SERVER WITHOUT ID
      dispatch(replace(`/restaurant/${slug}/feedback`));
    }
    return Promise.reject(err);
  }
};

export const updateRestaurantApi = (restaurantPatch) => async (dispatch) => {
  dispatch({
    type: UPDATE_RESTAURANT_REQUEST,
  });
  try {
    dispatch(setErrors({}));
    const { data } = await restaurantsAPI.patch(restaurantPatch);
    dispatch(setRestaurant(data));
    return data;
  } catch (err) {
    console.error(err);
    if (err.code === 403) {
      dispatch(showConfirmPhoneModal());
    }
    dispatch(setErrors(err));
    return Promise.reject(err);
  }
};

export const createMenuApi = (name, restaurantId) => async (dispatch) => {
  dispatch({
    type: UPDATE_RESTAURANT_REQUEST,
  });
  try {
    dispatch(setErrors({}));
    const { data } = await menuAPI.create(name, restaurantId);
    dispatch(addRestaurantMenuItem(data));
    return data;
  } catch (err) {
    console.error(err);
    if (err.code === 403) {
      dispatch(showConfirmPhoneModal());
    }
    dispatch(setErrors(err));
    return Promise.reject(err);
  }
};

export const updateMenuApi = (menuData) => async (dispatch) => {
  dispatch({
    type: UPDATE_RESTAURANT_REQUEST,
  });
  try {
    dispatch(setErrors({}));
    const { data } = await menuAPI.patch(menuData);
    dispatch(updateRestaurantMenuItem(data));
    return data;
  } catch (err) {
    console.error(err);
    if (err.code === 403) {
      dispatch(showConfirmPhoneModal());
    }
    dispatch(setErrors(err));
    return Promise.reject(err);
  }
};

export const removeMenuApi = (id) => async (dispatch) => {
  dispatch({
    type: UPDATE_RESTAURANT_REQUEST,
  });
  try {
    dispatch(setErrors({}));
    const { data } = await menuAPI.remove(id);
    dispatch(removeRestaurantMenuItem(id));
    return data;
  } catch (err) {
    console.error(err);
    if (err.code === 403) {
      dispatch(showConfirmPhoneModal());
    }
    dispatch(setErrors(err));
    return Promise.reject(err);
  }
};
