import React from 'react';
import InputMaskComponent from 'react-input-mask';
import PropTypes from 'prop-types';

export const PHONE_MASK = '+38 099 999 99 99';

const InputMask = ({
  className,
  placeholder,
  required,
  mask,
  onBlur,
  maskChar,
  onChange,
  maskPlaceholder,
  ...props
}) => {
  return (
    <div
      className={`input ${props.error ? 'input__error' : ''} ${
        className || ''
      }`}
    >
      <div className="input__wrapper">
        <div className="input__field">
          <InputMaskComponent
            maskPlaceholder={maskPlaceholder}
            mask={mask}
            value={props.value}
            onBlur={onBlur}
            onChange={onChange}
          >
            <input
              className="input__component"
              autoComplete="off"
              {...props}
              placeholder={placeholder}
            />
          </InputMaskComponent>
          {placeholder && (
            <label htmlFor={props.name} className="input__label">
              {placeholder}
            </label>
          )}
        </div>
        <div className="input__icons">
          {required && !props.value && (
            <span className="input__required-text">Обов’язкове поле</span>
          )}
        </div>
      </div>
      {props.error && <p className="input__error-message">{props.error}</p>}
    </div>
  );
};

export default InputMask;

InputMask.PropsTypes = {
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  maskPlaceholder: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.oneOf([PropTypes.string, PropTypes.array]),
  unit: PropTypes.string,
  className: PropTypes.string,
};

InputMask.defaultProps = {
  onChange: () => {},
  onBlur: () => {},
  value: '',
  placeholder: '',
  error: null,
  name: 'input',
  maskPlaceholder: null,
  className: null,
  required: false,
};
