import React from 'react';
import fieldIcons from './icons/field';
import common from './icons/common';
import social from './icons/social';
import logo from './icons/logo';
import dropdown from './icons/dropdown';
import restaurant from './icons/restaurant';
import checkout from './icons/checkout';

const icons = {
  ...fieldIcons,
  ...common,
  ...social,
  ...logo,
  ...dropdown,
  ...restaurant,
  ...checkout,
};

const Icon = ({ name, onClick, className, withHover, ...props }) => {
  if (icons[name] === undefined) {
    throw new Error(`${name} - not found`);
  }
  return (
    <div
      {...props}
      onClick={onClick}
      className={`icon ${withHover ? 'icon_with-hover' : ''} ${
        className || ''
      }`}
    >
      {icons[name]}
    </div>
  );
};

export default Icon;

Icon.defaultProps = {
  className: '',
  withHover: true,
  name: '',
  onClick: () => {},
};
