import React from 'react';
import { connect } from 'react-redux';
import { show } from 'redux-modal';
import CartWidget from './CartWidget';
import Ring from './Ring';
import Icon from './common/Icon';
import { MODAL_NAME as USER_BURGER_MENU } from './modals/UserBurgerMenu';

const UserControls = ({
  isShowFeedBack,
  isShowWaiter,
  isDineIn,
  isShowCheckoutControls,
  show,
}) => {
  const showMenu = () => {
    show(USER_BURGER_MENU);
  };

  return (
    <div className="user-controls">
      <div className="user-controls__ring">
        <Ring
          isShowFeedBack={isShowFeedBack}
          isShowWaiter={isShowWaiter}
          isDineIn={isDineIn}
        />
      </div>
      <div data-aos-delay="100" data-aos="fade-up">
        {isShowCheckoutControls && <CartWidget />}
      </div>
      <div data-aos-delay="100" data-aos="fade-left">
        <Icon
          name="menu"
          onClick={showMenu}
          className="user-controls__burger"
        />
      </div>
    </div>
  );
};

export default connect(null, { show })(UserControls);
