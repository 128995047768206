import React from 'react';
import ReactModal from 'react-modal';
import Header from '../Header';

const customStyles = {
  content: {
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    backgroundColor: 'white',
  },
};

ReactModal.setAppElement('#root');

const Modal = ({ isOpen, onClose, title, customHeader, children }) => {
  return (
    <ReactModal isOpen={isOpen} onRequestClose={onClose} style={customStyles}>
      <div className="page app-modal">
        {customHeader || (
          <Header title={title} rightIcon="close" onRightIconClick={onClose} />
        )}
        {children}
      </div>
    </ReactModal>
  );
};

export default Modal;
