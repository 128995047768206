import * as types from './types';

const initialState = {
  restaurantData: null,
  restaurantMenus: null,
  isLoading: true,
  fieldErrors: {},
  isCheckInByTable: false,
  editedProduct: {
    name: '',
    weight: '',
    price: '',
    category: '',
    menu: '',
    unit: null,
    description: '',
    options: [],
    option_groups: [],
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_RESTAURANT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.FETCH_RESTAURANT_SUCCESS:
    case types.SET_RESTAURANT:
      return {
        ...state,
        restaurantData: action.payload.restaurant,
        restaurantMenus: action.payload.restaurant.menu,
        isLoading: false,
        isCheckInByTable: false,
      };
    case types.FETCH_RESTAURANT_MENUS:
      return {
        ...state,
        restaurantMenus: action.payload.menus,
        isLoading: false,
        isCheckInByTable: false,
      };
    case types.SET_FIELD_ERRORS:
      return {
        ...state,
        fieldErrors: action.payload.errors,
      };
    case types.ADD_RESTAURANT_MENU_ITEM:
      return {
        ...state,
        restaurantData: {
          ...state.restaurantData,
          menu: [
            ...state.restaurantData.menu,
            { ...action.payload, categories: [], is_active: true },
          ],
        },
        restaurantMenus: [
          ...state.restaurantData.menu,
          { ...action.payload, categories: [], is_active: true },
        ],
      };
    case types.UPDATE_RESTAURANT_MENU_ITEM:
      return {
        ...state,
        restaurantData: {
          ...state.restaurantData,
          menu: state.restaurantData.menu.map((item) => {
            if (item.id === action.payload.data.id) return action.payload.data;
            return item;
          }),
        },
        restaurantMenus: state.restaurantData.menu.map((item) => {
          if (item.id === action.payload.data.id) return action.payload.data;
          return item;
        }),
      };
    case types.REMOVE_RESTAURANT_MENU_ITEM:
      return {
        ...state,
        restaurantData: {
          ...state.restaurantData,
          menu: state.restaurantData.menu.filter(
            (item) => item.id !== action.payload.id
          ),
        },
        restaurantMenus: state.restaurantMenus.filter(
          (item) => item.id !== action.payload.id
        ),
      };
    case types.CHECK_IN_BY_TABLE:
      return {
        ...state,
        isCheckInByTable: true,
      };
    case types.SET_EDITED_PRODUCT:
      return {
        ...state,
        editedProduct: action.payload.product,
      };

    default:
      return state;
  }
}
