import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import Header from '../../../components/Header';
import Loader from '../../../components/Loader';
import DropdownMenu from '../../../components/common/DropdownMenu';
import staffAPI from '../../../api/staff';
import restaurantsAPI from '../../../api/restaurants';
import Icon from '../../../components/common/Icon';
import { showConfirmPhoneModal } from '../../../redux/reducers/auth/actions';

const Staff = ({ restaurant, showLoader, showConfirmPhoneModal }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [staffData, setStaffData] = useState([]);

  useEffect(() => {
    if (restaurant?.id) {
      restaurantsAPI
        .getStaff(restaurant.id)
        .then(({ data }) => {
          setStaffData(data);
        })
        .catch((e) => {
          console.error(e);
          if (e.code === 403) {
            showConfirmPhoneModal();
          }
        });
    }
    // eslint-disable-next-line
  }, [restaurant, showConfirmPhoneModal]);

  const handleGoBack = () => {
    history.push(`/restaurant/${restaurant.slug}/control-center`);
  };

  const handleEditWorkerClick = (workerId) => {
    history.push(
      `/restaurant/${restaurant.slug}/control-center/staff/${workerId}`
    );
  };

  const handleDeleteWorkerClick = async (worker) => {
    Swal.fire({
      heightAuto: false,
      title: `${t('control_center.staff.remove_message')} “${worker.name}”?`,
      showCancelButton: true,
      confirmButtonText: t('swal.yes'),
      cancelButtonText: t('swal.no'),
      showClass: {
        popup: 'animate__animated modalFadeInUp',
      },
      hideClass: {
        popup: 'animate__animated modalFadeOutDown',
      },
      preConfirm: () =>
        staffAPI.remove(worker.id).catch((error) => console.error(error)),
    }).then((data) => {
      if (data.value) {
        setStaffData((staffList) =>
          staffList.filter((staffItem) => staffItem.id !== worker.id)
        );
      }
    });
  };

  if (showLoader) {
    return <Loader />;
  }

  return (
    <div className="page staff">
      <Header
        title={t('control_center.staff_title')}
        leftIcon="back"
        onLeftIconClick={handleGoBack}
      />
      <div className="staff__content">
        <div className="staff__list">
          {staffData.map((worker) => (
            <div key={worker.id} className="staff__item staff-item">
              <DropdownMenu
                className="staff-item__dropdown"
                options={[
                  {
                    label: t('control_center.staff.edit'),
                    onClick: () => handleEditWorkerClick(worker.id),
                  },
                  {
                    label: t('control_center.staff.remove'),
                    onClick: () => handleDeleteWorkerClick(worker),
                  },
                ]}
              />
              {worker.name && <p className="staff-item__name">{worker.name}</p>}
              {worker.email && (
                <p className="staff-item__email">{worker.email}</p>
              )}
              {worker.role && (
                <p className="staff-item__position">{worker.role}</p>
              )}
            </div>
          ))}
        </div>
        <Link
          className="staff__link restaurant__link"
          to={`/restaurant/${restaurant.slug}/control-center/staff/create`}
        >
          <Icon name="plus" />
          {t('control_center.staff.add')}
        </Link>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  restaurant: state.restaurant.restaurantData,
  showLoader: state.restaurant.isLoading,
});

export default connect(mapStateToProps, { showConfirmPhoneModal })(Staff);
