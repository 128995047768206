import React from 'react';
import { useTranslation } from 'react-i18next';
import DropdownMenu from './common/DropdownMenu';

const OptionGroup = ({
  group,
  handleRadioButtonClick,
  selectedGroup,
  productId,
  handleEditClick,
  handleDeleteClick,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className="option-group"
      onClick={() => handleRadioButtonClick(group.id)}
    >
      <div className="option-group__name">{group.name}</div>
      {group.external_name && (
        <div className="option-group__tecnical-name">{group.external_name}</div>
      )}
      <ul className="option-group__options-list">
        {group.options.map((option) => (
          <li className="option-group__options-list__item">{option.name}</li>
        ))}
      </ul>
      <div className="option-group__options-list__controls">
        {productId ? (
          <label className="container option-group__options-list__radio">
            <input
              type="radio"
              name="groupSelect"
              checked={group.id === selectedGroup}
            />
            <span
              className="checkmark"
              onClick={() => handleRadioButtonClick(group.id)}
            />
          </label>
        ) : (
          <DropdownMenu
            options={[
              {
                label: t('option_groups.edit'),
                onClick: () => handleEditClick(group.id),
              },
              {
                label: t('option_groups.remove'),
                onClick: () => handleDeleteClick(group.id),
              },
            ]}
          />
        )}
      </div>
    </div>
  );
};

export default OptionGroup;
