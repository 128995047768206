import axios from 'axios';

// Default request headers
const defaultHeaders = {
  'Accept-Language': window.localStorage.getItem('i18nextLng'),
  'Content-Type': 'application/json',
};

function getHeaders(useAuth = false) {
  const headers = {
    ...defaultHeaders,
    'Accept-Language': window.localStorage.getItem('i18nextLng'),
  };

  if (useAuth) {
    const access = localStorage.getItem('token');
    if (access !== null) {
      headers.Authorization = `Bearer ${access}`;
    }
  }

  return headers;
}

/**
 * Generate request config
 * @param {Object} cfg
 * @return {Object}
 */
function getConfig(cfg = {}) {
  return {
    baseURL: process.env.REACT_APP_API_URL,
    ...cfg,
    headers: {
      ...cfg.headers,
      ...getHeaders(cfg.useAuth),
      'Accept-Language':
        cfg.language ?? window.localStorage.getItem('i18nextLng'),
    },
    withCredentials: true,
  };
}

/**
 * Refresh token request
 * @return {Promise<any>}
 */
export function refreshToken() {
  return new Promise((res, rej) => {
    const refresh = localStorage.getItem('refreshToken');

    if (refresh === null) {
      rej(Error('Refresh token is not set. User is not logged in.'));
    }

    axios
      .post(`${process.env.REACT_APP_API_URL}/accounts/auth/refresh/`, {
        refresh,
      })
      .then((response) => {
        const { access, refresh: serverToken } = response.data;
        localStorage.setItem('token', access);
        localStorage.setItem('refreshToken', serverToken);
        res(response.data);
      })
      .catch((e) => {
        rej(e);
      });
  });
}

/**
 * Request handler
 * @param {Object} cfg
 * @return {Promise<any>}
 */
function request(cfg = {}) {
  return new Promise((res, rej) => {
    axios(getConfig(cfg))
      .then((result) => res(result))
      .catch((e) => {
        if (e.response && e.response.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('refreshToken');
          window.location = '/';
          refreshToken()
            .then(() => {
              axios(getConfig(cfg))
                .then((response) => res(response))
                .catch((refreshError) => rej(refreshError));
            })
            .catch((error) => rej(error));
        } else {
          rej(e);
        }
      });
  });
}

/**
 * Make GET request
 * @param {string} url
 * @param {string} language
 * @param {Object} query
 * @param {boolean} useAuth
 * @return {Promise<any>}
 */
export function get(url, query = {}, useAuth = false, language) {
  return request({
    method: 'get',
    url,
    params: query,
    useAuth,
    language,
  });
}

/**
 * Make POST request
 * @param {string} url
 * @param {Object} data
 * @param {Object} query
 * @param {boolean} useAuth
 * @return {Promise<any>}
 */
export function post(url, data = {}, query = {}, useAuth = false) {
  return request({
    method: 'post',
    url,
    params: query,
    data,
    useAuth,
  });
}

/**
 * Make PATCH request
 * @param {string} url
 * @param {Object} data
 * @param {Object} query
 * @param {boolean} useAuth
 * @return {Promise<any>}
 */
export function patch(url, data = {}, query = {}, useAuth = false, language) {
  return request({
    method: 'patch',
    url,
    params: query,
    data,
    useAuth,
    language,
  });
}

/**
 * Make PUT request
 * @param {string} url
 * @param {Object} data
 * @param {Object} query
 * @param {boolean} useAuth
 * @return {Promise<any>}
 */
export function put(url, data = {}, query = {}, useAuth = false, language) {
  return request({
    method: 'put',
    url,
    params: query,
    data,
    useAuth,
    language,
  });
}

/**
 * Make DELETE request
 * @param {string} url
 * @param {Object} data
 * @param {Object} query
 * @param {boolean} useAuth
 * @return {Promise<any>}
 */
export function del(url, data = {}, query = {}, useAuth = false) {
  return request({
    method: 'delete',
    url,
    params: query,
    data,
    useAuth,
  });
}
