import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';

const Select = ({ valueId, label, className, options, onSelect, error }) => {
  const [isOpen, toggleDropdown] = useState(false);
  const ref = useRef();

  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      toggleDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  });

  const onDropdownSelect = (option) => {
    onSelect(option);
    toggleDropdown(false);
  };

  const onArrowDropdownClick = () => {
    toggleDropdown(!isOpen);
  };

  // eslint-disable-next-line eqeqeq
  const value = options.find((item) => item.id === valueId);

  return (
    <div
      className={`select ${isOpen ? 'select_open' : ''} ${
        error ? 'select__error' : ''
      } ${className || ''}`}
      onClick={onArrowDropdownClick}
      ref={ref}
    >
      {label && value?.name && <div className="select__label">{label}</div>}
      <div className="select__value">{value?.name || label}</div>
      <Icon name={isOpen ? 'topArrow' : 'downArrow'} />
      <div
        className={`select__dropdown ${isOpen ? 'select__dropdown_open' : ''}`}
      >
        {options.map((option) => (
          <div
            className={`select__option ${
              option.name === value?.name ? 'select__option_active' : ''
            }`}
            key={option.id}
            onClick={() => onDropdownSelect(option)}
          >
            {option.name}
          </div>
        ))}
      </div>
      {error && <p className="select__error-message">{error}</p>}
    </div>
  );
};

export default Select;

Select.PropsTypes = {
  onSelect: PropTypes.func,
  value: PropTypes.oneOf([PropTypes.shape, PropTypes.string]),
  options: PropTypes.array,
  error: PropTypes.string,
};

Select.defaultProps = {
  onSelect: () => {},
  value: '',
  options: [],
  error: '',
};
