import { get, post, put, del, patch } from '../utils/axios';

const optionsAPI = {
  create: async (data) => {
    try {
      return await post('/options/', data, null, true);
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },

  update: async (data) => {
    try {
      return await put(`/options/${data.id}/`, data, null, true);
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },

  patch: async ({ id, ...data }) => {
    try {
      return await patch(`/options/${id}/`, data, null, true);
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },

  get: async (id) => {
    try {
      return await get(`/options/${id}/`, null, true);
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },

  remove: async (id) => {
    try {
      return await del(`/options/${id}/`, null, true, true);
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },

  /**
   * DnD
   * @param id
   * @param origin - old index
   * @param destination - new index
   * @returns {Promise<*>}
   */
  move: async (id, origin, destination) => {
    try {
      return await put(
        `/products/${id}/move/`,
        { origin, destination },
        null,
        true
      );
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },
};

export default optionsAPI;
