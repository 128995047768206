import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import ProductOption from '../components/ProductOption';
import Icon from '../components/common/Icon';
import restaurantsAPI from '../api/restaurants';
import Loader from '../components/Loader';
import Header from '../components/Header';
import optionsAPI from '../api/options';
import { showConfirmPhoneModal } from '../redux/reducers/auth/actions';

const RestaurantOptions = ({
  restaurant,
  showLoader,
  showConfirmPhoneModal,
}) => {
  const history = useHistory();
  const [restaurantOptions, setRestaurantOptions] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (restaurant?.id) {
      restaurantsAPI
        .getOptions(restaurant.id, window.localStorage.getItem('language'))
        .then(({ data }) => {
          if (data.length > 0) {
            setRestaurantOptions(data);
          } else {
            history.replace(`/restaurant/${restaurant.slug}/option/create`);
          }
        })
        .catch((e) => {
          console.error(e);
          if (e.code === 403) {
            showConfirmPhoneModal();
          }
        });
    }
    // eslint-disable-next-line
  }, [history, restaurant, showConfirmPhoneModal]);

  const handleEdit = (option) => {
    history.push({
      pathname: `/restaurant/${restaurant.slug}/option/edit`,
      state: { id: option.id },
    });
  };

  const handleRemove = (option) => {
    Swal.fire({
      heightAuto: false,
      title: `${t('option.remove')} “${option.name}”?`,
      showCancelButton: true,
      confirmButtonText: t('swal.yes'),
      cancelButtonText: t('swal.no'),
      showClass: {
        popup: 'animate__animated modalFadeInUp',
      },
      hideClass: {
        popup: 'animate__animated modalFadeOutDown',
      },
      preConfirm: () =>
        optionsAPI.remove(option.id).catch((error) => console.error(error)),
    }).then((data) => {
      if (data.value) {
        setRestaurantOptions(
          restaurantOptions.filter((item) => item.id !== option.id)
        );
      }
    });
  };

  const handleHide = (option) => {
    optionsAPI
      .patch({ id: option.id, is_active: !option.is_active })
      .then(() => {
        restaurantsAPI.getOptions(restaurant.id).then(({ data }) => {
          setRestaurantOptions(data);
        });
      });
  };

  const handleBackClick = () => {
    history.push(`/restaurant/${restaurant.slug}`);
  };

  if (showLoader) {
    return <Loader />;
  }

  return (
    <div className="page restaurant-options">
      <Header
        title={t('options.title')}
        leftIcon="back"
        onLeftIconClick={handleBackClick}
      />

      <div className="restaurant-options__content">
        <div className="restaurant-options__list">
          {restaurantOptions.map((option) => (
            <ProductOption
              key={option.id}
              option={option}
              isRestaurantOptions
              onEditClick={handleEdit}
              onDeleteClick={handleRemove}
              onHideClick={handleHide}
            />
          ))}
        </div>
        <Link
          to={`/restaurant/${restaurant.slug}/option/create`}
          className="restaurant-options__content__link"
        >
          <Icon name="plus" />
        </Link>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  restaurant: state.restaurant.restaurantData,
  showLoader: state.restaurant.isLoading,
});

export default connect(mapStateToProps, { showConfirmPhoneModal })(
  RestaurantOptions
);
