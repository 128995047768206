import React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from './common/Icon';

const RestaurantSubscriptionsBlock = ({ subscription, className }) => {
  const handlePayClick = (url) => {
    window.location.href = url;
  };
  const { t } = useTranslation();

  return (
    <div className={`subscription-block ${className && className}`}>
      {subscription.buttons.map((subscriptionType) => (
        <div
          className="subscription-block__container"
          key={subscriptionType.name}
          onClick={() => handlePayClick(subscriptionType.url[0])}
        >
          {subscription.is_expired && !subscription.is_trial ? (
            <div className="subscription-block__text">
              <div className="subscription-block__title subscription-block__title_error">
                <Icon name="info" />
                {t('restaurant_page.subscription_block.payment_error')}
              </div>
              <div className="subscription-block__description">{`${t(
                'restaurant_page.subscription_block.title'
              )} ${subscriptionType.amount}${subscriptionType.currency}`}</div>
            </div>
          ) : (
            <div className="subscription-block__text">
              <div className="subscription-block__title">{`${t(
                'restaurant_page.subscription_block.title'
              )} ${subscriptionType.amount}${subscriptionType.currency}`}</div>
              <div className="subscription-block__description">{`${t(
                'restaurant_page.subscription_block.description'
              )} ${
                subscriptionType.name === 'Standart'
                  ? t('restaurant_page.subscription_block.month')
                  : t('restaurant_page.subscription_block.year')
              }`}</div>
            </div>
          )}
          <Icon
            name="back"
            className="subscription-block__pay-button"
            onClick={() => handlePayClick(subscriptionType.url[0])}
          />
        </div>
      ))}
    </div>
  );
};

export default RestaurantSubscriptionsBlock;
