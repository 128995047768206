import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Icon from './common/Icon';
import serviceAPI from '../api/service';
import AnimateNumber from './common/AnimateNumber';

const Ring = ({ isShowFeedBack, isShowWaiter, isDineIn }) => {
  const { t } = useTranslation();

  const SECONDS_TO_CALL_WAITER = 180; // 3 minute
  const WAITER_OPTIONS = [
    { name: 'card', label: t('waite_options.card') },
    { name: 'cash', label: t('waite_options.cash') },
    { name: 'waiter', label: t('waite_options.waiter') },
  ];
  const [isOpen, toggle] = useState(false);

  const [seconds, setSeconds] = useState(SECONDS_TO_CALL_WAITER);
  const [isActive, setIsActive] = useState(false);
  const history = useHistory();
  const { slug } = useParams();

  useEffect(() => {
    let interval = null;
    if (isActive) {
      interval = setInterval(() => {
        setSeconds((seconds) => seconds - 1);
      }, 1000);
      if (seconds === 0) {
        clearInterval(interval);
        setIsActive(false);
      }
    } else if (!isActive && seconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);

  const handleToggleRing = (isOpen) => {
    if (!isActive) {
      toggle(isOpen);
    } else {
      const ringIcon = document.querySelector('.ring__ring-icon');
      ringIcon.style.animation = 'ring 3s 1 ease';
      setTimeout(() => {
        ringIcon.style = 'none';
      }, 1000);
    }
  };

  const handleItemClick = async (action) => {
    try {
      switch (action) {
        case 'cash':
          await serviceAPI.requestCashBill();
          break;
        case 'card':
          await serviceAPI.requestCardBill();
          break;
        case 'waiter':
          await serviceAPI.requestWaiter();
          break;
        case 'feedback':
          history.push(`/restaurant/${slug}/feedback`);
          break;
        default:
      }
    } catch (e) {
      console.error(e);
    }

    toggle(false);
    setSeconds(SECONDS_TO_CALL_WAITER);
    setIsActive(true);
  };

  if (!isShowFeedBack && !isShowWaiter) {
    return null;
  }

  return (
    <div
      className={`ring animate__animated animate__fadeInLeft  ${
        isActive ? 'ring_active' : ''
      }`}
    >
      <ul
        className={`ring__list ${
          !isActive && isOpen ? 'ring__list_show' : 'ring__list_hide'
        }`}
      >
        <>
          {isShowFeedBack && (
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
            <li
              key="feedback"
              className={`ring__list-item animate__animated ${
                isOpen ? 'animate__fadeInUp' : 'animate__fadeOutDown'
              }`}
              onClick={() => handleItemClick('feedback')}
            >
              {t('feedback.title')}
            </li>
          )}
          {isShowWaiter &&
            isDineIn &&
            WAITER_OPTIONS.map((option) => (
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
              <li
                key={option.name}
                className={`ring__list-item animate__animated ${
                  isOpen ? 'animate__fadeInUp' : 'animate__fadeOutDown'
                }`}
                onClick={() => handleItemClick(option.name)}
              >
                {option.label}
              </li>
            ))}
        </>
      </ul>
      <div className="ring__button" onClick={() => handleToggleRing(!isOpen)}>
        {isOpen ? (
          <Icon name="close" />
        ) : (
          <>
            <Icon
              className={
                isActive ? 'ring__ring-icon  ring__ring-icon_waiting' : ''
              }
              name={isActive ? 'filledRing' : 'ring'}
            />
            {isActive && (
              <span className="ring__timer">
                <AnimateNumber value={seconds} />
              </span>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Ring;
