import React, { useEffect, useState } from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
} from 'react-places-autocomplete';
import PropTypes from 'prop-types';
import { loadScript } from '../../utils/loadScripts';
import Loader from '../Loader';

const GooglePlacesAutocomplete = ({
  name,
  value,
  placeholder,
  onBlur,
  onChange,
  error,
  className,
}) => {
  const [loadedScripts, setLoadedScripts] = useState(false);
  const [address, setAddress] = useState('');
  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&libraries=places&language=uk`
    ).then(() => {
      setLoadedScripts(true);
    });
  }, []);

  useEffect(() => {
    if (value && loadedScripts) {
      geocodeByAddress(value).then(([result]) => {
        setAddress(result.formatted_address);
      });
    }
    // eslint-disable-next-line
    }, [value, loadedScripts]);

  const onInputChange = (address) => {
    setAddress(address);
    if (address === '') {
      onChange(address);
    }
  };

  const onSelect = (address) => {
    geocodeByAddress(address)
      .then(([googleAddress]) => {
        setAddress(googleAddress.formatted_address);
        onChange(googleAddress);
      })
      .catch((error) => console.error('Error', error));
  };
  if (!loadedScripts) {
    return <Loader />;
  }

  return (
    <PlacesAutocomplete
      value={address}
      onChange={onInputChange}
      onSelect={onSelect}
      searchOptions={{
        componentRestrictions: { country: 'ua' },
        // eslint-disable-next-line no-undef
        location: new google.maps.LatLng(50.402, 30.392),
        // eslint-disable-next-line no-undef
        origin: new google.maps.LatLng(50.402, 30.392),
        radius: 30 * 1000,
        types: ['address'],
      }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div
          className={`input ${error ? 'input__error' : ''} ${className || ''}`}
        >
          <div className="input__wrapper">
            <div className="input__field">
              <input
                {...getInputProps({
                  name,
                  onBlur,
                  className: `input__component ${className || ''} `,
                })}
                placeholder={placeholder}
              />
              {placeholder && (
                <label htmlFor={name} className="input__label">
                  {placeholder}
                </label>
              )}
            </div>

            {suggestions.length > 0 && (
              <div className="google-place-auto-complete__dropdown">
                {loading && (
                  <div className="google-place-auto-complete__dropdown-item">
                    Loading...
                  </div>
                )}
                {suggestions.map((suggestion, index) => (
                  <div
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    {...getSuggestionItemProps(suggestion)}
                    className="google-place-auto-complete__dropdown-item"
                  >
                    {suggestion.description}
                  </div>
                ))}
              </div>
            )}
          </div>
          {error && <p className="input__error-message">{error}</p>}
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default GooglePlacesAutocomplete;

GooglePlacesAutocomplete.defaultProps = {
  onBlur: () => {},
};

GooglePlacesAutocomplete.PropsTypes = {
  onBlur: PropTypes.func,
};
