import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from './common/Button';

const PosSystemSyncButton = ({ syncMenuRequest }) => {
  const [syncing, setSyncing] = useState(false);
  const [syncingSuccess, setSyncingSuccess] = useState(false);
  const { t } = useTranslation();

  const syncMenu = async () => {
    setSyncing(true);
    setSyncingSuccess(false);
    try {
      await syncMenuRequest();
      setSyncingSuccess(true);
    } catch (e) {
      console.error(e);
    }
    document.getElementById('syncing-succeeded').onanimationend = () =>
      setSyncing(false);
  };
  const syncButtonContent = () => {
    if (syncing) {
      return syncingSuccess ? (
        <div className="sync-button__syncing-success">
          <svg
            id="syncing-succeeded"
            width="35"
            height="26"
            viewBox="0 0 35 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.2 20.4L4.2 13.4C3.4268 12.6268 2.1732 12.6268 1.4 13.4C0.626801 14.1732 0.626802 15.4268 1.4 16.2L9.78579 24.5857C10.5668 25.3668 11.8332 25.3668 12.6142 24.5857L33.8 3.39995C34.5732 2.62675 34.5732 1.37315 33.8 0.599951C33.0268 -0.173248 31.7732 -0.173247 31 0.599952L11.2 20.4Z"
              fill="white"
            />
          </svg>
        </div>
      ) : (
        <div className="sync-button__syncing">
          <svg
            width="46"
            height="36"
            viewBox="0 0 46 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M23.0001 35.9999C27.2001 35.9999 31.0001 34.5999 34.0001 32.1999C35.0001 31.3999 35.0001 29.9999 34.2001 29.1999C33.4001 28.3999 32.4001 28.3999 31.6001 28.9999C29.2001 30.9999 26.2001 31.9999 23.0001 31.9999C16.8001 31.9999 11.4001 27.9999 9.60007 22.3999L12.2001 23.9999C13.4001 24.3999 14.6001 23.9999 15.0001 22.9999C15.6001 21.9999 15.2001 20.7999 14.2001 20.1999L7.20007 16.1999C6.20007 15.5999 5.00007 15.9999 4.40007 16.9999L0.400072 23.9999C-0.199928 24.9999 0.200072 26.1999 1.20007 26.7999C2.20007 27.3999 3.40007 26.9999 4.00007 25.9999L5.60007 23.1999C8.00007 30.5999 14.8001 35.9999 23.0001 35.9999Z"
              fill="white"
            />
            <path
              d="M44.6002 9.2C43.6002 8.6 42.4002 9 41.8002 10L40.2002 12.8C38.0002 5.4 31.2002 0 23.0002 0C18.8002 0 15.0002 1.4 12.0002 3.8C11.0002 4.6 11.0002 6 11.8002 6.8C12.6002 7.6 13.6002 7.6 14.4002 7C16.8002 5 19.8002 4 23.0002 4C29.2002 4 34.6002 8 36.4002 13.6L33.8002 12C32.6002 11.6 31.4002 12 31.0002 13C30.4002 14 30.8002 15.2 31.8002 15.8L38.8002 19.8C39.8002 20.4 41.0002 20 41.6002 19L45.6002 12C45.8002 10.8 45.6002 9.6 44.6002 9.2Z"
              fill="white"
            />
          </svg>
        </div>
      );
    }
    if (syncingSuccess)
      return (
        <div className="sync-button__syncing-text sync-button__syncing-text_resync">
          {t('pos_system.re-sync')}
        </div>
      );
    return (
      <div className="sync-button__syncing-text">{t('pos_system.sync')}</div>
    );
  };

  return (
    <Button className="sync-button" onClick={syncMenu}>
      {syncButtonContent()}
    </Button>
  );
};

export default PosSystemSyncButton;
