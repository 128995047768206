import React from 'react';
import STROKE_COLOR from './colors';

const fieldIcons = {
  clear: (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 0C3.36 0 0 3.36 0 7.5C0 11.64 3.36 15 7.5 15C11.64 15 15 11.64 15 7.5C15 3.36 11.64 0 7.5 0Z"
        fill="#160B24"
      />
      <path
        d="M11 4.705L10.295 4L7.5 6.795L4.705 4L4 4.705L6.795 7.5L4 10.295L4.705 11L7.5 8.205L10.295 11L11 10.295L8.205 7.5L11 4.705Z"
        fill="white"
      />
    </svg>
  ),

  showPassword: (
    <svg
      width="24"
      height="16"
      viewBox="0 0 24 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 13C9.19995 13 6.99995 10.8 6.99995 8C6.99995 5.2 9.19995 3 12 3C14.8 3 17 5.2 17 8C17 10.8 14.8 13 12 13ZM12 5C10.3 5 8.99995 6.3 8.99995 8C8.99995 9.7 10.3 11 12 11C13.7 11 15 9.7 15 8C15 6.3 13.7 5 12 5ZM12 2C16 2 19.6 4.4 21.2 8C19.6 11.6 16 14 12 14C7.99995 14 4.39995 11.6 2.79995 8C4.39995 4.4 7.99995 2 12 2ZM12 0C6.79995 0 2.29995 3.3 0.699951 8C2.29995 12.7 6.79995 16 12 16C17.2 16 21.6999 12.7 23.2999 8C21.6999 3.3 17.2 0 12 0Z"
        fill="#8B8592"
      />
    </svg>
  ),
  hidePassword: (
    <svg
      width="24"
      height="20"
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 10C17 10.2 17 10.5 16.9 10.7L14.1 7.9L11.3 5.1C11.5 5 11.8 5 12 5C14.8 5 17 7.2 17 10ZM12 2C10.9 2 9.79995 2.2 8.69995 2.5L10.4 4.2C10.9 4 11.5 4 12 4C16 4 19.6 6.4 21.2 10C20.7 11.1 20 12.1 19.2 12.9L20.6 14.3C21.8 13.1 22.6999 11.6 23.2999 10C21.6999 5.3 17.2 2 12 2ZM21.2 17.8L19.7999 19.2L17.4 16.8C15.7 17.5 13.9 18 12 18C6.79995 18 2.29995 14.7 0.699951 10C1.49995 7.7 2.99995 5.7 4.89995 4.3L2.79995 2.2L4.19995 0.800003L21.2 17.8ZM9.29995 8.7C9.09995 9.1 8.99995 9.5 8.99995 10C8.99995 11.6 10.4 13 12 13C12.5 13 12.9 12.9 13.3 12.7L9.29995 8.7ZM15.8 15.2L14.7 14.1C14 14.7 13 15 12 15C9.19995 15 6.99995 12.8 6.99995 10C6.99995 9 7.29995 8 7.79995 7.2L6.39995 5.8C4.89995 6.8 3.59995 8.3 2.89995 10C4.49995 13.6 8.09995 16 12.1 16C13.3 16 14.6 15.7 15.8 15.2Z"
        fill="#8B8592"
      />
    </svg>
  ),

  topArrow: (
    <svg
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6 7.4L12 6L6 -2.62268e-07L-2.62268e-07 6L1.4 7.4L6 2.8L10.6 7.4Z"
        fill="#160B24"
      />
    </svg>
  ),
  downArrow: (
    <svg
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.4 0.6L-3.57639e-07 2L6 8L12 2L10.6 0.6L6 5.2L1.4 0.6Z"
        fill="#160B24"
      />
    </svg>
  ),

  topCircleArrow: (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="17"
        cy="17"
        r="16.5"
        transform="rotate(-180 17 17)"
        fill="white"
        stroke={STROKE_COLOR}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7071 13.7071C17.3166 13.3166 16.6834 13.3166 16.2929 13.7071L11.7 18.3C11.3134 18.6866 11.3134 19.3134 11.7 19.7C12.0866 20.0866 12.7134 20.0866 13.1 19.7L17 15.8L20.9 19.7C21.2866 20.0866 21.9134 20.0866 22.3 19.7C22.6866 19.3134 22.6866 18.6866 22.3 18.3L17.7071 13.7071Z"
        fill="#8B8592"
      />
    </svg>
  ),
  downCircleArrow: (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="17" cy="17" r="16.5" fill="white" stroke={STROKE_COLOR} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.2929 20.2929C16.6834 20.6834 17.3166 20.6834 17.7071 20.2929L22.3 15.7C22.6866 15.3134 22.6866 14.6866 22.3 14.3C21.9134 13.9134 21.2866 13.9134 20.9 14.3L17 18.2L13.1 14.3C12.7134 13.9134 12.0866 13.9134 11.7 14.3C11.3134 14.6866 11.3134 15.3134 11.7 15.7L16.2929 20.2929Z"
        fill="#8B8592"
      />
    </svg>
  ),
  pen: (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="17" cy="17" r="16.5" fill="white" stroke={STROKE_COLOR} />
      <path
        d="M19.6 10.0998L9 20.7998V24.9998H13.2L23.9 14.3998L19.6 10.0998ZM12.4 22.9998H11V21.5998L19.6 12.9998L21 14.3998L12.4 22.9998ZM26.7 11.5998L25.3 12.9998L21 8.6998L22.4 7.2998L26.7 11.5998Z"
        fill="#8B8592"
      />
    </svg>
  ),
  apply: (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="17" cy="17" r="16.5" fill="white" stroke={STROKE_COLOR} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 21.2L9.80002 17L8.40002 18.4L14 24L26 12L24.6 10.6L14 21.2Z"
        fill="#8B8592"
      />
    </svg>
  ),
  delete: (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="17" cy="17" r="16.5" fill="white" stroke={STROKE_COLOR} />
      <path
        d="M21 14V24H13V14H21ZM23 12H11V24C11 25.1 11.9 26 13 26H21C22.1 26 23 25.1 23 24V12ZM24 9H20.4L19.4 8H14.6L13.6 9H10V11H14.4L15.4 10H18.6L19.6 11H24V9Z"
        fill="#8B8592"
      />
    </svg>
  ),
  search: (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.8 10.3C12.6 9.2 13 7.9 13 6.5C13 2.9 10.1 0 6.5 0C2.9 0 0 2.9 0 6.5C0 10.1 2.9 13 6.5 13C7.9 13 9.3 12.5 10.3 11.8L15.3 16.8L16.7 15.4L11.8 10.3ZM3.3 9.7C1.5 7.9 1.5 5.1 3.3 3.3C5.1 1.5 7.9 1.5 9.7 3.3C11.5 5.1 11.5 7.9 9.7 9.7C7.9 11.4 5.1 11.4 3.3 9.7Z"
        fill="#8B8592"
      />
    </svg>
  ),
};

export default fieldIcons;
