import React, { useState } from 'react';

import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';
import { Redirect, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Icon from '../../components/common/Icon';
import Button from '../../components/common/Button';
import { socialLogin } from '../../redux/reducers/auth/actions';
import { isMobile } from '../../redux/reducers/ui/selectors';
import Loader from '../../components/Loader';

const Start = ({ isAuthenticated, socialLogin }) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();

  if (isAuthenticated) {
    return <Redirect to="/restaurants" />;
  }
  const responseSuccessGoogle = (res) => {
    socialLogin('google', res.accessToken);
  };
  const responseSuccessFacebook = (res) => {
    if (res.accessToken) {
      socialLogin('facebook', res.accessToken);
    }
  };

  const onSocialIconClick = (renderProps) => {
    setLoading(true);
    renderProps.onClick();
  };

  const responseFailureGoogle = (res) => {
    setLoading(false);
    console.error(res);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="start" data-aos="fade-up" data-aos-delay="300">
      <Icon className="start__logo" name="logo" withHover={false} />
      <div className="start__content">
        <p className="start__title">{t('login.hint')}</p>
        <div className="start__social-btn-list">
          <FacebookLogin
            appId={process.env.REACT_APP_FACEBOOK_OAUTH_KEY}
            callback={responseSuccessFacebook}
            render={(renderProps) => (
              <Icon
                onClick={() => onSocialIconClick(renderProps)}
                className="start__social-btn"
                name="fb"
              />
            )}
          />
          <GoogleLogin
            clientId={process.env.REACT_APP_GOOGLE_OAUTH_KEY}
            onSuccess={responseSuccessGoogle}
            onFailure={responseFailureGoogle}
            render={(renderProps) => (
              <Icon
                onClick={() => onSocialIconClick(renderProps)}
                disabled={renderProps.disabled}
                className="start__social-btn"
                name="google"
              />
            )}
          />
        </div>

        <p className="start__text">{t('login.or')}</p>
        <Button
          className="start__button"
          onClick={() => history.push('/enter-with-email')}
        >
          {t('login.with_email')}
        </Button>
      </div>
      <footer className="start__footer">
        <p>
          {t('login.privacy_rules_hint')}{' '}
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://api.fitel.io/media/fitelio/uploads/3289f89b-1ec1-4d41-b3a8-8b7d8d2ea672/%D0%9F%D0%BE%D0%BB%D1%96%D1%82%D0%B8%D0%BA%D0%B0_%D0%BF%D1%80%D0%B8%D0%B2%D0%B0%D1%82%D0%BD%D0%BE%D1%81%D1%82%D1%96.PROCESSING_POLICY.pdf"
          >
            {t('login.usage_rules')}
          </a>{' '}
          {t('login.and')}{' '}
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://api.fitel.io/media/fitelio/uploads/3289f89b-1ec1-4d41-b3a8-8b7d8d2ea672/%D0%9F%D0%BE%D0%BB%D1%96%D1%82%D0%B8%D0%BA%D0%B0_%D0%BF%D1%80%D0%B8%D0%B2%D0%B0%D1%82%D0%BD%D0%BE%D1%81%D1%82%D1%96.PROCESSING_POLICY.pdf"
          >
            {t('login.privacy_policy')}
          </a>
        </p>
      </footer>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isMobile: isMobile(state),
});

export default connect(mapStateToProps, { socialLogin })(Start);
