import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Input from '../../components/common/Input';
import DropdownMenu from '../../components/common/DropdownMenu';
import Icon from '../../components/common/Icon';

const RestaurantMenuItem = ({
  onApplyChanges,
  isShowControls,
  onMenuHideClick,
  onClick,
  handleDelete,
  menu,
  isEdit = false,
  closeAddMenu,
  currentLang,
}) => {
  const [inputValue, setInputValue] = useState(menu[`name_${currentLang}`]);
  const [editState, toggleEdit] = useState(isEdit);
  const { t } = useTranslation();

  if (isShowControls) {
    return (
      <div
        onClick={() => !editState && onClick()}
        className="restaurant__menu-list-item restaurant-menu-list-item"
      >
        {editState ? (
          <Input
            className="restaurant-menu-list-item__input"
            onChange={(value) => setInputValue(value)}
            placeholder={t('restaurant_page.menu_name_placeholder')}
            value={inputValue}
            rightIcon="apply"
            rightIconClick={() => {
              onApplyChanges(inputValue);
              toggleEdit(false);
            }}
            rightSecondIcon="close"
            rightSecondIconClick={() => {
              if (!menu[`name_${currentLang}`]) {
                closeAddMenu();
              } else {
                toggleEdit(false);
                setInputValue(menu[`name_${currentLang}`]);
              }
            }}
          />
        ) : (
          <>
            <p className="restaurant-menu-list-item__name">
              {menu[`name_${currentLang}`] ?? menu.name}
            </p>
            <div className="restaurant-menu-list-item__icons">
              {!menu.is_active && (
                <Icon
                  name="eye"
                  onClick={(event) => onMenuHideClick(menu, event)}
                  className="restaurant-menu-list-item__hide-icon"
                />
              )}
              <DropdownMenu
                options={[
                  {
                    icon: 'pen',
                    label: t('restaurant_page.menu_edit'),
                    onClick: () => toggleEdit(true),
                  },
                  {
                    icon: 'delete',
                    label: t('restaurant_page.menu_remove'),
                    onClick: () => handleDelete(menu),
                  },
                  {
                    label: menu.is_active
                      ? t('restaurant_menu_page.menu_hide')
                      : t('restaurant_menu_page.menu_show'),
                    icon: 'hide',
                    onClick: () => onMenuHideClick(menu),
                  },
                ]}
              />
            </div>
          </>
        )}
      </div>
    );
  }
  return (
    <div
      onClick={onClick}
      className="restaurant__menu-list-item restaurant-menu-list-item"
    >
      <p className="restaurant-menu-list-item__name">
        {menu[`name_${currentLang}`] ?? menu.name}
      </p>
      <Icon
        name="back"
        className="restaurant-menu-list-item__button"
        onClick={onClick}
      />
    </div>
  );
};

RestaurantMenuItem.defaultProps = {
  closeAddMenu: () => {},
  menu: {},
};

RestaurantMenuItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  menu: PropTypes.object,
  closeAddMenu: PropTypes.func,
};

export default RestaurantMenuItem;
