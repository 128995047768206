const info = {
  takeAway: null,
  worker: null,
  order: null,
  review: null,
  delivery: null,
  'taxi-delivery': null,
  payment: null,
  options: null,
  option_group: null,
  option_group_disabled: null,
  required_group: null,
  options_amount: null,
  maxQuantity: null,
  pos_systems: null,
  iiko_payments: null,
};

export default info;
