export function convertFileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;

    reader.readAsDataURL(file);
  });
}

export function srcSet(photo) {
  if (typeof photo === 'string') {
    return null;
  }
  if (photo[400] && photo[1300]) {
    return photo && `${photo[400]} 400w, ${photo[1300]} 1300w`;
  }
  return '';
}

export function getImage(image) {
  if (!image) {
    return null;
  }
  if (image.content) {
    return image.content;
  }
  if (image[400]) {
    return image;
  }
  return null;
}
