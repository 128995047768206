import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Toggle from '../../components/common/Toggle';
import TextArea from '../../components/common/TextArea';
import Select from '../../components/common/Select';
import restaurantsAPI from '../../api/restaurants';
import Info from '../../components/common/Info';
import { updateRestaurantApi } from '../../redux/reducers/restaurant/actions';
import { showConfirmPhoneModal } from '../../redux/reducers/auth/actions';

const DELAY_OPTIONS = [
  { id: '15', name: '15' },
  { id: '30', name: '30' },
  { id: '60', name: '60' },
];
const INTERVAL_OPTIONS = [
  { id: '15', name: '15' },
  { id: '30', name: '30' },
  { id: '60', name: '60' },
];

const RestaurantDelivery = ({
  restaurant,
  showConfirmPhoneModal,
  setTakeAwayDeliveryData,
  takeAwayDeliveryData,
  controlCenterDisabled,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (restaurant?.id) {
      restaurantsAPI
        .getTakeAwayDelivery(restaurant.id)
        .then(({ data }) => {
          setTakeAwayDeliveryData(data);
        })
        .catch((e) => {
          console.error(e);
          if (e.code === 403) {
            showConfirmPhoneModal();
          }
        });
    }
    // eslint-disable-next-line
  }, [restaurant?.id, showConfirmPhoneModal]);

  const handleToggleChange = (toggleName) => {
    setTakeAwayDeliveryData({
      ...takeAwayDeliveryData,
      [toggleName]: !takeAwayDeliveryData[toggleName],
    });
  };

  const handleTextAreaChange = (value, type) => {
    setTakeAwayDeliveryData({
      ...takeAwayDeliveryData,
      [type]: {
        ...takeAwayDeliveryData[type],
        note_text: value,
      },
    });
  };

  const handleSelectChange = (option, type, selectName) => {
    setTakeAwayDeliveryData({
      ...takeAwayDeliveryData,
      [type]: {
        ...takeAwayDeliveryData[type],
        [selectName]: option.id,
      },
    });
  };

  return (
    <div className="page restaurant-delivery">
      <div className="restaurant-delivery__content">
        <div className="restaurant-delivery__block">
          <Info name="takeAway" className="restaurant-delivery__info-button" />
          <div className="restaurant-delivery__block-row">
            <Toggle
              checked={takeAwayDeliveryData.is_takeaway}
              label={t('control_center.delivery.takeaway_label')}
              onChange={() => handleToggleChange('is_takeaway')}
              disabled={controlCenterDisabled}
            />
          </div>

          {takeAwayDeliveryData.is_takeaway && (
            <>
              <div className="restaurant-delivery__block-row mt-6">
                <TextArea
                  label={t(
                    'control_center.delivery.is_delivery_note_text_label'
                  )}
                  value={takeAwayDeliveryData.takeaway.note_text}
                  onChange={(value) => handleTextAreaChange(value, 'takeaway')}
                />
              </div>

              <div className="restaurant-delivery__block-row mt-6">
                <Select
                  valueId={takeAwayDeliveryData.takeaway.date_delay_min}
                  label={t('control_center.delivery.date_delay_label')}
                  options={DELAY_OPTIONS}
                  className="mr-3"
                  onSelect={(option) =>
                    handleSelectChange(option, 'takeaway', 'date_delay_min')
                  }
                />
                <Select
                  valueId={takeAwayDeliveryData.takeaway.date_offset_min}
                  options={INTERVAL_OPTIONS}
                  label={t('control_center.delivery.date_offset_label')}
                  onSelect={(option) =>
                    handleSelectChange(option, 'takeaway', 'date_offset_min')
                  }
                />
              </div>
            </>
          )}
        </div>

        <div className="restaurant-delivery__block">
          <Info name="delivery" className="restaurant-delivery__info-button" />
          <div className="restaurant-delivery__block-row">
            <Toggle
              checked={takeAwayDeliveryData.is_delivery}
              label={t('control_center.delivery.is_delivery_label')}
              onChange={() => handleToggleChange('is_delivery')}
              disabled={controlCenterDisabled}
            />
          </div>

          {takeAwayDeliveryData.is_delivery && (
            <>
              <div className="restaurant-delivery__block-row mt-6">
                <TextArea
                  label={t(
                    'control_center.delivery.taxi_delivery_note_text_label'
                  )}
                  value={takeAwayDeliveryData.delivery.note_text}
                  onChange={(value) => handleTextAreaChange(value, 'delivery')}
                />
              </div>

              <div className="restaurant-delivery__block-row mt-6">
                <Select
                  valueId={takeAwayDeliveryData.delivery.date_delay_min}
                  label={t('control_center.delivery.date_delay_label')}
                  options={DELAY_OPTIONS}
                  className="mr-3"
                  onSelect={(option) =>
                    handleSelectChange(option, 'delivery', 'date_delay_min')
                  }
                />
                <Select
                  valueId={takeAwayDeliveryData.delivery.date_offset_min}
                  options={INTERVAL_OPTIONS}
                  label={t('control_center.delivery.date_offset_label')}
                  onSelect={(option) =>
                    handleSelectChange(option, 'delivery', 'date_offset_min')
                  }
                />
              </div>
            </>
          )}
        </div>

        <div className="restaurant-delivery__block">
          <Info
            name="taxi-delivery"
            className="restaurant-delivery__info-button"
          />
          <div className="restaurant-delivery__block-row">
            <Toggle
              checked={takeAwayDeliveryData.is_taxi_delivery}
              label={t('control_center.delivery.taxi_delivery_label')}
              onChange={() => handleToggleChange('is_taxi_delivery')}
              disabled={controlCenterDisabled}
            />
          </div>

          {takeAwayDeliveryData.is_taxi_delivery && (
            <>
              <div className="restaurant-delivery__block-row mt-6">
                <TextArea
                  label={t(
                    'control_center.delivery.taxi_delivery_note_text_label'
                  )}
                  value={takeAwayDeliveryData.taxi_delivery.note_text}
                  onChange={(value) =>
                    handleTextAreaChange(value, 'taxi_delivery')
                  }
                />
              </div>

              <div className="restaurant-delivery__block-row mt-6">
                <Select
                  valueId={takeAwayDeliveryData.taxi_delivery.date_delay_min}
                  label={t('control_center.delivery.date_delay_label')}
                  options={DELAY_OPTIONS}
                  className="mr-3"
                  onSelect={(option) =>
                    handleSelectChange(
                      option,
                      'taxi_delivery',
                      'date_delay_min'
                    )
                  }
                />
                <Select
                  valueId={takeAwayDeliveryData.taxi_delivery.date_offset_min}
                  options={INTERVAL_OPTIONS}
                  label={t('control_center.delivery.date_offset_label')}
                  onSelect={(option) =>
                    handleSelectChange(
                      option,
                      'taxi_delivery',
                      'date_offset_min'
                    )
                  }
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  restaurant: state.restaurant.restaurantData,
});

export default connect(mapStateToProps, {
  updateRestaurantApi,
  showConfirmPhoneModal,
})(RestaurantDelivery);
