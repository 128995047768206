import { CHANGE_WIDTH, FIRST_LOAD_COMPLETED, TOGGLE_SIDE_MENU } from './types';

export const toggleSideMenu = () => {
  return {
    type: TOGGLE_SIDE_MENU,
  };
};

export const changeAppWidth = (width) => {
  return {
    type: CHANGE_WIDTH,
    payload: { width },
  };
};

export const firstLoadComplete = () => {
  return {
    type: FIRST_LOAD_COMPLETED,
  };
};
