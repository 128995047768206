import React, { useMemo, useState } from 'react';
import { connectModal } from 'redux-modal';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Modal from '../common/Modal';
import Button from '../common/Button';
import Counter from '../common/Counter';
import CartOptionsHeader from '../CartOptionsHeader';

export const MODAL_NAME = 'cart-product-options-modal';

const CartProductOptionsModal = ({
  show,
  handleHide,
  product,
  onProductAdd,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const requiredGroups = product?.option_group_settings.filter(
    (settings) => settings.required
  );
  const { t } = useTranslation();

  const isSaveButtonDisabled = useMemo(() => {
    if (!requiredGroups) return false;

    const selectedRequiredGroups = requiredGroups?.filter((required) =>
      selectedOptions.find(
        (selected) => selected.group_id === required.group.id
      )
    );

    return selectedRequiredGroups.length < requiredGroups.length;

    // eslint-disable-next-line
  }, [selectedOptions]);
  const getSelectedOption = (option) => {
    const existedOption = selectedOptions.find(
      (selected) =>
        selected.id === option.id && selected.group_id === option.group_id
    );
    if (existedOption) {
      return existedOption;
    }
    return null;
  };

  const getOptionsFromGroupCount = (groupId) => {
    return selectedOptions
      .filter((selected) => selected.group_id === groupId)
      .reduce((count, option) => {
        return count + option.quantity;
      }, 0);
  };

  const onIncrementOption = (option) => {
    const existedOption = getSelectedOption(option);
    if (existedOption) {
      setSelectedOptions((selectedOptions) => {
        return selectedOptions.map((selected) => {
          if (
            selected.id === option.id &&
            selected.group_id === option.group_id
          ) {
            // eslint-disable-next-line no-param-reassign
            selected.quantity += 1;
          }
          return selected;
        });
      });
    } else {
      setSelectedOptions((selectedOptions) => [
        ...selectedOptions,
        { id: option.id, quantity: 1, group_id: option.group_id },
      ]);
    }
  };

  const onDecrementOption = (option) => {
    const existedOption = getSelectedOption(option);
    if (existedOption) {
      if (existedOption.quantity > 1) {
        setSelectedOptions((selectedOptions) => {
          return selectedOptions.map((selected) => {
            if (
              selected.id === option.id &&
              selected.group_id === option.group_id
            ) {
              // eslint-disable-next-line no-param-reassign
              selected.quantity -= 1;
            }
            return selected;
          });
        });
      } else {
        setSelectedOptions((selectedOptions) => {
          return selectedOptions.filter(
            (selected) =>
              selected.id !== option.id || selected.group_id !== option.group_id
          );
        });
      }
    }
  };

  const getOptionCount = (option) => {
    const existedOption = getSelectedOption(option);
    if (existedOption) {
      return existedOption.quantity;
    }
    return 0;
  };

  const getFullPrice = () => {
    return selectedOptions.reduce((price, option) => {
      const optionProduct = product.options.find(
        (productOption) => productOption.id === option.id
      );
      if (optionProduct) {
        return price + optionProduct.price * option.quantity;
      }
      const optionFromGroup = product.option_group_settings
        .find(
          (option_group_setting) =>
            option_group_setting.group.id === option.group_id
        )
        .group.options.find((groupOption) => groupOption.id === option.id);
      if (optionFromGroup) {
        return price + optionFromGroup.price * option.quantity;
      }
      return price;
    }, product.price);
  };

  const handleRadioButtonClick = (option, group) => {
    group.options.forEach((groupOption) => {
      if (groupOption.id !== option.id) onDecrementOption(groupOption);
    });
    if (
      !selectedOptions.find(
        (selectedOption) =>
          option.id === selectedOption.id &&
          option.group_id === selectedOption.group_id
      )
    )
      onIncrementOption(option);
  };

  const onSaveClick = async () => {
    await onProductAdd(product.id, selectedOptions);
    handleHide();
  };

  if (!product) {
    return null;
  }
  return (
    <Modal
      isOpen={show}
      customHeader={
        <CartOptionsHeader
          product={product}
          rightIcon="close"
          onRightIconClick={handleHide}
          title
        />
      }
    >
      <div
        data-aos="fade-up"
        data-aos-delay="400"
        className="product-options__body"
      >
        {product.option_group_settings.length !== 0 && (
          <>
            {product.option_group_settings.map((option_group_setting) => (
              <>
                <p className="product-options__list-title">{`${t(
                  'cart_product_options.title'
                )} ${option_group_setting.group.name.toLowerCase()}`}</p>
                <>
                  {option_group_setting.required && (
                    <p
                      className={`product-options__list-description ${
                        option_group_setting.max_amount > 1 &&
                        'product-options__list__description_required'
                      }`}
                    >
                      {t('cart_product_options.required_options')}
                    </p>
                  )}
                  {option_group_setting.max_amount > 1 && (
                    <p className="product-options__list-description">
                      {`${t('cart_product_options.max_options')} ${
                        option_group_setting.max_amount > 9
                          ? option_group_setting.max_amount
                          : `0${option_group_setting.max_amount}`
                      }`}
                    </p>
                  )}
                  <div className="product-options__list">
                    {option_group_setting.group.options.map((option) => {
                      Object.defineProperty(option, 'group_id', {
                        value: option_group_setting.group.id,
                        writable: false,
                      });
                      return option_group_setting.required &&
                        option_group_setting.max_amount === 1 ? (
                        <div
                          key={option_group_setting.id + option.id}
                          className="required-product-option"
                        >
                          <div>
                            <label className="container container_cart">
                              <input
                                type="radio"
                                name={option_group_setting.id}
                              />
                              <span
                                className="checkmark"
                                onClick={() =>
                                  handleRadioButtonClick(
                                    option,
                                    option_group_setting.group
                                  )
                                }
                              />
                            </label>
                          </div>
                          <div>
                            <div className="required-product-option__name">
                              <p>{option.name}</p>
                            </div>
                            <div>
                              <p>{`+${option.price} ₴`}</p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          key={option_group_setting.id + option.id}
                          className="product-option"
                        >
                          <div className="product-option__name">
                            <p>{option.name}</p>
                          </div>
                          <div className="product-option__bottom-line">
                            <div className="product-option__price">
                              <p>{`${option.price} ₴`}</p>
                            </div>
                            <Counter
                              disableIncrement={
                                getOptionsFromGroupCount(
                                  option_group_setting.group.id
                                ) === option_group_setting.max_amount
                              }
                              handleIncrement={() => onIncrementOption(option)}
                              handleDecrement={() => onDecrementOption(option)}
                              value={getOptionCount(option)}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              </>
            ))}
          </>
        )}
        {product.options.length !== 0 && (
          <>
            <p className="product-options__list-title">
              {t('options.modal_title')}
            </p>
            <div className="product-options__list">
              {product.options.map((option) => {
                Object.defineProperty(option, 'group_id', {
                  value: null,
                  writable: false,
                });
                return (
                  <div key={option.id} className="product-option">
                    <div className="product-option__name">
                      <p>{option.name}</p>
                    </div>
                    <div className="product-option__bottom-line">
                      <div className="product-option__price">
                        <p>{`${option.price} ₴`}</p>
                      </div>
                      <Counter
                        handleIncrement={() => onIncrementOption(option)}
                        handleDecrement={() => onDecrementOption(option)}
                        value={getOptionCount(option)}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}

        <div className="product-options__summary">
          <p className="product-options__summary-text">
            {t('cart_product_options.total')}
          </p>
          <p className="product-options__summary-price">{getFullPrice()} ₴</p>
        </div>

        <Button
          disabled={isSaveButtonDisabled}
          onClick={onSaveClick}
          className="product-options__save-btn"
          type="submit"
        >
          {selectedOptions.length === 0
            ? t('cart_product_options.button_without_options')
            : t('cart_product_options.button_with_options')}
        </Button>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  cart: state.cart,
});

export default connectModal({ name: MODAL_NAME, destroyOnHide: true })(
  connect(mapStateToProps)(CartProductOptionsModal)
);

CartProductOptionsModal.defaultProps = {
  restaurantId: '',
};
