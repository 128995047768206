import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Input from './common/Input';
import Icon from './common/Icon';
import DropdownMenu from './common/DropdownMenu';

// TODO refactor RestaurantMenuEditableLabel end remove from it classes restaurant__menu-list-item!
const RestaurantMenuEditableLabel = React.forwardRef(
  (
    {
      onClick,
      onApplyChanges,
      onCloseEdit,
      isShowControls,
      handleDelete,
      handleHide,
      handleMoveUp,
      handleAddProduct,
      handleMoveDown,
      handleCollapse,
      item,
      isEdit = false,
      className,
      isNoCollapsed,
      showCollapseIcon,
      isProductsInCategory,
      isSecondaryMerchantAvailable,
      handleSecondaryMerchantUse,
      currentLang,
    },
    ref
  ) => {
    const [inputValue, setInputValue] = useState(
      item[`name_${currentLang}`] ?? item.name
    );
    const [editState, toggleEdit] = useState(isEdit);
    const { t } = useTranslation();
    const dropdownRef = useRef();

    const handleEdit = () => {
      toggleEdit(true);
    };

    const handleOnClick = (e) => {
      if (!isNoCollapsed && !isProductsInCategory)
        dropdownRef.current.handleDropdownClick(e);
      onClick();
    };

    const options = [
      {
        icon: 'dropdown-plus',
        label: t('restaurant_menu_page.product_add'),
        onClick: handleAddProduct,
      },
      {
        icon: 'pen',
        label: t('restaurant_menu_page.category_edit'),
        onClick: handleEdit,
      },
      {
        icon: 'hide',
        label: t('restaurant_menu_page.category_hide'),
        onClick: handleHide,
      },
      {
        icon: 'topArrow',
        label: t('restaurant_menu_page.move_up'),
        onClick: handleMoveUp,
      },
      {
        icon: 'downArrow',
        label: t('restaurant_menu_page.move_down'),
        onClick: handleMoveDown,
      },
      {
        icon: 'delete',
        label: t('restaurant_menu_page.category_remove'),
        onClick: handleDelete,
      },
    ];

    if (isSecondaryMerchantAvailable) {
      options.push({
        icon: 'card',
        label: `${
          item.use_secondary_merchant
            ? t('control_center.settings.dont_use_additional_merchant')
            : t('control_center.settings.use_additional_merchant')
        }`,
        onClick: handleSecondaryMerchantUse,
      });
    }

    if (isShowControls) {
      return (
        <div
          ref={ref}
          onClick={(e) => !editState && handleOnClick(e)}
          className={`restaurant-menu-list-item ${className || ''}`}
        >
          {editState ? (
            <Input
              required
              className="restaurant-menu-list-item__input"
              onChange={(value) => setInputValue(value)}
              placeholder={t('restaurant_menu_page.category_name_placeholder')}
              value={inputValue}
              rightIcon="apply"
              rightIconClick={() => {
                onApplyChanges(inputValue);
                toggleEdit(false);
              }}
              rightSecondIcon="close"
              rightSecondIconClick={() => {
                if (!item[`name_${currentLang}`]) {
                  onCloseEdit();
                } else {
                  toggleEdit(false);
                  setInputValue(item[`name_${currentLang}`]);
                }
              }}
            />
          ) : (
            <>
              <p className="restaurant-menu-list-item__name">
                {item[`name_${currentLang}`] ?? item.name}
              </p>
              <div className="restaurant-menu-list-item__icons">
                {!item.is_active && (
                  <Icon
                    onClick={() => handleHide()}
                    name="hide"
                    className="restaurant-menu-list-item__icon-hide"
                  />
                )}
                {showCollapseIcon && (
                  <Icon
                    onClick={() => handleCollapse()}
                    name={`${
                      isNoCollapsed ? 'topCircleArrow' : 'downCircleArrow'
                    }`}
                    className="restaurant-menu-list-item__icon-arrow"
                  />
                )}
                <DropdownMenu ref={dropdownRef} options={options} />
              </div>
            </>
          )}
        </div>
      );
    }
    return (
      <div
        onClick={onClick}
        className="restaurant__menu-list-item restaurant-menu-list-item"
      >
        <p className="restaurant-menu-list-item__name">
          {item[`name_${currentLang}`] ?? item.name}
        </p>
      </div>
    );
  }
);

RestaurantMenuEditableLabel.defaultProps = {
  item: {},
  onClick: () => {},
  onApplyChanges: () => {},
  onCloseEdit: () => {},
  handleDelete: () => {},
  handleHide: () => {},
  handleMoveUp: () => {},
  handleMoveDown: () => {},
  isShowControls: false,
  className: '',
};

RestaurantMenuEditableLabel.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object,
  onClick: PropTypes.func,
  onApplyChanges: PropTypes.func,
  onCloseEdit: PropTypes.func,
  isShowControls: PropTypes.bool,
  handleDelete: PropTypes.func,
  handleHide: PropTypes.func,
  handleMoveUp: PropTypes.func,
  handleMoveDown: PropTypes.func,
  className: PropTypes.string,
};

export default RestaurantMenuEditableLabel;
