import React from 'react';
import { useTranslation } from 'react-i18next';
import ProductOption from '../../components/ProductOption';
import DropdownMenu from '../../components/common/DropdownMenu';

const OptionsList = ({
  selectedOption,
  handleOptionChange,
  setModalOptionsIsOpen,
}) => {
  const { t } = useTranslation();
  return (
    <div className="product__options-list">
      <div className="product__options-list__title">
        Опції
        <DropdownMenu
          options={[
            {
              label: t('options.add_option'),
              onClick: () => setModalOptionsIsOpen(true),
            },
          ]}
        />
      </div>
      {selectedOption.map((option) => (
        <ProductOption
          key={option.id}
          option={option}
          handleOptionChange={handleOptionChange}
        />
      ))}
    </div>
  );
};

export default OptionsList;
