import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { show } from 'redux-modal';
import Icon from './Icon';
import { MODAL_NAME as RESTAURANT_LANGUAGES_MODAL } from '../modals/RestaurantLanguagesModal';

const ContentLanguageSelector = ({
  className,
  options,
  value,
  isShowControls,
  show,
  label,
  use,
}) => {
  const [showed, toggleMenu] = useState(false);
  const [location, setLocation] = useState({ top: 68, left: 210 });
  const { t } = useTranslation();
  const Ref = useRef();
  // TODO fix position on resize event

  const handleOptionClick = (e, option) => {
    e.stopPropagation();
    toggleMenu(false);
    option.onClick();
  };

  const handleAddLanguageClick = () => {
    show(RESTAURANT_LANGUAGES_MODAL);
  };

  const handleOpen = () => {
    const menuTop = Ref.current.offsetTop;
    const menuLeft = Ref.current.offsetLeft;
    if (use) setLocation({ top: menuTop + 48, left: menuLeft });
    toggleMenu(!showed);
  };

  return (
    isShowControls && (
      <div
        ref={Ref}
        className={`language-block__select ${className || ''} ${
          showed ? 'language-block__select_open' : ''
        }`}
        onClick={handleOpen}
      >
        {value && (
          <div className="language-block__input">
            <div className="language-block__input-text">{`${t(
              `country_and_languages.${label}`
            )} ${value}`}</div>

            <Icon
              name={showed ? 'topArrow' : 'downArrow'}
              onClick={handleOpen}
              withHover={false}
            />
          </div>
        )}
        {showed && (
          <div
            className={`language-block__dropdown ${
              showed ? 'language-block__dropdown_open' : ''
            }`}
            style={location}
          >
            <div className="language-block__dropdown-content">
              {options.map((option) => (
                <div
                  className="language-block__option"
                  key={option.id}
                  onClick={
                    option.value === value
                      ? () => toggleMenu(false)
                      : (e) => handleOptionClick(e, option)
                  }
                >
                  {`${t('country_and_languages.menu_translation')} ${
                    option.value
                  }`}
                  {option.value === value && <Icon name="marker" />}
                </div>
              ))}
              {isShowControls && (
                <div
                  className="language-block__add-button language-block__add-button_active"
                  onClick={handleAddLanguageClick}
                >
                  <Icon name="dropdown-plus" />
                  <p className="language-block__add-button_text">
                    {t('country_and_languages.add_language')}
                  </p>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    )
  );
};

export default connect(null, {
  show,
})(ContentLanguageSelector);

ContentLanguageSelector.PropsTypes = {
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  value: PropTypes.oneOf([PropTypes.shape, PropTypes.string]),
  options: PropTypes.array,
  error: PropTypes.string,
};

ContentLanguageSelector.defaultProps = {
  onChange: () => {},
  onSelect: () => {},
  value: '',
  options: [],
  error: '',
};
