import { del, get, post, put, patch } from '../utils/axios';

const menuAPI = {
  create: async (name, restaurant) => {
    try {
      return await post('/menu/', { name, restaurant }, null, true);
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },

  update: async (data) => {
    try {
      return await put(`/menu/${data.id}/`, data, null, true);
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },

  patch: async (data) => {
    try {
      const menuId = data.id;
      // eslint-disable-next-line no-param-reassign
      delete data.id;
      return await patch(`/menu/${menuId}/`, data, null, true);
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },

  get: async (slug) => {
    try {
      return await get(`/menu/${slug}/`, null, true);
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },

  remove: async (id) => {
    try {
      return await del(`/menu/${id}/`, null, null, true);
    } catch (e) {
      console.error(e);
      const { data } = e.response;
      return Promise.reject(data);
    }
  },
};

export default menuAPI;
